import styled from 'styled-components'

import { CSSAnimation } from '../../../style/animations/css-animations'
import { getThemeValue } from '../../../style/utils/theme'

type STProps = {
  $size: number
  $fill: ThemeColor
}

export const STLoader = styled.div<STProps>`
  display: flex;
  align-items: center;
  justify-content: center;

  svg {
    ${CSSAnimation.Animation.spinner};

    ${({ $size }) => `width: ${$size}px;`}
    ${({ $size }) => `height: ${$size}px;`}

    path.svg-spinner-a {
      fill: transparent;
      stroke: transparent;
    }

    path.svg-spinner-b {
      fill: ${(props) => getThemeValue(props, props.$fill)};
      stroke: ${(props) => getThemeValue(props, props.$fill)};
    }

    path.svg-spinner-c {
      fill: transparent;
      stroke: transparent;
    }
  }
`
