import { useSelector } from 'react-redux'
import { ClassName, STUserZonePlansPurchaseLauncher } from './style'
import { StoreState } from '../../../../../models/app/model'
import { useNavigate } from 'react-router'
import { RouteDataLauncherPurchaseConfirm, Routes } from '../../../../../constants/routes'
import { UserZoneCommonBannerBack } from '../../../@-common/banner-back/component'
import { Trans } from '../../../../../components/common/intl/trans'
import { DeviceNumberSelector } from '../../../../../components/common/device-number-selector/component'
import { useDispatch } from 'react-redux'
import { useEffect } from 'react'
import { UtilsEncDec, UtilsNumber } from '@dn/utils'
import { BuyLicenseFieldMR } from '../../../../../models/buy-license/model'
import { BuyLicenseMC } from '../../../../../store/actions-mutators/buy-license/mutators'
import { Button } from '../../../../../components/common/button/component'
import { ProductButtonLauncher } from '../../../../../components/common/product-button/launcher/component'
import { SafePayments } from '../../../../../components/common/safe-payments/component'
import { useTrans } from '../../../../../hooks/use-trans'

// ~~~~~~ Component

export const UserZonePlansPurchaseLauncher = () => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const nextStepBackText = useTrans('v2.pages.order-solution.OrderTitle')

  // ~~~~~~ State

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  const subs = useSelector((state: StoreState) => state.subscriptions)

  const launcherProducts = useSelector((state: StoreState) => state.launcher)

  const buyLicense = useSelector((state: StoreState) => state.buyLicense)

  // ~~~~~~ Computed

  const isLoading = !currentUser.isSessionChecked || currentUser.uiStatus === 'running'

  const isRunning = buyLicense.uiStatus === 'running'

  const disabled = !(Number(buyLicense.quantity) > 0)

  const canGetLauncherTrial = !subs.some((sub) => sub.product_id === 'launcher')

  const launcherYearly = launcherProducts.list.find((product) => product.interval === 'year')

  // ~~~~~~ Handlers

  function onClickGoBack() {
    if (isLoading) return

    const target = canGetLauncherTrial ? Routes.UserZonePlansLauncher : Routes.UserZonePlans

    navigate(target)
  }

  function onChangeDeviceQtty(value: string) {
    const finalValue = UtilsNumber.safeStrOfNumberInt(value)

    dispatch(BuyLicenseFieldMR.quantity.MC.change(finalValue))
  }

  function onClickContinue() {
    if (!launcherYearly) return

    const nextDataObj: RouteDataLauncherPurchaseConfirm = {
      backPath: window.location.pathname,
      backText: nextStepBackText,
      qtty: Number(buyLicense.quantity),
      price: launcherYearly.price,
      productId: launcherYearly.id,
    }

    const nextData = UtilsEncDec.base64UrlEncode(JSON.stringify(nextDataObj))

    const paymentConfirmRoute = Routes.genUserZonePlansLauncherPurchaseConfirm(nextData)

    navigate(paymentConfirmRoute)
  }

  // ~~~~~~ Effects

  // - On mount clear model to buy, and set Launcher

  useEffect(() => {
    dispatch(BuyLicenseMC.clearData())

    dispatch(BuyLicenseFieldMR.productId.MC.change('launcher'))

    //
  }, [dispatch])

  // ~~~~~~ Render

  if (isLoading || !launcherYearly) return

  return (
    <STUserZonePlansPurchaseLauncher>
      {/* Banner back */}

      <UserZoneCommonBannerBack
        noImage
        backIntl={canGetLauncherTrial ? 'pages.common.AddProduct' : 'pages.common.Plans'}
        backIntlValues={{ value: 'Launcher' }}
        kind="launcher"
        onClickGoBack={onClickGoBack}
      />

      {/* Content */}

      <div className={`${ClassName}--content`}>
        {/* Title */}

        <div className={`${ClassName}--content--title`}>
          <Trans id="v2.pages.order-solution.OrderTitle" />
        </div>

        {/* Subtitle */}

        <div className={`${ClassName}--content--subtitle`}>
          <Trans id="v2.pages.order-solution.order-sub-title.Launcher" />
        </div>

        {/* Num of devices (license qtty) */}

        <div className={`${ClassName}--content--device-selector`}>
          <DeviceNumberSelector
            product="Launcher"
            price={launcherYearly.price}
            qtty={buyLicense.quantity}
            onChange={onChangeDeviceQtty}
          />
        </div>

        {/* Action */}

        <div className={`${ClassName}--content--actions`}>
          {/* Continue to payment */}

          <ProductButtonLauncher
            className={`${ClassName}--content--actions--action first`}
            intlId="pages.purchase-flow.common.ProceedToPayment"
            running={isRunning}
            disabled={disabled || isRunning}
            active={!disabled && !isRunning}
            onClick={onClickContinue}
          />

          {/* Cancel */}

          <Button
            colorType="default"
            intlId="common.Cancel"
            disabled={false}
            running={false}
            active={true}
            onClick={onClickGoBack}
          />
        </div>

        {/* Safe Payments */}

        <div className={`${ClassName}--safe-pay`}>
          <SafePayments />
        </div>
      </div>

      {/* - */}
    </STUserZonePlansPurchaseLauncher>
  )
}
