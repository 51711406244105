import styled from 'styled-components'

import { Theme } from '../../../../style/theme'
import { Colors } from '../../../../style/theme/colors'
import { theme } from '../../../../style/utils/theme'

export const STInfosList = styled.div`
  padding-top: 4px;

  display: flex;
  align-items: center;

  ${Theme.Fonts.Scale.OldE};
  color: ${theme(Colors.BROOKLYN_80_80)};

  .info-error-icon {
    margin-right: 4px;

    svg {
      width: 24px;

      path {
        fill: ${theme(Colors.BROOKLYN_80_80)};
      }
    }
  }
`
