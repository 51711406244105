import 'react-app-polyfill/stable'

import { compose } from 'redux'
import { createRoot } from 'react-dom/client'
import { fromEvent, take } from 'rxjs'
import TagManager, { TagManagerArgs } from 'react-gtm-module'

import { HocAuth0, HocIntl, HocRouter, HocStore, HocTheme } from '@dn/hocs'
import { ConstEventsWindow } from '@dn/constants'

import { App } from './components/app'

import { GetMessagesErr, getMessages } from './intl/get-messages'
import { Config } from './config'
import { AppInitState } from './models/app/model'
import { getStore } from './store/store-config'
import { GlobalStyle } from './style/global-style'

// NOTE: As this file is copied in src folder as index.tsx
// paths should be relatives to this file
import './style/fonts/fonts.css'

// Third parties
import './third-parties/leaddyno/library'
import 'react-loading-skeleton/dist/skeleton.css'

// Third parties
// import { DNSentry } from './third-parties/sentry/library'

// ~~~~~~ Helpers

const renderApp = (ProvidedApp: () => JSX.Element) => {
  const container = document.getElementById('root') as HTMLDivElement

  const root = createRoot(container)

  root.render(<ProvidedApp />)
}

// ~~~~~~ Google GTM

const tagManagerArgs: TagManagerArgs = {
  gtmId: Config.ThirdParty.Google.GTM.Id,
  preview: Config.ThirdParty.Google.GTM.Env,
  auth: Config.ThirdParty.Google.GTM.Auth,
}

TagManager.initialize(tagManagerArgs)

// ~~~~~~~ Provide App Promise

const AppPromise = getMessages()
  .then(({ locale, messages }) => {
    const ProvidedApp = compose<typeof App>(
      HocStore.genProvider(getStore()),
      HocTheme.genProvider(AppInitState, GlobalStyle),
      HocIntl.genProvider(Config.Intl.SupportedLocales, AppInitState, locale, messages),
      HocRouter.withProvider,
      HocAuth0.genProvider(Config.ThirdParty.Auth0),
    )(App)

    return ProvidedApp
  })
  .catch((err: GetMessagesErr) => {
    return Promise.reject()
  })

// ~~~~~~ Init React App

fromEvent(window, ConstEventsWindow.Load)
  .pipe(take(1))
  .subscribe({
    next: () => {
      AppPromise.then((ProvidedApp) => renderApp(ProvidedApp)).catch(() => undefined)
    },
  })
