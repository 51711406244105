import Skeleton from 'react-loading-skeleton'
import { ClassName, STUserZonePlansRootLoading } from './style'
import { Trans } from '../../../../../components/common/intl/trans'

// ~~~~~~ Component

export const UserZonePlansRootLoading = () => {
  // ~~~~~~ Render

  return (
    <STUserZonePlansRootLoading>
      {/* Title block */}

      <div className={`${ClassName}--title`}>
        <Trans id="pages.common.Plans" />
      </div>

      {/* Cards block */}

      <div className={`${ClassName}--block-2`}>
        <div className={`${ClassName}--block-2--card`}>
          <Skeleton style={{ height: '100%' }} />
        </div>

        <div className={`${ClassName}--block-2--card`}>
          <Skeleton style={{ height: '100%' }} />
        </div>

        <div className={`${ClassName}--block-2--card`}>
          <Skeleton style={{ height: '100%' }} />
        </div>
      </div>
    </STUserZonePlansRootLoading>
  )
}
