import { ProductIds } from '../../../../constants/product-ids'
import { AppInitState } from '../../../../models/app/model'
import { dynReducer } from '../../../../utils/store'
import { ApiStoreProductsAT, ApiStoreProductsAC } from '../../../actions/api-store/products/actions'
import {
  ApiStoreProductsEpicMC,
  ApiStoreProductsEpicMT,
} from '../../../epics/api-store/products/mutators'

const initState = AppInitState.broadcastSub

type State = typeof initState

const reducer: any = {}

const ProductId: DN.Models.Product.ProductId = ProductIds.BroadcastSub

// ####################################################################################################
// ~~~~~~ Action to call api list prodcuts
// ####################################################################################################

reducer[ApiStoreProductsAT.LIST_PRODUCTS] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreProductsAC.listProducts>,
): State => {
  if (payload.productId !== ProductId) return state

  return {
    ...initState,
    uiStatus: 'running',
  }
}

// ####################################################################################################
// ~~~~~~ Api List prodcuts ok
// ####################################################################################################

reducer[ApiStoreProductsEpicMT.OK_BROADCAST_SUB] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreProductsEpicMC.okBroadcastProducts>,
): State => {
  return {
    ...initState,
    uiStatus: 'completed',
    products: payload.products,
  }
}

// ####################################################################################################
// ~~~~~~ Api list prodcuts error
// ####################################################################################################

reducer[ApiStoreProductsEpicMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreProductsEpicMC.error>,
): State => {
  if (payload.productId !== ProductId) return state

  return {
    ...state,
    uiStatus: 'init',
    errors: payload.errors,
  }
}

// ~~~~~~

export const BroadcastReducer = dynReducer(initState, reducer)
