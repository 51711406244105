import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'

import { ClassName, STProductPageBcastContent } from './style'
import { MediaQuery } from '../../../../style/constants/media-queries'
import { Button } from '../../../../components/common/button/component'
import { Routes } from '../../../../constants/routes'
import { LazySVG } from '../../../../components/svgs/lazy-svg/component'
import { Trans } from '../../../../components/common/intl/trans'
import { Config } from '../../../../config'
import { StoreState } from '../../../../models/app/model'
import { useListProduct } from '../../../../hooks/api/use-list-product'
import { ProductIds } from '../../../../constants/product-ids'
import { ProductPageBcastContentSubCard } from './subscribe-card/component'
import { ProductButtonBcast } from '../../../../components/common/product-button/bcast/component'

import backgroundSM from '../../../../imgs/pngs/broadcast-landing-background-sm.png'
import backgroundMD from '../../../../imgs/pngs/broadcast-landing-background-md.png'
import backgroundLG from '../../../../imgs/pngs/broadcast-landing-background.png'
import background2x from '../../../../imgs/pngs/broadcast-landing-background.png'

// ~~~~~~ Constants

const ArrowLeftIcon = LazySVG('icons/arrow-long-left')

const ArrowRightUpIcon = LazySVG('icons/arrow-right-up')

const SupportIcon = LazySVG('icons/support-circle')

const GradientLogo = LazySVG('logos/broadcast-gradient')

// ~~~~~~ Component

export const ProductPageBcastContent = () => {
  // ~~~~~~ Hooks

  useListProduct(ProductIds.BroadcastSub)

  const navigate = useNavigate()

  // ~~~~~ State

  const metaProducts = useSelector((state: StoreState) => state.broadcastSub)

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  // ~~~~~~ Computed

  const isRunning =
    !currentUser.isSessionChecked ||
    metaProducts.uiStatus === 'running' ||
    metaProducts.uiStatus === 'init'

  const premiumMonthPrice = metaProducts.products.premium.month.price

  const premiumYearPrice = metaProducts.products.premium.year.price

  // ~~~~~~ Handlers

  function onClickGoToMain() {
    navigate(Routes.Root)
  }

  function onClickSub2Free() {
    navigate(Routes.BcastSub2Free)
  }

  function onClickSub2Premium(interval: 'yearly' | 'monthly') {
    navigate(Routes.genBcastSub2Premium(interval))
  }

  function onClickGetInTouch() {
    window.open(Config.Links.LetUsKnow, '_blank')
  }

  // ~~~~~~ Render

  return (
    <STProductPageBcastContent>
      {/* Info + Sub to Free */}

      <div className={`${ClassName}--top`}>
        {/* Background */}

        <picture className={`${ClassName}--top--bg-pic`}>
          <source media={`(max-width: ${MediaQuery.SM})`} srcSet={backgroundSM} />
          <source media={`(max-width: ${MediaQuery.MD})`} srcSet={backgroundMD} />
          <img srcSet={`${backgroundLG}, ${background2x} 2x`} alt="broadcast" />
        </picture>

        {/* Info */}

        <div className={`${ClassName}--top--left`}>
          {/* Back */}

          <div className={`${ClassName}--top--left--back`}>
            <Button
              className={`${ClassName}--top--left--back--action`}
              colorType="only-link"
              width="unset"
              padding="0"
              intlId="pages.products.common.GoToMain"
              active={true}
              disabled={false}
              running={false}
              onClick={onClickGoToMain}
              IconLeft={ArrowLeftIcon}
            />
          </div>

          <div style={{ margin: 'auto', maxWidth: 500 }}>
            {/* Product logo */}

            <div className={`${ClassName}--top--left--logo`}>
              <GradientLogo size={32} className={`${ClassName}--top--left--logo--img`} />
            </div>

            {/* Title */}

            <div className={`${ClassName}--top--left--title`}>
              <Trans id="pages.products.broadcast.Title" />
            </div>

            {/* Info list */}

            <div className={`${ClassName}--top--left--info-list`}>
              {/* Title */}

              <div className={`${ClassName}--top--left--info-list--title`}>
                <Trans id="pages.products.common.WhatToKnow" />
              </div>

              {/* Points */}

              <ul className={`${ClassName}--top--left--info-list--points`}>
                {/* 40 Viewers... */}

                <div className={`${ClassName}--top--left--info-list--points--point`}>
                  <span className={`${ClassName}--top--left--info-list--points--point--bullet`}>
                    •
                  </span>
                  <Trans id="pages.products.broadcast.InviteViewer" />
                </div>

                {/* X days... */}

                <div className={`${ClassName}--top--left--info-list--points--point`}>
                  <span className={`${ClassName}--top--left--info-list--points--point--bullet`}>
                    •
                  </span>
                  <Trans
                    id="pages.products.broadcast.UnlimitedBroadcastFreeFullVersion"
                    values={{ value: Config.Trials.BroadcastWeb.days }}
                  />
                </div>

                {/* Daily limit... */}

                <div className={`${ClassName}--top--left--info-list--points--point`}>
                  <span className={`${ClassName}--top--left--info-list--points--point--bullet`}>
                    •
                  </span>

                  <Trans id="pages.products.broadcast.DailyLimitFreeFullVersion" />
                </div>
              </ul>
            </div>

            {/* Action Trial */}

            <ProductButtonBcast
              intlId="pages.products.common.StartTrial"
              active={!isRunning}
              disabled={isRunning}
              running={isRunning}
              onClick={onClickSub2Free}
            />
          </div>
        </div>

        {/* Right Space */}

        <div className={`${ClassName}--top--right`} />

        {/* - */}
      </div>

      {/* Sub to Premium (month/year) */}

      <div className={`${ClassName}--bottom`}>
        {/* Title */}

        <div className={`${ClassName}--bottom--title`}>
          <Trans id="pages.products.broadcast.BuyRemoveLimit" />
        </div>

        {/* Sub cards */}

        <div className={`${ClassName}--bottom--sub-cards`}>
          {/* Monthly  */}

          <ProductPageBcastContentSubCard
            isRunning={isRunning}
            isMonthly={true}
            price={premiumMonthPrice}
            onClick={() => onClickSub2Premium('monthly')}
          />

          {/* Space */}

          <div className={`${ClassName}--bottom--sub-cards--space`} />

          {/* Yearly */}

          <ProductPageBcastContentSubCard
            isRunning={isRunning}
            isMonthly={false}
            price={premiumYearPrice}
            onClick={() => onClickSub2Premium('yearly')}
          />
        </div>

        {/* Get in touch */}

        <div className={`${ClassName}--bottom--get-in-touch`}>
          <div className={`${ClassName}--bottom--get-in-touch--left`}>
            <div className={`${ClassName}--bottom--get-in-touch--left--left`}>
              <SupportIcon size={56} />
            </div>

            <div className={`${ClassName}--bottom--get-in-touch--left--right`}>
              <div className={`${ClassName}--bottom--get-in-touch--left--right--title`}>
                <Trans id="pages.products.broadcast.TellUsNeed" />
              </div>

              <div className={`${ClassName}--bottom--get-in-touch--left--right--subtitle`}>
                <Trans id="pages.products.broadcast.InputHelp" />
              </div>
            </div>
          </div>

          <div className={`${ClassName}--bottom--get-in-touch--right`}>
            <Button
              className={`${ClassName}--bottom-get-in-touch--right--action`}
              colorType="primary-link"
              intlId="pages.products.broadcast.GetInTouch"
              IconRight={ArrowRightUpIcon}
              running={false}
              disabled={false}
              active={true}
              onClick={onClickGetInTouch}
            />
          </div>
        </div>
      </div>

      {/* - */}
    </STProductPageBcastContent>
  )
}
