import { Observable, of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { catchError, map } from 'rxjs/operators'
import { ApiRoutes } from '../constants/routes'
import { ApiHeadersUtils } from '../utils/headers'

type Req = DN.Models.Subscription.Api.ChangePaymentMethod.Req
type Res = DN.Models.Subscription.Api.ChangePaymentMethod.Res
type ResParsed = DNApiResParsed<DN.Models.Subscription.Api.ChangePaymentMethod.ResParsed>
type ResError = DNApiError<DN.Models.Subscription.Api.ChangePaymentMethod.ResError>

const { ChangePaymentMethod } = ApiRoutes.Subscriptions

export const apiStoreSubscriptionsChangePaymentMethod$ = (
  token: string,
  subscriptionId: string,
  paymentMethodId: string,
  headers?: AppHeaders,
) => {
  const body: Req = {
    payment_method_id: paymentMethodId,
  }

  return ajax<Res>({
    ...ApiHeadersUtils.genHeadersWithAuth(token, headers),
    method: ChangePaymentMethod.Method,
    url: ChangePaymentMethod.getUrl(subscriptionId),
    body,
  }).pipe(
    map((res): ResParsed => {
      return {
        status: res.status,
        response: {
          subscriptionId,
          paymentMethodId,
        },
      }
    }),
    catchError(
      (res: DNApiRes<void>): Observable<ResError> =>
        of({
          status: res.status,
          response: {
            errors: [{ id: 'api.subscriptions.change-payment-method.Error' }],
            subscriptionId,
            paymentMethodId,
          },
        }),
    ),
  )
}
