const Jaro = {
  80: '#2F9E44',
  70: '#37B24D',
  60: '#40C057',
  50: '#51CF66',
  40: '#69DB7C',
  30: '#8CE99A',
  20: '#B2F2BB',
  10: '#D3F9D8',
  5: '#EBFBEE',
} as const

const JaroRGBA = {
  10: (alpha: number) => `rgba(211, 249, 216, ${alpha})`,
}

// ~~~~~~

const JARO_10_10: ThemeColor = {
  light: Jaro[10],
  dark: Jaro[10],
} as const

const JARO_50_50: ThemeColor = {
  light: Jaro[50],
  dark: Jaro[50],
} as const

const JARO_50_30: ThemeColor = {
  light: Jaro[50],
  dark: Jaro[30],
} as const

const JARO_RGBA_10_10 = (alpha: number) =>
  ({
    light: JaroRGBA[10](alpha / 100),
    dark: JaroRGBA[10](alpha / 100),
  }) as const

// ~~~~~~

export const JaroColors = {
  JARO_10_10,
  JARO_50_50,
  JARO_50_30,

  JARO_RGBA_10_10,
} as const
