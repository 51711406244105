import { styled } from 'styled-components'
import { Colors } from '../../../style/theme/colors'
import { Fonts } from '../../../style/theme/fonts'
import { theme } from '../../../style/utils/theme'
import { Transition } from '../../../style/animations/css-animations'
import { MediaQuery } from '../../../style/constants/media-queries'

// ~~~~~~ Constants

export const ClassName = 'platform-card'

// ~~~~~~ Component

export const STPlatformCard = styled.div`
  cursor: pointer;

  ${Fonts.Weight[700]}
  ${Fonts.Scale.Title}

  width: 286px;
  height: 144px;

  border-radius: 6px;

  ${Transition.ColorBorderAndBackgroundFaster}

  border: solid 1px ${theme(Colors.WOLF_40_40)};
  background-color: ${theme(Colors.WOLF_05_05)};

  display: flex;
  align-items: center;
  justify-content: center;

  .${ClassName}--logo {
    margin-right: 24px;

    svg {
      path {
        ${Transition.FillFaster}
      }
    }

    &.windows {
      svg {
        width: 58px;

        path {
          fill: ${theme(Colors.WOLF_40_40)};
        }
      }
    }

    &.android {
      svg {
        width: 64px;

        path.svg-main {
          fill: ${theme(Colors.WOLF_40_40)};
        }
      }
    }
  }

  &:hover {
    border-color: ${theme(Colors.BRONX_60_60)};

    background-color: ${theme(Colors.BRONX_05_05)};

    .${ClassName}--logo {
      &.windows {
        svg {
          path {
            fill: ${theme(Colors.BRONX_60_60)};
          }
        }
      }

      &.android {
        svg {
          path.svg-main {
            fill: ${theme(Colors.BRONX_60_60)};
          }
        }
      }
    }
  }

  &.selected {
    border-color: ${theme(Colors.BRONX_60_60)};

    background-color: ${theme(Colors.BRONX_05_05)};

    .${ClassName}--logo {
      &.windows {
        svg {
          path {
            fill: ${theme(Colors.BRONX_60_60)};
          }
        }
      }

      &.android {
        svg {
          path.svg-main {
            fill: ${theme(Colors.BRONX_60_60)};
          }
        }
      }
    }
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    width: 100%;
  }
`
