import styled from 'styled-components'
import { Fonts } from '../../../../style/theme/fonts'
import { MediaQuery } from '../../../../style/constants/media-queries'
import { Layout } from '../../../../style/theme/layout'

// ~~~~~~ Constants

export const ClassName = 'user-zone-plans-root'

// ~~~~~~ Component

export const STUserZonePlansRoot = styled.div`
  padding-top: 16px;

  .${ClassName}--content {
    ${Layout.PaddingWidth.Default}

    .${ClassName}--content--title {
      ${Fonts.Weight[800]}
      ${Fonts.Scale.Headline2}

      margin-bottom: 16px;
    }

    .${ClassName}--content--subs {
      margin-bottom: 32px;

      .${ClassName}--content--subs--sub {
        margin-bottom: 16px;
      }
    }

    .${ClassName}--content--title-2 {
      ${Fonts.Weight[800]}
      ${Fonts.Scale.Title}

      margin-bottom: 8px;
    }

    .${ClassName}--content--subtitle-2 {
      ${Fonts.Weight[400]}
      ${Fonts.Scale.BodyBig}

      margin-bottom: 16px;
    }
  }

  /* 1280 */

  @media (max-width: ${MediaQuery.MaxWidth2k}) {
    .${ClassName}--content {
      ${Layout.PaddingWidth.Desktop}
    }
  }

  /* 960 */

  @media (max-width: ${MediaQuery.MD}) {
    .${ClassName}--content {
      ${Layout.PaddingWidth.Tablet}
    }
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    .${ClassName}--content {
      ${Layout.PaddingWidth.Mobile}
    }
  }
`
