import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import Scrollbars from 'react-custom-scrollbars'

import { LayoutTopContent } from '../../../components/layouts/top-content/component'
import { LayoutTopContentContent } from '../../../components/layouts/top-content/parts/content/component'
import { LayoutTopContentTopLeft } from '../../../components/layouts/top-content/parts/top/left/component'
import { PageCommonOPTopLeft } from '../../@-common/obtaining-products/top/left/component'
import { Routes } from '../../../constants/routes'
import { STPagePurchaseLauncher } from './style'
import { PagePurchaseLauncherContent } from './content/component'
import { StoreState } from '../../../models/app/model'

// ~~~~~~ Component

export const PagePurchaseLauncher = () => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  // ~~~~~~ State

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  // ~~~~~~ Computed

  const goToUserZone = currentUser.uiStatus === 'running' ? false : !!currentUser.email

  // ~~~~~~ Handlers

  function onClickGoBack() {
    navigate(Routes.Launcher)
  }

  // ~~~~~~ Effects

  useEffect(() => {
    if (!goToUserZone) return

    navigate(Routes.UserZone)

    //
  }, [goToUserZone, navigate])

  // ~~~~~~ Render

  return (
    <STPagePurchaseLauncher>
      <Scrollbars>
        <LayoutTopContent>
          {/* Top */}

          {/* - Left */}

          <LayoutTopContentTopLeft>
            <PageCommonOPTopLeft onClickGoBack={onClickGoBack} />
          </LayoutTopContentTopLeft>

          {/* Content */}

          <LayoutTopContentContent>
            <PagePurchaseLauncherContent />
          </LayoutTopContentContent>

          {/* - */}
        </LayoutTopContent>
      </Scrollbars>
    </STPagePurchaseLauncher>
  )
}
