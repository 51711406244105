const prefix = 'mt-checkout-session'

// ~~~~~~ Mutators Types

export const CheckoutSessionMT = {
  SET_SESSION: `${prefix}-set-session`,
} as const

// ~~~~~~ Mutators Creators

type SessionData = {
  price: number
  quantity: number
  email: string
  product_id: DN.Models.Product.ProductId
  trial_period_days: number | null
  is_free: boolean
}

export const CheckoutSessionMC = {
  setSession: (data: SessionData) => ({
    type: CheckoutSessionMT.SET_SESSION,
    payload: data,
  }),
} as const
