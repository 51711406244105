import { HooksData } from '@dn/hooks'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'

import { LazySVG } from '../../../../../components/svgs/lazy-svg/component'
import { ClassName, STPagePurchaseBcastContentStep4Done } from './style'
import { StoreState } from '../../../../../models/app/model'
import { Trans } from '../../../../../components/common/intl/trans'
import { ProductButtonBcast } from '../../../../../components/common/product-button/bcast/component'
import { Config } from '../../../../../config'
import { LinkCard } from '../../../../../components/common/link-card/component'
import { useCustomAuth } from '../../../../../hooks/auth/use-custom-auth'
import { Routes } from '../../../../../constants/routes'
import { PagePurchaseCommonTabSteps } from '../../../@-common/tab-steps/component'

// ~~~~~~ Constants

const Logo = LazySVG('logos/broadcast-gradient')

const TitleIcon = LazySVG('icons/party')

const BcastInActionIcon = LazySVG('icons/videos')

const ManageAccountIcon = LazySVG('icons/user-circle-filled')

const SupportIcon = LazySVG('icons/support-call')

// ~~~~~~ Component

export const PagePurchaseBcastContentStep4Done = () => {
  // ~~~~~~ Hooks

  const { login } = useCustomAuth()

  const navigate = useNavigate()

  // ~~~~~~ State

  const currentUserEmail = useSelector((state: StoreState) => state.currentUser.email)

  // ~~~~~~ Computed

  const prevCurUserEmail = HooksData.PrevValue.useHook(currentUserEmail)

  // ~~~~~~ Handlers

  function onClickAction() {
    window.open(Config.BroadcastWeb.Url, '_blank')
  }

  function onClickShowInAction() {
    window.open(Config.Links.BroadcastInAction, '_blank')
  }

  function onClickManageAccount() {
    login()
  }

  function onClickSupport() {
    window.open(Config.Links.BroadcastSupport, '_blank')
  }

  // ~~~~~~ Effects

  // - From not-logged to logged: Redirect to user-zone

  useEffect(() => {
    if (prevCurUserEmail || !currentUserEmail) return

    navigate(Routes.UserZone)

    //
  }, [currentUserEmail, navigate, prevCurUserEmail])

  // ~~~~~~ Render

  return (
    <STPagePurchaseBcastContentStep4Done>
      {/* Steps */}

      <div className={`${ClassName}--steps`}>
        <PagePurchaseCommonTabSteps kind="subscription" />
      </div>

      {/* Logo */}

      <div className={`${ClassName}--logo`}>
        <Logo size={32} />
      </div>

      {/* Title */}

      <div className={`${ClassName}--title`}>
        <Trans id="pages.purchase-flow.account-ready-step.AccountCreated" />

        <span className={`${ClassName}--title--icon`}>
          <TitleIcon size={56} />
        </span>
      </div>

      {/* Subtitle */}

      <div className={`${ClassName}--subtitle`}>
        <Trans id="v2.pages.plan-details.new-subscription-description.broadcast.premium-broadcast-description.TimeToStartBroadcast" />
      </div>

      {/* Action */}

      <div className={`${ClassName}--action`}>
        <ProductButtonBcast
          intlId="common.StartBroadcasting"
          running={false}
          disabled={false}
          active={true}
          onClick={onClickAction}
        />
      </div>

      {/* Separator */}

      <hr />

      {/* Links */}

      <div className={`${ClassName}--links`}>
        <div className={`${ClassName}--links--title`}>
          <Trans id="pages.purchase-flow.account-ready-step.OtherLinksUseFul" />
        </div>

        {/* Bcast in Action */}

        <div className={`${ClassName}--links--link`}>
          <LinkCard
            Icon={BcastInActionIcon}
            intlId="pages.common.ProductInAction"
            intlValues={{ value: 'Broadcast' }}
            onClick={onClickShowInAction}
          />
        </div>

        {/* Manage Account */}

        <div className={`${ClassName}--links--link`}>
          <LinkCard
            Icon={ManageAccountIcon}
            intlId="pages.purchase-flow.account-ready-step.ManageAccount"
            onClick={onClickManageAccount}
          />
        </div>

        {/* Support Center */}

        <div className={`${ClassName}--links--link`}>
          <LinkCard
            Icon={SupportIcon}
            intlId="pages.purchase-flow.account-ready-step.CheckSupport"
            onClick={onClickSupport}
          />
        </div>
      </div>

      {/* - */}
    </STPagePurchaseBcastContentStep4Done>
  )
}
