const prefix = 'mt-intl'

export const IntlMT = {
  CHANGE_LANG: `${prefix}-change-lang`,
} as const

export const IntlMC = {
  changeLang: (lang: Lang, messages: Messages) => ({
    type: IntlMT.CHANGE_LANG,
    payload: {
      lang,
      messages,
    },
  }),
}
