import { dynReducer } from '../../../utils/store'
import { AppInitState } from '../../../models/app/model'
import { ApiLauncherReleaseInfoAT } from '../../actions/api-launcher/release-info/action'
import {
  EpicApiLauncherReleaseInfoMC,
  EpicApiLauncherReleaseInfoMT,
} from '../../epics/api-launcher/release-info/get/mutators'

const initState = AppInitState.launcherReleaseInfo

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ Action to start calling release api
// ####################################################################################################

reducer[ApiLauncherReleaseInfoAT.GET] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'running',
  }
}

// ####################################################################################################
// ~~~~~~ Api montage release info ok
// ####################################################################################################

reducer[EpicApiLauncherReleaseInfoMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiLauncherReleaseInfoMC.ok>,
): State => {
  return {
    ...state,
    uiStatus: 'completed',
    ...payload,
  }
}

// ####################################################################################################
// ~~~~~~ Show - ERROR
// ####################################################################################################

reducer[EpicApiLauncherReleaseInfoMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiLauncherReleaseInfoMC.error>,
): State => {
  return {
    ...state,
    uiStatus: 'init',
    errors: payload.errors,
  }
}

// ~~~~~~

export const launcherReleaseInfoReducer = dynReducer(initState, reducer)
