import { useNavigate } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'
import { useEffect, useState } from 'react'
import { HooksData } from '@dn/hooks'
import { UtilsEncDec } from '@dn/utils'

import {
  RouteDataBcastPurchaseConfirm,
  RouteDataChangePaymentMethod,
  Routes,
} from '../../../../../constants/routes'
import { useUserZoneDecDataParam } from '../../../../../hooks/user-zone-dec-data-param/hook'
import { UserZoneCommonBannerBack } from '../../../@-common/banner-back/component'
import { ClassName, STUserZonePlansPurchaseConfirmationBcastUpg2Premium } from './style'
import { Trans } from '../../../../../components/common/intl/trans'
import { StoreState } from '../../../../../models/app/model'
import { CreditCard } from '../../../../../components/common/credit-card/component'
import { LazySVG } from '../../../../../components/svgs/lazy-svg/component'
import { Button } from '../../../../../components/common/button/component'
import { useTrans } from '../../../../../hooks/use-trans'
import { DangerTrans } from '../../../../../components/common/intl/danger-trans'
import { Dollars } from '../../../../../components/common/dollars/component'
import { ProductButtonBcast } from '../../../../../components/common/product-button/bcast/component'
import { ApiStoreSubscriptionsAC } from '../../../../../store/actions/api-store/subscriptions/actions'
import { Config } from '../../../../../config'

/**
 * This is the card selector for the sub after select payment interval (month or year)
 * It permmits select a card or create a new one
 * Is only visible if the sub have a card (normal is to have the default card)
 * If there are cards but not a default one, it should not be here
 */

// ~~~~~~ Constants

const ChangeCardIcon = LazySVG('icons/pencil')

const SubscribeIcon = LazySVG('icons/lock')

// ~~~~~~ Component

export const UserZonePlansPurchaseConfirmBcastUpg2Premium = () => {
  // ~~~~~~ Hooks

  const data = useUserZoneDecDataParam<RouteDataBcastPurchaseConfirm>()

  const navigate = useNavigate()

  const chCardBackText = useTrans('v2.pages.upgrade-plan.payment-details.PaymentDetails')

  const intervalText = (
    useTrans(
      data ? (data.interval === 'monthly' ? 'common.Month' : 'common.Year') : 'wildcard',
    ) as string
  ).toLowerCase()

  const dispatch = useDispatch()

  // ~~~~~~ State

  // - Local

  const [card, setCard] = useState<DN.Models.Card.ModelFront | undefined>()

  // - Store

  const broadcastSub = useSelector((state: StoreState) => state.broadcastSub)

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  const cards = useSelector((state: StoreState) => state.cards)

  const subs = useSelector((state: StoreState) => state.subscriptions)

  // ~~~~~~ Computed

  const isLoading = !currentUser.isSessionChecked || currentUser.uiStatus === 'running'

  const sub = data ? subs.find((sub) => sub.subscription_id === data.subId) : undefined

  const isRunning = sub ? sub.uiStatus === 'running' : false

  const monthlyPrice = broadcastSub.products.premium.month.price

  const yearlyPrice = broadcastSub.products.premium.year.price

  const price = data ? (data.interval === 'monthly' ? monthlyPrice : yearlyPrice) : 0

  const prevPriceId = HooksData.PrevValue.useHook(sub ? sub.price_id : undefined)

  // ~~~~~~ Handlers

  function onClickGoBack() {
    if (!data) return

    navigate(data.backPath)
  }

  function onClickChangeCard() {
    if (!data) return

    const nextDataObj: RouteDataChangePaymentMethod = {
      backPath: window.location.pathname,
      backText: chCardBackText,
      successPath: window.location.pathname,
      subId: data.subId,
    }

    const nextData = UtilsEncDec.base64UrlEncode(JSON.stringify(nextDataObj))

    const changeCardRoute = Routes.genUserZonePlansChangePaymentMethod(nextData)

    navigate(changeCardRoute)
  }

  function onClickSubscribe() {
    if (!data || !sub) return

    const priceId =
      data.interval === 'monthly'
        ? broadcastSub.products.premium.month.id
        : broadcastSub.products.premium.year.id

    dispatch(ApiStoreSubscriptionsAC.changePlan('upgrade', sub.subscription_id, priceId))
  }

  // ~~~~~~ Effects

  // - On update, return to plans

  useEffect(() => {
    if (prevPriceId === undefined || !sub || prevPriceId === sub.price_id) {
      return
    }

    navigate(Routes.UserZone)

    //
  }, [navigate, prevPriceId, sub])

  // - Set the sub card or return
  //   user can only be here if sub have a card

  useEffect(() => {
    if (!data) {
      navigate(Routes.UserZonePlans)

      return
    }

    if (isLoading || card) return

    const sub = subs.find((sub) => sub.subscription_id === data.subId)

    if (!sub || !sub.payment_method_id) {
      navigate(Routes.UserZonePlans)

      return
    }

    const subCard = cards.list.find((card) => card.payment_method_id === sub.payment_method_id)

    if (!subCard) {
      navigate(Routes.UserZonePlans)

      return
    }

    setCard(subCard)
    //
  }, [card, cards.list, data, isLoading, navigate, subs])

  // ~~~~~~ Render

  if (!data || !card || !sub) return null

  return (
    <STUserZonePlansPurchaseConfirmationBcastUpg2Premium>
      {/* Banner back */}

      <UserZoneCommonBannerBack
        noImage
        kind="bcast"
        backIntl="wildcard"
        backIntlValues={{ value: data.backText }}
        onClickGoBack={onClickGoBack}
      />

      {/* Content */}

      <div className={`${ClassName}--content`}>
        {/* Title */}

        <div className={`${ClassName}--content--title`}>
          <Trans id="v2.pages.upgrade-plan.payment-details.PaymentDetails" />
        </div>

        {/* Subtitle */}

        <div className={`${ClassName}--content--subtitle`}>
          {data.interval === 'monthly' ? (
            <Trans id="v2.pages.upgrade-plan.payment-details.PaymentDetailsDescriptionMonthly" />
          ) : (
            <Trans id="v2.pages.upgrade-plan.payment-details.PaymentDetailsDescriptionYearly" />
          )}
        </div>

        {/* Payment method */}

        <div className={`${ClassName}--content--payment-method`}>
          {/* Title */}

          <div className={`${ClassName}--content--payment-method--title`}>
            <Trans id="pages.common.PaymentMethod" />
          </div>

          {/* Current card for pay the product */}

          <div className={`${ClassName}--content--payment-method--card`}>
            {/* Card logo-last4 */}

            <div className={`${ClassName}--content--payment-method--card--last4`}>
              <CreditCard card={card} />
            </div>

            {/* Action: change card */}

            <div className={`${ClassName}--content--payment--method--card--change`}>
              <Button
                colorType="quaternary-link"
                intlId="pages.common.ChangePayment"
                IconLeft={ChangeCardIcon}
                leftIconMarginRight={4}
                size="s"
                fontWeight="600"
                running={false}
                disabled={false}
                active={true}
                onClick={onClickChangeCard}
              />
            </div>
          </div>
        </div>

        {/* Price info */}

        <div className={`${ClassName}--content--price-info`}>
          <div className={`${ClassName}--content--price-info--text`}>
            <DangerTrans
              template="Due Today <c>(then every {value})</c>"
              id="v2.pages.upgrade-plan.payment-details.DueToday"
              values={{
                value: intervalText,
                c: (chunks: string) => (
                  <span className={`${ClassName}--content--price-info--text--interval`}>
                    {chunks}
                  </span>
                ),
              }}
            />
          </div>

          <div className={`${ClassName}--content--price-info--value`}>
            <Dollars value={price} />
          </div>
        </div>

        {/* Space */}

        <hr className={`${ClassName}--content--separator`} />

        {/* Action */}

        <div className={`${ClassName}--content--actions`}>
          {/* Subscribe */}

          <ProductButtonBcast
            className={`${ClassName}--content--actions--action first`}
            intlId="v2.pages.upgrade-plan.payment-details.Subscribe"
            IconRight={undefined}
            IconLeft={SubscribeIcon}
            leftIconMarginRight={4}
            active={!isRunning}
            disabled={isRunning}
            running={isRunning}
            onClick={onClickSubscribe}
          />

          {/* Cancel */}

          <Button
            className={`${ClassName}--content--actions--action`}
            colorType="default"
            intlId="common.Cancel"
            running={false}
            disabled={false}
            active={true}
            onClick={onClickGoBack}
          />
        </div>

        {/* Info about rights */}

        <div className={`${ClassName}--content--info`}>
          <Trans id="v2.pages.upgrade-plan.payment-details.ConfirmDescription" />
        </div>

        {/* Links to terms and privacy */}

        <div className={`${ClassName}--content--info`}>
          <DangerTrans
            template="... <c>Terms</c> ... <d>Privacy</d>"
            id="v2.pages.upgrade-plan.payment-details.TermsAndConditions"
            values={{
              // Terms

              c: (chunks: string) => (
                <a target="_blank" rel="noreferrer" href={Config.Links.Terms}>
                  {chunks}
                </a>
              ),

              // Privacy

              d: (chunks: string) => (
                <a target="_blank" rel="noreferrer" href={Config.Links.Privacy}>
                  {chunks}
                </a>
              ),
            }}
          />
        </div>

        {/* - */}
      </div>
    </STUserZonePlansPurchaseConfirmationBcastUpg2Premium>
  )
}
