import Skeleton from 'react-loading-skeleton'

import { CreditCardBrand } from '../../../../../components/common/credit-card-brand/component'
import { Trans } from '../../../../../components/common/intl/trans'
import { CreditCardBrandSpritePosition } from '../../../../../style/constants/credit-card-brand'
import { UserZoneBillingRootCardMenu } from './menu/component'
import { ClassName, STUserZoneBillingRootCard } from './style'

// ~~~~~~ Types

type Props = {
  card: DN.Models.Card.ModelFront
}

// ~~~~~~ Constants

export const UserZoneBillingRootCard: React.FC<Props> = ({ card }) => {
  // ~~~~~~ Computed

  const isRunning = card.uiStatus === 'running'

  // ~~~~~~ Render

  return (
    <STUserZoneBillingRootCard>
      {isRunning ? (
        <Skeleton
          containerClassName={`${ClassName}--loading`}
          className={`${ClassName}--loading--bar`}
        />
      ) : (
        <>
          <div className={`${ClassName}--info`}>
            {/* Card num & "Default" block */}

            <div className={`${ClassName}--info--a`}>
              <CreditCardBrand
                cardCode={card.last4}
                $creditCardXPosition={
                  CreditCardBrandSpritePosition[
                    card.brand as keyof typeof CreditCardBrandSpritePosition
                  ]
                }
              />

              <div className={`${ClassName}--default ${card.is_default ? '' : 'fake-hidden'}`}>
                <Trans id="common.DefaultShe" />
              </div>
            </div>

            {/* Expires block */}

            <div className={`${ClassName}--info--b`}>
              <div className={`${ClassName}--expires`}>
                <Trans id="v2.pages.billing.Expires" />
                <span> </span>
                {`${card.exp_month}`.padStart(2, '0')}/{`${card.exp_year}`.slice(-2)}
              </div>
            </div>
          </div>

          <div className={`${ClassName}--menu`}>
            <UserZoneBillingRootCardMenu card={card} />
          </div>
        </>
      )}
    </STUserZoneBillingRootCard>
  )
}
