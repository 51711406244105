import { RemoteSVG } from '@dn/components'

import IconArrowCircleUp from '../../../imgs/svgs/icons/arrow-circle-up.svg'
import IconArrowCircle from '../../../imgs/svgs/icons/arrow-circle.svg'
import IconArrowDownCircle from '../../../imgs/svgs/icons/arrow-down-circle.svg'
import IconArrowDown from '../../../imgs/svgs/icons/arrow-down.svg'
import IconArrowLongDown from '../../../imgs/svgs/icons/arrow-long-down.svg'
import IconArrowLongLeft from '../../../imgs/svgs/icons/arrow-long-left.svg'
import IconArrowLongRight from '../../../imgs/svgs/icons/arrow-long-right.svg'
import IconArrowRightUp from '../../../imgs/svgs/icons/arrow-right-up.svg'
import IconCalendar from '../../../imgs/svgs/icons/calendar.svg'
import IconCheckCircle from '../../../imgs/svgs/icons/check-circle.svg'
import IconCheckFull from '../../../imgs/svgs/icons/check-full.svg'
import IconCheckSquare from '../../../imgs/svgs/icons/check-square.svg'
import IconCloseCircle from '../../../imgs/svgs/icons/close-circle.svg'
import IconClose from '../../../imgs/svgs/icons/close.svg'
import IconConnectDevice from '../../../imgs/svgs/icons/connect-device.svg'
import IconDanger from '../../../imgs/svgs/icons/danger.svg'
import IconEmail from '../../../imgs/svgs/icons/email.svg'
import IconExclamationTriangle from '../../../imgs/svgs/icons/exclamation-triangle.svg'
import IconEyeOff from '../../../imgs/svgs/icons/eye-off.svg'
import IconFilledEye from '../../../imgs/svgs/icons/filled-eye.svg'
import IconGift from '../../../imgs/svgs/icons/gift.svg'
import IconInfo from '../../../imgs/svgs/icons/info.svg'
import IconLock from '../../../imgs/svgs/icons/lock.svg'
import IconLogOut from '../../../imgs/svgs/icons/log-out.svg'
import IconMenu from '../../../imgs/svgs/icons/menu.svg'
import IconMoreHorizontal from '../../../imgs/svgs/icons/more-horizontal.svg'
import IconPaper from '../../../imgs/svgs/icons/paper.svg'
import IconParty from '../../../imgs/svgs/icons/party.svg'
import IconPencil from '../../../imgs/svgs/icons/pencil.svg'
import IconPlusCircle from '../../../imgs/svgs/icons/plus-circle.svg'
import IconRaiseHands from '../../../imgs/svgs/icons/raise-hands.svg'
import IconSetting from '../../../imgs/svgs/icons/setting.svg'
import IconStripe from '../../../imgs/svgs/icons/stripe.svg'
import IconSupportCall from '../../../imgs/svgs/icons/support-call.svg'
import IconSupportCircle from '../../../imgs/svgs/icons/support-circle.svg'
import IconTimes from '../../../imgs/svgs/icons/times.svg'
import IconUserCircleFilled from '../../../imgs/svgs/icons/user-circle-filled.svg'
import IconUserCircle from '../../../imgs/svgs/icons/user-circle.svg'
import IconVideos from '../../../imgs/svgs/icons/videos.svg'

import LogoBcastGradient from '../../../imgs/svgs/logos/broadcast-gradient.svg'
import LogoBcastSubDarkSmall from '../../../imgs/svgs/logos/broadcast-sub-dark-small.svg'
import LogoBcastSubDark from '../../../imgs/svgs/logos/broadcast-sub-dark.svg'
import LogoBcastSubLightSmall from '../../../imgs/svgs/logos/broadcast-sub-light-small.svg'
import LogoBcastSubLight from '../../../imgs/svgs/logos/broadcast-sub-light.svg'
import LogoLcherDarkSmall from '../../../imgs/svgs/logos/launcher-dark-small.svg'
import LogoLcherDark from '../../../imgs/svgs/logos/launcher-dark.svg'
import LogoLcherGradient from '../../../imgs/svgs/logos/launcher-gradient.svg'
import LogoLcherLightSmall from '../../../imgs/svgs/logos/launcher-light-small.svg'
import LogoLcherLight from '../../../imgs/svgs/logos/launcher-light.svg'
import LogoDNStore from '../../../imgs/svgs/logos/logo-display-note-store.svg'
import LogoMonDarkSmall from '../../../imgs/svgs/logos/montage-dark-small.svg'
import LogoMonDark from '../../../imgs/svgs/logos/montage-dark.svg'
import LogoMonGradient from '../../../imgs/svgs/logos/montage-gradient.svg'
import LogoMonLightSmall from '../../../imgs/svgs/logos/montage-light-small.svg'
import LogoMonLight from '../../../imgs/svgs/logos/montage-light.svg'

import LogoWindows from '../../../imgs/svgs/logos/windows.svg'
import LogoAndroid from '../../../imgs/svgs/logos/android.svg'

// ~~~~~~ Constants

const SvgFiles = {
  // Icons
  'icons/arrow-circle-up': IconArrowCircleUp,
  'icons/arrow-circle': IconArrowCircle,
  'icons/arrow-down-circle': IconArrowDownCircle,
  'icons/arrow-down': IconArrowDown,
  'icons/arrow-long-down': IconArrowLongDown,
  'icons/arrow-long-left': IconArrowLongLeft,
  'icons/arrow-long-right': IconArrowLongRight,
  'icons/arrow-right-up': IconArrowRightUp,
  'icons/calendar': IconCalendar,
  'icons/check-circle': IconCheckCircle,
  'icons/check-full': IconCheckFull,
  'icons/check-square': IconCheckSquare,
  'icons/close-circle': IconCloseCircle,
  'icons/close': IconClose,
  'icons/connect-device': IconConnectDevice,
  'icons/danger': IconDanger,
  'icons/email': IconEmail,
  'icons/exclamation-triangle': IconExclamationTriangle,
  'icons/eye-off': IconEyeOff,
  'icons/filled-eye': IconFilledEye,
  'icons/gift': IconGift,
  'icons/info': IconInfo,
  'icons/lock': IconLock,
  'icons/log-out': IconLogOut,
  'icons/menu': IconMenu,
  'icons/more-horizontal': IconMoreHorizontal,
  'icons/paper': IconPaper,
  'icons/party': IconParty,
  'icons/pencil': IconPencil,
  'icons/plus-circle': IconPlusCircle,
  'icons/raise-hands': IconRaiseHands,
  'icons/setting': IconSetting,
  'icons/stripe': IconStripe,
  'icons/support-call': IconSupportCall,
  'icons/support-circle': IconSupportCircle,
  'icons/times': IconTimes,
  'icons/user-circle-filled': IconUserCircleFilled,
  'icons/user-circle': IconUserCircle,
  'icons/videos': IconVideos,

  // Logos

  'logos/broadcast-gradient': LogoBcastGradient,
  'logos/broadcast-sub-dark-small': LogoBcastSubDarkSmall,
  'logos/broadcast-sub-dark': LogoBcastSubDark,
  'logos/broadcast-sub-light-small': LogoBcastSubLightSmall,
  'logos/broadcast-sub-light': LogoBcastSubLight,
  'logos/launcher-dark-small': LogoLcherDarkSmall,
  'logos/launcher-dark': LogoLcherDark,
  'logos/launcher-gradient': LogoLcherGradient,
  'logos/launcher-light-small': LogoLcherLightSmall,
  'logos/launcher-light': LogoLcherLight,
  'logos/logo-display-note-store': LogoDNStore,
  'logos/montage-dark-small': LogoMonDarkSmall,
  'logos/montage-dark': LogoMonDark,
  'logos/montage-gradient': LogoMonGradient,
  'logos/montage-light-small': LogoMonLightSmall,
  'logos/montage-light': LogoMonLight,
  'logos/windows': LogoWindows,
  'logos/android': LogoAndroid,
}

// ~~~~~~ Types

type SvgKeys = keyof typeof SvgFiles

// ~~~~~~~ Generator

export const LazySVG = (svgKey: SvgKeys) => {
  // ~~~~~~ Component

  return (props: { size: number; [key: string]: any }): JSX.Element => {
    // ~~~~~~ Computed

    const src = SvgFiles[svgKey]

    // ~~~~~~ Render

    return <RemoteSVG src={src} {...props} />
  }
}
