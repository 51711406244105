const prefix = 'at-api-store-checkout'

// ~~~~~~ Action Types

export const ApiStoreCheckoutAT = {
  CHANGE_EMAIL_WITH_PASS: `${prefix}-change-email-pass`,
  SEND_EMAIL_PASS: `${prefix}-send-email-pass`,
  VALIDATE_EMAIL_CODE: `${prefix}-validate-email-code`,

  CREATE_SESSION: `${prefix}-create-session`,
  START_FREE_SUB_OR_TRIAL: `${prefix}-start-free-sub-or-trial`,

  SHOW_SESSION: `${prefix}-show-session`,

  SET_DEFAULT_CARD: `${prefix}-set-default-card`,
} as const

// ~~~~~~ Action Mutators

export const ApiStoreCheckoutAC = {
  changeEmailPass: () => ({
    type: ApiStoreCheckoutAT.CHANGE_EMAIL_WITH_PASS,
  }),

  sendEmailPass: (data: DN.Models.Checkout.Api.SendEmailPass.Req) => ({
    type: ApiStoreCheckoutAT.SEND_EMAIL_PASS,
    payload: data,
  }),

  validateEmailCode: (data: DN.Models.Checkout.Api.ValidateEmailCode.Req) => ({
    type: ApiStoreCheckoutAT.VALIDATE_EMAIL_CODE,
    payload: data,
  }),

  startFreeSubOrTrial: (
    productId: DN.Models.Product.ProductId,
    data: DN.Models.Subscription.Api.Create.Req,
  ) => ({
    type: ApiStoreCheckoutAT.START_FREE_SUB_OR_TRIAL,
    payload: {
      productId,
      data,
    },
  }),

  createCheckoutSession: (
    productId: DN.Models.Product.ProductId,
    data: DN.Models.Checkout.Api.CreateSession.Req,
  ) => ({
    type: ApiStoreCheckoutAT.CREATE_SESSION,
    payload: {
      productId,
      data,
    },
  }),

  showSession: (data: DN.Models.Checkout.Api.ShowSession.Req) => ({
    type: ApiStoreCheckoutAT.SHOW_SESSION,
    payload: data,
  }),

  setDefaultCard: (paymentMethodId: string) => ({
    type: ApiStoreCheckoutAT.SET_DEFAULT_CARD,
    payload: {
      paymentMethodId,
    },
  }),
} as const
