import { Trans } from '../intl/trans'
import { STBadgeText } from './style'

// ~~~~~~ Types

type Props = {
  className?: string
  // kind:  'red'
  intl: IntlMsgId
}

// ~~~~~~ Component

export const BadgeText: React.FC<Props> = ({ className, intl }) => {
  // ~~~~~~ Render

  return (
    <STBadgeText className={className}>
      <Trans id={intl} />
    </STBadgeText>
  )
}
