import { Observable, of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { catchError, map } from 'rxjs/operators'
import { ApiRoutes } from '../constants/routes'
import { ApiHeadersUtils } from '../utils/headers'

const { Cards } = ApiRoutes

// ####################################################################################################
// ~~~~~~ Remove Card
// ####################################################################################################

type Res = DN.Models.Card.Api.Remove.Res
type ResParsed = DNApiResParsed<DN.Models.Card.Api.Remove.ResParsed>
type ResError = DNApiError<DN.Models.Card.Api.Remove.ResError>

export const apiStoreCardRem$ = (token: string, cardId: string, headers?: AppHeaders) => {
  return ajax<Res>({
    ...ApiHeadersUtils.genHeadersWithAuth(token, headers),
    method: Cards.RemCard.Method,
    url: Cards.RemCard.getUrl(cardId),
  }).pipe(
    map((res): ResParsed => {
      return {
        status: res.status,
        response: {
          id: cardId,
        },
      }
    }),
    catchError((res: DNApiRes<void>): Observable<ResError> => {
      return of({
        status: res.status,
        response: {
          cardId,
          errors: [{ id: 'api.card.rem-card.Error' }],
        },
      })
    }),
  )
}
