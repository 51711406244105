import { ReactComponent as LoaderSVG } from '../../../imgs/svgs/icons/spinner.svg'
import { Colors } from '../../../style/theme/colors'
import { STLoader } from './style'

interface Props {
  size: number
  color?: ThemeColor
}

export const Loader: React.FC<Props> = ({ size, color }) => {
  return (
    <STLoader data-test="loader" $size={size} $fill={color || Colors.WOLF_90_90}>
      <LoaderSVG height={size} width={size} />
    </STLoader>
  )
}
