import styled from 'styled-components'
import { MediaQuery } from '../../../../style/constants/media-queries'
import { Fonts } from '../../../../style/theme/fonts'
import { Colors } from '../../../../style/theme/colors'
import { Layout } from '../../../../style/theme/layout'
import { theme } from '../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'product-page-montage-content'

// ~~~~~~ Component

export const STProductPageMontage = styled.div`
  height: auto;
  min-height: 100%;

  margin-left: auto;
  margin-right: auto;

  ${Layout.PaddingWidth.Default}

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* Top */

  .${ClassName}--top {
    position: relative;
    overflow: hidden;

    display: flex;

    padding-bottom: 64px;

    /* Background when tablet\mobile */

    .${ClassName}--top--bg-pic {
      position: absolute;

      display: none;
      z-index: 0;

      inset: 0;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    /* Info */

    .${ClassName}--top--left {
      flex: 1;

      z-index: 1;

      padding-top: 32px;

      /* Go back */

      .${ClassName}--top--left--back {
        margin-bottom: 32px;

        .${ClassName}--top--left--back--action {
          ${Fonts.Weight[600]}
          ${Fonts.Scale.BodyBig}
        }
      }

      /* Logo */

      .${ClassName}--top--left--logo {
        .${ClassName}--top--left--logo--img {
          width: 100px;

          margin-bottom: 8px;
        }
      }

      /* Title */

      .${ClassName}--top--left--title {
        ${Fonts.Weight[800]}
        ${Fonts.Scale.OldA}

        margin-bottom: 16px;
      }

      /* Info list */

      .${ClassName}--top--left--info-list {
        .${ClassName}--top--left--info-list--title {
          ${Fonts.Weight[300]}
          ${Fonts.Scale.Title}

          color: ${theme(Colors.WOLF_90_90)};

          margin-bottom: 12px;
        }

        /* Points */

        .${ClassName}--top--left--info-list--points {
          ${Fonts.Weight[600]}
          ${Fonts.Scale.OldD}

          margin-bottom: 24px;

          .${ClassName}--top--left--info-list--points--point {
            margin-bottom: 8px;

            .${ClassName}--top--left--info-list--points--point--bullet {
              margin-right: 8px;
            }

            .${ClassName}--top--left--info-list--points--point--caption {
              ${Fonts.Weight[300]}
            }
          }
        }
      }
    }

    /* Right: Image when big */

    .${ClassName}--top--right {
      flex: 1;

      padding-top: 32px;

      .${ClassName}--top--right--pic {
        img {
          width: 100%;
        }
      }
    }
  }

  /* Bottom */

  .${ClassName}--bottom {
    background-color: ${theme(Colors.WOLF_90_90)};
    ${Layout.Border.Radius.B}

    padding: 40px;

    display: flex;
    justify-content: space-between;

    .${ClassName}--bottom--left {
      color: ${theme(Colors.WOLF_00_00)};

      padding-right: 28px;

      .${ClassName}--bottom--left--title {
        ${Fonts.Weight[800]}
        ${Fonts.Scale.Headline2}

        margin-bottom: 8px;
      }

      .${ClassName}--bottom--left--subtitle {
        ${Fonts.Weight[400]}
        ${Fonts.Scale.OldD}
      }
    }

    .${ClassName}--bottom--right {
      display: flex;
      align-items: center;

      .${ClassName}--bottom--right--action--big {
        ${Fonts.Scale.Subtitle}
      }

      .${ClassName}--bottom--right--action--small {
        display: none;
      }
    }
  }

  /* 1280 */

  @media (max-width: ${MediaQuery.MaxWidth2k}) {
    ${Layout.PaddingWidth.Desktop}

    /* Top */

    .${ClassName}--top {
      /* Left */

      .${ClassName}--top--left {
        .${ClassName}--top--left--back {
          margin-bottom: 24px;
        }

        .${ClassName}--top--left--logo {
          display: flex;

          .${ClassName}--top--left--logo--img {
            width: 80px;
          }
        }

        .${ClassName}--top--left--title {
          ${Fonts.Scale.OldB}
        }

        .${ClassName}--top--left--info-list {
          .${ClassName}--top--left--info-list--title {
            ${Fonts.Scale.OldD}
          }

          .${ClassName}--top--left--info-list--points {
            ${Fonts.Scale.Subtitle}
          }
        }
      }
    }

    /* Bottom */

    .${ClassName}--bottom {
      .${ClassName}--bottom--left {
        text-align: center;

        .${ClassName}--bottom--left--title {
          ${Fonts.Scale.Title}
        }

        .${ClassName}--bottom--left--subtitle {
          ${Fonts.Scale.Subtitle}
        }
      }

      .${ClassName}--bottom--right {
        .${ClassName}--bottom--right--action--big {
          ${Fonts.Scale.BodyBig}

          padding-top: 12px;
          padding-bottom: 12px;
        }
      }
    }
  }

  /* 960 */

  @media (max-width: ${MediaQuery.MD}) {
    ${Layout.PaddingWidth.Clear}

    /* Top */

    .${ClassName}--top {
      ${Layout.PaddingWidth.Tablet}

      justify-content: center;

      /* Background table/mobile */

      .${ClassName}--top--bg-pic {
        display: unset; // Show
      }

      /* Left */

      .${ClassName}--top--left {
        padding-top: 24px;

        .${ClassName}--top--left--back {
          margin-bottom: 32px;

          .${ClassName}--top--left--back--action {
            ${Fonts.Scale.Body}
          }
        }

        .${ClassName}--top--left--title {
          ${Fonts.Scale.Headline1}
        }

        .${ClassName}--top--left--info-list {
          .${ClassName}--top--left--info-list--title {
            ${Fonts.Scale.Subtitle}
          }

          .${ClassName}--top--left--info-list--points {
            ${Fonts.Scale.BodyBig}
          }
        }
      }

      /* Right */

      .${ClassName}--top--right {
        display: none;
      }
    }

    /* Bottom */

    .${ClassName}--bottom {
      ${Layout.PaddingWidth.Tablet}

      background-color: ${theme(Colors.WOLF_00_00)};

      flex-direction: column;
      align-items: center;

      .${ClassName}--bottom--left {
        color: ${theme(Colors.WOLF_90_90)};

        padding-right: 0;

        .${ClassName}--top--left--back {
          margin-bottom: 24px;
        }

        .${ClassName}--bottom--left--title {
          ${Fonts.Scale.Title}

          margin-bottom: 8px;
        }

        .${ClassName}--bottom--left--subtitle {
          ${Fonts.Scale.Subtitle}

          margin-bottom: 24px;
        }
      }

      .${ClassName}--bottom--right {
        .${ClassName}--bottom--right--action--big {
          display: none;
        }

        .${ClassName}--bottom--right--action--small {
          display: unset; // show

          ${Fonts.Scale.BodyBig}
          padding: 12px 24px 12px 24px;
        }
      }
    }
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    /* Top */

    .${ClassName}--top {
      ${Layout.PaddingWidth.Mobile}

      padding-bottom: 40px;

      .${ClassName}--top--left {
        .${ClassName}--top--left--logo {
          .${ClassName}--top--left--logo--img {
            margin-bottom: 8px;
          }
        }

        .${ClassName}--top--left--title {
          ${Fonts.Scale.Headline2}
        }

        .${ClassName}--top--left--info-list {
          .${ClassName}--top--left--info-list--title {
            ${Fonts.Scale.BodyBig}
          }
        }
      }
    }

    /* Bottom */

    .${ClassName}--bottom {
      ${Layout.PaddingWidth.Mobile}

      padding-top: 32px;
      padding-bottom: 40px;

      text-align: center;

      .${ClassName}--bottom--left {
        padding: 0;

        color: ${theme(Colors.WOLF_90_90)};

        .${ClassName}--bottom--left--title {
          ${Fonts.Scale.OldD}
        }

        .${ClassName}--bottom--left--subtitle {
          ${Fonts.Scale.BodyBig}
        }
      }

      .${ClassName}--bottom--right {
        width: 100%;

        .${ClassName}--bottom--right--action--big {
          display: none;
        }

        .${ClassName}--bottom--right--action--small {
          ${Fonts.Scale.Body}

          padding-top: 10px;
          padding-bottom: 10px;

          width: 100%;
        }
      }
    }
  }
`
