import styled from 'styled-components'

// ~~~~~~ Constants

export const ClassName = 'page-product-montage'

// ~~~~~~ Component

export const STPageProductMontage = styled.div`
  height: 100vh;
`
