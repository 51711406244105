import { Routes as Switch, Route, Navigate } from 'react-router'
import { UserZoneBillingRoot } from './0-root/component'
import { Routes, RoutesBilling } from '../../../constants/routes'
import { UserZoneBillingInfoEdit } from './1-billing/edit/component'
import { UserZoneBillingPaymentMethodAdd } from './1-payment-method/add/component'
import { UserZoneBillingPaymentMethodEdit } from './1-payment-method/edit/component'

// ~~~~~~ Component

export const UserZoneBilling = () => {
  // ~~~~~~ Render

  return (
    <Switch>
      {/* Root */}

      <Route path="" element={<UserZoneBillingRoot />} />

      {/* Billing info: Edit */}

      <Route path={RoutesBilling.EditBillingInfo} element={<UserZoneBillingInfoEdit />} />

      {/* Payment method: Add */}

      <Route path={RoutesBilling.AddPaymentMethod} element={<UserZoneBillingPaymentMethodAdd />} />

      {/* Payment method: Edit */}

      <Route
        path={RoutesBilling.EditPaymentMethod}
        element={<UserZoneBillingPaymentMethodEdit />}
      />

      {/* Unknown routes */}

      <Route path="*" element={<Navigate replace to={Routes.Root} />} />

      {/* - */}
    </Switch>
  )
}
