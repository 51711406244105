import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ModalsIds } from '../../../../constants/modals'
import { StoreState } from '../../../../models/app/model'
import { ApiStoreCheckoutAC } from '../../../../store/actions/api-store/checkout/actions'
import { genAnimatedDialog } from '../../../common/compound-dialog/component'
import { DangerTrans } from '../../../common/intl/danger-trans'
import { Trans } from '../../../common/intl/trans'
import { DialogConfirmation } from '../component'

// ~~~~~~ Constants

const Dialog = genAnimatedDialog(ModalsIds.ConfirmChangeDefaultCard)

// ~~~~~~

export const DialogConfirmChangeDefaultCard: React.FC<{}> = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const [doClose, setDoClose] = useState(0)

  const [closeOnEnd, setCloseOnEnd] = useState(false)

  const cardId = useSelector((state: StoreState) => state.cards.tmpCardId)

  const cards = useSelector((state: StoreState) => state.cards)

  // ~~~~~~ Computed

  const isRunning = cards.uiStatusChange === 'running'

  const card = cards.list.find((val) => val.payment_method_id === cardId)

  // ~~~~~~ Handlers

  function onClickClose() {
    setDoClose(performance.now())
  }

  function onClickConfirm() {
    if (!cardId) return

    dispatch(ApiStoreCheckoutAC.setDefaultCard(cardId))

    setCloseOnEnd(true)
  }

  // ~~~~~~ Effects

  useEffect(() => {
    if (!closeOnEnd || isRunning) return

    setCloseOnEnd(false)
    setDoClose(performance.now())
  }, [isRunning, closeOnEnd, dispatch])

  // ~~~~~~ Render

  return (
    <DialogConfirmation
      data-test="dialog-confirm-change-default-card"
      $isDefaultCardModel
      isNoJustifyContent
      Dialog={Dialog}
      title={'v2.pages.billing.change-default-card.SetDefaultCard'}
      contentText="dialogs.confirm.change-default-card.Content"
      contentTextComponent={
        card ? (
          <div>
            <div className="question-style">
              <DangerTrans
                template="If you set <c>Card ending {value}</c> as the default payment method, it will be used for all future subscription payments on your account."
                id="v2.pages.billing.change-default-card.SetDefaultCardDescription"
                values={{
                  value: card.last4,
                  c: (chunks: string) => <span className="highlighted-text">{chunks}</span>,
                }}
              />
            </div>
            <div>
              <Trans id="v2.pages.billing.change-default-card.ContentTextQuestion" />
            </div>
          </div>
        ) : undefined
      }
      actionButtons={{
        okActionIntl: 'v2.pages.billing.SetDefault',
      }}
      isRunning={isRunning}
      doClose={doClose}
      onEndDissapear={() => undefined}
      onClickClose={onClickClose}
      onClickConfirm={onClickConfirm}
    />
  )
}
