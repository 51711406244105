import { StateObservable, ofType } from 'redux-observable'
import { Observable, filter, map } from 'rxjs'
import { StoreState } from '../../../../models/app/model'
import { ApiStoreCheckoutAC } from '../../../actions/api-store/checkout/actions'
import { EpicApiStoreCardChangeMC, EpicApiStoreCardChangeMT } from '../card/change/mutators'

type Action = ReturnType<typeof EpicApiStoreCardChangeMC.ok>

export const apiStoreChainingFromChangeCardToCheckoutSetDefaultCardEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(EpicApiStoreCardChangeMT.OK),

    filter(({ payload }) => payload.setDefault),

    map(({ payload }) => ApiStoreCheckoutAC.setDefaultCard(payload.data.card.payment_method_id)),
  )
