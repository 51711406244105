import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { UtilsEncDec, UtilsNumber } from '@dn/utils'
import { RouteDataMontagePurchaseConfirm, Routes } from '../../../../../constants/routes'
import { StoreState } from '../../../../../models/app/model'
import { ClassName, STUserZonePlansPurchaseMontage } from './style'
import { UserZoneCommonBannerBack } from '../../../@-common/banner-back/component'
import { ProductButtonMontage } from '../../../../../components/common/product-button/montage/component'
import { DeviceNumberSelector } from '../../../../../components/common/device-number-selector/component'
import { Trans } from '../../../../../components/common/intl/trans'
import { SafePayments } from '../../../../../components/common/safe-payments/component'
import { BuyLicenseFieldMR } from '../../../../../models/buy-license/model'
import { Button } from '../../../../../components/common/button/component'
import { useEffect } from 'react'
import { BuyLicenseMC } from '../../../../../store/actions-mutators/buy-license/mutators'
import { useTrans } from '../../../../../hooks/use-trans'

// ~~~~~~ Component

export const UserZonePlansPurchaseMontage = () => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const nextStepBackText = useTrans('v2.pages.order-solution.OrderTitle')

  // ~~~~~~ State

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  const subs = useSelector((state: StoreState) => state.subscriptions)

  const montageProducts = useSelector((state: StoreState) => state.montage.products)

  const buyLicense = useSelector((state: StoreState) => state.buyLicense)

  // ~~~~~~ Computed

  const isLoading = !currentUser.isSessionChecked || currentUser.uiStatus === 'running'

  const isRunning = buyLicense.uiStatus === 'running'

  const disabled = !(Number(buyLicense.quantity) > 0)

  const canGetMontageTrial = !subs.some((sub) => sub.product_id === 'montage')

  const platform = buyLicense.platform === 'android' ? 'android' : 'windows'

  const montageYearly = montageProducts[platform].year

  // ~~~~~~ Handlers

  function onClickGoBack() {
    if (isLoading) return

    const target = canGetMontageTrial ? Routes.UserZonePlansMontage : Routes.UserZonePlans

    navigate(target)
  }

  function onChangePlatform(value: string) {
    const finalValue = value === 'Android' ? 'android' : 'windows'

    dispatch(BuyLicenseFieldMR.platform.MC.change(finalValue))

    const subId = montageProducts[finalValue].year.id

    dispatch(BuyLicenseFieldMR.subId.MC.change(subId))
  }

  function onChangeDeviceQtty(value: string) {
    const finalValue = UtilsNumber.safeStrOfNumberInt(value)

    dispatch(BuyLicenseFieldMR.quantity.MC.change(finalValue))
  }

  function onClickContinue() {
    if (!montageYearly) return

    const nextDataObj: RouteDataMontagePurchaseConfirm = {
      backPath: window.location.pathname,
      backText: nextStepBackText,
      qtty: Number(buyLicense.quantity),
      price: montageYearly.price,
      productId: montageYearly.id,
    }

    const nextData = UtilsEncDec.base64UrlEncode(JSON.stringify(nextDataObj))

    const paymentConfirmRoute = Routes.genUserZonePlansMontagePurchaseConfirm(nextData)

    navigate(paymentConfirmRoute)
  }

  // ~~~~~~ Effects

  // - On mount clear model to buy, and set Montage

  useEffect(() => {
    dispatch(BuyLicenseMC.clearData())

    dispatch(BuyLicenseFieldMR.productId.MC.change('montage'))

    dispatch(BuyLicenseFieldMR.platform.MC.change('windows'))

    //
  }, [dispatch])

  // ~~~~~~ Render

  if (isLoading || !montageYearly) return null

  return (
    <STUserZonePlansPurchaseMontage>
      {/* Banner back */}

      <UserZoneCommonBannerBack
        noImage
        backIntl={canGetMontageTrial ? 'pages.common.AddProduct' : 'pages.common.Plans'}
        backIntlValues={{ value: 'Montage' }}
        kind="montage"
        onClickGoBack={onClickGoBack}
      />

      {/* Content */}

      <div className={`${ClassName}--content`}>
        {/* Title */}

        <div className={`${ClassName}--content--title`}>
          <Trans id="v2.pages.order-solution.OrderTitle" />
        </div>

        {/* Subtitle */}

        <div className={`${ClassName}--content--subtitle`}>
          <Trans id="v2.pages.order-solution.order-sub-title.Montage" />
        </div>

        {/* Num of devices (license qtty) */}

        <div className={`${ClassName}--content--device-selector`}>
          <DeviceNumberSelector
            product="Montage"
            platforms={['Windows', 'Android']}
            price={montageYearly.price}
            qtty={buyLicense.quantity}
            onChangePlatform={onChangePlatform}
            onChange={onChangeDeviceQtty}
          />
        </div>

        {/* Action */}

        <div className={`${ClassName}--content--actions`}>
          {/* Continue to payment */}

          <ProductButtonMontage
            className={`${ClassName}--content--actions--action first`}
            intlId="pages.purchase-flow.common.ProceedToPayment"
            running={isRunning}
            disabled={disabled || isRunning}
            active={!disabled && !isRunning}
            onClick={onClickContinue}
          />

          {/* Cancel */}

          <Button
            colorType="default"
            intlId="common.Cancel"
            disabled={false}
            running={false}
            active={true}
            onClick={onClickGoBack}
          />
        </div>

        {/* Safe Payments */}

        <div className={`${ClassName}--safe-pay`}>
          <SafePayments />
        </div>
      </div>

      {/* - */}
    </STUserZonePlansPurchaseMontage>
  )
}
