import { useSelector } from 'react-redux'
import { LazySVG } from '../../../../components/svgs/lazy-svg/component'
import { useCustomAuth } from '../../../../hooks/auth/use-custom-auth'
import { ClassName, STPageCommonTopRight } from './style'
import { StoreState } from '../../../../models/app/model'
import { Loader } from '../../../../components/common/loader/component'
import { Colors } from '../../../../style/theme/colors'
import { Trans } from '../../../../components/common/intl/trans'
import { UserMenu } from '../../../../components/common/user-menu/component'

// ~~~~~~ Constants

const UserIcon = LazySVG('icons/user-circle')

// ~~~~~~ Component

export const PageCommonTopRight = () => {
  // ~~~~~~ Hooks

  const { login, isLoading } = useCustomAuth()

  // ~~~~~~ State

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  // ~~~~~~ Computed

  const accountIsLoading = isLoading || currentUser.uiStatus === 'running'

  const userIsLogged = !!currentUser.email

  // ~~~~~~ Handlers

  function onClickNotLogged() {
    login()
  }

  // ~~~~~~ Render

  // Loading

  if (accountIsLoading) {
    return (
      <STPageCommonTopRight>
        <Loader size={20} color={Colors.WOLF_00_00} />
      </STPageCommonTopRight>
    )
  }

  // Logged

  if (userIsLogged) {
    return (
      <STPageCommonTopRight>
        <div className={`${ClassName}--logged`}>
          <UserMenu />
        </div>
      </STPageCommonTopRight>
    )
  }

  // Not logged

  return (
    <STPageCommonTopRight onClick={onClickNotLogged}>
      <div className={`${ClassName}--not-logged`}>
        <UserIcon size={20} />

        <div className={`${ClassName}--not-logged--text`}>
          <Trans id="common.NavigationAccountLink" />
        </div>
      </div>
    </STPageCommonTopRight>
  )
}
