import styled from 'styled-components'
import { MediaQuery } from '../../../../../style/constants/media-queries'
import { Colors } from '../../../../../style/theme/colors'
import { Fonts } from '../../../../../style/theme/fonts'
import { Layout } from '../../../../../style/theme/layout'
import { theme } from '../../../../../style/utils/theme'
import { HexAlpha } from '../../../../../style/theme/colors/alpha'

// ~~~~~~ Constants

export const ClassName = 'user-zone-plans-product-launcher'

// ~~~~~~ Component

export const STUserZonePlansProductLauncher = styled.div`
  height: auto;
  min-height: 100%;

  margin-left: auto;
  margin-right: auto;

  ${Layout.PaddingWidth.Default}

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  /* Top */

  .${ClassName}--top {
    position: relative;
    overflow: hidden;

    display: flex;

    padding-bottom: 64px;

    /* Background when tablet\mobile */

    .${ClassName}--top--bg-pic {
      position: absolute;

      display: none;
      z-index: 0;

      inset: 0;
      overflow: hidden;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    /* Info */

    .${ClassName}--top--left {
      flex: 1;

      z-index: 1;

      padding-top: 32px;

      /* Go back */

      .${ClassName}--top--left--back {
        margin-bottom: 32px;

        .${ClassName}--top--left--back--action {
          ${Fonts.Weight[600]}
          ${Fonts.Scale.BodyBig}
        }
      }

      /* Logo */

      .${ClassName}--top--left--logo {
        .${ClassName}--top--left--logo--img {
          width: 100px;

          margin-bottom: 8px;
        }
      }

      /* Title */

      .${ClassName}--top--left--title {
        ${Fonts.Weight[800]}
        ${Fonts.Scale.OldA}

        margin-bottom: 16px;
      }

      /* Info list */

      .${ClassName}--top--left--info-list {
        .${ClassName}--top--left--info-list--title {
          ${Fonts.Weight[300]}
          ${Fonts.Scale.Title}

          color: ${theme(Colors.WOLF_90_90)};

          margin-bottom: 12px;
        }

        /* Points */

        .${ClassName}--top--left--info-list--points {
          ${Fonts.Weight[600]}
          ${Fonts.Scale.OldD}

          margin-bottom: 24px;

          .${ClassName}--top--left--info-list--points--point {
            margin-bottom: 8px;

            .${ClassName}--top--left--info-list--points--point--bullet {
              margin-right: 8px;
            }

            .${ClassName}--top--left--info-list--points--point--caption {
              ${Fonts.Weight[300]}
            }
          }
        }
      }
    }

    /* Right: Image when big */

    .${ClassName}--top--right {
      flex: 1;

      padding-top: 32px;

      .${ClassName}--top--right--pic {
        img {
          width: 100%;
        }
      }
    }
  }

  /* Bottom */

  .${ClassName}--bottom {
    display: flex;
    flex-direction: column;

    .${ClassName}--bottom--left {
      padding-right: 28px;

      margin-bottom: 16px;

      .${ClassName}--bottom--left--title {
        ${Fonts.Weight[800]}
        ${Fonts.Scale.Headline2}

        margin-bottom: 8px;
      }

      .${ClassName}--bottom--left--subtitle {
        ${Fonts.Weight[400]}
        ${Fonts.Scale.OldD}
      }
    }

    .${ClassName}--bottom--right {
      width: 100%;
      max-width: 688px;

      padding: 16px 24px;

      box-shadow: 0px 4px 16px ${theme(Colors.WOLF_90_90)}${HexAlpha[10]};
      border: solid 1px ${theme(Colors.WOLF_10_10)};
      ${Layout.Border.Radius.B}

      display: flex;
      align-items: center;
      justify-content: space-between;

      margin-bottom: 40px;

      .${ClassName}--bottom--right--left {
        .${ClassName}--bottom--right--left--info {
          ${Fonts.Weight[300]}
          ${Fonts.Scale.BodyBig}
        }

        .${ClassName}--bottom--right--left--price {
          ${Fonts.Weight[800]}
          ${Fonts.Scale.Headline1}
        }
      }
    }
  }

  /* Help */

  .${ClassName}--help {
    padding-bottom: 40px;
  }

  /* 1280 */

  @media (max-width: ${MediaQuery.MaxWidth2k}) {
    ${Layout.PaddingWidth.Desktop}

    /* Top */

    .${ClassName}--top {
      margin-bottom: 48px;
      /* Left */

      .${ClassName}--top--left {
        .${ClassName}--top--left--back {
          margin-bottom: 24px;
        }

        .${ClassName}--top--left--logo {
          display: flex;

          .${ClassName}--top--left--logo--img {
            width: 80px;
          }
        }

        .${ClassName}--top--left--title {
          ${Fonts.Scale.OldB}
        }

        .${ClassName}--top--left--info-list {
          .${ClassName}--top--left--info-list--title {
            ${Fonts.Scale.OldD}
          }

          .${ClassName}--top--left--info-list--points {
            ${Fonts.Scale.Subtitle}
          }
        }
      }
    }

    /* Bottom */

    .${ClassName}--bottom {
      .${ClassName}--bottom--left {
        .${ClassName}--bottom--left--title {
          ${Fonts.Scale.Title}
        }

        .${ClassName}--bottom--left--subtitle {
          ${Fonts.Scale.Subtitle}
        }
      }

      .${ClassName}--bottom--right {
        max-width: 622px;
      }
    }

    .${ClassName}--help {
      text-align: center;
    }
  }

  /* 960 */

  @media (max-width: ${MediaQuery.MD}) {
    ${Layout.PaddingWidth.Clear}

    /* Top */

    .${ClassName}--top {
      ${Layout.PaddingWidth.Tablet}

      justify-content: center;

      /* Background table/mobile */

      .${ClassName}--top--bg-pic {
        display: unset; // Show
      }

      /* Left */

      .${ClassName}--top--left {
        padding-top: 24px;

        .${ClassName}--top--left--back {
          margin-bottom: 32px;

          .${ClassName}--top--left--back--action {
            ${Fonts.Scale.Body}
          }
        }

        .${ClassName}--top--left--title {
          ${Fonts.Scale.Headline1}
        }

        .${ClassName}--top--left--info-list {
          .${ClassName}--top--left--info-list--title {
            ${Fonts.Scale.Subtitle}
          }

          .${ClassName}--top--left--info-list--points {
            ${Fonts.Scale.BodyBig}
          }
        }
      }

      /* Right */

      .${ClassName}--top--right {
        display: none;
      }
    }

    /* Bottom */

    .${ClassName}--bottom {
      ${Layout.PaddingWidth.Tablet}

      background-color: ${theme(Colors.WOLF_00_00)};

      flex-direction: column;
      align-items: center;

      .${ClassName}--bottom--left {
        color: ${theme(Colors.WOLF_90_90)};

        padding-right: 0;

        .${ClassName}--top--left--back {
          margin-bottom: 24px;
        }

        .${ClassName}--bottom--left--title {
          ${Fonts.Scale.Title}

          margin-bottom: 8px;
        }

        .${ClassName}--bottom--left--subtitle {
          ${Fonts.Scale.Subtitle}

          margin-bottom: 24px;
        }
      }

      .${ClassName}--bottom--right {
        max-width: 526px;
      }
    }
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    /* Top */

    .${ClassName}--top {
      ${Layout.PaddingWidth.Mobile}

      padding-bottom: 40px;

      .${ClassName}--top--left {
        .${ClassName}--top--left--logo {
          .${ClassName}--top--left--logo--img {
            margin-bottom: 8px;
          }
        }

        .${ClassName}--top--left--title {
          ${Fonts.Scale.Headline2}
        }

        .${ClassName}--top--left--info-list {
          .${ClassName}--top--left--info-list--title {
            ${Fonts.Scale.BodyBig}
          }
        }
      }
    }

    /* Bottom */

    .${ClassName}--bottom {
      ${Layout.PaddingWidth.Mobile}

      padding-top: 32px;

      text-align: center;

      .${ClassName}--bottom--left {
        padding: 0;

        color: ${theme(Colors.WOLF_90_90)};

        .${ClassName}--bottom--left--title {
          ${Fonts.Scale.OldD}
        }

        .${ClassName}--bottom--left--subtitle {
          ${Fonts.Scale.BodyBig}
        }
      }

      .${ClassName}--bottom--right {
        box-sizing: border-box;
        width: 100%;

        flex-direction: column;

        .${ClassName}--bottom--right--left {
          margin-bottom: 16px;
        }
      }
    }
  }
`
