import { UtilsStorageLocal } from '@dn/utils'
import { StorageKeys } from '../../../constants/storage'

// ####################################################################################################
// ~~~~~~ Get
// ####################################################################################################

const get: () => boolean = () => {
  const langStored = UtilsStorageLocal.getItem(StorageKeys.DebugLang)

  return langStored === 'true'
}

// ~~~~~~

export const LocalStorageDebugLang = {
  get,
} as const
