import { UtilsStorageLocal } from '@dn/utils'
import { Config } from '../../../config'
import { StorageKeys } from '../../../constants/storage'

// ~~~~~~ Constants

const langs: Lang[] = Config.Intl.SupportedLocales

// ####################################################################################################
// ~~~~~~ Get
// ####################################################################################################

const get: () => Lang | '' = () => {
  const langStored = UtilsStorageLocal.getItem(StorageKeys.Lang)

  return langs.includes(langStored as any) ? (langStored as Lang) : ''
}

// ####################################################################################################
// ~~~~~~ Set
// ####################################################################################################

const set = (lang: Lang) => {
  UtilsStorageLocal.setItem(StorageKeys.Lang, lang)
}

// ####################################################################################################
// ~~~~~~ Clear
// ####################################################################################################

const clear = () => {
  UtilsStorageLocal.removeItem(StorageKeys.Lang)
}

// ~~~~~~

export const LocalStorageLang = {
  get,
  set,
  clear,
} as const
