import { Observable, of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { catchError, map } from 'rxjs/operators'
import { ApiRoutes } from '../constants/routes'
import { ApiHeadersUtils } from '../utils/headers'

const { CurrentUser } = ApiRoutes

// ####################################################################################################
// ~~~~~~ Current User Show
// ####################################################################################################

type Res = DN.Models.CurrentUser.Api.Show.Res
type ResParsed = DNApiResParsed<DN.Models.CurrentUser.Api.Show.ResParsed>
type ResError = DNApiError<DN.Models.CurrentUser.Api.Show.ResError>

export const apiStoreCurrentUserShow$ = (auth0Token: string, headers?: AppHeaders) => {
  return ajax<Res>({
    ...ApiHeadersUtils.genHeadersWithAuth(auth0Token, headers),
    method: CurrentUser.Show.Method,
    url: CurrentUser.Show.getUrl(),
  }).pipe(
    map((res): ResParsed => {
      const finalCards = res.response.cards.map((card) => {
        const brand = card.brand

        return {
          ...card,
          brand: brand,
        }
      })

      return {
        status: res.status,
        response: {
          user: {
            ...res.response.user,
            token: res.response.token,
          },
          cards: finalCards,
          subscriptions: res.response.subscriptions,
          invoices: res.response.invoices,
          billing_info: res.response.billing_info,
        },
      }
    }),
    catchError((res: DNApiRes<void>): Observable<ResError> => {
      return of({
        status: res.status,
        response: [{ id: 'api.current-user.show.Error' }],
      })
    }),
  )
}
