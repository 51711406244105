import { LazySVG } from '../../../svgs/lazy-svg/component'
import { Trans } from '../../intl/trans'
import { STInfosList } from './style'

// ~~~~~~ Types

type Props = {
  infos: Infos[]
  className?: string
}

// ~~~~~~ Constants

const InfoIcon = LazySVG('icons/info')

// ~~~~~~ Component

export const InfosList: React.FC<Props> = ({ infos: errors, className }) => {
  if (!errors.length) return null

  return (
    <STInfosList data-test="infos-list" className={className}>
      <div className="info-error-icon">
        <InfoIcon size={24} />
      </div>

      <div>
        {errors.map((err, idx) => (
          <div data-test={`info-${idx}`} key={idx}>
            <div>
              <Trans id={err.id} values={err.values} />
            </div>
          </div>
        ))}
      </div>
    </STInfosList>
  )
}
