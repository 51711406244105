import styled from 'styled-components'

// ~~~~~~ Constants

export const ClassName = 'page-trials-or-free-montage'

// ~~~~~~ Component

export const STPageTrialsOrFreeMontage = styled.div`
  height: 100vh;
`
