import styled from 'styled-components'
import { Layout } from '../../../style/theme/layout'
import { theme } from '../../../style/utils/theme'
import { Colors } from '../../../style/theme/colors'
import { Fonts } from '../../../style/theme/fonts'
import { Transition } from '../../../style/animations/css-animations'
import { MediaQuery } from '../../../style/constants/media-queries'

// ~~~~~~ Constants

export const ClassName = 'link-card'

// ~~~~~~ Component

export const STLinkCard = styled.div`
  cursor: pointer;

  padding: 16px;

  width: calc(100% - 32px);

  display: flex;
  align-items: center;

  ${Layout.Border.Radius.B}

  border: solid 2px ${theme(Colors.WOLF_20_20)};

  color: ${theme(Colors.WOLF_90_90)};

  ${Transition.BorderColorFaster};

  &:hover {
    border-color: ${theme(Colors.WOLF_90_90)};
  }

  .${ClassName}--icon {
    display: flex;

    margin-right: 8px;

    svg {
      width: 20px;
      height: 20px;

      path {
        fill: ${theme(Colors.WOLF_90_90)};
      }
    }
  }

  .${ClassName}--text {
    ${Fonts.Weight[600]}
    ${Fonts.Scale.Subtitle}
  }

  .${ClassName}--arrow {
    display: flex;

    margin-left: auto;

    svg {
      width: 20px;
      height: 20px;

      path {
        fill: ${theme(Colors.WOLF_90_90)};
      }
    }
  }

  /* 960 */

  @media (max-width: ${MediaQuery.MD}) {
    .${ClassName}--text {
      ${Fonts.Scale.BodyBig}
    }
  }
`
