const prefix = 'at-api-montage-release-info'

// ~~~~~~ Action Types

export const ApiMontageReleaseInfoAT = {
  GET: `${prefix}-get`,
} as const

// ~~~~~~ Action Mutators

export const ApiMontageReleaseInfoAC = {
  start: () => ({
    type: ApiMontageReleaseInfoAT.GET,
  }),
} as const
