import styled from 'styled-components'
import { Colors } from '../../../../style/theme/colors'
import { Layout } from '../../../../style/theme/layout'
import { theme } from '../../../../style/utils/theme'
import { Transition } from '../../../../style/animations/css-animations'
import { Fonts } from '../../../../style/theme/fonts'
import { MediaQuery } from '../../../../style/constants/media-queries'

// ~~~~~~ Constants

export const ClassName = 'user-zone-plans-sub-change-payment-method'

// ~~~~~~ Component

export const STUserZonePlansSubChangePaymentMethod = styled.div`
  margin-top: 24px;

  ${Layout.MarginMaxWidth.MiddleContent}

  .${ClassName}--content {
    .${ClassName}--content--go-back {
      ${Fonts.Weight[600]}
      ${Fonts.Scale.BodyBig}

        margin-bottom: 32px;
    }

    .${ClassName}--content--title {
      ${Fonts.Weight[800]}
      ${Fonts.Scale.Headline1}

        margin-bottom: 8px;
    }

    .${ClassName}--content--subtitle {
      ${Fonts.Weight[300]}
      ${Fonts.Scale.BodyBig}

        margin-bottom: 16px;
    }

    .${ClassName}--content--select--title {
      ${Fonts.Weight[700]}
      ${Fonts.Scale.BodyBig}

        margin-bottom: 16px;
    }

    .${ClassName}--content--cards {
      .${ClassName}--content--cards--card {
        cursor: pointer;

        max-width: 480px;
        padding: 16px;
        width: calc(100% - 32px);

        border: solid 1px ${theme(Colors.WOLF_40_40)};

        ${Layout.Border.Radius.B}

        display: flex;
        justify-content: space-between;

        margin-bottom: 16px;

        ${Transition.ColorBorderAndBackgroundFaster};

        &.last {
          margin-bottom: 24px;
        }

        &.selected {
          border-color: ${theme(Colors.BRONX_50_50)};
          background-color: ${theme(Colors.BRONX_05_05)};

          .${ClassName}--content--cards--card--a {
            .${ClassName}--content--cards--card--a--check {
              svg {
                display: unset;
              }

              .${ClassName}--content--cards--card--a--check--empty {
                display: none;
              }
            }
          }
        }

        .${ClassName}--content--cards--card--check {
          .${ClassName}--content--cards--card--empty {
          }
        }
      }

      .${ClassName}--content--cards--card--a {
        display: flex;

        .${ClassName}--content--cards--card--a--check {
          display: flex;
          align-items: center;

          margin-right: 16px;

          svg {
            display: none;

            width: 28px;
            height: 28px;

            path {
              fill: ${theme(Colors.BRONX_50_50)};
            }
          }

          .${ClassName}--content--cards--card--a--check--empty {
            width: 20px;
            height: 20px;

            margin: 2px;

            border-radius: 50%;

            border: solid 2px ${theme(Colors.WOLF_40_40)};
          }
        }

        .${ClassName}--content--cards--card--a--last4 {
          display: flex;
        }
      }

      .${ClassName}--content--cards--card--b {
        display: flex;
        align-items: center;

        .${ClassName}--content--cards--card--b--default {
          ${Fonts.Weight[400]}
          ${Fonts.Scale.Body}

            ${Layout.Border.Radius.A}

            padding: 2px 4px;

          color: ${theme(Colors.BRONX_60_60)};
          background-color: ${theme(Colors.BRONX_10_10)};
        }
      }
    }

    .${ClassName}--content--add-payment {
      margin-bottom: 16px;
    }

    .${ClassName}--content--actions {
      display: flex;

      padding-bottom: 72px;

      .${ClassName}--content--actions--action {
        &.first {
          margin-right: 16px;
        }
      }
    }
  }

  /* 1280 */

  @media (max-width: ${MediaQuery.MaxWidth2k}) {
    .${ClassName}--content {
      ${Layout.PaddingWidth.Desktop}

      .${ClassName}--content--go-back {
        margin-bottom: 24px;
      }

      .${ClassName}--content--title {
        ${Fonts.Scale.Headline2}
      }
    }
  }

  /* 960 */

  @media (max-width: ${MediaQuery.MD}) {
    .${ClassName}--content {
      ${Layout.PaddingWidth.Tablet}
    }
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    .${ClassName}--content {
      ${Layout.PaddingWidth.Mobile}

      .${ClassName}--content--title {
        ${Fonts.Scale.Title}
      }

      .${ClassName}--content--subtitle {
        ${Fonts.Scale.Subtitle}
      }

      .${ClassName}--content--actions {
        flex-direction: column;

        .${ClassName}--content--actions--action {
          &.first {
            margin-right: 0;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
`
