import { ofType, StateObservable } from 'redux-observable'
import { Observable } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'

import { StoreState } from '../../../../../models/app/model'

import { ApiUtils } from '../../../utils'
import { ApiStoreSubscriptionsCancelPlanEpicMC } from './mutators'
import {
  ApiStoreSubscriptionsAC,
  ApiStoreSubscriptionsAT,
} from '../../../../actions/api-store/subscriptions/actions'

import { apiStoreSubscriptionsCancelPlan$ } from '../../../../../services/api-store/subscriptions/cancel-plan'

type Action = ReturnType<typeof ApiStoreSubscriptionsAC.cancelPlan>

export const apiStoreSubscriptionsCancelPlanEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiStoreSubscriptionsAT.CANCEL_PLAN),
    mergeMap(({ payload }) =>
      apiStoreSubscriptionsCancelPlan$(state$.value.currentUser.token, payload.subscriptionId),
    ),
    map((res) =>
      ApiUtils.isNotError(res)
        ? ApiStoreSubscriptionsCancelPlanEpicMC.ok(res.response)
        : ApiStoreSubscriptionsCancelPlanEpicMC.error(res.response),
    ),
  )
