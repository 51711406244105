import { AppInitState } from '../../../models/app/model'
import { dynReducer } from '../../../utils/store'
import {
  EpicApiStoreCurrentUserShowMT,
  EpicApiStoreCurrentUserShowMC,
} from '../../epics/api-store/current-user/show/mutators'

const initState = AppInitState.invoices

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ Current user - Show OK
// ####################################################################################################

reducer[EpicApiStoreCurrentUserShowMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiStoreCurrentUserShowMC.ok>,
): State => {
  return payload.invoices.map((invoice) => ({
    ...invoice,
    uiStatus: 'init',
  }))
}

// ~~~~~~

export const invoicesReducer = dynReducer(initState, reducer)
