import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'
import Skeleton from 'react-loading-skeleton'

import { ClassName, STProductPageLauncher } from './style'
import { MediaQuery } from '../../../../style/constants/media-queries'
import { Button } from '../../../../components/common/button/component'
import { Routes } from '../../../../constants/routes'
import { LazySVG } from '../../../../components/svgs/lazy-svg/component'
import { Trans } from '../../../../components/common/intl/trans'
import { Config } from '../../../../config'
import { StoreState } from '../../../../models/app/model'
import { DangerTrans } from '../../../../components/common/intl/danger-trans'
import { Dollars } from '../../../../components/common/dollars/component'
import { useListProduct } from '../../../../hooks/api/use-list-product'
import { ProductIds } from '../../../../constants/product-ids'
import { Colors } from '../../../../style/theme/colors'
import { ProductButtonLauncher } from '../../../../components/common/product-button/launcher/component'

import backgroundSM from '../../../../imgs/pngs/launcher-landing-background-sm.png'
import backgroundMD from '../../../../imgs/pngs/launcher-landing-background-md.png'
import backgroundLG from '../../../../imgs/pngs/launcher-landing-background-lg.png'
import background2x from '../../../../imgs/pngs/launcher-landing-background.png'

// ~~~~~~ Constants

const ArrowLeftIcon = LazySVG('icons/arrow-long-left')

const ArrowRightIcon = LazySVG('icons/arrow-long-right')

const GradientLogo = LazySVG('logos/launcher-gradient')

// ~~~~~~ Component

export const ProductPageLauncherContent = () => {
  // ~~~~~~ Hooks

  useListProduct(ProductIds.Launcher)

  const navigate = useNavigate()

  // ~~~~~ State

  const metaProducts = useSelector((state: StoreState) => state.launcher)

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  // ~~~~~~ Computed

  const isRunning =
    !currentUser.isSessionChecked ||
    metaProducts.uiStatus === 'running' ||
    metaProducts.uiStatus === 'init'

  const productYearly = metaProducts.list.find((product) => product.interval === 'year') || {
    price: '??',
  }

  // ~~~~~~ Handlers

  function onClickGoToMain() {
    navigate(Routes.Root)
  }

  function onClickStartTrial() {
    navigate(Routes.LauncherStartTrial)
  }

  function onClickPurchase() {
    navigate(Routes.LauncherPurchase)
  }

  // ~~~~~~ Render

  return (
    <STProductPageLauncher>
      {/* Info + Trial */}

      <div className={`${ClassName}--top`}>
        {/* Background table/mobile */}

        <picture className={`${ClassName}--top--bg-pic`}>
          <source media={`(max-width: ${MediaQuery.SM})`} srcSet={backgroundSM} />
          <source media={`(max-width: ${MediaQuery.MD})`} srcSet={backgroundMD} />
          <img srcSet="data:,x" alt="launcher" />
        </picture>

        {/* Info */}

        <div className={`${ClassName}--top--left`}>
          {/* Back */}

          <div className={`${ClassName}--top--left--back`}>
            <Button
              className={`${ClassName}--top--left--back--action`}
              colorType="only-link"
              width="unset"
              padding="0"
              intlId="pages.products.common.GoToMain"
              active={true}
              disabled={false}
              running={false}
              onClick={onClickGoToMain}
              IconLeft={ArrowLeftIcon}
            />
          </div>

          <div style={{ margin: 'auto', maxWidth: 500 }}>
            {/* Product logo */}

            <div className={`${ClassName}--top--left--logo`}>
              <GradientLogo size={32} className={`${ClassName}--top--left--logo--img`} />
            </div>

            {/* Title */}

            <div className={`${ClassName}--top--left--title`}>
              <Trans id="pages.products.launcher.Title" />
            </div>

            {/* Info list */}

            <div className={`${ClassName}--top--left--info-list`}>
              {/* Title */}

              <div className={`${ClassName}--top--left--info-list--title`}>
                <Trans id="pages.products.common.WhatToKnow" />
              </div>

              {/* Points */}

              <ul className={`${ClassName}--top--left--info-list--points`}>
                {/* X days... */}

                <div className={`${ClassName}--top--left--info-list--points--point`}>
                  <span className={`${ClassName}--top--left--info-list--points--point--bullet`}>
                    •
                  </span>
                  <Trans
                    id="pages.products.common.DayNoObligationTrial"
                    values={{ value: Config.Trials.Launcher.days }}
                  />
                </div>

                {/* No card required... */}

                <div className={`${ClassName}--top--left--info-list--points--point`}>
                  <span className={`${ClassName}--top--left--info-list--points--point--bullet`}>
                    •
                  </span>
                  <Trans id="pages.products.common.NoCreditCardRequired" />
                </div>

                {/* Only windows... */}

                <div className={`${ClassName}--top--left--info-list--points--point`}>
                  <span className={`${ClassName}--top--left--info-list--points--point--bullet`}>
                    •
                  </span>
                  <span>
                    <Trans id="pages.products.launcher.WindowsOnly" />
                  </span>
                  <span> </span>
                  <span className={`${ClassName}--top--left--info-list--points--point--caption`}>
                    ({Config.Others.Launcher.Versions.Windows})
                  </span>
                </div>
              </ul>
            </div>

            {/* Action Trial */}

            <ProductButtonLauncher
              intlId="pages.products.common.StartTrial"
              active={!isRunning}
              disabled={isRunning}
              running={isRunning}
              onClick={onClickStartTrial}
            />
          </div>
        </div>

        {/* Right image (only Desktop) */}

        <div className={`${ClassName}--top--right`}>
          {/*  */}

          <picture className={`${ClassName}--top--right--pic`}>
            <source media={`(max-width: ${MediaQuery.MD})`} srcSet="data:,x" />
            <img srcSet={`${backgroundLG}, ${background2x} 2x`} alt="launcher" />
          </picture>

          {/* - */}
        </div>
      </div>

      {/* Purchase */}

      <div className={`${ClassName}--bottom`}>
        <div className={`${ClassName}--bottom--left`}>
          {/* Want to buy... */}

          <div className={`${ClassName}--bottom--left--title`}>
            <Trans id="pages.products.launcher.JustBuyLicense" />
          </div>

          {/* Purchase for... */}

          <div className={`${ClassName}--bottom--left--subtitle`}>
            {isRunning ? (
              <Skeleton />
            ) : (
              <DangerTrans
                template="...buy for only <c>{value}</c> <d>per license/year</d>"
                id="pages.products.launcher.LicenseInfo"
                values={{
                  value: productYearly.price,

                  c: (chunks: string) => (
                    <b>
                      <Dollars value={Number(chunks)} />
                    </b>
                  ),

                  d: (chunks: string) => <b>{chunks}</b>,
                }}
              />
            )}
          </div>
        </div>

        {/* Action (buy) */}

        <div className={`${ClassName}--bottom--right`}>
          {/* Style for desktop */}

          <Button
            colorType="secondary-link"
            className={`${ClassName}--bottom--right--action--big`}
            intlId="pages.products.common.BuyLicense"
            IconRight={ArrowRightIcon}
            iconLoadColor={Colors.WOLF_00_00}
            active={!isRunning}
            disabled={isRunning}
            running={isRunning}
            onClick={onClickPurchase}
          />

          {/* Style for tablet/mobile */}

          <Button
            colorType="primary-link"
            className={`${ClassName}--bottom--right--action--small`}
            intlId="pages.products.common.BuyLicense"
            IconRight={ArrowRightIcon}
            active={!isRunning}
            disabled={isRunning}
            running={isRunning}
            onClick={onClickPurchase}
          />
        </div>
      </div>

      {/* - */}
    </STProductPageLauncher>
  )
}
