import { AppInitState } from '../../../../models/app/model'
import { dynReducer } from '../../../../utils/store'
import { SubscriptionMC, SubscriptionMT } from '../../../actions-mutators/subscriptions/mutators'
import {
  ApiStoreSubscriptionsAT,
  ApiStoreSubscriptionsAC,
} from '../../../actions/api-store/subscriptions/actions'
import {
  EpicApiStoreCardAddMT,
  EpicApiStoreCardAddMC,
} from '../../../epics/api-store/card/add/mutators'
import {
  ApiStoreSubscriptionsCancelPlanEpicMC,
  ApiStoreSubscriptionsCancelPlanEpicMT,
} from '../../../epics/api-store/subscriptions/cancel-plan/mutators'
import {
  ApiStoreSubscriptionsChangePaymentMethodEpicMT,
  ApiStoreSubscriptionsChangePaymentMethodEpicMC,
} from '../../../epics/api-store/subscriptions/change-payment-method/mutators'
import {
  ApiStoreSubscriptionsChangePlanEpicMC,
  ApiStoreSubscriptionsChangePlanEpicMT,
} from '../../../epics/api-store/subscriptions/change-plan/mutators'
import {
  ApiStoreSubscriptionsReactivatePlanEpicMT,
  ApiSubscriptionsReactivatePlanEpicMC,
} from '../../../epics/api-store/subscriptions/reactivate-plan/mutators'

const initState = AppInitState.tmpSubscription

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ Clear subscription
// ####################################################################################################

reducer[SubscriptionMT.CLEAR_TMP_SUBSCRIPTION] = (state: State): State => {
  return {
    ...state,
    ...initState,
  }
}

// ####################################################################################################
// ~~~~~~ Set subscription
// ####################################################################################################

reducer[SubscriptionMT.SET_TMP_SUBSCRIPTION] = (
  state: State,
  { payload }: ReturnType<typeof SubscriptionMC.setTmpSubscription>,
): State => {
  return {
    ...initState,
    ...payload.subscription,
    product_name:
      payload.subscription.interval === 'month'
        ? 'Monthly subscription'
        : payload.subscription.interval === 'year'
          ? 'Annual subscription'
          : payload.subscription.product_name,
  }
}

// ####################################################################################################
// ~~~~~~ Set subscription card
// ####################################################################################################

reducer[SubscriptionMT.SET_TMP_SUBSCRIPTION_CARD] = (
  state: State,
  { payload }: ReturnType<typeof SubscriptionMC.setTmpSubscriptionCard>,
): State => {
  return {
    ...state,
    card_brand: payload.card.brand,
    card_last4: payload.card.last4,
  }
}

// ####################################################################################################
// ~~~~~~ Set interval
// ####################################################################################################

reducer[SubscriptionMT.SET_TMP_INTERVAL] = (
  state: State,
  { payload }: ReturnType<typeof SubscriptionMC.setTmpInterval>,
): State => {
  return {
    ...state,
    interval: payload.interval,
  }
}

// ####################################################################################################
// ~~~~~~ Cancel Plan - START
// ####################################################################################################

reducer[ApiStoreSubscriptionsAT.CANCEL_PLAN] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreSubscriptionsAC.cancelPlan>,
): State => {
  if (state.subscription_id !== payload.subscriptionId) return state

  return {
    ...state,
    uiStatus: 'running',
    errors: [],
  }
}

// ####################################################################################################
// ~~~~~~ Cancel Plan - OK
// ####################################################################################################

reducer[ApiStoreSubscriptionsCancelPlanEpicMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreSubscriptionsCancelPlanEpicMC.ok>,
): State => {
  if (state.subscription_id !== payload.subscription_id) return state

  return {
    ...state,
    ...payload,
    uiStatus: 'completed',
    errors: [],
  }
}

// ####################################################################################################
// ~~~~~~ Cancel Plan - ERROR
// ####################################################################################################

reducer[ApiStoreSubscriptionsCancelPlanEpicMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreSubscriptionsCancelPlanEpicMC.error>,
): State => {
  if (state.subscription_id !== payload.subscriptionId) return state

  return {
    ...state,
    uiStatus: 'init',
    errors: payload.errors,
  }
}

// ####################################################################################################
// ~~~~~~ Reactivate Plan - START
// ####################################################################################################

reducer[ApiStoreSubscriptionsAT.REACTIVATE_PLAN] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreSubscriptionsAC.cancelPlan>,
): State => {
  if (state.subscription_id !== payload.subscriptionId) return state

  return {
    ...state,
    uiStatus: 'running',
    errors: [],
  }
}

// ####################################################################################################
// ~~~~~~ Reactivate Plan - OK
// ####################################################################################################

reducer[ApiStoreSubscriptionsReactivatePlanEpicMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof ApiSubscriptionsReactivatePlanEpicMC.ok>,
): State => {
  if (state.subscription_id !== payload.subscription_id) return state

  return {
    ...state,
    ...payload,
    uiStatus: 'completed',
    errors: [],
  }
}

// ####################################################################################################
// ~~~~~~ Reactivate Plan - ERROR
// ####################################################################################################

reducer[ApiStoreSubscriptionsReactivatePlanEpicMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof ApiSubscriptionsReactivatePlanEpicMC.error>,
): State => {
  if (state.subscription_id !== payload.subscriptionId) return state

  return {
    ...state,
    uiStatus: 'init',
    errors: payload.errors,
  }
}

// ####################################################################################################
// ~~~~~~ Change Plan - START
// ####################################################################################################

reducer[ApiStoreSubscriptionsAT.CHANGE_PLAN] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreSubscriptionsAC.changePlan>,
): State => {
  if (state.subscription_id !== payload.subscriptionId) return state

  return {
    ...state,
    uiStatus: 'running',
    errors: [],
  }
}

// ####################################################################################################
// ~~~~~~ Change Plan - OK
// ####################################################################################################

reducer[ApiStoreSubscriptionsChangePlanEpicMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreSubscriptionsChangePlanEpicMC.ok>,
): State => {
  if (state.subscription_id !== payload.data.subscription_id) return state

  return {
    ...payload.data,
    uiStatus: 'completed',
    errors: [],
  }
}

// ####################################################################################################
// ~~~~~~ Change Plan - ERROR
// ####################################################################################################

reducer[ApiStoreSubscriptionsChangePlanEpicMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreSubscriptionsChangePlanEpicMC.error>,
): State => {
  if (state.subscription_id !== payload.subscriptionId) return state

  return {
    ...state,
    uiStatus: 'init',
    errors: payload.errors,
  }
}

// ####################################################################################################
// ~~~~~~ Add card - OK - Add default card to this subscription if hasn't one
// ####################################################################################################

reducer[EpicApiStoreCardAddMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiStoreCardAddMC.ok>,
): State => {
  if (!payload.data.card.is_default || state.payment_method_id) return state

  return {
    ...state,
    card_brand: payload.data.card.brand,
    card_last4: payload.data.card.last4,
    payment_method_id: payload.data.card.payment_method_id,
  }
}

// ####################################################################################################
// ~~~~~~ Change payment method
// ####################################################################################################

reducer[ApiStoreSubscriptionsAT.CHANGE_PAYMENT_METHOD] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreSubscriptionsAC.changePaymentMethod>,
): State => {
  if (payload.subscriptionId !== state.subscription_id) return state

  return {
    ...state,
    uiStatus: 'running',
  }
}

// ####################################################################################################
// ~~~~~~ Change payment method - OK
// ####################################################################################################

reducer[ApiStoreSubscriptionsChangePaymentMethodEpicMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreSubscriptionsChangePaymentMethodEpicMC.ok>,
): State => {
  if (payload.data.subscriptionId !== state.subscription_id) return state

  const { card } = payload

  return {
    ...state,
    uiStatus: 'init',
    payment_method_id: card.payment_method_id,
    card_brand: card.brand,
    card_last4: card.last4,
  }
}

// ####################################################################################################
// ~~~~~~ Change payment method - ERROR
// ####################################################################################################

reducer[ApiStoreSubscriptionsChangePaymentMethodEpicMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreSubscriptionsChangePaymentMethodEpicMC.error>,
): State => {
  if (payload.subscriptionId !== state.subscription_id) return state

  return {
    ...state,
    ...payload,
  }
}

// ~~~~~~

export const tmpSubscriptionReducer = dynReducer(initState, reducer)
