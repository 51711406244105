import { Observable, of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { catchError, map } from 'rxjs/operators'
import { SubscriptionModel } from '../../../models/subscriptions/model'
import { ApiRoutes } from '../constants/routes'
import { ApiHeadersUtils } from '../utils/headers'

type Req = DN.Models.Subscription.Api.Create.Req
type Res = DN.Models.Subscription.Api.Create.Res
type ResParsed = DNApiResParsed<DN.Models.Subscription.Api.Create.ResParsed>
type ResError = DNApiError<DN.Models.Subscription.Api.Create.ResError>

const { Create } = ApiRoutes.Subscriptions

// Buy free/trial product
export const apiStoreSubscriptionsCreate$ = (
  token: string,
  prodcutId: DN.Models.Product.ProductId,
  data: Req,
  headers?: AppHeaders,
) => {
  const finalHeaders = token
    ? ApiHeadersUtils.genHeadersWithAuth(token, headers)
    : ApiHeadersUtils.genHeaders(headers)

  return ajax<Res>({
    ...finalHeaders,
    method: Create.Method,
    url: Create.getUrl(),
    body: data,
  }).pipe(
    map((res): ResParsed => {
      return {
        status: res.status,
        response: SubscriptionModel.fromBackToFront(res.response),
      }
    }),
    catchError(
      (res: DNApiRes<void>): Observable<ResError> =>
        res.status === 412 && prodcutId === 'broadcast-subscription'
          ? of({
              status: res.status,
              response: [{ id: 'api.subscriptions.create.Broadcast412' }],
            })
          : of({
              status: res.status,
              response: [{ id: 'api.subscriptions.create.Error' }],
            }),
    ),
  )
}
