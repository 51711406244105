const prefix = 'mt-store-api-checkout-set-default-card-epic'

// ~~~~~~ Mutators Types

export const ApiStoreCheckoutSetDefaultCardEpicMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const ApiStoreCheckoutSetDefaultCardEpicMC = {
  ok: (data: DN.Models.Checkout.Api.SetDefaultCard.ResParsed, card: DN.Models.Card.ModelFront) => ({
    type: ApiStoreCheckoutSetDefaultCardEpicMT.OK,
    payload: {
      data,
      card,
    },
  }),

  error: (errors: DN.Models.Checkout.Api.SetDefaultCard.ResError) => ({
    type: ApiStoreCheckoutSetDefaultCardEpicMT.ERROR,
    error: true,
    payload: {
      errors,
    },
  }),
} as const
