import styled from 'styled-components'
import { genSTUserZonePlansRootAddProductAltCommonStyle } from '../common-style'

// ~~~~~~ Constants

export const ClassName = 'user-zone-plans-root-add-product-alt-launcher'

// ~~~~~~ Component

export const STUserZonePlansRootAddProductAltLauncher = styled.div`
  ${genSTUserZonePlansRootAddProductAltCommonStyle(ClassName)}
`
