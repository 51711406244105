const prefix = 'mt-montage-latest-version-epic'

// ~~~~~~ Mutators Types

export const EpicApiMontageReleaseInfoMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const EpicApiMontageReleaseInfoMC = {
  ok: (data: DN.Models.MontageReleaseInfo.Api.Get.ResParsed) => ({
    type: EpicApiMontageReleaseInfoMT.OK,
    payload: data,
  }),

  error: (data: DN.Models.MontageReleaseInfo.Api.Get.ResError) => ({
    type: EpicApiMontageReleaseInfoMT.ERROR,
    error: true,
    payload: {
      errors: data,
    },
  }),
} as const
