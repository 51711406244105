import { fromEvent } from 'rxjs'
import { take } from 'rxjs/operators'
import { Config } from '../../config'
import { WindowEvents } from '../../constants/window-events'
import { UtilsObservables } from '@dn/utils'

const { LeadDyno } = window

if (LeadDyno) {
  LeadDyno.key = Config.ThirdParty.LeadDyno.Key

  LeadDyno.recordVisit()
  LeadDyno.autoWatch()

  fromEvent(window, WindowEvents.Load)
    .pipe(take(1))
    .subscribe(UtilsObservables.tapLogObserver('LeadDyno'))
}
