import { useAuth0 } from '@auth0/auth0-react'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { StoreProductIds } from '../../constants/product-ids'
import { StoreState } from '../../models/app/model'
import { ApiStoreProductsAC } from '../../store/actions/api-store/products/actions'

export const useListProduct = (productId?: DN.Models.Product.ProductId) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const { isLoading } = useAuth0()

  // ~~~~~~ State

  const products = useSelector((store: StoreState) =>
    productId ? store[StoreProductIds[productId]] : undefined,
  )

  // ~~~~~~ Effects

  useEffect(() => {
    if (isLoading || !productId || !products) return

    if (products.uiStatus === 'init' && !products.errors.length) {
      dispatch(ApiStoreProductsAC.listProducts(productId))
    }
  }, [dispatch, isLoading, productId, products])
}
