import styled from 'styled-components'
import { MediaQuery } from '../../../style/constants/media-queries'
import { Colors } from '../../../style/theme/colors'
import { Fonts } from '../../../style/theme/fonts'
import { Layout } from '../../../style/theme/layout'
import { theme } from '../../../style/utils/theme'

// ~~~~~~ Component

export const STLayoutTopContent = styled.div`
  width: 100%;
  height: 100vh;

  display: flex;
  flex-direction: column;

  background-color: ${theme(Colors.WOLF_00_00)};

  .bottom-ref {
    position: absolute;
    bottom: 0;
  }

  .layout-top-content-top,
  .layout-top-content-content {
    width: 100%;
  }

  .layout-top-content-top {
    background-color: ${theme(Colors.WOLF_90_90)};

    z-index: 1;
    height: 56px;
    min-height: 56px;

    padding: 0px ${Layout.Spacing[5]}px 0px ${Layout.Spacing[5]}px;
    width: calc(100% - ${Layout.Spacing[5] * 2}px);

    ${Fonts.Scale.Body}

    display: flex;
    justify-content: center;

    .layout-top-content-top-content {
      max-width: ${MediaQuery.LG};

      width: 100%;

      display: flex;
      align-items: center;
      justify-content: space-between;
    }
  }

  .layout-top-header {
    padding-left: 120px;
    padding-right: 120px;

    width: calc(100% - 240px);

    background-color: ${theme(Colors.WOLF_05_05)};
  }

  .layout-top-nav {
    max-width: ${MediaQuery.LG};
    margin-left: auto;
    margin-right: auto;

    ${Layout.PaddingWidth.Default}
  }

  .layout-top-content-content {
    /* Fix menu not clickable */
    z-index: 0;

    max-width: ${MediaQuery.LG};
    margin-left: auto;
    margin-right: auto;

    ${Layout.PaddingWidth.Default}

    &.no-padding {
      padding-left: 0px;
      padding-right: 0px;
      width: 100%;
    }
  }

  .layout-top-footer {
    margin-bottom: 0;
    margin-top: auto;
  }

  /* 1280 */

  @media (max-width: ${MediaQuery.MaxWidth2k}) {
    .layout-top-content-top,
    .layout-top-header,
    .layout-top-nav,
    .layout-top-content-content {
      ${Layout.PaddingWidth.Desktop}

      &.no-padding {
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
      }
    }
  }

  /* 960 */

  @media (max-width: ${MediaQuery.MD}) {
    .layout-top-content-top,
    .layout-top-header,
    .layout-top-nav,
    .layout-top-content-content {
      ${Layout.PaddingWidth.Tablet}

      &.no-padding {
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
      }
    }
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    .layout-top-content-top,
    .layout-top-header,
    .layout-top-nav,
    .layout-top-content-content {
      ${Layout.PaddingWidth.Mobile}

      &.no-padding {
        padding-left: 0px;
        padding-right: 0px;
        width: 100%;
      }
    }
  }
`
