import { UtilsLang } from '@dn/utils'
import { FormattedNumber, IntlProvider } from 'react-intl'
import { Config } from '../../../config'

// ~~~~~~ Types

type Props = {
  value: number
}

// ~~~~~~ Component

export const Dollars: React.FC<Props> = ({ value }) => {
  // ~~~~~~ Render

  return (
    <IntlProvider locale={UtilsLang.getBrowserLang(Config.Intl.DefaultLocale)}>
      <FormattedNumber
        value={value}
        // eslint-disable-next-line react/style-prop-object
        style="currency"
        currency="USD"
        minimumFractionDigits={0}
      />
    </IntlProvider>
  )
}
