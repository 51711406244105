import styled from 'styled-components'

import { Transition } from '../../../style/animations/css-animations'

import { Colors } from '../../../style/theme/colors'
import { Fonts } from '../../../style/theme/fonts'
import { Layout } from '../../../style/theme/layout'
import { theme } from '../../../style/utils/theme'
import { MediaQuery } from '../../../style/constants/media-queries'

// ~~~~~~ Constants

export const ClassName = 'intl-input'

const ShowWidth = 32

// ~~~~~~ Helpers

const getFocussedBorderColor = (hasErrors: boolean) => {
  return hasErrors ? Colors.BROOKLYN_40_40 : Colors.WOLF_90_90
}

// ~~~~~~ Types

export type STProps = {
  $hideBorder?: true
  $hasErrors: boolean
  $height?: number
  $hasIcon?: boolean
  $disabled: boolean
  $iconWidth?: number
  $isPassword: boolean
  $textAlign?: 'center' | 'left' | 'right'
}

// ~~~~~~ Component

export const STIntlInput = styled.div<STProps>`
  width: 100%;

  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'default')};

  position: relative;

  /* Label */

  .${ClassName}--label {
    ${Fonts.Weight[700]}
    ${Fonts.Scale.OldD}

    user-select: none;

    color: ${theme(Colors.WOLF_90_90)};

    margin-bottom: 4px;
  }

  /* Sublabel */

  .${ClassName}--sublabel {
    ${Fonts.Weight[400]}
    ${Fonts.Scale.BodyBig}

    user-select: none;

    color: ${theme(Colors.WOLF_90_90)};

    padding-bottom: 12px;
  }

  /* Extra icon */

  .${ClassName}--icon-container {
    position: absolute;
    height: ${({ $height }) => ($height ? $height : 56)}px;

    display: flex;
    justify-content: center;
    align-items: center;

    margin-left: 8px;
    margin-right: 8px;

    .intl-icon {
      width: 24px;
      height: 24px;

      display: flex;
      justify-content: center;
      align-items: center;

      svg {
        padding: 2px;
        width: ${({ $iconWidth }) => $iconWidth ?? 20}px;
      }
    }
  }

  /* Input */

  .${ClassName}--input {
    position: relative;

    /* Eye pass reveal */

    .${ClassName}--input--show-pass {
      cursor: pointer;
      position: absolute;

      right: 16px;
      top: 14px;
      z-index: 1;

      svg {
        padding: 2px;
        width: 24px;

        path {
          fill: ${theme(Colors.WOLF_90_90)};
        }
      }
    }

    /* Input */

    input {
      cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'text')};

      ${Layout.Border.Radius.A}

      height: ${(props) => (props.$height ? `${props.$height - 35}px` : '20px')};

      /* Text */

      ${Fonts.Scale.BodyBig}
      ${Fonts.Weight[500]}

      text-align: ${({ $textAlign }) => ($textAlign ? $textAlign : 'left')};

      width: calc(
        100% -
          (
            20px +
              ${({ $hasIcon, $isPassword }) =>
                ($hasIcon ? 36 : 16) + ($isPassword ? ShowWidth : 0)}px
          )
      );

      padding-top: 16px;
      padding-right: ${({ $isPassword }) => ($isPassword ? 16 + ShowWidth : 16)}px;

      padding-bottom: 16px;
      padding-left: ${({ $hasIcon }) => ($hasIcon ? 36 : 16)}px;

      opacity: ${({ $disabled }) => ($disabled ? 0.5 : 1)};

      /* Colors */

      background-color: ${theme(Colors.WOLF_05_05)};

      color: ${theme(Colors.WOLF_90_90)};

      /* Border */

      border: ${({ $hideBorder }) =>
        $hideBorder ? 'none' : `solid ${Layout.Border.Size.Number.B}px`};

      border-color: ${theme(Colors.WOLF_40_40)};

      ${Transition.BorderColorFaster}

      &:hover {
        border-color: ${theme(Colors.WOLF_90_90)};
      }

      &:focus {
        border-color: ${({ $hasErrors }) => theme(getFocussedBorderColor($hasErrors))};
      }

      &::placeholder {
        color: ${theme(Colors.WOLF_40_40)};
      }
    }
  }

  /* Errors (if boxed) */

  .${ClassName}--errors {
    margin-top: ${Layout.Spacing[2]}px;

    display: flex;

    .${ClassName}--errors--icon {
      width: 16px;
      height: 16px;

      margin-top: 3px;
      margin-right: ${Layout.Spacing[1]}px;

      svg path {
        fill: ${theme(Colors.BROOKLYN_40_40)};
      }
    }
  }

  /* 1280 */

  @media (max-width: ${MediaQuery.MaxWidth2k}) {
    /* Label */

    .${ClassName}--label {
      ${Fonts.Scale.Subtitle}
    }

    /* Sublabel */

    .${ClassName}--sublabel {
      ${Fonts.Scale.Body}
    }
  }

  /* 960 */

  @media (max-width: ${MediaQuery.MD}) {
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    /* Label */

    .${ClassName}--label {
      ${Fonts.Scale.BodyBig}
    }
  }
`
