import { useNavigate } from 'react-router'
import { useDispatch } from 'react-redux'
import { UtilsEncDec } from '@dn/utils'

import { Button } from '../../../../../../components/common/button/component'
import { InfosBox } from '../../../../../../components/common/infos/box/component'
import { ClassName, STUserZonePlansProductDetailBcastFree } from './style'
import { RouteDataBcastUpg2Premium, Routes } from '../../../../../../constants/routes'
import { Config } from '../../../../../../config'
import { SubscriptionMC } from '../../../../../../store/actions-mutators/subscriptions/mutators'
import { ModalsMC } from '../../../../../../store/actions-mutators/modals/mutators'
import { ModalsIds } from '../../../../../../constants/modals'
import { Trans } from '../../../../../../components/common/intl/trans'
import { LazySVG } from '../../../../../../components/svgs/lazy-svg/component'
import { Dollars } from '../../../../../../components/common/dollars/component'
import { DangerTrans } from '../../../../../../components/common/intl/danger-trans'

// ~~~~~~ Constants

const StartBcastingIcon = LazySVG('icons/arrow-right-up')

const UpgradeIcon = LazySVG('icons/arrow-circle-up')

// ~~~~~~ Types

type Props = {
  isNew: boolean
  sub: DN.Models.Subscription.ModelFront
}

// ~~~~~~ Component

export const UserZonePlansProductDetailBcastFree: React.FC<Props> = ({ isNew, sub }) => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  const dispatch = useDispatch()

  // ~~~~~~ Computed

  const isCanceled = !!sub.cancel_at

  // ~~~~~~ Handlers

  function onClickStartBcasting() {
    window.open(Config.BroadcastWeb.Url, '_blank')
  }

  function onClickCancelPlan() {
    if (!sub) return

    dispatch(SubscriptionMC.setTmpSubscription(sub))

    dispatch(ModalsMC.open(ModalsIds.ConfirmCancelPlan))
  }

  function onClickUpgradeToPremium() {
    if (!sub) return

    const dataObj: RouteDataBcastUpg2Premium = {
      subId: sub.subscription_id,
      backPath: window.location.pathname,
      backIntl: 'v2.pages.plan-details.PlanDetails',
    }

    const data = UtilsEncDec.base64UrlEncode(JSON.stringify(dataObj))

    navigate(Routes.genUserZonePlansBcastUpg2Premium(data))
  }

  // ~~~~~~ Render

  return (
    <STUserZonePlansProductDetailBcastFree>
      {/* Plan Details */}

      <div className={`${ClassName}--title`}>
        <Trans id="v2.pages.plan-details.PlanDetails" />
      </div>

      {/* Info for new Free subs */}

      {isNew ? (
        <InfosBox
          className={`${ClassName}--info`}
          titleIntl="v2.pages.plan-details.new-subscription-description.broadcast.Title"
          contentText={[
            {
              id: 'v2.pages.plan-details.new-subscription-description.broadcast.free-broadcast-description.FreeBroadcastAdded',
            },
            {
              id: 'v2.pages.plan-details.new-subscription-description.broadcast.free-broadcast-description.EnjoyFreePremiumBroadcast',
            },
          ]}
        >
          <div style={{ marginTop: 8 }}>
            <Button
              colorType="quaternary-link"
              intlId="common.StartBroadcasting"
              IconRight={StartBcastingIcon}
              rightIconMarginLeft={4}
              padding="0"
              size="s"
              fontWeight="500"
              running={false}
              disabled={false}
              active={true}
              onClick={onClickStartBcasting}
            />
          </div>
        </InfosBox>
      ) : undefined}

      {/* Details */}

      <div className={`${ClassName}--details`}>
        {/* Subscription */}

        <div className={`${ClassName}--details--row`}>
          {/* Title */}

          <div className={`${ClassName}--details--row--title`}>
            <Trans id="pages.user-zone.plans-tab.Subscription" />
          </div>

          {/* Price/Upgrade */}

          <div>
            <div className={`${ClassName}--details--row--highlight`}>
              <Dollars value={sub.amount} />
            </div>

            <div>
              <Button
                colorType="quaternary-link"
                intlId="pages.common.UpgradePremium"
                intlValues={{ value: 'Premium' }}
                IconLeft={UpgradeIcon}
                leftIconMarginRight={4}
                padding="0"
                size="s"
                fontWeight="500"
                running={false}
                disabled={false}
                active={true}
                onClick={onClickUpgradeToPremium}
              />
            </div>
          </div>
        </div>

        {/* Latest Version */}

        <div className={`${ClassName}--details--row`}>
          {/* Title */}

          <div className={`${ClassName}--details--row--title`}>
            <Trans id="v2.pages.plan-details.LatestVersion" />
          </div>

          {/* Action: go bcast.online */}

          <div>
            <Button
              colorType="quaternary-link"
              intlId="wildcard"
              intlValues={{ value: 'Broadcast.online' }}
              IconRight={StartBcastingIcon}
              rightIconMarginLeft={4}
              padding="0"
              size="s"
              fontWeight="500"
              running={false}
              disabled={false}
              active={true}
              onClick={onClickStartBcasting}
            />
          </div>
        </div>
      </div>

      {/* Cancel Plan */}

      {!isCanceled ? (
        <>
          <div className={`${ClassName}--title`}>
            <Trans id="v2.pages.plan-details.CancelPlan" />
          </div>

          <div className={`${ClassName}--cancel`}>
            <div className={`${ClassName}--cancel--line`}>
              <DangerTrans
                template="... <c>{value}</c>..."
                id="v2.pages.plan-details.CancelHelp"
                values={{
                  value: Config.Emails.Orders,
                  c: (chunks: string) => <b>{chunks}</b>,
                }}
              />
            </div>

            <div className={`${ClassName}--cancel--line`}>
              <Trans id="v2.pages.plan-details.IfCancelFreeBroadcast" />
            </div>

            <div className={`${ClassName}--cancel--action`}>
              <Button
                colorType="quinary-link"
                intlId="dialogs.confirm.cancel-plan.Title"
                rightIconMarginLeft={4}
                padding="0"
                size="s"
                fontWeight="500"
                running={false}
                disabled={false}
                active={true}
                onClick={onClickCancelPlan}
              />
            </div>
          </div>
        </>
      ) : undefined}

      {/* - */}
    </STUserZonePlansProductDetailBcastFree>
  )
}
