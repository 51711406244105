import { StateObservable, ofType } from 'redux-observable'
import { Observable, EMPTY } from 'rxjs'
import { tap, mergeMap } from 'rxjs/operators'
import { ToastUtil } from '../../../../components/common/toast/util'
import { StoreState } from '../../../../models/app/model'
import {
  EpicApiStoreBillingUpdateMC,
  EpicApiStoreBillingUpdateMT,
} from '../../api-store/billing/update/mutators'
import { EpicApiStoreCardAddMC, EpicApiStoreCardAddMT } from '../../api-store/card/add/mutators'
import {
  EpicApiStoreCardChangeMC,
  EpicApiStoreCardChangeMT,
} from '../../api-store/card/change/mutators'
import { EpicApiStoreCardRemMC, EpicApiStoreCardRemMT } from '../../api-store/card/rem/mutators'
import {
  ApiStoreCheckoutSetDefaultCardEpicMC,
  ApiStoreCheckoutSetDefaultCardEpicMT,
} from '../../api-store/checkout/set-default-card/mutators'
import {
  ApiStoreSubscriptionsCancelPlanEpicMC,
  ApiStoreSubscriptionsCancelPlanEpicMT,
} from '../../api-store/subscriptions/cancel-plan/mutators'
import {
  ApiStoreSubscriptionsChangePaymentMethodEpicMC,
  ApiStoreSubscriptionsChangePaymentMethodEpicMT,
} from '../../api-store/subscriptions/change-payment-method/mutators'
import {
  ApiStoreSubscriptionsChangePlanEpicMC,
  ApiStoreSubscriptionsChangePlanEpicMT,
} from '../../api-store/subscriptions/change-plan/mutators'
import {
  ApiSubscriptionsReactivatePlanEpicMC,
  ApiStoreSubscriptionsReactivatePlanEpicMT,
} from '../../api-store/subscriptions/reactivate-plan/mutators'

type Action =
  // Billing
  | ReturnType<typeof EpicApiStoreBillingUpdateMC.ok>

  // Card
  | ReturnType<typeof EpicApiStoreCardAddMC.ok>
  | ReturnType<typeof EpicApiStoreCardChangeMC.ok>
  | ReturnType<typeof EpicApiStoreCardRemMC.ok>

  // Checkout
  | ReturnType<typeof ApiStoreCheckoutSetDefaultCardEpicMC.ok>

  // Subscriptions
  | ReturnType<typeof ApiStoreSubscriptionsCancelPlanEpicMC.ok>
  | ReturnType<typeof ApiSubscriptionsReactivatePlanEpicMC.ok>
  | ReturnType<typeof ApiStoreSubscriptionsChangePaymentMethodEpicMC.ok>
  | ReturnType<typeof ApiStoreSubscriptionsChangePlanEpicMC.ok>

export const toastSuccessEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(
      // Billing
      EpicApiStoreBillingUpdateMT.OK,

      // Card
      EpicApiStoreCardAddMT.OK,
      EpicApiStoreCardChangeMT.OK,
      EpicApiStoreCardRemMT.OK,

      // Checkout
      ApiStoreCheckoutSetDefaultCardEpicMT.OK,

      // Subscriptions
      ApiStoreSubscriptionsCancelPlanEpicMT.OK,
      ApiStoreSubscriptionsReactivatePlanEpicMT.OK,
      ApiStoreSubscriptionsChangePaymentMethodEpicMT.OK,
      ApiStoreSubscriptionsChangePlanEpicMT.OK,
    ),

    tap(({ type, payload }) => {
      let id: IntlMsgId = 'wildcard'
      let values: { [key: string]: any } | undefined

      switch (type) {
        case EpicApiStoreBillingUpdateMT.OK:
          id = 'api.billing.update.Ok'
          break

        case EpicApiStoreCardAddMT.OK:
          id = 'api.card.add-card.Ok'
          break

        case EpicApiStoreCardChangeMT.OK:
          id = 'api.card.change-card.Ok'
          break

        case EpicApiStoreCardRemMT.OK:
          id = 'api.card.rem-card.Ok'
          break

        case ApiStoreCheckoutSetDefaultCardEpicMT.OK:
          id = 'api.checkout.set-default-card.Ok'

          values = {
            value: payload.card.last4,
          }

          break

        case ApiStoreSubscriptionsCancelPlanEpicMT.OK:
          id = 'api.subscriptions.cancel-plan.Ok'

          break

        case ApiStoreSubscriptionsReactivatePlanEpicMT.OK:
          id = 'api.subscriptions.reactivate-plan.Ok'
          break

        case ApiStoreSubscriptionsChangePaymentMethodEpicMT.OK:
          id = 'api.subscriptions.change-payment-method.OkUpdated'
          // Sub (+)Add Payment method -> Change paymen method (opt)-> Add payment method
          // This has no sense (should (+)Add => (?)Change)
          // 'api.subscriptions.change-payment-method.OkAdded'

          values = {
            value: payload.card.last4,
          }

          break

        case ApiStoreSubscriptionsChangePlanEpicMT.OK:
          id =
            payload.kind === 'upgrade'
              ? 'api.subscriptions.change-plan.OkUpgraded'
              : payload.kind === 'month-to-year'
                ? 'api.subscriptions.change-plan.OkUpdatedToYear'
                : 'api.subscriptions.change-plan.OkUpdatedToMonth'

          break
      }

      ToastUtil.basic({
        kind: 'success',
        duration: 5000,
        intlIds: [{ id, values }],
      })
    }),

    mergeMap(() => EMPTY),
  )
