import { ofType, StateObservable } from 'redux-observable'
import { Observable } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'

import { StoreState } from '../../../../../models/app/model'
import { apiStoreBillingUpdate$ } from '../../../../../services/api-store/billing/update'
import { ApiStoreBillingAC, ApiStoreBillingAT } from '../../../../actions/api-store/billing/actions'

import { ApiUtils } from '../../../utils'
import { EpicApiStoreBillingUpdateMC } from './mutators'

type Action = ReturnType<typeof ApiStoreBillingAC.update>

export const apiStoreBillingUpdateEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiStoreBillingAT.UPDATE),

    mergeMap(({ payload }) => apiStoreBillingUpdate$(state$.value.currentUser.token, payload)),

    map((res) =>
      ApiUtils.isNotError(res)
        ? EpicApiStoreBillingUpdateMC.ok(res.response)
        : EpicApiStoreBillingUpdateMC.error(res.response),
    ),
  )
