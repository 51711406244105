// ~~~~~~ Types

export type RouteDataBcastPurchaseConfirm = {
  backPath: string
  backText: string
  interval: 'monthly' | 'yearly'
  subId: string
  subIdReq: boolean
}

export type RouteDataMontagePurchaseConfirm = {
  backPath: string
  backText: string
  qtty: number
  price: number
  productId: string
}

export type RouteDataLauncherPurchaseConfirm = {
  backPath: string
  backText: string
  qtty: number
  price: number
  productId: string
}

export type RouteDataAddPaymentMethod = {
  backPath: string
  backText: string
  successPath: string
}

export type RouteDataChangePaymentMethod = {
  backPath: string
  backText: string
  successPath: string
  subId: string
}

export type RouteDataBcastUpg2Premium = {
  backPath: string
  backIntl: IntlMsgId
  subId: string
}

// ~~~~~~ User zone: Billing

export const RoutesBilling = {
  EditBillingInfo: '/billing-info/edit',

  AddPaymentMethod: '/card/new',
  EditPaymentMethod: '/card/:cardId/edit',
} as const

// ~~~~~~ User zone: Plans

export const RoutesPlans = {
  // Payment

  AddPaymentMethod: '/add-payment-method/:data',
  ChangePaymentMethod: '/change-payment-method/:data',

  // Montage

  Montage: '/montage',
  MontageDetail: '/montage/:subId',
  MontagePurchase: '/montage/purchase',
  MontagePurchaseConfirm: '/montage/purchase/confirm/:data',
  MontagePurchaseSuccess: '/success/montage/purchase/:stripeSessionId',
  MontageSelectPlatformToGetTrial: '/montage/get-trial/select-platform',

  // Launcher

  Launcher: '/launcher',
  LauncherDetail: '/launcher/:subId',
  LauncherPurchase: '/launcher/purchase',
  LauncherPurchaseConfirm: '/launcher/purchase/confirm/:data',
  LauncherPurchaseSuccess: '/success/launcher/purchase/:stripeSessionId',

  // Bcast

  Bcast: '/broadcast',
  BcastDetail: '/broadcast/:subId',

  BcastSub2Premium: '/broadcast/subscribe-to-premium/:interval', // monthly|yearly
  BcastSub2PremiumSuccess: '/success/broadcast/subscribe-to-premium/:stripeSessionId',

  BcastUpg2Premium: '/broadcast/upg-to-premium/:data',
  BcastPurchaseConfirm: '/broadcast/purchase/confirm/:data',

  BcastDetailChangePlan: '/broadcast/:subId/change-plan',
} as const

// ~~~~~~ Full routes

export const Routes = {
  Debug: '/debug/:kind', // (on|qa|off)

  // Public

  // - Root

  Root: '/',

  // - Products

  Montage: '/montage',
  Launcher: '/launcher',
  Bcast: '/broadcast',

  // - Trials or free

  MontageStartTrial: '/montage/start-trial',
  LauncherStartTrial: '/launcher/start-trial',
  BcastSub2Free: '/broadcast/subscribe-to-free',

  // - Purchase

  MontagePurchase: '/montage/purchase',
  MontagePurchaseSuccess: '/success/montage/purchase/:stripeSessionId',

  LauncherPurchase: '/launcher/purchase',
  LauncherPurchaseSuccess: '/success/launcher/purchase/:stripeSessionId',

  BcastSub2Premium: '/broadcast/subscribe-to-premium/:interval', // monthly|yearly
  BcastSub2PremiumSuccess: '/success/broadcast/subscribe-to-premium/:stripeSessionId',

  // Private

  // - Root

  UserZone: '/user-zone',
  UserZoneNested: '/user-zone/*',

  // - Plans

  UserZonePlans: '/user-zone/plans',
  UserZonePlansNested: 'plans/*',

  // -- Plans: Payment

  UserZonePlansAddPaymentMethod: `/user-zone/plans${RoutesPlans.AddPaymentMethod}`,
  UserZonePlansChangePaymentMethod: `/user-zone/plans${RoutesPlans.ChangePaymentMethod}`,

  // -- Plans: Montage

  UserZonePlansMontage: `/user-zone/plans${RoutesPlans.Montage}`,
  UserZonePlansMontageDetail: `/user-zone/plans${RoutesPlans.MontageDetail}`,
  UserZonePlansMontagePurchase: `/user-zone/plans${RoutesPlans.MontagePurchase}`,
  UserZonePlansMontagePurchaseConfirm: `/user-zone/plans${RoutesPlans.MontagePurchaseConfirm}`,
  UserZonePlansMontagePurchaseSuccess: `/user-zone/plans${RoutesPlans.MontagePurchaseSuccess}`,
  UserZonePlansMontageSelectPlatformToGetTrial: `/user-zone/plans${RoutesPlans.MontageSelectPlatformToGetTrial}`,

  // -- Plans: Launcher

  UserZonePlansLauncher: `/user-zone/plans${RoutesPlans.Launcher}`,
  UserZonePlansLauncherDetail: `/user-zone/plans${RoutesPlans.LauncherDetail}`,
  UserZonePlansLauncherPurchase: `/user-zone/plans${RoutesPlans.LauncherPurchase}`,
  UserZonePlansLauncherPurchaseConfirm: `/user-zone/plans${RoutesPlans.LauncherPurchaseConfirm}`,
  UserZonePlansLauncherPurchaseSuccess: `/user-zone/plans${RoutesPlans.LauncherPurchaseSuccess}`,

  // -- Plans: Bcast

  UserZonePlansBcast: `/user-zone/plans${RoutesPlans.Bcast}`,
  UserZonePlansBcastDetail: `/user-zone/plans${RoutesPlans.BcastDetail}`,

  UserZonePlansBcastSub2Premium: `/user-zone/plans${RoutesPlans.BcastSub2Premium}`, // monthly|yearly
  UserZonePlansBcastSub2PremiumSuccess: `/user-zone/plans/success${RoutesPlans.BcastSub2PremiumSuccess}`,

  UserZonePlansBcastUpg2Premium: `/user-zone/plans${RoutesPlans.BcastUpg2Premium}`,
  UserZonePlansBcastPurchaseConfirm: `/user-zone/plans${RoutesPlans.BcastPurchaseConfirm}`,

  UserZonePlansBcastDetailChangePlan: `/user-zone/plans${RoutesPlans.BcastDetailChangePlan}`,

  // - Billing

  UserZoneBilling: '/user-zone/billing',
  UserZoneBillingNested: 'billing/*',

  UserZoneBillinEditBillingInfo: `/user-zone/billing${RoutesBilling.EditBillingInfo}`,

  UserZoneBillinAddPaymentMethod: `/user-zone/billing${RoutesBilling.AddPaymentMethod}`,
  UserZoneBillinEditPaymentMethod: `/user-zone/billing${RoutesBilling.EditPaymentMethod}`,

  // Redirects

  // - Redirect with login

  RedirectWithLogin: '/redirect-with-login',

  // Generators

  // - Public

  genBcastSub2Premium: (interval: 'monthly' | 'yearly') =>
    Routes.BcastSub2Premium.replace(':interval', interval),

  // - Private

  genUserZonePlansMontagePurchaseConfirm: (data: string) =>
    Routes.UserZonePlansMontagePurchaseConfirm.replace(':data', data),

  genUserZonePlansLauncherPurchaseConfirm: (data: string) =>
    Routes.UserZonePlansLauncherPurchaseConfirm.replace(':data', data),

  genUserZonePlansBcastSub2Premium: (interval: 'monthly' | 'yearly') =>
    Routes.UserZonePlansBcastSub2Premium.replace(':interval', interval),

  genUserZonePlansBcastUpg2Premium: (data: string) =>
    Routes.UserZonePlansBcastUpg2Premium.replace(':data', data),

  genUserZonePlansBcastPurchaseConfirm: (data: string) =>
    Routes.UserZonePlansBcastPurchaseConfirm.replace(':data', data),

  // -- Generic payments

  genUserZonePlansAddPaymentMethod: (data: string) =>
    Routes.UserZonePlansAddPaymentMethod.replace(':data', data),

  genUserZonePlansChangePaymentMethod: (data: string) =>
    Routes.UserZonePlansChangePaymentMethod.replace(':data', data),

  // -- Detail

  genUserZonePlansMontageDetail: (subId: string) =>
    Routes.UserZonePlansMontageDetail.replace(':subId', subId),

  genUserZonePlansLauncherDetail: (subId: string) =>
    Routes.UserZonePlansLauncherDetail.replace(':subId', subId),

  genUserZonePlansBcastDetail: (subId: string) =>
    Routes.UserZonePlansBcastDetail.replace(':subId', subId),

  genUserZonePlansBcastDetailChangePlan: (subId: string) =>
    Routes.UserZonePlansBcastDetailChangePlan.replace(':subId', subId),

  // -- Billing: Edit Payment Method

  genEditPaymentMethod: (cardId: string) =>
    Routes.UserZoneBillinEditPaymentMethod.replace(':cardId', cardId),
} as const
