import { Routes as Switch, Route, Navigate } from 'react-router'
import { Routes, RoutesPlans } from '../../../constants/routes'
import { UserZonePlansRoot } from './0-root/component'
import { UserZonePlansProductMontage } from './product/montage/component'
import { UserZonePlansProductLauncher } from './product/launcher/component'
import { UserZonePlansProductBcast } from './product/bcast/component'
import { UserZonePlansPurchaseLauncher } from './purchase/launcher/component'
import { UserZonePlansPurchaseMontage } from './purchase/montage/component'
import { UserZonePlansPurchaseBcastSub2Premium } from './purchase/bcast/sub-2-premium/component'
import { UserZonePlansPurchaseBcastUpg2Premium } from './purchase/bcast/upg-2-premium/component'
import { UserZonePlansProductDetailBcast } from './product-detail/bcast/component'
import { UserZonePlansDetailLauncher } from './product-detail/launcher/component'
import { UserZonePlansDetailMontage } from './product-detail/montage/component'
import { UserZonePlansSubAddPaymentMethod } from './sub-add-payment-method/component'
import { UserZonePlansPurchaseConfirmBcastUpg2Premium } from './purchase-confirm/bcast-upg-2-premimum/component'
import { UserZonePlansSubChangePaymentMethod } from './sub-change-payment-method/component'
import { UserZonePlansPurchaseStripeSuccessPurchaseBcast } from './stripe-success-purchase/bcast/component'
import { UserZonePlansProductDetailBcastChangePlan } from './product-detail/bcast/change-plan/component'
import { UserZonePlansPurchaseConfirmLauncher } from './purchase-confirm/launcher/component'
import { UserZonePlansPurchaseConfirmMontage } from './purchase-confirm/montage/component'
import { UserZonePlansPurchaseStripeSuccessPurchaseLauncher } from './stripe-success-purchase/launcher/component'
import { UserZonePlansPurchaseStripeSuccessPurchaseMontage } from './stripe-success-purchase/montage/component'
import { UserZonePlansGetTrialSelectPlatformMontage } from './select-platform-to-get-trial/montage/component'

// ~~~~~~ Component

export const UserZonePlans = () => {
  // ~~~~~~ Render

  return (
    <Switch>
      <Route path="" element={<UserZonePlansRoot />} />

      <Route path={Routes.UserZonePlansNested} element={<UserZonePlansRoot />} />

      {/* Add Payment (from product, for purchase) */}

      <Route path={RoutesPlans.AddPaymentMethod} element={<UserZonePlansSubAddPaymentMethod />} />

      <Route
        path={RoutesPlans.ChangePaymentMethod}
        element={<UserZonePlansSubChangePaymentMethod />}
      />

      {/* PRODUCT */}

      {/* - Product Main */}

      <Route path={RoutesPlans.Montage} element={<UserZonePlansProductMontage />} />

      <Route path={RoutesPlans.Launcher} element={<UserZonePlansProductLauncher />} />

      <Route path={RoutesPlans.Bcast} element={<UserZonePlansProductBcast />} />

      {/* - Product Detail */}

      <Route path={RoutesPlans.MontageDetail} element={<UserZonePlansDetailMontage />} />

      <Route path={RoutesPlans.LauncherDetail} element={<UserZonePlansDetailLauncher />} />

      <Route path={RoutesPlans.BcastDetail} element={<UserZonePlansProductDetailBcast />} />

      {/* - Product Detail Change Plan */}

      <Route
        path={RoutesPlans.BcastDetailChangePlan}
        element={<UserZonePlansProductDetailBcastChangePlan />}
      />

      {/* SELECT_PLATFORM_GET_TRIAL */}

      <Route
        path={RoutesPlans.MontageSelectPlatformToGetTrial}
        element={<UserZonePlansGetTrialSelectPlatformMontage />}
      />

      {/* PURCHASE */}

      {/* - Purchase Main */}

      <Route path={RoutesPlans.MontagePurchase} element={<UserZonePlansPurchaseMontage />} />

      <Route path={RoutesPlans.LauncherPurchase} element={<UserZonePlansPurchaseLauncher />} />

      <Route
        path={RoutesPlans.BcastSub2Premium}
        element={<UserZonePlansPurchaseBcastSub2Premium />}
      />

      <Route
        path={RoutesPlans.BcastUpg2Premium}
        element={<UserZonePlansPurchaseBcastUpg2Premium />}
      />

      {/* - Purchase Confirmation */}

      <Route
        path={RoutesPlans.MontagePurchaseConfirm}
        element={<UserZonePlansPurchaseConfirmMontage />}
      />

      <Route
        path={RoutesPlans.LauncherPurchaseConfirm}
        element={<UserZonePlansPurchaseConfirmLauncher />}
      />

      <Route
        path={RoutesPlans.BcastPurchaseConfirm}
        element={<UserZonePlansPurchaseConfirmBcastUpg2Premium />}
      />

      {/* SUCCESS - PURCHASE (stripe return) */}

      <Route
        path={RoutesPlans.MontagePurchaseSuccess}
        element={<UserZonePlansPurchaseStripeSuccessPurchaseMontage />}
      />

      <Route
        path={RoutesPlans.LauncherPurchaseSuccess}
        element={<UserZonePlansPurchaseStripeSuccessPurchaseLauncher />}
      />

      <Route
        path={RoutesPlans.BcastSub2PremiumSuccess}
        element={<UserZonePlansPurchaseStripeSuccessPurchaseBcast />}
      />

      {/* Unknown routes */}

      <Route path="*" element={<Navigate replace to={Routes.Root} />} />

      {/* - */}
    </Switch>
  )
}
