import { Config } from '../../../config'
import { HttpVerbs } from '../../../constants/http'

const { Api } = Config

// ####################################################################################################
// ~~~~~~ Public
// ####################################################################################################

// ~~~~~~ Montage

const ReleaseInfo = {
  Get: {
    Method: HttpVerbs.GET,
    getUrl: Api.Montage.URI,
  },
}

// ~~~~~~

export const ApiRoutes = {
  ReleaseInfo,
} as const
