import { ModalsMC, ModalsMT } from '../../actions-mutators/modals/mutators'
import { AppInitState } from '../../../models/app/model'
import { dynReducer } from '../../../utils/store'

const initState = AppInitState.modals

type State = typeof initState

const reducer: any = {}

// ################################################################################
// ~~~~~~ Action to open modal
// ################################################################################

reducer[ModalsMT.OPEN_MODAL] = (
  state: State,
  { payload }: ReturnType<typeof ModalsMC.open>,
): State => {
  const cleanList = state.list.filter((modalId) => modalId !== payload.modalId)

  const newConfirm = payload.fun
    ? {
        ...state.confirm,
        [payload.modalId]: payload.fun,
      }
    : state.confirm

  const newList = [...cleanList, payload.modalId]

  return {
    list: newList,
    confirm: newConfirm,
  }
}

// ################################################################################
// ~~~~~~ Action to close modal
// ################################################################################

reducer[ModalsMT.CLOSE_MODAL] = (
  state: State,
  { payload }: ReturnType<typeof ModalsMC.close>,
): State => {
  const newList = state.list.filter((modal) => modal !== payload.modalId)

  const newConfirm = {
    ...state.confirm,
    [payload.modalId]: undefined,
  }

  return {
    list: newList,
    confirm: newConfirm,
  }
}

// ~~~~~~

export const modalsReducer = dynReducer(initState, reducer)
