import styled from 'styled-components'
import { Fonts } from '../../../../../style/theme/fonts'
import { theme } from '../../../../../style/utils/theme'
import { Colors } from '../../../../../style/theme/colors'
import { MediaQuery } from '../../../../../style/constants/media-queries'
import { Layout } from '../../../../../style/theme/layout'

// ~~~~~~ Constants

export const ClassName = 'user-zone-common-payment-method-new'

// ~~~~~~ Component

export const STUserZoneCommonPaymentMethodNew = styled.div`
  margin-top: 24px;

  ${Layout.MarginMaxWidth.MiddleContent}

  .${ClassName}--content {
    .${ClassName}--content--go-back {
      ${Fonts.Weight[600]}
      ${Fonts.Scale.BodyBig}

      margin-bottom: 32px;
    }

    .${ClassName}--content--title {
      ${Fonts.Weight[800]}
      ${Fonts.Scale.Headline1}

      margin-bottom: 8px;
    }

    .${ClassName}--content--subtitle {
      ${Fonts.Weight[300]}
      ${Fonts.Scale.BodyBig}

      margin-bottom: 16px;
    }

    .${ClassName}--content--form {
      margin-bottom: 24px;
    }

    hr {
      border: none;
      border-top: solid 1px ${theme(Colors.WOLF_10_10)};

      margin-bottom: 24px;
    }

    .${ClassName}--content--actions {
      display: flex;

      padding-bottom: 72px;

      .${ClassName}--content--actions--action {
        &.first {
          margin-right: 16px;
        }
      }
    }
  }

  /* 1280 */

  @media (max-width: ${MediaQuery.MaxWidth2k}) {
    .${ClassName}--content {
      ${Layout.PaddingWidth.Desktop}

      .${ClassName}--content--go-back {
        margin-bottom: 24px;
      }

      .${ClassName}--content--title {
        ${Fonts.Scale.Headline2}
      }
    }
  }

  /* 960 */

  @media (max-width: ${MediaQuery.MD}) {
    .${ClassName}--content {
      ${Layout.PaddingWidth.Tablet}
    }
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    .${ClassName}--content {
      ${Layout.PaddingWidth.Mobile}

      .${ClassName}--content--title {
        ${Fonts.Scale.Title}
      }

      .${ClassName}--content--subtitle {
        ${Fonts.Scale.Subtitle}
      }

      .${ClassName}--content--actions {
        flex-direction: column;

        .${ClassName}--content--actions--action {
          &.first {
            margin-right: 0;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
`
