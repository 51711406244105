import styled from 'styled-components'

import { Colors } from '../../style/theme/colors'
import { theme } from '../../style/utils/theme'
import { Misc } from '../../style/theme/misc'
import { Fonts } from '../../style/theme/fonts'

// ~~~~~~ Helpers

const getHeight = (sended: boolean, hasSendError: boolean) => {
  let init = 80

  if (sended) init += 40

  if (hasSendError) init += 40

  return init
}

// ~~~~~~ Types

type STProps = {
  $sended: boolean
  $hasSendError: boolean
  $disabled: boolean
}

// ~~~~~~ Component

export const STDebugActions = styled.div<STProps>`
  position: absolute;
  z-index: 1000000;

  user-select: none;

  ${Fonts.Scale.Label}

  background-color: ${theme(Colors.WOLF_10_10)};
  color: ${theme(Colors.WOLF_80_80)};

  border: solid 1px #aaa;
  padding: 14px;

  width: 100px;
  height: ${({ $sended, $hasSendError }) => getHeight($sended, $hasSendError)}px;

  ${Misc.BorderRadius}

  bottom: 0;
  right: 0;

  display: flex;
  flex-direction: column;

  .title {
    padding-bottom: 10px;
  }

  .ok {
    color: green;
  }

  .error {
    padding-top: 5px;
    padding-bottom: 5px;

    color: ${theme(Colors.BROOKLYN_40_40)};
  }

  textarea {
    resize: none;
    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'auto')};
  }

  button {
    margin-top: 4px;
    cursor: pointer;
  }
`
