import { useDispatch, useSelector } from 'react-redux'
import { useEffect, useState } from 'react'
import { HooksData } from '@dn/hooks'
import { useNavigate } from 'react-router'

import { IntlInput } from '../../../../components/common/intl-input/component'
import { DangerTrans } from '../../../../components/common/intl/danger-trans'
import { Trans } from '../../../../components/common/intl/trans'
import { ProductButtonBcast } from '../../../../components/common/product-button/bcast/component'
import { LazySVG } from '../../../../components/svgs/lazy-svg/component'
import { ClassName, STPageCommonOPStep1CreateAccount } from './style'
import { StoreState } from '../../../../models/app/model'
import { useCustomAuth } from '../../../../hooks/auth/use-custom-auth'
import { useValidator } from '../../../../hooks/validators/use-validator'
import { BuyLicenseModel, BuyLicenseFieldMR } from '../../../../models/buy-license/model'
import { KeyCodes } from '../../../../constants/key-codes'
import { ApiStoreCheckoutAC } from '../../../../store/actions/api-store/checkout/actions'
import { Config } from '../../../../config'
import { Routes } from '../../../../constants/routes'

// ~~~~~~ Constants

const WarningIcon = LazySVG('icons/exclamation-triangle')

// ~~~~~~ Types

type Props = {
  Logo: ReturnType<typeof LazySVG>
  ProductButton: typeof ProductButtonBcast
  tabSteps: React.ReactNode
}

// ~~~~~~ Component

export const PageCommonOPStep1CreateAccount: React.FC<Props> = ({
  Logo,
  ProductButton,
  tabSteps,
}) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const { login } = useCustomAuth()

  const navigate = useNavigate()

  const validateEmail = useValidator(BuyLicenseModel.validations.Email, BuyLicenseFieldMR.email)

  const validatePassword = useValidator(
    BuyLicenseModel.validations.Password,
    BuyLicenseFieldMR.password,
  )

  // ~~~~~~ State

  // - Local

  const [isDirty, setIsDirty] = useState(false)

  // - Store

  const buyLicense = useSelector((state: StoreState) => state.buyLicense)

  const currentUserEmail = useSelector((state: StoreState) => state.currentUser.email)

  // ~~~~~~ Computed

  const prevCurUserEmail = HooksData.PrevValue.useHook(currentUserEmail)

  const isRunning = buyLicense.uiStatus === 'running'

  const hasAccountError = !!buyLicense.errors.find(
    (err) => err.id === 'api.checkout.send-email-pass.Error412',
  )

  // ~~~~~~ Handlers

  function onClickLogIn() {
    login()
  }

  function onChEmail(value: string) {
    isDirty && validateEmail(value)

    dispatch(BuyLicenseFieldMR.email.MC.change(value))
  }

  function onChPassword(value: string) {
    isDirty && validatePassword(value)

    dispatch(BuyLicenseFieldMR.password.MC.change(value))
  }

  function onKeyDown(key: string) {
    if (isRunning || key !== KeyCodes.ENTER) return

    onSubmit()
  }

  function onClickTerms() {
    window.open(Config.Links.Terms, '_blank')
  }

  function onClickPrivacy() {
    window.open(Config.Links.Privacy, '_blank')
  }

  function onSubmit() {
    setIsDirty(true)

    // Validate

    const errors = [
      validateEmail(buyLicense.email).length,
      validatePassword(buyLicense.password).length,
    ]

    if (errors.find(Boolean)) return

    // Clean dirty

    setIsDirty(false)

    // Submit

    dispatch(
      ApiStoreCheckoutAC.sendEmailPass({
        email: buyLicense.email,
        password: buyLicense.password,
      }),
    )
  }

  // ~~~~~~ Effects

  // - From not-logged to logged: Redirect to user-zone

  useEffect(() => {
    if (prevCurUserEmail || !currentUserEmail) return

    navigate(Routes.UserZone)

    //
  }, [currentUserEmail, navigate, prevCurUserEmail])

  // ~~~~~~ Render

  return (
    <STPageCommonOPStep1CreateAccount>
      {/* Steps */}

      <div className={`${ClassName}--steps`}>{tabSteps}</div>

      {/* Logo */}

      <div className={`${ClassName}--logo`}>
        <Logo size={32} />
      </div>

      {/* Title */}

      <div className={`${ClassName}--title`}>
        <Trans id="pages.purchase-flow.create-account-step.GetAccountCreated" />
      </div>

      {/* Subtitle */}

      <div className={`${ClassName}--subtitle`}>
        {hasAccountError ? (
          <>
            {/* Warning Account exists */}

            <div className={`${ClassName}--subtitle--has-account`}>
              <div className={`${ClassName}--subtitle--has-account--icon`}>
                <WarningIcon size={16} />
              </div>

              <div>
                <DangerTrans
                  template="... - <c>Log in</c>"
                  id="pages.purchase-flow.create-account-step.AlreadyHaveAccountWarning"
                  values={{
                    c: (chunks: string) => (
                      <span
                        className={`${ClassName}--subtitle--has-account--action`}
                        onClick={onClickLogIn}
                      >
                        {chunks}
                      </span>
                    ),
                  }}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            {/* Login notice */}

            <DangerTrans
              template="Been here before? <c>log in</c>"
              id="pages.purchase-flow.create-account-step.AlreadyHaveAccount"
              values={{
                c: (chunks: string) => (
                  <span className={`${ClassName}--subtitle--action`} onClick={onClickLogIn}>
                    {chunks}
                  </span>
                ),
              }}
            />
          </>
        )}
      </div>

      {/* Email input */}

      <div className={`${ClassName}--field email`}>
        <IntlInput
          label="pages.purchase-flow.create-account-step.EmailAddress"
          sublabel="pages.purchase-flow.create-account-step.EmailDescription"
          value={buyLicense.email}
          errors={buyLicense.email_err}
          doFocus={1}
          $disabled={isRunning}
          onChange={onChEmail}
          onKeyDown={onKeyDown}
        />
      </div>

      {/* Pass input */}

      <div className={`${ClassName}--field pass last`}>
        <IntlInput
          label="pages.purchase-flow.create-account-step.CreatePassword"
          sublabel="pages.purchase-flow.create-account-step.PasswordLabelDescription"
          type="password"
          value={buyLicense.password}
          errors={buyLicense.password_err}
          $disabled={isRunning}
          onChange={onChPassword}
          onKeyDown={onKeyDown}
        />
      </div>

      {/* Action */}

      <ProductButton
        className={`${ClassName}--action`}
        intlId="pages.purchase-flow.common.Continue"
        running={isRunning}
        disabled={isRunning}
        active={!isRunning}
        onClick={onSubmit}
      />

      {/* Footer: Privacy & Terms */}

      <div className={`${ClassName}--footer`}>
        <DangerTrans
          template="... <c>Terms & Conditions</c> and <d>Privacy Policy</d>"
          id="pages.purchase-flow.create-account-step.AgreeTermsAndConditions"
          values={{
            c: (chunks: string) => (
              <span className={`${ClassName}--footer--link`} onClick={onClickTerms}>
                {chunks}
              </span>
            ),
            d: (chunks: string) => (
              <span className={`${ClassName}--footer--link`} onClick={onClickPrivacy}>
                {chunks}
              </span>
            ),
          }}
        />
      </div>

      {/* - */}
    </STPageCommonOPStep1CreateAccount>
  )
}
