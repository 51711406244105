import { useNavigate } from 'react-router'
import { Routes } from '../../../../../constants/routes'
import { UserZoneCommonBannerBack } from '../../../@-common/banner-back/component'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../../../models/app/model'
import { useEffect } from 'react'
import { ClassName, STUserZonePlansGetTrialSelectPlatformMontage } from './style'
import { Trans } from '../../../../../components/common/intl/trans'
import { PlatformCard } from '../../../../../components/common/platform-card/component'
import { ProductButtonMontage } from '../../../../../components/common/product-button/montage/component'
import { BuyLicenseFieldMR } from '../../../../../models/buy-license/model'
import { useDispatch } from 'react-redux'
import { ApiStoreCheckoutAC } from '../../../../../store/actions/api-store/checkout/actions'

// ~~~~~~ Component

export const UserZonePlansGetTrialSelectPlatformMontage = () => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  const dispatch = useDispatch()

  // ~~~~~~ State

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  const metaProducts = useSelector((state: StoreState) => state.montage)

  const subs = useSelector((state: StoreState) => state.subscriptions)

  const invoices = useSelector((state: StoreState) => state.invoices)

  const buyLicense = useSelector((state: StoreState) => state.buyLicense)

  // ~~~~~~ Computed

  const montageProducts = metaProducts.products

  const sub = subs.find((sub) => sub.product_id === 'montage')

  const invoice = invoices.find((invoice) => invoice.product_id === 'montage')

  const platformIsValid = buyLicense.platform === 'windows' || buyLicense.platform === 'android'

  const isLoading = !currentUser.isSessionChecked || metaProducts.uiStatus !== 'completed'

  const buyLicenseIsRunning = buyLicense.uiStatus === 'running'

  // ~~~~~~ Handlers

  function onClickWindows() {
    if (isLoading || buyLicenseIsRunning) return

    dispatch(BuyLicenseFieldMR.platform.MC.change('windows'))

    const subId = montageProducts['windows'].year.id

    dispatch(BuyLicenseFieldMR.subId.MC.change(subId))
  }

  function onClickAndroid() {
    if (isLoading || buyLicenseIsRunning) return

    dispatch(BuyLicenseFieldMR.platform.MC.change('android'))

    const subId = montageProducts['android'].year.id

    dispatch(BuyLicenseFieldMR.subId.MC.change(subId))
  }

  function onClickGoBack() {
    const target = Routes.UserZonePlansMontage

    navigate(target)
  }

  function onClickContinue() {
    dispatch(
      ApiStoreCheckoutAC.startFreeSubOrTrial('montage', {
        email: currentUser.email,
        price_of_license_of_product_id: buyLicense.subId,
        code: '',
      }),
    )
  }

  // ~~~~~~ Effects

  // ~~~~~~ Effects

  // - If user has some launcher sub
  //   trial: Nav to trial details
  //   other: Return to plans

  useEffect(() => {
    if (!sub) return

    sub.trial_end
      ? navigate(`${Routes.genUserZonePlansMontageDetail(sub.subscription_id)}?new=true`)
      : navigate(Routes.UserZonePlans)

    //
  }, [sub, navigate])

  // - If user has a montage invoice cannot get a trial
  //
  useEffect(() => {
    if (!invoice) return

    navigate(Routes.UserZonePlans)

    //
  }, [invoice, navigate])

  // ~~~~~~ Render

  return (
    <STUserZonePlansGetTrialSelectPlatformMontage>
      {/* Banner back */}

      <UserZoneCommonBannerBack
        noImage
        backIntl={'pages.common.AddProduct'}
        backIntlValues={{ value: 'Montage' }}
        kind="montage"
        onClickGoBack={onClickGoBack}
      />

      {/* Content */}

      <div className={`${ClassName}--content`}>
        {/* Title */}

        <div className={`${ClassName}--content--title`}>
          <Trans id="pages.purchase-flow.create-account-step.SelectPlatform" />
        </div>

        {/* Platforms */}

        <div className={`${ClassName}--content--platforms`}>
          {/* Windows */}

          <div className={`${ClassName}--content--platforms--platform`}>
            <PlatformCard
              kind="windows"
              selected={buyLicense.platform === 'windows'}
              onClick={onClickWindows}
            />
          </div>

          {/* Android */}

          <div className={`${ClassName}--content--platforms--platform last`}>
            <PlatformCard
              kind="android"
              selected={buyLicense.platform === 'android'}
              onClick={onClickAndroid}
            />
          </div>
        </div>

        {/* Space */}

        <hr className={`${ClassName}--content--separator`} />

        {/* Actions */}

        <div className={`${ClassName}--content--actions`}>
          {/* Action */}

          <ProductButtonMontage
            className={`${ClassName}--content--actions--action`}
            intlId="pages.purchase-flow.common.Continue"
            active={platformIsValid && !isLoading && !buyLicenseIsRunning}
            disabled={!platformIsValid || isLoading || buyLicenseIsRunning}
            running={isLoading || buyLicenseIsRunning}
            onClick={onClickContinue}
          />
        </div>

        {/* - */}
      </div>
    </STUserZonePlansGetTrialSelectPlatformMontage>
  )
}
