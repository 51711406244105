import styled from 'styled-components'

// ~~~~~~ Constants

export const ClassName = 'page-purchase-sub-success'

// ~~~~~~ Component

export const STPagePurchaseSubSuccess = styled.div`
  height: 100vh;
`
