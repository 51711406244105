import { LazySVG } from '../../../svgs/lazy-svg/component'
import { Trans } from '../../intl/trans'
import { ClassName, STBackLink } from './style'

// ~~~~~~ Constants

const ArrowIcon = LazySVG('icons/arrow-long-left')

// ~~~~~~ Props

type Props = {
  className?: string
  intlId: IntlMsgId
  intlValues?: { [key: string]: string | number }
  onClick: () => void
}

// ~~~~~~ Component

export const BackLink: React.FC<Props> = ({ className, intlId, intlValues, onClick }) => {
  // ~~~~~~ Render

  return (
    <STBackLink className={className}>
      <div className={`${ClassName}--content`} onClick={onClick}>
        <div className={`${ClassName}--content--arrow`}>
          <ArrowIcon size={20} />
        </div>

        <div className={`${ClassName}--content--text`}>
          <Trans id={intlId} values={intlValues} />
        </div>
      </div>
    </STBackLink>
  )
}
