import { useDispatch } from 'react-redux'
import { GenFieldMR } from '../../utils/store'
import { ValidateUtils, Validators } from '../../utils/validations'

export const useValidator = (validators: Validators, fieldMR: GenFieldMR<any, any>) => {
  const dispatch = useDispatch()

  function validator(value: string, ...others: any[]) {
    const errors = ValidateUtils.validate([value, ...others], validators)

    dispatch(errors.length ? fieldMR.MC.setInvalid(errors) : fieldMR.MC.setValid())

    return errors
  }

  return validator
}
