import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useAuth0 } from '@auth0/auth0-react'
import { useLocation, useNavigate } from 'react-router'

import { StoreState } from '../../models/app/model'
import { ApiStoreCurrentUserAC } from '../../store/actions/api-store/current-user/actions'
import { AuthMC } from '../../store/actions-mutators/auth/mutators'
import { RoutesOld } from '../../constants/routes-old'
import { UtilsLog } from '../../utils/logs'

// ~~~~~~ Component

export const useCheckSession = () => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  const location = useLocation()

  const dispatch = useDispatch()

  // isAuthenticated: returns if we have the auth0 token
  const { getAccessTokenSilently, isLoading, isAuthenticated } = useAuth0()

  // ~~~~~~ State

  const isSessionChecked = useSelector((state: StoreState) => state.currentUser.isSessionChecked)

  // ~~~~~~ Effects

  useEffect(() => {
    if (isLoading || isSessionChecked) return

    if (isAuthenticated) {
      UtilsLog.devLog('UseCheckSession', 'isAuthenticated')

      dispatch(AuthMC.getTokenStart())

      getAccessTokenSilently()
        .then((token) => dispatch(ApiStoreCurrentUserAC.show(token)))
        .catch((err) => {
          UtilsLog.devLog('Error getting token', err.message)
          dispatch(AuthMC.getTokenError())
        })

      return
    }

    UtilsLog.devLog('UseCheckSession', 'NOT', 'isAuthenticated')

    dispatch(AuthMC.setSessionChecked())

    const inUserZone = !!~location.pathname.search(RoutesOld.UserZone)

    inUserZone && navigate(RoutesOld.Main)
  }, [
    dispatch,
    getAccessTokenSilently,
    navigate,
    isAuthenticated,
    isLoading,
    isSessionChecked,
    location.pathname,
  ])
}
