import { useLocation } from 'react-router'
import { Trans } from '../../../../components/common/intl/trans'
import { ClassName, STUserZoneCommonHeader } from './style'
import { Routes } from '../../../../constants/routes'

// ~~~~~~ Constants

const ShowInPaths = [Routes.UserZone, Routes.UserZonePlans, Routes.UserZoneBilling]

// ~~~~~~ Component

export const UserZoneCommonHeader = () => {
  // ~~~~~~ Hooks

  const path = useLocation().pathname

  const isVisible = ShowInPaths.includes(path as any)

  // ~~~~~~ Render

  if (!isVisible) return null

  return (
    <STUserZoneCommonHeader>
      <div className={`${ClassName}--header`}>
        <div className={`${ClassName}--header--content`}>
          <Trans id="pages.user-zone.ManageAccountTitle" />
        </div>
      </div>
    </STUserZoneCommonHeader>
  )
}
