import { StateObservable, ofType } from 'redux-observable'
import { EMPTY, Observable, filter, mergeMap, of } from 'rxjs'
import { StoreState } from '../../../../models/app/model'
import { EpicApiStoreCardAddMC, EpicApiStoreCardAddMT } from '../card/add/mutators'
import { ApiStoreCheckoutAC } from '../../../actions/api-store/checkout/actions'

type Action = ReturnType<typeof EpicApiStoreCardAddMC.ok>

export const apiStoreChainingFromAddCardToCheckoutSetDefaultCardEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(EpicApiStoreCardAddMT.OK),

    filter(({ payload }) => payload.setDefault),

    mergeMap(({ payload }) => {
      const defaultCard = state$.value.cards.list.find((card) => card.is_default)

      // Creating a card when no default card exists
      // NOTE: will set the card as default (BUG: backend returns card.is_default: false)
      if (!defaultCard || defaultCard.payment_method_id === payload.data.card.payment_method_id) {
        return EMPTY
      }

      return of(ApiStoreCheckoutAC.setDefaultCard(payload.data.card.payment_method_id))
    }),
  )
