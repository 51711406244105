import styled from 'styled-components'

// ~~~~~~ Constants

export const ClassName = 'page-trials-or-free-bcast'

// ~~~~~~ Component

export const STPageTrialsOrFreeBcast = styled.div`
  height: 100vh;
`
