import styled from 'styled-components'
import { MediaQuery } from '../../../style/constants/media-queries'

// ~~~~~~ Constants

export const ClassName = 'page-root-content'

// ~~~~~~ Component

export const STPageRootContent = styled.div`
  margin-top: 16px;
  margin-bottom: 64px;

  .${ClassName}--products {
    height: 100%;

    padding-left: 24px;
    padding-right: 24px;

    width: calc(100% - 48px);

    display: flex;
    flex-direction: row;
    justify-content: center;

    .${ClassName}--products--card {
      margin: 12px;
    }
  }

  @media (max-width: ${MediaQuery.MD}) {
    .${ClassName}--products {
      flex-direction: column;
      align-items: center;
      justify-content: center;
    }
  }

  @media (max-width: ${MediaQuery.SM}) {
    .${ClassName}--products {
      padding-left: 0px;
      padding-right: 0px;

      width: 100%;

      .${ClassName}--products--card {
        margin-left: 0px;
        margin-right: 0px;

        padding-left: 12px;
        padding-right: 12px;
      }
    }
  }
`
