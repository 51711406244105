const prefix = 'mt-store-api-products-epic'

// ~~~~~~ Mutators Types

export const ApiStoreProductsEpicMT = {
  OK: `${prefix}-ok-list`,
  OK_BROADCAST_SUB: `${prefix}-ok-broadcast-products`,
  OK_MONTAGE: `${prefix}-ok-montage`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const ApiStoreProductsEpicMC = {
  okList: (
    productId: DN.Models.Product.ProductId,
    products: DN.Models.Product.Api.List.ResParsedList,
  ) => ({
    type: ApiStoreProductsEpicMT.OK,
    payload: {
      productId,
      products,
    },
  }),

  okBroadcastProducts: (products: DN.Models.Product.Api.List.ResParsedBroadcastProducts) => ({
    type: ApiStoreProductsEpicMT.OK_BROADCAST_SUB,
    payload: {
      products,
    },
  }),

  okMontageProducts: (products: DN.Models.Product.Api.List.ResParsedMontageProducts) => ({
    type: ApiStoreProductsEpicMT.OK_MONTAGE,
    payload: {
      products,
    },
  }),

  error: (productId: DN.Models.Product.ProductId, errors: Infos[]) => ({
    type: ApiStoreProductsEpicMT.ERROR,
    error: true,
    payload: {
      productId,
      errors,
    },
  }),
} as const
