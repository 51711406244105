const prefix = 'mt-store-api-subscriptions-cancel-plan-epic'

// ~~~~~~ Mutators Types

export const ApiStoreSubscriptionsCancelPlanEpicMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const ApiStoreSubscriptionsCancelPlanEpicMC = {
  ok: (data: DN.Models.Subscription.Api.CancelPlan.ResParsed) => ({
    type: ApiStoreSubscriptionsCancelPlanEpicMT.OK,
    payload: data,
  }),

  error: (data: DN.Models.Subscription.Api.CancelPlan.ResError) => ({
    type: ApiStoreSubscriptionsCancelPlanEpicMT.ERROR,
    error: true,
    payload: data,
  }),
} as const
