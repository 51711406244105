import { useSelector, useDispatch } from 'react-redux'
import { StoreState } from '../../../../../models/app/model'
import { PagePurchaseCommonTabSteps } from '../../../@-common/tab-steps/component'
import { ClassName, STPagePurchaseLauncherStep3Payment } from './style'
import { Trans } from '../../../../../components/common/intl/trans'
import { LazySVG } from '../../../../../components/svgs/lazy-svg/component'
import { UtilsNumber } from '@dn/utils'
import { BuyLicenseFieldMR } from '../../../../../models/buy-license/model'
import { ProductButtonLauncher } from '../../../../../components/common/product-button/launcher/component'
import { SafePayments } from '../../../../../components/common/safe-payments/component'
import { ApiStoreCheckoutAC } from '../../../../../store/actions/api-store/checkout/actions'
import { Routes } from '../../../../../constants/routes'
import { DeviceNumberSelector } from '../../../../../components/common/device-number-selector/component'

// ~~~~~~ Constants

const Logo = LazySVG('logos/launcher-gradient')

// ~~~~~~ Component

export const PagePurchaseLauncherStep3Payment = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const buyLicense = useSelector((state: StoreState) => state.buyLicense)

  // ~~~~~~ Computed

  const isRunning = buyLicense.uiStatus === 'running'

  const disabled = !(Number(buyLicense.quantity) > 0)

  // ~~~~~~ Handlers

  function onChangeQuantity(value: string) {
    const finalValue = UtilsNumber.safeStrOfNumberInt(value)

    dispatch(BuyLicenseFieldMR.quantity.MC.change(finalValue))
  }

  function onClickAction() {
    if (!buyLicense.productId) return

    dispatch(
      ApiStoreCheckoutAC.createCheckoutSession('launcher', {
        email: buyLicense.email,
        code: buyLicense.code,

        price_of_license_of_product_id: buyLicense.subId,
        quantity: Number(buyLicense.quantity),

        // Remove the :stripeSessionId (stripe will add this at the end of this route)
        success_path: Routes.LauncherPurchaseSuccess.split('/').slice(0, -1).join('/'),
        cancel_path: Routes.LauncherPurchase,
      }),
    )
  }

  // ~~~~~~ Render

  return (
    <STPagePurchaseLauncherStep3Payment>
      {/* Steps */}

      <div className={`${ClassName}--steps`}>
        <PagePurchaseCommonTabSteps kind="license" />
      </div>

      {/* Logo */}

      <div className={`${ClassName}--logo`}>
        <Logo size={32} />
      </div>

      {/* Title */}

      <div className={`${ClassName}--title`}>
        <Trans id="pages.purchase-flow.payment-step.OrderSummary" />
      </div>

      {/* Order */}

      <div className={`${ClassName}--order`}>
        <DeviceNumberSelector
          product="Launcher"
          price={buyLicense.unitPrice}
          qtty={buyLicense.quantity}
          onChange={onChangeQuantity}
        />
      </div>

      {/* Action */}

      <div className={`${ClassName}--action`}>
        <ProductButtonLauncher
          intlId="pages.purchase-flow.common.ProceedToPayment"
          running={isRunning}
          disabled={disabled || isRunning}
          active={!disabled && !isRunning}
          onClick={onClickAction}
        />
      </div>

      {/* Safe Payments */}

      <div className={`${ClassName}--safe-pay`}>
        <SafePayments />
      </div>

      {/* - */}
    </STPagePurchaseLauncherStep3Payment>
  )
}
