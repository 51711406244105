import { Observable, of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { catchError, map } from 'rxjs/operators'
import { ApiRoutes } from '../constants/routes'
import { AppHeaders, ApiHeadersUtils } from '../utils/headers'

const { Cards } = ApiRoutes

// ####################################################################################################
// ~~~~~~ Add Card
// ####################################################################################################

type Req = DN.Models.Card.Api.Add.Req
type Res = DN.Models.Card.Api.Add.Res
type ResParsed = DNApiResParsed<DN.Models.Card.Api.Add.ResParsed>
type ResError = DNApiError<DN.Models.Card.Api.Add.ResError>

export const apiStoreCardAdd$ = (token: string, paymentId: string, headers?: AppHeaders) => {
  const body: Req = {
    setup_intent: paymentId,
  }

  return ajax<Res>({
    ...ApiHeadersUtils.genHeadersWithAuth(token, headers),
    method: Cards.AddCard.Method,
    url: Cards.AddCard.getUrl(),
    body,
  }).pipe(
    map((res): ResParsed => {
      const brand = res.response.card.brand

      return {
        status: res.status,
        response: {
          card: {
            ...res.response.card,
            brand: brand,
          },
        },
      }
    }),
    catchError((res: DNApiRes<void>): Observable<ResError> => {
      return of({
        status: res.status,
        response: [{ id: 'api.card.add-card.Error' }],
      })
    }),
  )
}
