import { Config } from '../config'

// ####################################################################################################
// ~~~~~~ Logs
// ####################################################################################################

const remLog = (...args: any[]) =>
  Config.Environment === 'development' && console.log('\n', ...args, '\n\n')

const devLogT = (...args: any[]): void => {
  if (!Config.Features.Debug) return

  console.groupCollapsed(...args)
  console.log(...args)
  console.trace()
  console.groupEnd()
}

const devLog = (...args: any[]) => Config.Features.Debug && console.log('\n', ...args, '\n\n')

const devInfo = (...args: any[]) => Config.Features.Debug && console.info('\n', ...args, '\n\n')

const devDebug = (...args: any[]) => Config.Features.Debug && console.debug('\n', ...args, '\n\n')

const devWarn = (...args: any[]) => Config.Features.Debug && console.warn('\n', ...args, '\n\n')

const devError = (...args: any[]) => Config.Features.Debug && console.error('\n', ...args, '\n\n')

// ~~~~~~

export const UtilsLog = {
  devLog,
  remLog,
  devLogT,

  devInfo,
  devDebug,
  devWarn,
  devError,
} as const
