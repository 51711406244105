import { Observable, of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { catchError, map } from 'rxjs/operators'
import { ApiRoutes } from '../constants/routes'
import { ApiHeadersUtils } from '../utils/headers'

type Req = DN.Models.Checkout.Api.SetDefaultCard.Req
type Res = DN.Models.Checkout.Api.SetDefaultCard.Res
type ResParsed = DNApiResParsed<DN.Models.Checkout.Api.SetDefaultCard.ResParsed>
type ResError = DNApiError<DN.Models.Checkout.Api.SetDefaultCard.ResError>

const { SetDefaultCard: ChangePaymentMethod } = ApiRoutes.Checkout

export const apiStoreCheckoutSetDefaultCard$ = (
  token: string,
  paymentMethodId: string,
  headers?: AppHeaders,
) => {
  const body: Req = {
    payment_method_id: paymentMethodId,
  }

  return ajax<Res>({
    ...ApiHeadersUtils.genHeadersWithAuth(token, headers),
    method: ChangePaymentMethod.Method,
    url: ChangePaymentMethod.getUrl(),
    body,
  }).pipe(
    map((res): ResParsed => {
      return {
        status: res.status,
        response: {
          paymentMethodId,
        },
      }
    }),
    catchError(
      (res: DNApiRes<void>): Observable<ResError> =>
        of({
          isResError: true,
          status: res.status,
          response: [{ id: 'api.checkout.set-default-card.Error' }],
        }),
    ),
  )
}
