import styled from 'styled-components'

import { Colors } from '../../../../style/theme/colors'
import { Fonts } from '../../../../style/theme/fonts'
import { theme } from '../../../../style/utils/theme'

export const STUserMenuMobile = styled.div`
  .user-menu-btn {
    display: flex;

    ${Fonts.Weight[600]}
    ${Fonts.Scale.Body}

    color: ${theme(Colors.WOLF_00_00)};

    .icon {
      display: flex;

      svg {
        width: 25px;
        height: 25px;

        path {
          fill: ${theme(Colors.WOLF_00_00)};
        }
      }
    }
  }
`
