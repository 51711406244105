import styled from 'styled-components'
import { Colors } from '../../../../../../style/theme/colors'
import { theme } from '../../../../../../style/utils/theme'
import { Fonts } from '../../../../../../style/theme/fonts'
import { Transition } from '../../../../../../style/animations/css-animations'

// ~~~~~~ Constants

export const ClassName = 'user-zone-common-payment-method-form-inner-form'

// ~~~~~~ Component

export const STUserZoneCommonPaymentMethodFormInnerForm = styled.div`
  .${ClassName}--stripe-form {
    margin-bottom: 8px;
  }

  .${ClassName}--set-as-default {
    cursor: pointer;

    display: flex;
    align-items: center;

    margin-bottom: 16px;

    &.disabled {
      cursor: not-allowed;
    }

    .${ClassName}--set-as-default--input {
    }

    .${ClassName}--set-as-default--checkbox {
      position: relative;

      width: 16px;
      height: 16px;

      margin-right: 8px;

      border-radius: 4px;

      border: 2px solid ${theme(Colors.WOLF_90_90)};

      ${Transition.BackgroundColorFaster}

      svg {
        display: none;

        position: absolute;
        top: -2px;
        right: -2px;

        path {
          fill: ${theme(Colors.WOLF_00_00)};
        }
      }

      &.checked {
        background-color: ${theme(Colors.WOLF_90_90)};

        svg {
          display: block;
        }
      }
    }

    .${ClassName}--set-as-default--label {
      ${Fonts.Scale.BodyBig}
      ${Fonts.Weight[600]}

      color: ${theme(Colors.WOLF_90_90)};
    }
  }
`
