import { ofType, StateObservable } from 'redux-observable'
import { Observable, of, zip } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'

import { StoreState } from '../../../../../models/app/model'

import { ApiUtils } from '../../../utils'
import { ApiStoreSubscriptionsChangePlanEpicMC } from './mutators'
import {
  ApiStoreSubscriptionsAC,
  ApiStoreSubscriptionsAT,
} from '../../../../actions/api-store/subscriptions/actions'
import { apiStoreSubscriptionsChangePlan$ } from '../../../../../services/api-store/subscriptions/change-plan'

type Action = ReturnType<typeof ApiStoreSubscriptionsAC.changePlan>

export const apiStoreSubscriptionsChangePlanEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiStoreSubscriptionsAT.CHANGE_PLAN),

    mergeMap(({ payload }) =>
      zip(
        of(payload),
        apiStoreSubscriptionsChangePlan$(
          state$.value.currentUser.token,
          payload.subscriptionId,
          payload.priceId,
        ),
      ),
    ),

    map(([payload, res]) => {
      if (!ApiUtils.isNotError(res)) return [payload, res] as const

      if (!res.response.payment_method_id || res.response.card_brand) return [payload, res] as const

      const card = state$.value.cards.list.find(
        (card) => card.payment_method_id === res.response.payment_method_id,
      )

      if (!card) return [payload, res] as const

      res.response = {
        ...res.response,
        card_brand: card.brand,
        card_last4: card.last4,
      }

      return [payload, res] as const
    }),

    map(([payload, res]) =>
      ApiUtils.isNotError(res)
        ? ApiStoreSubscriptionsChangePlanEpicMC.ok(payload.kind, res.response)
        : ApiStoreSubscriptionsChangePlanEpicMC.error(res.response),
    ),
  )
