import Scrollbars from 'react-custom-scrollbars'

import { Footer } from '../../components/common/footer/component'
import { LayoutTopContent } from '../../components/layouts/top-content/component'
import { LayoutTopContentContent } from '../../components/layouts/top-content/parts/content/component'
import { LayoutTopContentTopLeft } from '../../components/layouts/top-content/parts/top/left/component'
import { LayoutTopContentTopRight } from '../../components/layouts/top-content/parts/top/right/component'
import { PageCommonTopLeft } from '../@-common/top/left/component'
import { PageCommonTopRight } from '../@-common/top/right/component'
import { PageRootContent } from './content/component'
import { STPageRoot } from './style'
import { LayoutTopContentHeader } from '../../components/layouts/top-content/parts/header/component'
import { PageRootHeader } from './header/component'
import { LayoutTopContentFooter } from '../../components/layouts/top-content/parts/footer/component'

// ~~~~~~ Component

export const PageRoot = () => {
  // ~~~~~~ Render

  return (
    <STPageRoot>
      <Scrollbars>
        <LayoutTopContent>
          {/* Top */}

          {/* - Left */}

          <LayoutTopContentTopLeft>
            <PageCommonTopLeft />
          </LayoutTopContentTopLeft>

          {/* - Right */}

          <LayoutTopContentTopRight>
            <PageCommonTopRight />
          </LayoutTopContentTopRight>

          {/* Header */}

          <LayoutTopContentHeader>
            <PageRootHeader />
          </LayoutTopContentHeader>

          {/* Content */}

          <LayoutTopContentContent>
            <PageRootContent />
          </LayoutTopContentContent>

          {/* Footer */}

          <LayoutTopContentFooter>
            <Footer />
          </LayoutTopContentFooter>

          {/* - */}
        </LayoutTopContent>
      </Scrollbars>
    </STPageRoot>
  )
}
