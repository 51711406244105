import { Trans } from '../../../components/common/intl/trans'
import { ClassName, STPageRootHeader } from './style'

export const PageRootHeader = () => {
  // ~~~~~~ Render

  return (
    <STPageRootHeader>
      {/* Header */}

      <div className={`${ClassName}--header`}>
        <div className={`${ClassName}--header--content`}>
          {/* Title */}

          <div className={`${ClassName}--header--content--title`}>
            <Trans id="common.OurSolutions" />
          </div>

          {/* Subtitle */}

          <div className={`${ClassName}--header--content--subtitle`}>
            <Trans id="pages.main.Subtitle" />
          </div>
        </div>

        {/* - */}
      </div>
    </STPageRootHeader>
  )
}
