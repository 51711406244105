import { Trans } from '../intl/trans'
import { Config } from '../../../config'
import { STFooter } from './style'

// ~~~~~~ Constants

const year = new Date().getFullYear()

// ~~~~~~ Component

export const Footer = () => {
  // ~~~~~~ Render

  return (
    <STFooter>
      <div className="left-footer">
        {/* Back to */}

        <div className="back-to-link footer-text">
          <Trans id="common.BackTo" />
        </div>

        {/* DN website link */}

        <a href={Config.Links.Website} target="_blank" rel="noreferrer">
          DisplayNote.com
        </a>
      </div>

      {/* Terms and condition link in the footer */}

      <div className="footer-right">
        <a href={Config.Links.Terms} target="_blank" rel="noreferrer">
          <Trans id="common.TermsAndConditions" />
        </a>

        {/* Privacy and policy link in the footer */}

        <a href={Config.Links.Privacy} target="_blank" rel="noreferrer">
          <Trans id="common.PrivacyAndPolicy" />
        </a>

        {/* Cookies link in footer */}

        <a href={Config.Links.Cookies} target="_blank" rel="noreferrer">
          <Trans id="common.Cookies" />
        </a>

        {/* Copyright text */}

        <div className="footer-text">
          <div style={{ whiteSpace: 'nowrap' }}>© DisplayNote {year}</div>
        </div>
      </div>
    </STFooter>
  )
}
