import styled from 'styled-components'

// ~~~~~~ Constants

export const ClassName = 'user-zone'

// ~~~~~~ Component

export const STUserZone = styled.div`
  height: 100vh;
`
