import styled from 'styled-components'

// ~~~~~~ Constants

export const ClassName = 'main'

// ~~~~~~ Component

export const STPageRoot = styled.div`
  height: 100vh;
`
