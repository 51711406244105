import { useSelector } from 'react-redux'
import { UtilsDates, UtilsEncDec } from '@dn/utils'
import { Button } from '../../../../../../components/common/button/component'
import { Dollars } from '../../../../../../components/common/dollars/component'
import { Trans } from '../../../../../../components/common/intl/trans'
import { LazySVG } from '../../../../../../components/svgs/lazy-svg/component'
import { ClassName, STUserZonePlansRootProductCardBcast } from './style'
import { StoreState } from '../../../../../../models/app/model'
import { useNavigate } from 'react-router'
import { RouteDataBcastUpg2Premium, Routes } from '../../../../../../constants/routes'
import { BadgeText } from '../../../../../../components/common/badge-text/component'
import { CreditCard } from '../../../../../../components/common/credit-card/component'

// ~~~~~~ Constants

const Logo = LazySVG('logos/broadcast-gradient')

const ArrowRightIcon = LazySVG('icons/arrow-long-right')

const PlusInCircleIcon = LazySVG('icons/plus-circle')

// ~~~~~~ Types

type Props = {
  sub: DN.Models.Subscription.ModelFront
}

// ~~~~~~ Component

export const UserZonePlansRootProductCardBcast: React.FC<Props> = ({ sub }) => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  // ~~~~~~ State

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  const metaProducts = useSelector((state: StoreState) => state.broadcastSub)

  const cards = useSelector((state: StoreState) => state.cards)

  // ~~~~~~ Computed

  const isLoading = !currentUser.isSessionChecked || currentUser.uiStatus === 'running'

  const nextBillingDate = UtilsDates.getMediumDate(sub.current_period_end)

  const card = cards.list.find((card) => card.payment_method_id === sub.payment_method_id)

  const isFree = sub.price_id === metaProducts.products.free.id

  const isCanceled = !!sub.cancel_at

  const isUnpaid = sub.subscription_status === 'past_due' || sub.subscription_status === 'unpaid'

  // ~~~~~~ Handlers

  function onClickUpgradeToPremium() {
    const dataObj: RouteDataBcastUpg2Premium = {
      subId: sub.subscription_id,
      backPath: window.location.pathname,
      backIntl: 'pages.common.Plans',
    }

    const data = UtilsEncDec.base64UrlEncode(JSON.stringify(dataObj))

    navigate(Routes.genUserZonePlansBcastUpg2Premium(data))
  }

  function onClickManagePlan() {
    navigate(Routes.genUserZonePlansBcastDetail(sub.subscription_id))
  }

  // ~~~~~~ Render

  if (isLoading) return null

  return (
    <STUserZonePlansRootProductCardBcast>
      {/* Normal blocks */}

      <div className={`${ClassName}--a`}>
        {/* Logo + Price */}

        <div className={`${ClassName}--a--logo-price`}>
          <div className={`${ClassName}--a--logo-price--logo`}>
            <Logo size={24} />

            <div className={`${ClassName}--a--logo-price--text`}>
              {isFree ? <Trans id="common.Free" /> : 'Premium'}
            </div>
          </div>

          {/* Price (not visible on mobile) */}

          <div className={`${ClassName}--a--logo-price--price`}>
            <span>
              <Dollars value={sub.amount} />
            </span>
            <span> </span>
            <span className={`${ClassName}--a--logo-price--price--interval`}>
              (
              {sub.interval === 'month' ? (
                <Trans id="pages.common.Monthly" />
              ) : (
                <Trans id="pages.common.Yearly" />
              )}
              )
            </span>
          </div>

          {/* Canceled (only visible on mobile) */}

          {isCanceled ? (
            <div className={`${ClassName}--a--logo-price--canceled`}>
              <BadgeText intl="pages.user-zone.plans-tab.status.Canceled" />
            </div>
          ) : undefined}
        </div>

        {/* Sub + price (only visible on mobile) */}

        <div className={`${ClassName}--a--sub-price`}>
          <div className={`${ClassName}--a--sub-price--title`}>
            <Trans id="pages.user-zone.plans-tab.Subscription" />
          </div>

          <div className={`${ClassName}--a--sub-price--value`}>
            <span>
              <Dollars value={sub.amount} />
            </span>
            <span> </span>
            <span className={`${ClassName}--a--sub-price--value--interval`}>
              (
              {sub.interval === 'month' ? (
                <Trans id="pages.common.Monthly" />
              ) : (
                <Trans id="pages.common.Yearly" />
              )}
              )
            </span>
          </div>
        </div>

        {/* Next billing date */}

        <div className={`${ClassName}--a--next-billing`}>
          <div className={`${ClassName}--a--next-billing--title`}>
            <Trans id="pages.user-zone.plans-tab.NextBillingDate" />
          </div>

          <div className={`${ClassName}--a--next-billing--value`}>
            {isFree ? '-' : nextBillingDate}
          </div>
        </div>

        {/* Payment Method */}

        <div className={`${ClassName}--a--payment-method`}>
          <div className={`${ClassName}--a--payment-method--title`}>
            <Trans id="pages.common.PaymentMethod" />
          </div>

          <div className={`${ClassName}--a--payment-method--value`}>
            {!isFree && card ? (
              <CreditCard card={card} warning={isUnpaid || undefined} />
            ) : (
              <Button
                colorType="quaternary-link"
                intlId="pages.common.UpgradePremium"
                intlValues={{ value: 'Premium' }}
                leftIconMarginRight={4}
                IconLeft={PlusInCircleIcon}
                size="s"
                fontWeight="600"
                padding="0"
                running={false}
                disabled={false}
                active={true}
                onClick={onClickUpgradeToPremium}
              />
            )}
          </div>
        </div>

        {/* Action */}

        <div className={`${ClassName}--a--action`}>
          {/* Cancel badge */}

          {isCanceled ? (
            <BadgeText
              className={`${ClassName}--a--action--canceled`}
              intl="pages.user-zone.plans-tab.status.Canceled"
            />
          ) : undefined}

          {/* Action: Manage */}

          <Button
            colorType="only-link"
            padding="0"
            intlId="pages.user-zone.plans-tab.ManagePlan"
            IconRight={ArrowRightIcon}
            size="s"
            running={false}
            disabled={false}
            active={true}
            onClick={onClickManagePlan}
          />
        </div>
      </div>

      {/* Action (mobile view) */}

      <div className={`${ClassName}--c`}>
        <div className={`${ClassName}--c--action`}>
          <Button
            colorType="default-link"
            intlId="pages.user-zone.plans-tab.ManagePlan"
            IconRight={ArrowRightIcon}
            size="s"
            running={false}
            disabled={false}
            active={true}
            onClick={onClickManagePlan}
          />
        </div>
      </div>

      {/* - */}
    </STUserZonePlansRootProductCardBcast>
  )
}
