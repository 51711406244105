const prefix = 'at-api-store-subscriptions'

// ~~~~~~ Action Types

export const ApiStoreSubscriptionsAT = {
  CHANGE_PAYMENT_METHOD: `${prefix}-change-payment-method`,

  CHANGE_PLAN: `${prefix}-change-plan`,
  CANCEL_PLAN: `${prefix}-cancel-plan`,
  REACTIVATE_PLAN: `${prefix}-reactivate-plan`,
} as const

// ~~~~~~ Action Mutators

export type ChangePlanKind = 'upgrade' | 'month-to-year' | 'year-to-month'

export const ApiStoreSubscriptionsAC = {
  changePaymentMethod: (subscriptionId: string, paymentMethodId: string) => ({
    type: ApiStoreSubscriptionsAT.CHANGE_PAYMENT_METHOD,
    payload: {
      subscriptionId,
      paymentMethodId,
    },
  }),

  changePlan: (kind: ChangePlanKind, subscriptionId: string, priceId: string) => ({
    type: ApiStoreSubscriptionsAT.CHANGE_PLAN,
    payload: {
      kind,
      subscriptionId,
      priceId,
    },
  }),

  cancelPlan: (subscriptionId: string) => ({
    type: ApiStoreSubscriptionsAT.CANCEL_PLAN,
    payload: {
      subscriptionId,
    },
  }),

  reactivatePlan: (subscriptionId: string) => ({
    type: ApiStoreSubscriptionsAT.REACTIVATE_PLAN,
    payload: {
      subscriptionId,
    },
  }),
} as const
