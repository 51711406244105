const initModel: DN.Models.Root.Model = {
  token: '',
  showSideMenu: false,
}

// ~~~~~~

export const RootModel = {
  initModel,
}
