import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import { ClassName, STUserZonePlansProductLauncher } from './style'
import { Routes } from '../../../../../constants/routes'
import { MediaQuery } from '../../../../../style/constants/media-queries'
import { Button } from '../../../../../components/common/button/component'
import { Dollars } from '../../../../../components/common/dollars/component'
import { Trans } from '../../../../../components/common/intl/trans'
import { ProductButtonLauncher } from '../../../../../components/common/product-button/launcher/component'
import { LazySVG } from '../../../../../components/svgs/lazy-svg/component'
import { Config } from '../../../../../config'
import { StoreState } from '../../../../../models/app/model'
import { NeedHelp } from '../../../@-common/need-help/component'
import { ApiStoreCheckoutAC } from '../../../../../store/actions/api-store/checkout/actions'

import backgroundSM from '../../../../../imgs/pngs/launcher-landing-background-sm.png'
import backgroundMD from '../../../../../imgs/pngs/launcher-landing-background-md.png'
import backgroundLG from '../../../../../imgs/pngs/launcher-landing-background-lg.png'
import background2x from '../../../../../imgs/pngs/launcher-landing-background.png'

// ~~~~~~ Constants

const ArrowLeftIcon = LazySVG('icons/arrow-long-left')

const ArrowRightIcon = LazySVG('icons/arrow-long-right')

const GradientLogo = LazySVG('logos/launcher-gradient')

// ~~~~~~ Component

export const UserZonePlansProductLauncher = () => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  const dispatch = useDispatch()

  // ~~~~~~ State

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  const metaProducts = useSelector((state: StoreState) => state.launcher)

  const subs = useSelector((state: StoreState) => state.subscriptions)

  const invoices = useSelector((state: StoreState) => state.invoices)

  const buyLicense = useSelector((state: StoreState) => state.buyLicense)

  // ~~~~~~ Computed

  const isLoading = !currentUser.isSessionChecked || metaProducts.uiStatus !== 'completed'

  const buyLicenseIsRunning = buyLicense.uiStatus === 'running'

  const productYearly = metaProducts.list.find((product) => product.interval === 'year')

  const sub = subs.find((sub) => sub.product_id === 'launcher')

  const invoice = invoices.find((invoice) => invoice.product_id === 'launcher')

  // ~~~~~~ Handlers

  function onClickGoBack() {
    navigate(Routes.UserZonePlans)
  }

  function onClickStartTrial() {
    if (!productYearly) return

    dispatch(
      ApiStoreCheckoutAC.startFreeSubOrTrial('launcher', {
        email: currentUser.email,
        price_of_license_of_product_id: productYearly.id,
        code: '',
      }),
    )
  }

  function onClickPurchase() {
    navigate(Routes.UserZonePlansLauncherPurchase)
  }

  // ~~~~~~ Effects

  // - If user has some launcher sub
  //   trial: Nav to trial details
  //   other: Return to plans
  //
  useEffect(() => {
    if (!sub) return

    sub.trial_end
      ? navigate(`${Routes.genUserZonePlansLauncherDetail(sub.subscription_id)}?new=true`)
      : navigate(Routes.UserZonePlans)

    //
  }, [sub, navigate])

  // - If user has a launcher invoice cannot get a trial
  //
  useEffect(() => {
    if (!invoice) return

    navigate(Routes.UserZonePlans)

    //
  }, [invoice, navigate])

  // ~~~~~~ Render

  return (
    <STUserZonePlansProductLauncher>
      {/* Info + Trial */}

      <div className={`${ClassName}--top`}>
        {/* Background table/mobile */}

        <picture className={`${ClassName}--top--bg-pic`}>
          <source media={`(max-width: ${MediaQuery.SM})`} srcSet={backgroundSM} />
          <source media={`(max-width: ${MediaQuery.MD})`} srcSet={backgroundMD} />
          <img srcSet="data:,x" alt="launcher" />
        </picture>

        {/* Info */}

        <div className={`${ClassName}--top--left`}>
          {/* Back */}

          <div className={`${ClassName}--top--left--back`}>
            <Button
              className={`${ClassName}--top--left--back--action`}
              colorType="only-link"
              width="unset"
              padding="0"
              intlId="pages.common.Plans"
              active={true}
              disabled={false}
              running={false}
              onClick={onClickGoBack}
              IconLeft={ArrowLeftIcon}
            />
          </div>

          <div style={{ margin: 'auto', maxWidth: 500 }}>
            {/* Product logo */}

            <div className={`${ClassName}--top--left--logo`}>
              <GradientLogo size={32} className={`${ClassName}--top--left--logo--img`} />
            </div>

            {/* Title */}

            <div className={`${ClassName}--top--left--title`}>
              <Trans id="pages.products.launcher.Title" />
            </div>

            {/* Info list */}

            <div className={`${ClassName}--top--left--info-list`}>
              {/* Title */}

              <div className={`${ClassName}--top--left--info-list--title`}>
                <Trans id="pages.products.common.WhatToKnow" />
              </div>

              {/* Points */}

              <ul className={`${ClassName}--top--left--info-list--points`}>
                {/* X days... */}

                <div className={`${ClassName}--top--left--info-list--points--point`}>
                  <span className={`${ClassName}--top--left--info-list--points--point--bullet`}>
                    •
                  </span>
                  <Trans
                    id="pages.products.common.DayNoObligationTrial"
                    values={{ value: Config.Trials.Launcher.days }}
                  />
                </div>

                {/* No card required... */}

                <div className={`${ClassName}--top--left--info-list--points--point`}>
                  <span className={`${ClassName}--top--left--info-list--points--point--bullet`}>
                    •
                  </span>
                  <Trans id="pages.products.common.NoCreditCardRequired" />
                </div>

                {/* Only windows... */}

                <div className={`${ClassName}--top--left--info-list--points--point`}>
                  <span className={`${ClassName}--top--left--info-list--points--point--bullet`}>
                    •
                  </span>
                  <span>
                    <Trans id="pages.products.launcher.WindowsOnly" />
                  </span>
                  <span> </span>
                  <span className={`${ClassName}--top--left--info-list--points--point--caption`}>
                    ({Config.Others.Launcher.Versions.Windows})
                  </span>
                </div>
              </ul>
            </div>

            {/* Action Trial */}

            <ProductButtonLauncher
              intlId="pages.products.common.StartTrial"
              active={!isLoading && !buyLicenseIsRunning}
              disabled={isLoading || buyLicenseIsRunning}
              running={isLoading || buyLicenseIsRunning}
              onClick={onClickStartTrial}
            />
          </div>
        </div>

        {/* Right image (only Desktop) */}

        <div className={`${ClassName}--top--right`}>
          {/*  */}

          <picture className={`${ClassName}--top--right--pic`}>
            <source media={`(max-width: ${MediaQuery.MD})`} srcSet="data:,x" />
            <img srcSet={`${backgroundLG}, ${background2x} 2x`} alt="launcher" />
          </picture>

          {/* - */}
        </div>
      </div>

      {/* Purchase */}

      <div className={`${ClassName}--bottom`}>
        <div className={`${ClassName}--bottom--left`}>
          {/* Want to buy... */}

          <div className={`${ClassName}--bottom--left--title`}>
            <Trans id="pages.products.launcher.JustBuyLicense" />
          </div>
        </div>

        {/* Action (buy) */}

        <div className={`${ClassName}--bottom--right`}>
          {productYearly ? (
            <>
              <div className={`${ClassName}--bottom--right--left`}>
                <div className={`${ClassName}--bottom--right--left--info`}>
                  <Trans id="pages.common.YearlyPerDevice" />
                </div>

                <div className={`${ClassName}--bottom--right--left--price`}>
                  <Dollars value={Number(productYearly.price)} />
                </div>
              </div>

              <div className={`${ClassName}--bottom--right--right`}>
                <Button
                  colorType="only-link"
                  intlId="pages.products.common.BuyLicense"
                  padding="0"
                  IconRight={ArrowRightIcon}
                  active={!isLoading}
                  disabled={isLoading}
                  running={isLoading}
                  onClick={onClickPurchase}
                />
              </div>
            </>
          ) : undefined}
        </div>
      </div>

      {/* Need help... */}

      <NeedHelp className={`${ClassName}--help`} />

      {/* - */}
    </STUserZonePlansProductLauncher>
  )
}
