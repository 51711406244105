import { genFieldMR } from '../../utils/store'

const initModel: DN.Models.CurrentUser.ModelFront = {
  uiStatus: 'init',
  errors: [],
  email: '',
  isSessionChecked: false,
  dateFormat: 'dd/mm/yyyy',
  token: '',
}

// ~~~~~~ Fields Mutators/Reducers

const prefix = 'mt-model-current-user'

export const CurrentUserFieldsMR = {
  dateFormat: genFieldMR(prefix, initModel, 'dateFormat', initModel.dateFormat),
}

export const CurrentUserModel = {
  initModel,
}
