import styled from 'styled-components'

import { Transition } from '../../../../style/animations/css-animations'
import { Colors } from '../../../../style/theme/colors'
import { Fonts } from '../../../../style/theme/fonts'
import { Misc } from '../../../../style/theme/misc'
import { theme } from '../../../../style/utils/theme'

export const STUserMenuDesktop = styled.div`
  #user-menu-desktop {
    display: flex;
    align-items: center;
  }

  .dropdown {
    margin-top: 8px;
    position: relative;

    .items {
      position: absolute;

      z-index: 2;
      right: 0;

      border-radius: 8px;

      margin-top: 30px;
      padding: 8px;
      min-width: 234px;

      background-color: ${theme(Colors.WOLF_90_90)};

      .item {
        padding-left: 12px;
        display: flex;
        align-items: center;

        ${Misc.BorderRadius};

        ${Fonts.Scale.Body};

        height: 40px;

        cursor: pointer;
        color: ${theme(Colors.WOLF_00_00)};

        ${Transition.Color}

        .icon {
          margin-right: 12px;

          display: flex;

          svg {
            width: 16px;
            height: 16px;

            path {
              fill: ${theme(Colors.WOLF_00_00)};
            }
          }
        }

        .item-text {
          padding-top: 1px;

          &:after {
            display: block;
            content: '';
            border-bottom: 1px solid ${theme(Colors.BRONX_30_30)};
            transform: scaleX(0);
            ${Transition.TransformFast}
          }
        }

        &:hover {
          color: ${theme(Colors.BRONX_30_30)};

          .item-text:after {
            transform: scaleX(1);
          }

          .icon {
            svg {
              path {
                ${Transition.FillFaster}

                fill: ${theme(Colors.BRONX_30_30)};
              }
            }
          }
        }

        &:active .item-text:after {
          transform: scaleX(0);
        }
      }

      hr {
        border: none;
        border-top: solid 1px ${theme(Colors.WOLF_60_60)};
      }
    }
  }
`
