import { catchError, map, Observable, of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { ApiRoutes } from '../constants/routes'

const { ReleaseInfo } = ApiRoutes

// ####################################################################################################
// ~~~~~~ ReleaseInfo latest version
// ####################################################################################################

type Res = DN.Models.LauncherReleaseInfo.Api.Get.Res
type ResParsed = DNApiResParsed<DN.Models.LauncherReleaseInfo.Api.Get.ResParsed>
type ResError = DNApiError<DN.Models.LauncherReleaseInfo.Api.Get.ResError>

export const apiLauncherReleaseInfoGet$ = (headers?: AppHeaders) => {
  return ajax<Res>({
    headers,
    method: ReleaseInfo.Get.Method,
    url: ReleaseInfo.Get.getUrl,
  }).pipe(
    map((res): ResParsed => {
      return {
        status: res.status,
        response: {
          files: res.response.files,
          version: res.response.version,
        },
      }
    }),
    catchError((res: DNApiRes<void>): Observable<ResError> => {
      return of({
        status: res.status,
        response: [{ id: 'api.release-info.Error' }],
      })
    }),
  )
}
