const prefix = 'mt-subscriptions'

// ~~~~~~ Mutators Types

export const SubscriptionMT = {
  SET_TMP_SUBSCRIPTION: `${prefix}-set-tmp-subscription`,
  SET_TMP_SUBSCRIPTION_CARD: `${prefix}-set-tmp-subscription-card`,
  CLEAR_TMP_SUBSCRIPTION: `${prefix}-clear-tmp-subscription`,
  SET_TMP_INTERVAL: `${prefix}-set-tmp-interval`,
} as const

// ~~~~~~ Mutators Creators

export const SubscriptionMC = {
  setTmpSubscription: (subscription: DN.Models.Subscription.ModelTmp) => ({
    type: SubscriptionMT.SET_TMP_SUBSCRIPTION,
    payload: {
      subscription,
    },
  }),

  setTmpSubscriptionCard: (card: DN.Models.Card.ModelFront) => ({
    type: SubscriptionMT.SET_TMP_SUBSCRIPTION_CARD,
    payload: {
      card,
    },
  }),

  clearTmpSubscription: () => ({
    type: SubscriptionMT.CLEAR_TMP_SUBSCRIPTION,
  }),

  setTmpInterval: (interval: DN.Models.Product.Interval) => ({
    type: SubscriptionMT.SET_TMP_INTERVAL,
    payload: {
      interval,
    },
  }),
} as const
