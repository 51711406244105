import { Observable, of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { catchError, map } from 'rxjs/operators'
import { ApiRoutes } from '../constants/routes'
import { ApiHeadersUtils } from '../utils/headers'

type Req = DN.Models.Checkout.Api.CreateSession.Req
type Res = DN.Models.Checkout.Api.CreateSession.Res
type ResParsed = DNApiResParsed<DN.Models.Checkout.Api.CreateSession.ResParsed>
type ResError = DNApiError<DN.Models.Checkout.Api.CreateSession.ResError>

const { CreateSession } = ApiRoutes.Checkout

// Buy non free product
export const apiStoreCheckoutCreateSession$ = (
  token: string,
  productId: DN.Models.Product.ProductId,
  data: Req,
  headers?: AppHeaders,
) => {
  const finalHeaders = token
    ? ApiHeadersUtils.genHeadersWithAuth(token, headers)
    : ApiHeadersUtils.genHeaders(headers)

  return ajax<Res>({
    ...finalHeaders,
    method: CreateSession.Method,
    url: CreateSession.getUrl(),
    body: data,
  }).pipe(
    map((res): ResParsed => {
      return {
        status: res.status,
        response: res.response,
      }
    }),
    catchError(
      (res: DNApiRes<void>): Observable<ResError> =>
        res.status === 412 && productId === 'broadcast-subscription'
          ? of({
              status: res.status,
              response: [{ id: 'api.checkout.create-session.Broadcast412' }],
            })
          : of({
              status: res.status,
              response: [{ id: 'api.checkout.create-session.Error' }],
            }),
    ),
  )
}
