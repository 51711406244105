import { genFieldMR, genModelMR } from '../../utils/store'
import { ValidateUtils } from '../../utils/validations'

const initModelShow: DN.Models.BillingInfo.ModelFront = {
  uiStatus: 'init',
  errors: [],

  contact_name: '',
  company: '',
  phone: '',
  address: '',
  cp_zip: '',
  country: '',
  city: '',
  state_region: '',
}

const initModelEdit: DN.Models.BillingInfo.ModelEdit = {
  uiStatus: 'init',
  errors: [],

  contact_name: '',
  contact_name_err: [],

  company: '',
  company_err: [],

  phone: '',
  phone_err: [],

  address: '',
  address_err: [],

  cp_zip: '',
  cp_zip_err: [],

  country: '',
  country_err: [],

  city: '',
  city_err: [],

  state_region: '',
  state_region_err: [],
}

// ~~~~~~

const prefixEdit = 'billing-info-edit'

export const BillingInfoFieldMR = {
  __model__: genModelMR(prefixEdit, initModelEdit),

  contactName: genFieldMR(prefixEdit, initModelEdit, 'contact_name', initModelEdit.contact_name),
  company: genFieldMR(prefixEdit, initModelEdit, 'company', initModelEdit.company),
  phone: genFieldMR(prefixEdit, initModelEdit, 'phone', initModelEdit.phone),
  address: genFieldMR(prefixEdit, initModelEdit, 'address', initModelEdit.address),
  country: genFieldMR(prefixEdit, initModelEdit, 'country', initModelEdit.country),
  cpZip: genFieldMR(prefixEdit, initModelEdit, 'cp_zip', initModelEdit.cp_zip),
  stateRegion: genFieldMR(prefixEdit, initModelEdit, 'state_region', initModelEdit.state_region),
  city: genFieldMR(prefixEdit, initModelEdit, 'city', initModelEdit.city),
}

// ~~~~~~ Validations

const { Validations } = ValidateUtils

const validations = {
  contactName: [{ error: Validations.MaxLength, value: 30 }],
  company: [{ error: Validations.MaxLength, value: 30 }],
  phone: [{ error: Validations.MaxLength, value: 30 }],
  address: [{ error: Validations.MaxLength, value: 60 }],
  cpZip: [{ error: Validations.MaxLength, value: 30 }],
  stateRegion: [{ error: Validations.MaxLength, value: 50 }],
  city: [{ error: Validations.MaxLength, value: 50 }],
}

// ~~~~~~

const fromBackToFront = (
  model: DN.Models.BillingInfo.ModelBack,
): DN.Models.BillingInfo.ModelFront => {
  return {
    ...initModelShow,
    contact_name: model.contact_name || '',
    company: model.company || '',
    phone: model.phone || '',
    address: model.address || '',
    cp_zip: model.cp_zip || '',
    country: model.country || '',
    city: model.city || '',
    state_region: model.state_region || '',
  }
}

// ~~~~~~

export const BillingInfoModel = {
  initModelShow,
  initModelEdit,

  validations,
  fromBackToFront,
} as const
