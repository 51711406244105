import { combineEpics } from 'redux-observable'

import { apiStoreSubscriptionsCreateEpic$ } from './api-store/subscriptions/create/epic'
import { apiStoreChekcoutCreateSessionEpic$ } from './api-store/checkout/create-session/epic'
import { apiStoreCheckoutSendEmailPassEpic$ } from './api-store/checkout/send-email-pass/epic'
import { apiStoreCheckoutShowSessionEpic$ } from './api-store/checkout/show-session/epic'
import { apiStoreCheckoutValidateEmailCodeEpic$ } from './api-store/checkout/validate-email-code/epic'

import { apiStoreCardAddEpic$ } from './api-store/card/add/epic'
import { apiStoreCardChangeEpic$ } from './api-store/card/change/epic'
import { apiStoreCardRemEpic$ } from './api-store/card/rem/epic'
import { apiStoreCurrentUserShowEpic$ } from './api-store/current-user/show/epic'

import { apiStoreListProductsEpic$ } from './api-store/products/epics'
import { changeLangEpic$ } from './intl/epic'
import { apiStoreSubscriptionsChangePaymentMethodEpic$ } from './api-store/subscriptions/change-payment-method/epic'
import { apiStoreCheckoutSetDefaultCardEpic$ } from './api-store/checkout/set-default-card/epic'
import { apiStoreSubscriptionsCancelPlanEpic$ } from './api-store/subscriptions/cancel-plan/epic'
import { apiStoreSubscriptionsChangePlanEpic$ } from './api-store/subscriptions/change-plan/epic'
import { apiStoreBillingUpdateEpic$ } from './api-store/billing/update/epic'
import { toastErrorsEpic$ } from './toast/errors/epic'
import { toastSuccessEpic$ } from './toast/success/epic'
import { apiStoreSubscriptionsReactivatePlanEpic$ } from './api-store/subscriptions/reactivate-plan/epic'
import { apiMontageReleaseInfoGetEpic$ } from './api-montage/release-info/get/epic'
import { apiLauncherReleaseInfoGetEpic$ } from './api-launcher/release-info/get/epic'
import { apiStoreChainingFromChangeCardToCheckoutSetDefaultCardEpic$ } from './api-store/chaining/from-change-card-to-checkout-set-default-card'
import { apiStoreChainingFromAddCardToCheckoutSetDefaultCardEpic$ } from './api-store/chaining/from-add-card-to-checkout-set-default-card'

export default combineEpics(
  changeLangEpic$ as any,

  // Get products

  apiStoreListProductsEpic$ as any,

  // Get release api info

  apiMontageReleaseInfoGetEpic$ as any,
  apiLauncherReleaseInfoGetEpic$ as any,

  // Current User

  apiStoreCurrentUserShowEpic$ as any,

  // Checkout

  apiStoreCheckoutSendEmailPassEpic$ as any,
  apiStoreCheckoutValidateEmailCodeEpic$ as any,
  apiStoreChekcoutCreateSessionEpic$ as any,

  // - Show data of checkout

  apiStoreCheckoutShowSessionEpic$ as any,

  // - Change default card

  apiStoreCheckoutSetDefaultCardEpic$ as any,

  // Card

  apiStoreCardAddEpic$ as any,
  apiStoreCardChangeEpic$ as any,
  apiStoreCardRemEpic$ as any,

  // Chaining

  // - Add card -> set default

  apiStoreChainingFromAddCardToCheckoutSetDefaultCardEpic$ as any,

  // - Change card -> set default

  apiStoreChainingFromChangeCardToCheckoutSetDefaultCardEpic$ as any,

  // Subscriptions

  apiStoreSubscriptionsCreateEpic$ as any,
  apiStoreSubscriptionsCancelPlanEpic$ as any,
  apiStoreSubscriptionsReactivatePlanEpic$ as any,
  apiStoreSubscriptionsChangePlanEpic$ as any,

  // - Change default card

  apiStoreSubscriptionsChangePaymentMethodEpic$ as any,

  // Billing

  apiStoreBillingUpdateEpic$ as any,

  // Toast

  toastErrorsEpic$ as any,
  toastSuccessEpic$ as any,
)
