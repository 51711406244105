import styled from 'styled-components'
import { Fonts } from '../../../../../style/theme/fonts'
import { MediaQuery } from '../../../../../style/constants/media-queries'
import { Colors } from '../../../../../style/theme/colors'
import { theme } from '../../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'page-trials-or-free-laucher-content-step-3'

// ~~~~~~ Component

export const STPageTrialsOrFreeLauncherContentStep3Ready = styled.div`
  max-width: 622px;
  margin-left: auto;
  margin-right: auto;

  margin-top: 32px;

  /* Steps */

  .${ClassName}--steps {
    margin-bottom: 56px;
  }

  /* Logo */

  .${ClassName}--logo {
    width: 98px;

    margin-bottom: 8px;
  }

  /* Title */

  .${ClassName}--title {
    ${Fonts.Weight[800]}
    ${Fonts.Scale.OldA}

    margin-bottom: 16px;

    .${ClassName}--title--icon {
      position: relative;

      svg {
        position: absolute;
        left: 8px;

        width: 56px;
        height: 56px;
      }
    }
  }

  /* Subtitle */

  .${ClassName}--subtitle {
    ${Fonts.Weight[300]}
    ${Fonts.Scale.OldD}

    margin-bottom: 24px;

    .${ClassName}--subtitle--acent {
      ${Fonts.Weight[700]}
    }

    .${ClassName}--subtitle--login {
      cursor: pointer;

      ${Fonts.Weight[700]}

      border-bottom: solid 2px ${theme(Colors.WOLF_90_90)};
    }
  }

  /* Action */

  .${ClassName}--action {
    margin-bottom: 32px;
  }

  /* Tip */

  .${ClassName}--tip {
    ${Fonts.Weight[400]}
    ${Fonts.Scale.Body}

    margin-bottom: 32px;
  }

  /* Horizontal space */

  hr {
    border: none;
    border-top: 1px solid ${theme(Colors.WOLF_10_10)};

    margin-bottom: 32px;
  }

  /* Links */

  .${ClassName}--links {
    padding-bottom: 78px;

    .${ClassName}--links--title {
      ${Fonts.Weight[700]}
      ${Fonts.Scale.OldD}

      margin-bottom: 16px;
    }

    .${ClassName}--links--link {
      margin-bottom: 8px;
    }
  }

  /* 1280 */

  @media (max-width: ${MediaQuery.MaxWidth2k}) {
    /* Title */

    .${ClassName}--title {
      ${Fonts.Scale.OldB}
    }
  }

  /* 960 */

  @media (max-width: ${MediaQuery.MD}) {
    /* Steps */

    .${ClassName}--steps {
      margin-bottom: 50px;
    }

    /* Logo */

    .${ClassName}--logo {
      width: 86px;
    }

    /* Title */

    .${ClassName}--title {
      ${Fonts.Scale.Headline1}
    }

    /* Subtitle */

    .${ClassName}--subtitle {
      ${Fonts.Scale.Subtitle}
    }

    /* Action */

    .${ClassName}--action {
      margin-bottom: 24px;
    }

    /* Tip */

    .${ClassName}--tip {
      margin-bottom: 24px;
    }
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    /* Steps */

    .${ClassName}--steps {
      margin-bottom: 32px;
    }

    /* Title */

    .${ClassName}--title {
      ${Fonts.Scale.Headline2}
    }

    /* Subtitle */

    .${ClassName}--subtitle {
      ${Fonts.Scale.BodyBig}
    }

    /* Field */

    .${ClassName}--field {
      &.last {
        margin-bottom: 24px;
      }
    }

    /* Action */

    .${ClassName}--action {
      margin-bottom: 16px;
    }

    /* Tip */

    .${ClassName}--tip {
      margin-bottom: 16px;
    }
  }
`
