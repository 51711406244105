const prefix = 'at-api-store-products'

// ~~~~~~ Action Types

export const ApiStoreProductsAT = {
  LIST_PRODUCTS: `${prefix}-list`,
} as const

// ~~~~~~ Action Mutators

export const ApiStoreProductsAC = {
  listProducts: (productId: DN.Models.Product.ProductId) => ({
    type: ApiStoreProductsAT.LIST_PRODUCTS,
    payload: { productId },
  }),
} as const
