import { AppInitState } from '../../../models/app/model'

import { dynReducer } from '../../../utils/store'
import {
  CheckoutSessionMC,
  CheckoutSessionMT,
} from '../../actions-mutators/checkout-session/mutators'
import { ApiStoreCheckoutAT } from '../../actions/api-store/checkout/actions'
import {
  ApiStoreCheckoutShowSessionEpicMC,
  ApiStoreCheckoutShowSessionEpicMT,
} from '../../epics/api-store/checkout/show-session/mutators'

const initState = AppInitState.checkoutSession

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ Show checkout session
// ####################################################################################################

reducer[ApiStoreCheckoutAT.SHOW_SESSION] = (state: State): State => {
  return {
    ...initState,
    uiStatus: 'running',
    errors: [],
  }
}

// ####################################################################################################
// ~~~~~~ Show checkout session - OK
// ####################################################################################################

reducer[ApiStoreCheckoutShowSessionEpicMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreCheckoutShowSessionEpicMC.ok>,
): State => {
  return {
    ...state,
    uiStatus: 'completed',
    ...payload,
  }
}

// ####################################################################################################
// ~~~~~~ Show checkout session - ERROR
// ####################################################################################################

reducer[ApiStoreCheckoutShowSessionEpicMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreCheckoutShowSessionEpicMC.error>,
): State => {
  return {
    ...state,
    uiStatus: 'init',
    errors: payload.errors,
  }
}

// ####################################################################################################
// ~~~~~~ Set session (for trial)
// ####################################################################################################

reducer[CheckoutSessionMT.SET_SESSION] = (
  state: State,
  { payload }: ReturnType<typeof CheckoutSessionMC.setSession>,
): State => {
  return {
    ...state,
    ...payload,
  }
}

// ~~~~~~

export const checkoutSessionReducer = dynReducer(initState, reducer)
