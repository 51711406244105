import toast from 'react-hot-toast'

import { Props, Toast } from './component'

const ToastDuration = 4000

type Data = Props & {
  duration?: number
}

const basic = ({ duration = ToastDuration, kind, intlIds }: Data) => {
  toast((_toastInstance) => <Toast kind={kind} intlIds={intlIds} />, {
    duration,
  })
}

export const ToastUtil = {
  basic,
}
