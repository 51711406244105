import styled from 'styled-components'
import { genSTUserZonePlansRootAddProductAltCommonStyle } from '../common-style'

// ~~~~~~ Constants

export const ClassName = 'user-zone-plans-root-add-product-alt-montage'

// ~~~~~~ Component

export const STUserZonePlansRootAddProductAltMontage = styled.div`
  ${genSTUserZonePlansRootAddProductAltCommonStyle(ClassName)}
`
