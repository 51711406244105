export const ElementEvents = {
  KeyDown: 'keydown',
  Click: 'click',

  MouseDown: 'mousedown',
  MouseUp: 'mouseup',
  MouseMove: 'mousemove',
  MouseDrag: 'mousedrag',
  MouseLeave: 'mouseleave',

  TouchStart: 'touchstart',
  TouchEnd: 'touchend',
  TouchCancel: 'touchcancel',
  TouchMove: 'touchmove',
} as const
