import { ProductIds } from '../../constants/product-ids'
import { Colors } from './colors'

type ColorState = 'base' | 'hover' | 'active'

// ~~~~~~

export const ColorsUtils = {
  getBackgroundColor: (ProductId: keyof typeof ProductIds) => {
    switch (ProductId) {
      case 'BroadcastSub':
        return Colors.MORRIE_5_5

      case 'Montage':
        return Colors.KIO_05_05

      case 'Launcher':
        return Colors.REMY_5_5

      default:
        return Colors.MORRIE_5_5
    }
  },

  getGradientColorType: (colorState: ColorState) => {
    switch (colorState) {
      case 'base':
        return {
          BroadcastSub: [Colors.MORRIE_30_30, Colors.MORRIE_50_50],
          Launcher: [Colors.REMY_30_30, Colors.REMY_40_40],
          Montage: [Colors.KIO_30_30, Colors.KIO_40_40],
          Payment: [Colors.BRONX_30_30, Colors.BRONX_40_40],
        }

      case 'hover':
        return {
          BroadcastSub: [Colors.MORRIE_30_30, Colors.MORRIE_30_30],
          Launcher: [Colors.REMY_30_30, Colors.REMY_30_30],
          Montage: [Colors.KIO_30_30, Colors.KIO_30_30],
          Payment: [Colors.BRONX_30_30, Colors.BRONX_40_40],
        }

      case 'active':
        return {
          BroadcastSub: [Colors.MORRIE_40_40, Colors.MORRIE_40_40],
          Launcher: [Colors.REMY_40_40, Colors.REMY_40_40],
          Montage: [Colors.KIO_40_40, Colors.KIO_40_40],
          Payment: [Colors.BRONX_30_30, Colors.BRONX_40_40],
        }

      default:
        return {
          BroadcastSub: [Colors.MORRIE_30_30, Colors.MORRIE_50_50],
          Launcher: [Colors.MORRIE_30_30, Colors.MORRIE_50_50],
          Montage: [Colors.KIO_30_30, Colors.KIO_40_40],
          Payment: [Colors.BRONX_30_30, Colors.BRONX_40_40],
        }
    }
  },
} as const
