import { useSelector } from 'react-redux'
import { ClassName, STUserZonePlansRoot } from './style'
import { StoreState } from '../../../../models/app/model'
import { UserZonePlansRootProductCardBcast } from './product-card/bcast/component'
import { UserZonePlansRootProductCardLauncher } from './product-card/launcher/component'
import { UserZonePlansRootProductCardMontage } from './product-card/montage/component'
import { UserZonePlansRootAddProductAltMontage } from './add-product-alt/montage/component'
import { UserZonePlansRootAddProductAltLauncher } from './add-product-alt/launcher/component'
import { UserZonePlansRootAddProductBcast } from './add-product/bcast/component'
import { UserZonePlansRootAddProductLauncher } from './add-product/launcher/component'
import { UserZonePlansRootAddProductMontage } from './add-product/montage/component'
import { Trans } from '../../../../components/common/intl/trans'
import { Footer } from '../../../../components/common/footer/component'
import { UserZonePlansRootLoading } from './loading/component'

// ~~~~~~ Constants

const AddProductMatrix = {
  '': ['bcast', 'mon', 'lcher'] as const,
  mon: ['bcast', 'lcher', 'mon'] as const,
  lcher: ['mon', 'bcast', 'lcher'] as const,
  'mon+lcher': ['bcast', 'mon', 'lcher'] as const,
}

// ~~~~~~ Component

export const UserZonePlansRoot = () => {
  // ~~~~~~ State

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  const subs = useSelector((state: StoreState) => state.subscriptions)

  // ~~~~~~ Computed

  const isLoading = !currentUser.isSessionChecked || currentUser.uiStatus === 'running'

  const userHasMontage = subs.some((sub) => sub.product_id === 'montage')

  const userHasLauncher = subs.some((sub) => sub.product_id === 'launcher')

  const userHasBcast = subs.some((sub) => sub.product_id === 'broadcast-subscription')

  const addProductIdx = [userHasMontage ? 'mon' : undefined, userHasLauncher ? 'lcher' : undefined]
    .filter(Boolean)
    .join('+')

  const addProductOrder = AddProductMatrix[addProductIdx as keyof typeof AddProductMatrix] || [
    'bcast',
    'mon',
    'lcher',
  ]

  // ~~~~~~ Render

  if (isLoading) return <UserZonePlansRootLoading />

  return (
    <STUserZonePlansRoot>
      <div className={`${ClassName}--content`}>
        {/* Title */}

        <div className={`${ClassName}--content--title`}>
          <Trans id="pages.common.Plans" />
        </div>

        {/* Subscription cards */}

        <div className={`${ClassName}--content--subs`}>
          {subs.map((sub) => (
            <div className={`${ClassName}--content--subs--sub`} key={sub.subscription_id}>
              {sub.product_id === 'broadcast-subscription' ? (
                <UserZonePlansRootProductCardBcast sub={sub} />
              ) : sub.product_id === 'launcher' ? (
                <UserZonePlansRootProductCardLauncher sub={sub} />
              ) : (
                <UserZonePlansRootProductCardMontage sub={sub} />
              )}
            </div>
          ))}
        </div>

        {/* Title 2 */}

        <div className={`${ClassName}--content--title-2`}>
          <Trans id="common.OurSolutions" />
        </div>

        {/* Subtitle 2 */}

        <div className={`${ClassName}--content--subtitle-2`}>
          <Trans id="pages.user-zone.main.Subtitle2" />
        </div>

        {/* Get Product */}

        {addProductOrder.map((product) => {
          switch (product) {
            case 'bcast':
              return userHasBcast ? undefined : (
                <div key="bcast" style={{ marginBottom: 16 }}>
                  <UserZonePlansRootAddProductBcast />
                </div>
              )

            case 'mon':
              return (
                <div key="mon" style={{ marginBottom: 16 }}>
                  {userHasMontage ? (
                    <UserZonePlansRootAddProductAltMontage />
                  ) : (
                    <UserZonePlansRootAddProductMontage />
                  )}
                </div>
              )

            case 'lcher':
              return (
                <div key="lcher" style={{ marginBottom: 16 }}>
                  {userHasLauncher ? (
                    <UserZonePlansRootAddProductAltLauncher />
                  ) : (
                    <UserZonePlansRootAddProductLauncher />
                  )}
                </div>
              )
          }

          return undefined
        })}
      </div>

      {/* Footer */}

      <Footer />

      {/* - */}
    </STUserZonePlansRoot>
  )
}
