import { AppInitState } from '../../../models/app/model'
import { dynReducer } from '../../../utils/store'
import { CardMT } from '../../actions-mutators/card/mutators'
import { DebuggerMC, DebuggerMT } from '../../actions-mutators/debugger/mutators'
import { IntlMT } from '../../actions-mutators/intl/mutators'
import { SubscriptionMT } from '../../actions-mutators/subscriptions/mutators'
import { ApiStoreBillingAT } from '../../actions/api-store/billing/actions'
import { ApiStoreCardAT } from '../../actions/api-store/card/actions'
import { ApiStoreCheckoutAT } from '../../actions/api-store/checkout/actions'
import { EpicApiStoreBillingUpdateMT } from '../../epics/api-store/billing/update/mutators'
import { EpicApiStoreCardAddMT } from '../../epics/api-store/card/add/mutators'
import { EpicApiStoreCardChangeMT } from '../../epics/api-store/card/change/mutators'
import { EpicApiStoreCardRemMT } from '../../epics/api-store/card/rem/mutators'
import { EpicApiStoreCurrentUserShowMT } from '../../epics/api-store/current-user/show/mutators'
import { ApiStoreSubscriptionsCancelPlanEpicMT } from '../../epics/api-store/subscriptions/cancel-plan/mutators'
import { ApiStoreSubscriptionsChangePaymentMethodEpicMT } from '../../epics/api-store/subscriptions/change-payment-method/mutators'
import { ApiStoreSubscriptionsChangePlanEpicMT } from '../../epics/api-store/subscriptions/change-plan/mutators'
import { ApiStoreSubscriptionsCreateEpicMT } from '../../epics/api-store/subscriptions/create/mutators'

const initState = AppInitState.debugger

type State = typeof initState

const reducer: any = {}

reducer[DebuggerMT.ENABLE] = (
  state: State,
  { payload }: ReturnType<typeof DebuggerMC.enable>,
): State => {
  return {
    ...state,
    enable: true,
    isQA: payload.kind === 'qa',
    sendError: '',
  }
}

// ####################################################################################################
// ~~~~~~ Debug disable
// ####################################################################################################

reducer[DebuggerMT.DISABLE] = (state: State): State => {
  return {
    ...state,
    enable: false,
    actions: [],
    sendError: '',
  }
}

// ~~~~~~

export const debuggerReducer = dynReducer(
  initState,
  reducer,
  (state) => state,

  // All actions

  (state, action) => {
    const sensitiveActions = [
      ApiStoreCheckoutAT.CHANGE_EMAIL_WITH_PASS,
      ApiStoreCheckoutAT.SEND_EMAIL_PASS,
      ApiStoreCardAT.ADD,
      ApiStoreCardAT.CHANGE,
      ApiStoreCardAT.REMOVE,
      ApiStoreCardAT.SHOW,
      EpicApiStoreCurrentUserShowMT.OK,
      CardMT.SET_TMP_CARD_ID,
      EpicApiStoreCardAddMT.OK,
      ApiStoreBillingAT.UPDATE,
      EpicApiStoreCardChangeMT.OK,
      EpicApiStoreCardRemMT.OK,
      EpicApiStoreBillingUpdateMT.OK,
      SubscriptionMT.SET_TMP_SUBSCRIPTION,
      ApiStoreSubscriptionsChangePaymentMethodEpicMT.OK,
      ApiStoreSubscriptionsChangePlanEpicMT.OK,
      ApiStoreSubscriptionsCancelPlanEpicMT.OK,
      ApiStoreSubscriptionsCreateEpicMT.OK,
      'buy-license-ch-password',
    ]

    if (!state.enable) return state

    // If pass sensitive data actions

    if (sensitiveActions.filter((act) => act === action.type).length > 0) {
      return {
        ...state,
        actions: [
          ...state.actions,
          {
            type: action.type,
            payload: 'FILTERED',
            meta: {
              pn: performance.now(),
            },
          },
        ],
      }
    }

    // If change lang, skip messages

    if (action.type === IntlMT.CHANGE_LANG) {
      return {
        ...state,
        actions: [
          ...state.actions,
          {
            type: action.type,
            payload: {
              lang: action.payload.lang,
            },
            meta: {
              pn: performance.now(),
            },
          },
        ],
      }
    }

    return {
      ...state,
      actions: [
        ...state.actions,
        {
          ...action,
          meta: {
            ...(action.meta || {}),
            pn: performance.now(),
          },
        },
      ],
    }
  },
)
