import { SetupIntent } from '@stripe/stripe-js'
import { useDispatch } from 'react-redux'
import { useNavigate, useParams } from 'react-router'
import { ApiStoreCardAC } from '../../../../../store/actions/api-store/card/actions'
import { Routes } from '../../../../../constants/routes'
import { UserZoneCommonPaymentMethodForm } from '../../../@-common/payment-method/form/component'
import { ClassName, STUserZoneBillingPaymentMethodEdit } from './style'
import { Trans } from '../../../../../components/common/intl/trans'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../../../models/app/model'
import { Button } from '../../../../../components/common/button/component'
import { PaymentButton } from '../../../../../components/common/gradient-button/component'
import { BackLink } from '../../../../../components/common/links/back-link/component'

// ~~~~~~ Component

export const UserZoneBillingPaymentMethodEdit = () => {
  // ~~~~~~ Hooks

  const cardId = useParams<{ cardId?: string }>().cardId

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const [submitIsRunning, setSubmitIsRunning] = useState(false)

  const [doSubmit, setDoSubmit] = useState(0)

  // ~~~~~~ State

  // - Local

  const [stripeIsReady, setStripeIsReady] = useState(false)

  // - Store

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  const metaCards = useSelector((state: StoreState) => state.cards)

  // ~~~~~~ Computed

  const card = metaCards.list.find((card) => card.payment_method_id === cardId)

  const isDefaultCard = (card || { is_default: false }).is_default

  const isRunning = submitIsRunning || metaCards.uiStatusChange === 'running'

  // ~~~~~~ Handlers

  function onClickGoBack() {
    navigate(Routes.UserZoneBilling)
  }

  function onStripeIsReady() {
    setStripeIsReady(true)
  }

  function onErrorLoading() {
    navigate(Routes.UserZoneBilling)
  }

  function onClickUpdate() {
    setDoSubmit(performance.now())
  }

  function onSubmitStart() {
    setSubmitIsRunning(true)
  }

  function onSubmitEnd() {
    setSubmitIsRunning(false)
  }

  function onSubmit(intent: SetupIntent, isDefault: boolean) {
    if (!cardId) return

    dispatch(ApiStoreCardAC.change(cardId, intent.id, isDefault))

    navigate(Routes.UserZoneBilling)
  }

  // ~~~~~~ Effects

  useEffect(() => {
    if (!currentUser.email || card) return

    navigate(Routes.UserZoneBilling)

    //
  }, [card, currentUser.email, navigate])

  // ~~~~~~ Render

  return (
    <STUserZoneBillingPaymentMethodEdit>
      <div className={`${ClassName}--content`}>
        {/* <- Back to Billing */}

        <BackLink
          className={`${ClassName}--content--go-back`}
          intlId="v2.pages.edit-payment.BackToBilling"
          onClick={onClickGoBack}
        />

        {/* Title */}

        <div className={`${ClassName}--content--title`}>
          <Trans id="v2.pages.edit-payment.EditPaymentTitle" />
        </div>

        {/* Subtitle */}

        <div className={`${ClassName}--content--subtitle`}>
          <Trans id="v2.pages.edit-payment.EditPaymentSubtitle" />
        </div>

        {/* Stripe form */}

        <UserZoneCommonPaymentMethodForm
          doSubmit={doSubmit}
          isDefaultCard={isDefaultCard}
          onErrorLoading={onErrorLoading}
          onSubmitStart={onSubmitStart}
          onSubmitEnd={onSubmitEnd}
          onSubmit={onSubmit}
          onStripeIsReady={onStripeIsReady}
        />

        {stripeIsReady ? (
          <>
            {/* Separator */}

            <hr />

            {/* Actions */}

            <div className={`${ClassName}--content--actions`}>
              {/* Update */}

              <PaymentButton
                className={`${ClassName}--content--actions--action first`}
                intlId="v2.pages.edit-payment.UpdatePaymentMethod"
                running={isRunning}
                active={!isRunning}
                disabled={isRunning}
                onClick={onClickUpdate}
              />

              {/* Cancel */}

              <Button
                className={`${ClassName}--content--actions--cancel`}
                colorType="default"
                intlId="common.Cancel"
                running={false}
                disabled={false}
                active={!isRunning}
                onClick={onClickGoBack}
              />
            </div>
          </>
        ) : undefined}
      </div>
    </STUserZoneBillingPaymentMethodEdit>
  )
}
