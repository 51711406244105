import Scrollbars from 'react-custom-scrollbars'
import { LayoutTopContent } from '../../../components/layouts/top-content/component'
import { LayoutTopContentContent } from '../../../components/layouts/top-content/parts/content/component'
import { LayoutTopContentTopLeft } from '../../../components/layouts/top-content/parts/top/left/component'
import { PageCommonOPTopLeft } from '../../@-common/obtaining-products/top/left/component'
import { useNavigate } from 'react-router'
import { Routes } from '../../../constants/routes'
import { STPagePurchaseSubSuccess } from './style'
import { PagePurchaseSubSuccessContent } from './content/component'

// ~~~~~~ Component

export const PagePurchaseSubSuccess = () => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  // ~~~~~~ Handlers

  function onClickGoBack() {
    navigate(Routes.Root)
  }

  // ~~~~~~ Render

  return (
    <STPagePurchaseSubSuccess>
      <Scrollbars>
        <LayoutTopContent>
          {/* Top */}

          {/* - Left */}

          <LayoutTopContentTopLeft>
            <PageCommonOPTopLeft onClickGoBack={onClickGoBack} />
          </LayoutTopContentTopLeft>

          {/* Content */}

          <LayoutTopContentContent>
            <PagePurchaseSubSuccessContent />
          </LayoutTopContentContent>

          {/* - */}
        </LayoutTopContent>
      </Scrollbars>
    </STPagePurchaseSubSuccess>
  )
}
