import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { LazySVG } from '../../../../../components/svgs/lazy-svg/component'
import { RouteDataMontagePurchaseConfirm, Routes } from '../../../../../constants/routes'
import { useTrans } from '../../../../../hooks/use-trans'
import { useUserZoneDecDataParam } from '../../../../../hooks/user-zone-dec-data-param/hook'
import { StoreState } from '../../../../../models/app/model'
import { ApiStoreCheckoutAC } from '../../../../../store/actions/api-store/checkout/actions'
import { ClassName, STUserZonePlansPurchaseConfirmMontage } from './style'
import { UserZoneCommonBannerBack } from '../../../@-common/banner-back/component'
import { Trans } from '../../../../../components/common/intl/trans'
import { DangerTrans } from '../../../../../components/common/intl/danger-trans'
import { Dollars } from '../../../../../components/common/dollars/component'
import { ProductButtonMontage } from '../../../../../components/common/product-button/montage/component'
import { Config } from '../../../../../config'
import { Button } from '../../../../../components/common/button/component'

// ~~~~~~ Constants

const SubscribeIcon = LazySVG('icons/lock')

// ~~~~~~ Component

export const UserZonePlansPurchaseConfirmMontage = () => {
  // ~~~~~~ Hooks

  const data = useUserZoneDecDataParam<RouteDataMontagePurchaseConfirm>()

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const intervalText = (useTrans('common.Year') as string).toLowerCase()

  // ~~~~~~ State

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  const buyLicense = useSelector((state: StoreState) => state.buyLicense)

  // ~~~~~~ Computed

  const isLoading = !currentUser.isSessionChecked || currentUser.uiStatus === 'running'

  const isRunning = buyLicense.uiStatus === 'running'

  // ~~~~~~ Handlers

  function onClickGoBack() {
    if (!data) return

    navigate(data.backPath)
  }

  function onClickSubscribe() {
    if (!data) return

    dispatch(
      ApiStoreCheckoutAC.createCheckoutSession('montage', {
        email: currentUser.email,
        code: '',

        price_of_license_of_product_id: data.productId,
        quantity: data.qtty,

        // Remove the :stripeSessionId (stripe will add this at the end of this route)
        success_path: Routes.UserZonePlansMontagePurchaseSuccess.split('/').slice(0, -1).join('/'),
        cancel_path: window.location.pathname,
      }),
    )
  }

  // ~~~~~~ Render

  if (!data || isLoading) return null

  return (
    <STUserZonePlansPurchaseConfirmMontage>
      {/* Banner back */}

      <UserZoneCommonBannerBack
        noImage
        kind="montage"
        backIntl="wildcard"
        backIntlValues={{ value: data.backText }}
        onClickGoBack={onClickGoBack}
      />

      {/* Content */}

      <div className={`${ClassName}--content`}>
        {/* Title */}

        <div className={`${ClassName}--content--title`}>
          <Trans id="v2.pages.upgrade-plan.payment-details.PaymentDetails" />
        </div>

        {/* Subtitle */}

        <div className={`${ClassName}--content--subtitle`}>
          <Trans id="v2.pages.order-solution.ConfirmPaymentMethod" values={{ value: 'Montage' }} />
        </div>

        {/* Price info */}

        <div className={`${ClassName}--content--price-info`}>
          <div className={`${ClassName}--content--price-info--text`}>
            <DangerTrans
              template="Due Today <c>(then every {value})</c>"
              id="v2.pages.upgrade-plan.payment-details.DueToday"
              values={{
                value: intervalText,
                c: (chunks: string) => (
                  <span className={`${ClassName}--content--price-info--text--interval`}>
                    {chunks}
                  </span>
                ),
              }}
            />
          </div>

          <div className={`${ClassName}--content--price-info--value`}>
            {data.qtty > 1 ? (
              <div className={`${ClassName}--content--price-info--value--qtty`}>
                <Dollars value={data.price} /> * {data.qtty}
              </div>
            ) : undefined}

            <div className={`${ClassName}--content--price-info--value--total`}>
              <Dollars value={data.price * data.qtty} />
            </div>
          </div>
        </div>

        {/* Space */}

        <hr className={`${ClassName}--content--separator`} />

        {/* Action */}

        <div className={`${ClassName}--content--actions`}>
          {/* Subscribe */}

          <ProductButtonMontage
            className={`${ClassName}--content--actions--action first`}
            intlId="v2.pages.upgrade-plan.payment-details.Subscribe"
            IconRight={undefined}
            IconLeft={SubscribeIcon}
            leftIconMarginRight={4}
            running={isRunning}
            disabled={isRunning}
            active={!isRunning}
            onClick={onClickSubscribe}
          />

          {/* Cancel */}

          <Button
            className={`${ClassName}--content--actions--action`}
            colorType="default"
            intlId="common.Cancel"
            active={true}
            disabled={false}
            running={false}
            onClick={onClickGoBack}
          />
        </div>

        {/* Info about rights */}

        <div className={`${ClassName}--content--info`}>
          <Trans id="v2.pages.upgrade-plan.payment-details.ConfirmDescription" />
        </div>

        {/* Links to terms and privacy */}

        <div className={`${ClassName}--content--info`}>
          <DangerTrans
            template="... <c>Terms</c> ... <d>Privacy</d>"
            id="v2.pages.upgrade-plan.payment-details.TermsAndConditions"
            values={{
              // Terms

              c: (chunks: string) => (
                <a target="_blank" rel="noreferrer" href={Config.Links.Terms}>
                  {chunks}
                </a>
              ),

              // Privacy

              d: (chunks: string) => (
                <a target="_blank" rel="noreferrer" href={Config.Links.Privacy}>
                  {chunks}
                </a>
              ),
            }}
          />
        </div>
      </div>
    </STUserZonePlansPurchaseConfirmMontage>
  )
}
