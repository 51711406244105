import { AppInitState } from '../../../../models/app/model'
import {
  addElementToList,
  dynReducer,
  remElementInList,
  updateElementInList,
} from '../../../../utils/store'
import {
  ApiStoreSubscriptionsAC,
  ApiStoreSubscriptionsAT,
} from '../../../actions/api-store/subscriptions/actions'
import {
  EpicApiStoreCardAddMT,
  EpicApiStoreCardAddMC,
} from '../../../epics/api-store/card/add/mutators'
import {
  EpicApiStoreCardChangeMC,
  EpicApiStoreCardChangeMT,
} from '../../../epics/api-store/card/change/mutators'
import {
  EpicApiStoreCardRemMT,
  EpicApiStoreCardRemMC,
} from '../../../epics/api-store/card/rem/mutators'
import {
  ApiStoreCheckoutSetDefaultCardEpicMT,
  ApiStoreCheckoutSetDefaultCardEpicMC,
} from '../../../epics/api-store/checkout/set-default-card/mutators'
import {
  EpicApiStoreCurrentUserShowMC,
  EpicApiStoreCurrentUserShowMT,
} from '../../../epics/api-store/current-user/show/mutators'
import {
  ApiStoreSubscriptionsCancelPlanEpicMC,
  ApiStoreSubscriptionsCancelPlanEpicMT,
} from '../../../epics/api-store/subscriptions/cancel-plan/mutators'
import {
  ApiStoreSubscriptionsChangePaymentMethodEpicMC,
  ApiStoreSubscriptionsChangePaymentMethodEpicMT,
} from '../../../epics/api-store/subscriptions/change-payment-method/mutators'
import {
  ApiStoreSubscriptionsChangePlanEpicMT,
  ApiStoreSubscriptionsChangePlanEpicMC,
} from '../../../epics/api-store/subscriptions/change-plan/mutators'
import {
  ApiStoreSubscriptionsCreateEpicMC,
  ApiStoreSubscriptionsCreateEpicMT,
} from '../../../epics/api-store/subscriptions/create/mutators'
import {
  ApiSubscriptionsReactivatePlanEpicMC,
  ApiStoreSubscriptionsReactivatePlanEpicMT,
} from '../../../epics/api-store/subscriptions/reactivate-plan/mutators'

const initState = AppInitState.subscriptions

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ Current user - Show OK
// ####################################################################################################

reducer[EpicApiStoreCurrentUserShowMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiStoreCurrentUserShowMC.ok>,
): State => {
  return payload.subscriptions.map((subscription) => ({
    ...subscription,
    uiStatus: 'init',
    errors: [],
  }))
}

// ####################################################################################################
// ~~~~~~ Buy Plan - OK
// ####################################################################################################

// Not required as we redirect to stripe

// ####################################################################################################
// ~~~~~~ Buy Trial/Free - OK
// ####################################################################################################

reducer[ApiStoreSubscriptionsCreateEpicMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreSubscriptionsCreateEpicMC.ok>,
): State => {
  if (!payload.isLogged) return state

  return addElementToList(state, payload.subscription, {
    sortBy: 'created_at',
    order: 'DESC',
  })
}

// ####################################################################################################
// ~~~~~~ Change payment method
// ####################################################################################################

reducer[ApiStoreSubscriptionsAT.CHANGE_PAYMENT_METHOD] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreSubscriptionsAC.changePaymentMethod>,
): State => {
  return updateElementInList(
    state,
    {
      uiStatus: 'running',
    },
    (subscription) => subscription.subscription_id === payload.subscriptionId,
  )
}

// ####################################################################################################
// ~~~~~~ Change payment method - OK
// ####################################################################################################

reducer[ApiStoreSubscriptionsChangePaymentMethodEpicMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreSubscriptionsChangePaymentMethodEpicMC.ok>,
): State => {
  return updateElementInList(
    state,
    {
      uiStatus: 'init',
      errors: [],
      payment_method_id: payload.card.payment_method_id,
      card_brand: payload.card.brand,
      card_last4: payload.card.last4,
    },
    (subscription) => subscription.subscription_id === payload.data.subscriptionId,
  )
}

// ####################################################################################################
// ~~~~~~ Change payment method - ERROR
// ####################################################################################################

reducer[ApiStoreSubscriptionsChangePaymentMethodEpicMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreSubscriptionsChangePaymentMethodEpicMC.error>,
): State => {
  return updateElementInList(
    state,
    {
      uiStatus: 'init',
      errors: payload.errors,
    },
    (subscription) =>
      subscription.subscription_id === payload.subscriptionId &&
      subscription.payment_method_id === payload.paymentMethodId,
  )
}

// ####################################################################################################
// ~~~~~~ Cancel Plan - START
// ####################################################################################################

reducer[ApiStoreSubscriptionsAT.CANCEL_PLAN] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreSubscriptionsAC.cancelPlan>,
): State => {
  return updateElementInList(
    state,
    {
      uiStatus: 'running',
      errors: [],
    },
    (subscription) => subscription.subscription_id === payload.subscriptionId,
  )
}

// ####################################################################################################
// ~~~~~~ Cancel Plan - OK
// ####################################################################################################

reducer[ApiStoreSubscriptionsCancelPlanEpicMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreSubscriptionsCancelPlanEpicMC.ok>,
): State => {
  if (payload.subscription_status === 'canceled') {
    return remElementInList(state, (sub) => sub.subscription_id === payload.subscription_id)
  }

  return updateElementInList(
    state,
    {
      ...payload,
      uiStatus: 'init',
    },
    (subscription) => subscription.subscription_id === payload.subscription_id,
  )
}

// ####################################################################################################
// ~~~~~~ Cancel Plan - ERROR
// ####################################################################################################

reducer[ApiStoreSubscriptionsCancelPlanEpicMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreSubscriptionsCancelPlanEpicMC.error>,
): State => {
  return updateElementInList(
    state,
    {
      uiStatus: 'init',
      errors: payload.errors,
    },
    (subscription) => subscription.subscription_id === payload.subscriptionId,
  )
}

// ####################################################################################################
// ~~~~~~ Reactivate plan - START
// ####################################################################################################

reducer[ApiStoreSubscriptionsAT.REACTIVATE_PLAN] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreSubscriptionsAC.reactivatePlan>,
): State => {
  return updateElementInList(
    state,
    {
      uiStatus: 'running',
      errors: [],
    },
    (subscription) => subscription.subscription_id === payload.subscriptionId,
  )
}

// ####################################################################################################
// ~~~~~~ Reactivate plan - OK
// ####################################################################################################

reducer[ApiStoreSubscriptionsReactivatePlanEpicMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof ApiSubscriptionsReactivatePlanEpicMC.ok>,
): State => {
  return updateElementInList(
    state,
    {
      ...payload,
      uiStatus: 'init',
    },
    (subscription) => subscription.subscription_id === payload.subscription_id,
  )
}

// ####################################################################################################
// ~~~~~~ Reactivate plan - ERROR
// ####################################################################################################

reducer[ApiStoreSubscriptionsReactivatePlanEpicMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof ApiSubscriptionsReactivatePlanEpicMC.error>,
): State => {
  return updateElementInList(
    state,
    {
      uiStatus: 'init',
      errors: payload.errors,
    },
    (subscription) => subscription.subscription_id === payload.subscriptionId,
  )
}

// ####################################################################################################
// ~~~~~~ Change Plan - START
// ####################################################################################################

reducer[ApiStoreSubscriptionsAT.CHANGE_PLAN] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreSubscriptionsAC.changePlan>,
): State => {
  return updateElementInList(
    state,
    {
      uiStatus: 'running',
      errors: [],
    },
    (subscription) => subscription.subscription_id === payload.subscriptionId,
  )
}

// ####################################################################################################
// ~~~~~~ Change Plan - OK
// ####################################################################################################

reducer[ApiStoreSubscriptionsChangePlanEpicMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreSubscriptionsChangePlanEpicMC.ok>,
): State => {
  return updateElementInList(
    state,
    {
      ...payload.data,
      uiStatus: 'init',
      errors: [],
    },
    (subscription) => subscription.subscription_id === payload.data.subscription_id,
  )
}

// ####################################################################################################
// ~~~~~~ Change Plan - ERROR
// ####################################################################################################

reducer[ApiStoreSubscriptionsChangePlanEpicMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreSubscriptionsChangePlanEpicMC.error>,
): State => {
  return updateElementInList(
    state,
    {
      uiStatus: 'init',
      errors: payload.errors,
    },
    (subscription) => subscription.subscription_id === payload.subscriptionId,
  )
}

// ####################################################################################################
// ~~~~~~ Add card - OK - Add default card to subscriptions without card
// ####################################################################################################

reducer[EpicApiStoreCardAddMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiStoreCardAddMC.ok>,
): State => {
  if (!payload.data.card.is_default) return state

  return updateElementInList(
    state,
    {
      payment_method_id: payload.data.card.payment_method_id,
      card_brand: payload.data.card.brand,
      card_last4: payload.data.card.last4,
    },
    // NOTE: (subscription) => !subscription.payment_method_id // Old setting only subs without card
    () => true, // All subs setted with default card
  )
}

// ####################################################################################################
// ~~~~~~ Change payment method (Default) - OK - Add default card to subscriptions without card
// ####################################################################################################

reducer[ApiStoreCheckoutSetDefaultCardEpicMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreCheckoutSetDefaultCardEpicMC.ok>,
): State => {
  return updateElementInList(
    state,
    {
      payment_method_id: payload.card.payment_method_id,
      card_brand: payload.card.brand,
      card_last4: payload.card.last4,
    },
    (subscription) => !subscription.payment_method_id,
  )
}

// ####################################################################################################
// ~~~~~~ Change card - OK - Modify subscription list with changed card
//                           and if is default add to subscriptiosn without card
// ####################################################################################################

reducer[EpicApiStoreCardChangeMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiStoreCardChangeMC.ok>,
): State => {
  const card = payload.data.card

  const patch: Partial<State[0]> = {
    payment_method_id: card.payment_method_id,
    card_brand: card.brand,
    card_last4: card.last4,
  }

  const listWithUpdatedCard = updateElementInList(
    state,
    patch,
    (subscription) => subscription.payment_method_id === payload.data.sourceId,
  )

  if (!card.is_default) return listWithUpdatedCard

  return updateElementInList(
    listWithUpdatedCard,
    patch,
    (subscription) => !subscription.payment_method_id,
  )
}

// ####################################################################################################
// ~~~~~~ Remove card - OK
// ####################################################################################################

reducer[EpicApiStoreCardRemMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiStoreCardRemMC.ok>,
): State => {
  return updateElementInList(
    state,
    {
      payment_method_id: undefined,
      card_brand: undefined,
      card_last4: undefined,
    },
    (subscription) => subscription.payment_method_id === payload.id,
  )
}

// ~~~~~~

export const subscriptionsReducer = dynReducer(initState, reducer)
