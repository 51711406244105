import { ofType, StateObservable } from 'redux-observable'
import { Observable, of, zip } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'

import { StoreState } from '../../../../../models/app/model'
import { apiStoreCardChange$ } from '../../../../../services/api-store/card/change'
import { ApiStoreCardAC, ApiStoreCardAT } from '../../../../actions/api-store/card/actions'
import { ApiUtils } from '../../../utils'
import { EpicApiStoreCardChangeMC } from './mutators'

type Action = ReturnType<typeof ApiStoreCardAC.change>

export const apiStoreCardChangeEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiStoreCardAT.CHANGE),

    mergeMap(({ payload }) =>
      zip(
        of(payload),
        apiStoreCardChange$(state$.value.currentUser.token, payload.cardId, payload.setupIntentId),
      ),
    ),

    map(([payload, res]) =>
      ApiUtils.isNotError(res)
        ? EpicApiStoreCardChangeMC.ok(res.response, payload.setDefault)
        : EpicApiStoreCardChangeMC.error(res.response),
    ),
  )
