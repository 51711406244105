import { HocIntl, HocTheme } from '@dn/hocs'
import { BillingInfoModel } from '../billing-info/model'
import { BuyLicenseModel } from '../buy-license/model'
import { CurrentUserModel } from '../current-user/model'
import { ProductModel } from '../products/model'
import { LauncherReleaseInfoModel } from '../release-info-launcher/model'
import { MontageReleaseInfoModel } from '../release-info-montage/model'
import { SubscriptionModel } from '../subscriptions/model'
import { CardModel } from '../cards/model'
import { RootModel } from '../root/model'
import { ModalsModel } from '../modals/model'
import { DebuggerModel } from '../debugger/model'
import { CheckoutModel } from '../checkout/model'

// ~~~~~~

export const AppInitState: DN.Models.App.Model = {
  // Root

  root: RootModel.initModel,

  // Intl

  intl: HocIntl.Model.initModel,

  // Modals

  modals: ModalsModel.initModel,

  // Theme

  theme: HocTheme.Model.initModel,

  // Release info

  montageReleaseInfo: MontageReleaseInfoModel.initModel,

  launcherReleaseInfo: LauncherReleaseInfoModel.initModel,

  // Current user

  currentUser: CurrentUserModel.initModel,

  // Debugger

  debugger: DebuggerModel.initModel,

  // Subscriptions

  subscriptions: [],

  tmpSubscription: SubscriptionModel.tmpInit,

  // Cards

  cards: CardModel.initModelList,

  // Invoices

  invoices: [],

  // Billing information

  billingInfoShow: BillingInfoModel.initModelShow,
  billingInfoEdit: BillingInfoModel.initModelEdit,

  // Buy license (trial also)

  buyLicense: BuyLicenseModel.initModel,

  // Checkout session

  checkoutSession: CheckoutModel.initModel,

  // Product: Launcher

  launcher: {
    uiStatus: 'init',
    list: [],
    errors: [],
  },

  // Product: Montage

  montage: ProductModel.initMontageModel,

  // Product: Broadcast Subscription (Web)

  broadcastSub: ProductModel.initBroadcastModel,
}

export type StoreState = typeof AppInitState

export type StoreStateProps = keyof StoreState
