const prefix = 'mt-store-api-checkout-show-session-epic'

// ~~~~~~ Mutators Types

export const ApiStoreCheckoutShowSessionEpicMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const ApiStoreCheckoutShowSessionEpicMC = {
  ok: (data: DN.Models.Checkout.Api.ShowSession.ResParsed) => ({
    type: ApiStoreCheckoutShowSessionEpicMT.OK,
    payload: data,
  }),

  error: (errors: DN.Models.Checkout.Api.ShowSession.ResError) => ({
    type: ApiStoreCheckoutShowSessionEpicMT.ERROR,
    error: true,
    payload: {
      errors,
    },
  }),
} as const
