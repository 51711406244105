import styled from 'styled-components'
import { Fonts } from '../../../../../../style/theme/fonts'
import { MediaQuery } from '../../../../../../style/constants/media-queries'
import { Layout } from '../../../../../../style/theme/layout'
import { Colors } from '../../../../../../style/theme/colors'
import { theme } from '../../../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'user-zone-product-detail-laucher'

// ~~~~~~ Component

export const STUserZonePlansProductDetailLauncher = styled.div`
  .${ClassName}--title {
    ${Fonts.Weight[700]}
    ${Fonts.Scale.OldD}

    margin-top: 16px;
    margin-bottom: 24px;
  }

  .${ClassName}--info {
    margin-top: 16px;
    margin-bottom: 24px;

    .${ClassName}--info--bold {
      ${Fonts.Weight[600]}
    }
  }

  .${ClassName}--details {
    margin-bottom: 24px;

    .${ClassName}--details--row {
      border-bottom: solid 1px ${theme(Colors.WOLF_05_05)};

      padding-top: 8px;
      padding-bottom: 8px;

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 8px;

      .hard {
        ${Fonts.Weight[600]}
      }

      .${ClassName}--details--row--title {
        ${Fonts.Weight[300]}
        ${Fonts.Scale.BodyBig}
      }

      .${ClassName}--details--row--highlight {
        ${Fonts.Weight[700]}
        ${Fonts.Scale.BodyBig}

        display: flex;

        margin-bottom: 8px;

        &.only {
          margin-bottom: 0;
        }

        &.smooth {
          ${Fonts.Weight[300]}
        }

        .${ClassName}--details--row--highlight--error {
          display: flex;
          align-items: center;

          margin-left: 4px;

          svg {
            width: 14px;
            height: 14px;

            path {
              fill: ${theme(Colors.LISA_70_70)};
            }
          }
        }

        .${ClassName}--details--row--highlight--interval {
          ${Fonts.Weight[300]}
          ${Fonts.Scale.BodyBig}

          margin-left: 4px;
        }
      }
    }
  }

  .${ClassName}--cancel {
    padding-bottom: 72px;

    .${ClassName}--cancel--line {
      margin-bottom: 8px;

      ${Fonts.Weight[400]}
      ${Fonts.Scale.Body}
    }
  }

  /* 1280 */

  @media (max-width: ${MediaQuery.MaxWidth2k}) {
    .${ClassName}--title {
      ${Fonts.Scale.Subtitle}
    }

    ${Layout.PaddingWidth.Desktop}
  }

  /* 960 */

  @media (max-width: ${MediaQuery.MD}) {
    ${Layout.PaddingWidth.Tablet}
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    ${Layout.PaddingWidth.Mobile}
  }
`
