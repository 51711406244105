import { useEffect } from 'react'
import { Route, Routes as Switch, useLocation } from 'react-router'
import TagManager, { DataLayerArgs } from 'react-gtm-module'

import { Routes } from '../../../constants/routes'
import { Config } from '../../../config'
import { UtilsLog } from '../../../utils/logs'

// ~~~~~~ Component

export const GTM = () => {
  // ~~~~~~ Render

  return (
    <Switch>
      {/* Root */}

      <Route path={Routes.Root} element={<InfoSender title="DisplayNote License Store" />} />

      {/* Products */}

      <Route path={Routes.Montage} element={<InfoSender title="Montage" />} />

      <Route path={Routes.Launcher} element={<InfoSender title="Launcher" />} />

      <Route path={Routes.Bcast} element={<InfoSender title="Broadcast" />} />

      {/* Trials or free */}

      <Route path={Routes.MontageStartTrial} element={<InfoSender title="Montage Start Trial" />} />

      <Route
        path={Routes.LauncherStartTrial}
        element={<InfoSender title="Launcher Start Trial" />}
      />

      <Route
        path={Routes.BcastSub2Free}
        element={<InfoSender title="Subscribe to Broadcast FREE" />}
      />

      {/* Purchase */}

      <Route path={Routes.MontagePurchase} element={<InfoSender title="Purchase Montage" />} />

      <Route
        path={Routes.MontagePurchaseSuccess}
        element={<InfoSender title="Purchase Montage SUCCESS" />}
      />

      <Route path={Routes.LauncherPurchase} element={<InfoSender title="Purchase Launcher" />} />

      <Route
        path={Routes.LauncherPurchaseSuccess}
        element={<InfoSender title="Purchase Launcher SUCCESS" />}
      />

      <Route
        path={Routes.BcastSub2Premium}
        element={<InfoSender title="Subscribe to Broadcast Premium" />}
      />

      <Route
        path={Routes.BcastSub2PremiumSuccess}
        element={<InfoSender title="Subscribe to Broadcast Premium SUCCESS" />}
      />

      {/* Private */}

      {/* - Plans */}

      <Route path={Routes.UserZone} element={<InfoSender title="User Zone Plans" />} />

      <Route path={Routes.UserZonePlans} element={<InfoSender title="User Zone Plans" />} />

      {/* - Plans: Payment */}

      <Route
        path={Routes.UserZonePlansAddPaymentMethod}
        element={<InfoSender title="User Zone Subscription Add Payment Method" />}
      />

      <Route
        path={Routes.UserZonePlansChangePaymentMethod}
        element={<InfoSender title="User Zone Subscription Change Payment Method" />}
      />

      {/* Plans: Montage */}

      <Route
        path={Routes.UserZonePlansMontage}
        element={<InfoSender title="User Zone Plans Montage" />}
      />

      <Route
        path={Routes.UserZonePlansMontageDetail}
        element={<InfoSender title="User Zone Plans Montage Plan Detail" />}
      />

      <Route
        path={Routes.UserZonePlansMontagePurchase}
        element={<InfoSender title="User Zone Plans Purchase Montage" />}
      />

      <Route
        path={Routes.UserZonePlansMontagePurchaseConfirm}
        element={<InfoSender title="User Zone Plans Purchase Montage Confirm" />}
      />

      <Route
        path={Routes.UserZonePlansMontagePurchaseSuccess}
        element={<InfoSender title="User Zone Plans Purchase Montage SUCCESS" />}
      />

      <Route
        path={Routes.UserZonePlansMontageSelectPlatformToGetTrial}
        element={<InfoSender title="User Zone Plans Select Platform To Get Montage Trial" />}
      />

      {/* Plans: Launcher */}

      <Route
        path={Routes.UserZonePlansLauncher}
        element={<InfoSender title="User Zone Plans Launcher" />}
      />

      <Route
        path={Routes.UserZonePlansLauncherDetail}
        element={<InfoSender title="User Zone Plans Launcher Plan Detail" />}
      />

      <Route
        path={Routes.UserZonePlansLauncherPurchase}
        element={<InfoSender title="User Zone Plans Purchase Launcher" />}
      />

      <Route
        path={Routes.UserZonePlansLauncherPurchaseConfirm}
        element={<InfoSender title="User Zone Plans Purchase Launcher Confirm" />}
      />

      <Route
        path={Routes.UserZonePlansLauncherPurchaseSuccess}
        element={<InfoSender title="User Zone Plans Purchase Launcher SUCCESS" />}
      />

      {/* Plans: Bcast */}

      <Route
        path={Routes.UserZonePlansBcast}
        element={<InfoSender title="User Zone Plans Broadcast" />}
      />

      <Route
        path={Routes.UserZonePlansBcastDetail}
        element={<InfoSender title="User Zone Plans Broadcast Plan Detail" />}
      />

      <Route
        path={Routes.UserZonePlansBcastSub2Premium}
        element={<InfoSender title="User Zone Plans Broadcast Subscribe to Premium" />}
      />

      <Route
        path={Routes.UserZonePlansBcastSub2PremiumSuccess}
        element={<InfoSender title="User Zone Plans Broadcast Subscribe to Premium SUCCESS" />}
      />

      <Route
        path={Routes.UserZonePlansBcastUpg2Premium}
        element={<InfoSender title="User Zone Plans Broadcast Upgrade to Premium" />}
      />

      <Route
        path={Routes.UserZonePlansBcastPurchaseConfirm}
        element={<InfoSender title="User Zone Plans Purchase Broadcast Confirm" />}
      />

      <Route
        path={Routes.UserZonePlansBcastDetailChangePlan}
        element={<InfoSender title="User Zone Plans Broadcast Change Plan" />}
      />

      {/* Billing */}

      <Route path={Routes.UserZoneBilling} element={<InfoSender title="User Zone Billing" />} />

      <Route
        path={Routes.UserZoneBillinEditBillingInfo}
        element={<InfoSender title="User Zone Billing Edit Info" />}
      />

      <Route
        path={Routes.UserZoneBillinAddPaymentMethod}
        element={<InfoSender title="User Zone Billing Add Payment Method" />}
      />

      <Route
        path={Routes.UserZoneBillinEditPaymentMethod}
        element={<InfoSender title="User Zone Billing Edit Payment Method" />}
      />

      {/* - */}
    </Switch>
  )
}

const InfoSender: React.FC<{ title: string }> = ({ title }) => {
  // ~~~~~~ Hooks

  const location = useLocation()

  // ~~~~~~ Effects

  // - Send info to GTM

  useEffect(() => {
    const info: DataLayerArgs = {
      dataLayer: {
        event: 'displaynote-store-event',
        page_path: location.pathname,
        page_title: title,
      },
    }

    Config.Features.DebugGTM && UtilsLog.devLog('GTM ~>', info)

    TagManager.dataLayer(info)

    // - ...
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [title])

  // ~~~~~~ Render

  return null
}
