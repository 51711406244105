import { BackLink } from '../../../../components/common/links/back-link/component'
import { LazySVG } from '../../../../components/svgs/lazy-svg/component'
import { ClassName, STUserZoneCommonBannerBack } from './style'

// ~~~~~~ Constants

const GradientLogoBcast = LazySVG('logos/broadcast-gradient')
const GradientLogoLauncher = LazySVG('logos/launcher-gradient')
const GradientLogoMontage = LazySVG('logos/montage-gradient')

type Props = {
  noImage?: true
  backIntl?: IntlMsgId // Default: Plans
  backIntlValues?: { [key: string]: string | number }

  kind: 'bcast' | 'launcher' | 'montage'
  text?: String

  onClickGoBack: () => void
}

// ~~~~~~ Component

export const UserZoneCommonBannerBack: React.FC<Props> = ({
  noImage,
  backIntl = 'pages.common.Plans',
  backIntlValues,
  kind,
  text,
  onClickGoBack,
}) => {
  // ~~~~~~ Render

  return (
    <STUserZoneCommonBannerBack $noImage={noImage} $kind={kind}>
      {/* Go back */}

      <BackLink
        className={`${ClassName}--go-back`}
        intlId={backIntl}
        intlValues={backIntlValues}
        onClick={onClickGoBack}
      />

      {/* Banner (if not mobile) */}

      <div className={`${ClassName}--a`}>
        {/* Logo */}

        <div className={`${ClassName}--a--logo`}>
          {kind === 'bcast' ? (
            <GradientLogoBcast size={32} />
          ) : kind === 'launcher' ? (
            <GradientLogoLauncher size={32} />
          ) : (
            <GradientLogoMontage size={32} />
          )}
        </div>

        {/* Text */}

        <div className={`${ClassName}--a--text`}>{text}</div>
      </div>
    </STUserZoneCommonBannerBack>
  )
}
