import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { ModalsIds } from '../../../../constants/modals'
import { StoreState } from '../../../../models/app/model'
import { ApiStoreCardAC } from '../../../../store/actions/api-store/card/actions'
import { genAnimatedDialog } from '../../../common/compound-dialog/component'
import { DangerTrans } from '../../../common/intl/danger-trans'
import { Trans } from '../../../common/intl/trans'
import { ActionButtons, DialogConfirmation } from '../component'

// ~~~~~~ Constants

const Dialog = genAnimatedDialog(ModalsIds.ConfirmRemCard)

// ~~~~~~ Component

export const DialogConfirmRemCard: React.FC<{}> = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const [doClose, setDoClose] = useState(0)

  const [closeOnEnd, setCloseOnEnd] = useState(false)

  const cardId = useSelector((state: StoreState) => state.cards.tmpCardId)

  const cards = useSelector((state: StoreState) => state.cards.list)

  // ~~~~~~ Computed

  const card = cards.find((card) => card.payment_method_id === cardId)

  const isRunning = card ? card.uiStatus === 'running' : false

  const modalTitle: IntlMsgId = card?.is_default
    ? 'v2.pages.billing.delete-payment.DefaultPaymentTitle'
    : 'v2.pages.billing.delete-payment.title'

  const actionButtons: ActionButtons = {
    okActionIntl: 'v2.pages.billing.Delete',
    cancelActionIntl: 'common.Cancel',
  }

  // ~~~~~~ Handlers

  function onClickClose() {
    setDoClose(performance.now())
  }

  function onClickConfirm() {
    dispatch(ApiStoreCardAC.remove(cardId))
    setCloseOnEnd(true)
  }

  // ~~~~~~ Effects

  useEffect(() => {
    if (!closeOnEnd || isRunning) return

    setCloseOnEnd(false)
    setDoClose(performance.now())
  }, [isRunning, closeOnEnd, dispatch])

  // ~~~~~~ Render

  return (
    <DialogConfirmation
      data-test="dialog-confirm-rem-card"
      isNoJustifyContent
      hideCloseBtn
      Dialog={Dialog}
      title={modalTitle}
      contentTextQuestionComponent={
        card ? (
          <div>
            <DangerTrans
              template="Are you sure you want to delete <c>Card ending {value}</c>?"
              id="v2.pages.billing.delete-payment.ContentTextQuestion"
              values={{
                value: card.last4,
                c: (chunks: string) => <span className="highlighted-text">{chunks}</span>,
              }}
            />
          </div>
        ) : undefined
      }
      contentText="dialogs.confirm.rem-card.Content"
      contentTextComponent={
        card ? (
          <div>
            {card.is_default ? (
              <div className="content-text-description">
                <Trans id="v2.pages.billing.delete-payment.content-text.ToAvoidIssueDescription" />
              </div>
            ) : undefined}

            <div className="content-text-description">
              <Trans
                id={
                  card.is_default
                    ? 'v2.pages.billing.delete-payment.content-text.CancelSubscriptionAuto'
                    : 'v2.pages.billing.delete-payment.content-text.CancelSubscription'
                }
              />
            </div>
          </div>
        ) : undefined
      }
      okButtonColor="quinary"
      actionButtons={actionButtons}
      isRunning={isRunning}
      doClose={doClose}
      onEndDissapear={() => undefined}
      onClickClose={onClickClose}
      onClickConfirm={onClickConfirm}
    />
  )
}
