import { useEffect } from 'react'
import { useDispatch } from 'react-redux'
import { BuyLicenseFieldMR } from '../../../../models/buy-license/model'
import { ClassName, STPagePurchaseSubSuccessContent } from './style'
import { PagePurchaseCommonTabSteps } from '../../@-common/tab-steps/component'
import { Loader } from '../../../../components/common/loader/component'
import { useNavigate, useParams } from 'react-router'
import { ApiStoreCheckoutAC } from '../../../../store/actions/api-store/checkout/actions'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../../models/app/model'
import { Routes } from '../../../../constants/routes'

// NOTE: sub -> kind: subscription

// ~~~~~~ Component

export const PagePurchaseSubSuccessContent = () => {
  // ~~~~~~ Hooks

  const stripeSessionId = useParams<{ stripeSessionId: string }>().stripeSessionId

  const dispatch = useDispatch()

  const navigate = useNavigate()

  // ~~~~~~ State

  const sessionInfo = useSelector((state: StoreState) => state.checkoutSession)

  // ~~~~~~ Effects

  // - Set step to [3] Payment for visuals
  //   Get info about the purchased product using the stripe session

  useEffect(() => {
    dispatch(BuyLicenseFieldMR.formStep.MC.change(3))

    if (!stripeSessionId) return

    dispatch(ApiStoreCheckoutAC.showSession({ session_id: stripeSessionId }))

    //
  }, [dispatch, stripeSessionId])

  // - If get info return with errors
  //   redirect to root

  useEffect(() => {
    if (!sessionInfo.errors.length) return

    navigate(Routes.Root)

    //
  }, [navigate, sessionInfo.errors.length])

  // - If get info return success
  //   redirect to purchase step-4

  useEffect(() => {
    if (!sessionInfo.product_id) return

    dispatch(BuyLicenseFieldMR.subId.MC.change('do-not-req-we-are-in-step-4'))

    dispatch(BuyLicenseFieldMR.formStep.MC.change(4))

    // Bcast is the only product with subscription
    // If you add other product with sub, check the sessionInfo.product_id
    // to decice were to navigate

    navigate(Routes.genBcastSub2Premium(sessionInfo.interval === 'month' ? 'monthly' : 'yearly'))

    //
  }, [dispatch, navigate, sessionInfo.interval, sessionInfo.price, sessionInfo.product_id])

  // ~~~~~~ Render

  return (
    <STPagePurchaseSubSuccessContent>
      {/* Steps */}

      <div className={`${ClassName}--steps`}>
        <PagePurchaseCommonTabSteps kind="subscription" />
      </div>

      {/* Loader */}

      <div className={`${ClassName}--loader`}>
        <Loader size={100} />
      </div>

      {/* - */}
    </STPagePurchaseSubSuccessContent>
  )
}
