import { createGlobalStyle } from 'styled-components'
import { normalize } from 'styled-normalize'

import { Colors } from './theme/colors'
import { Fonts } from './theme/fonts'
import { theme } from './utils/theme'
import { HexAlpha } from './theme/colors/alpha'

export const GlobalStyle = createGlobalStyle`
  ${normalize}

  body {

    width: 100vw;
    height: 100vh;

    display: flex;
    flex-direction: column;

    ${Fonts.Family}

    color: ${theme(Colors.WOLF_90_90)};

    overflow: hidden;

    user-select: none;

    text-align: left;

    /* Fix Safari ios bug: with a modal present you can scroll
    overflow: hidden;*/

    user-select: none;

    #side-menu-root {
      z-index: 500;
    }

    /* Modal Portal */

    #modal-root {
      position: relative;
      z-index: 1000;

      .modal {
        background-color: ${theme(Colors.WOLF_90_90)}${HexAlpha[90]};
        position: fixed;
        height: 100%;
        width: 100%;
        top: 0;
        left: 0;
      }
    }
  }

  /* Reset */

  :focus { outline: 0; }

  h1, h2, h3, h4, h5, h6, p, span, form {
    margin: 0;
    padding: 0;
  }

  textarea {
    margin: 0;
    border-radius: 0;
  }

  input {
    padding: 0;
    border-radius: 0;
    border-top-style: hidden;
    border-right-style: hidden;
    border-left-style: hidden;
    border-bottom-style: hidden;
  }

  ul,ol {
    padding: 0;
    padding-left: 8px;
    margin: 0;
    list-style-position: inside;
  }

  svg {
    width: 100%;
  }

  :focus { outline: 0; }

  /* Chrome, Safari, Edge, Opera */

  input::-webkit-outer-spin-button,
  input::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }

  /* Firefox */

  input[type=number] {
    -moz-appearance: textfield;
    appearance: textfield;
  }

  /* Edge password eye */

  ::-ms-reveal {
    display: none;
  }

  /* Toast */

  .toast {
    [role='status'] {
      margin: 0;
    }
  }

  /* Tooltip */

  .tooltip {
    border-radius: 10px !important;
    opacity: 1 !important;
  }
`
