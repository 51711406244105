const prefix = 'mt-store-api-subscriptions-reactivate-plan-epic'

// ~~~~~~ Mutators Types

export const ApiStoreSubscriptionsReactivatePlanEpicMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const ApiSubscriptionsReactivatePlanEpicMC = {
  ok: (data: DN.Models.Subscription.Api.ReactivatePlan.ResParsed) => ({
    type: ApiStoreSubscriptionsReactivatePlanEpicMT.OK,
    payload: data,
  }),

  error: (data: DN.Models.Subscription.Api.ReactivatePlan.ResError) => ({
    type: ApiStoreSubscriptionsReactivatePlanEpicMT.ERROR,
    error: true,
    payload: data,
  }),
} as const
