import { useNavigate } from 'react-router'
import { Routes } from '../../../../../constants/routes'
import { UserZoneCommonPaymentMethodNew } from '../../../@-common/payment-method/new/component'

// ~~~~~~ Component

export const UserZoneBillingPaymentMethodAdd = () => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  // ~~~~~~ Handlers

  function onAddNewCardSuccess() {
    navigate(Routes.UserZoneBilling)
  }

  // ~~~~~~ Routes

  return (
    <UserZoneCommonPaymentMethodNew
      backIntl="v2.pages.edit-payment.BackToBilling"
      backPath={Routes.UserZoneBilling}
      onSuccess={onAddNewCardSuccess}
    />
  )
}
