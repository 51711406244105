import styled from 'styled-components'

// ~~~~~~ Types

export type STProps = {
  $productLogoWidth?: number
  $fullWidthLogo?: true
  $marginBottom?: number
}

// ~~~~~~ Component

export const STCommonProductLogo = styled.div<STProps>`
  margin-bottom: ${({ $marginBottom }) => $marginBottom || 0}px;

  .product-logo {
    display: flex;
    align-items: flex-start;

    width: ${({ $productLogoWidth, $fullWidthLogo }) =>
      $fullWidthLogo ? '100%' : $productLogoWidth ? `${$productLogoWidth}px` : '197px'};

    svg {
      height: 100%;
    }
  }
`
