export const BreakPointValue = {
  XS: 320,
  S: 512,
  M: 768,
  L: 992,
  XL: 1200,
} as const

export enum BreakPoint {
  XS = 'xs',
  S = 's',
  M = 'm',
  L = 'l',
  XL = 'xl',
}

export const BreakPointMap = {
  [BreakPoint.XS]: BreakPointValue.XS,
  [BreakPoint.S]: BreakPointValue.S,
  [BreakPoint.M]: BreakPointValue.M,
  [BreakPoint.L]: BreakPointValue.L,
  [BreakPoint.XL]: BreakPointValue.XL,
} as const
