import { ApiMontageReleaseInfoAT } from '../../actions/api-montage/release-info/action'
import { dynReducer } from '../../../utils/store'
import {
  EpicApiMontageReleaseInfoMC,
  EpicApiMontageReleaseInfoMT,
} from '../../epics/api-montage/release-info/get/mutators'
import { AppInitState } from '../../../models/app/model'

const initState = AppInitState.montageReleaseInfo

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ Action to start calling release api
// ####################################################################################################

reducer[ApiMontageReleaseInfoAT.GET] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'running',
  }
}

// ####################################################################################################
// ~~~~~~ Api montage release info ok
// ####################################################################################################

reducer[EpicApiMontageReleaseInfoMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiMontageReleaseInfoMC.ok>,
): State => {
  return {
    ...state,
    uiStatus: 'completed',
    ...payload,
  }
}

// ####################################################################################################
// ~~~~~~ Show - ERROR
// ####################################################################################################

reducer[EpicApiMontageReleaseInfoMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiMontageReleaseInfoMC.error>,
): State => {
  return {
    ...state,
    uiStatus: 'init',
    errors: payload.errors,
  }
}

// ~~~~~~

export const montageReleaseInfoReducer = dynReducer(initState, reducer)
