import { UtilsHeaders } from '@dn/utils'
import { RoutesOld } from './constants/routes-old'

const env = process.env as ENV

export const NodeEnvironment = {
  DEV: 'development',
  PROD: 'production',
  TEST: 'test',
} as const

const ProjectName = env.REACT_APP_PROJECT_NAME || 'dn-license-store-dev'

const Environment = ProjectName === 'dn-license-store' ? 'production' : 'devel'

const Origin = window.location.origin.replace(/\/$/, '')

const Features = (env.REACT_APP_FEATURES || '').split(',').map((feature) => feature.trim())

const Version = env.REACT_APP_VERSION || '4.0.x'

const Revision = env.REACT_APP_REVISION || 'no-revision'

const Tag = env.REACT_APP_TAG || 'no-tag'

let AllBlocked = false

try {
  localStorage.getItem('test')
} catch (err) {
  AllBlocked = true
}

// ~~~~~~

export const Config = {
  // General

  AllBlocked,
  Origin,
  LogoutUrl: Origin,
  Environment: env.NODE_ENV || NodeEnvironment.PROD,
  ProjectName: env.REACT_APP_PROJECT_NAME || 'DN',
  BaseUrl: env.REACT_APP_BASE_URI || 'http://localhost:3000',

  // App

  App: {
    Version,
    Revision,
    Tag,
    Environment,
  },

  Emails: {
    Orders: env.REACT_APP_EMAIL_ORDERS || '',
  },

  Others: {
    Montage: {
      Versions: {
        Windows: 'Windows 10+',
        Android: 'Android 8+',
      },
    },
    Launcher: {
      Versions: {
        Windows: 'Windows 10+',
      },
    },
  },

  // Enabled features

  Features: {
    Debug: Features.includes('DEBUG'),
    DebugGTM: Features.includes('DEBUG_GTM'),
  },

  // Api

  Api: {
    Store: {
      URI: env.REACT_APP_API_URI || 'react-app-api-uri-not-defined',
      Headers: {
        ...UtilsHeaders.getSimpleHeaders(env.REACT_APP_API_HEADER),
        'Content-Type': 'application/json',
      },
    },

    Montage: {
      URI: env.REACT_APP_MONTAGE_API_LATEST_VERSION || 'no-montage-uri',
      Headers: UtilsHeaders.getMultiHeaders(env.REACT_APP_MONTAGE_API_LATEST_VERSION_HEADERS),
    },

    Launcher: {
      URI: env.REACT_APP_LAUNCHER_API_LATEST_VERSION || 'no-launcher-uri',
      Headers: {},
    },
  },

  // Logs

  // ...

  // Intl

  Intl: {
    DefaultLocale: (env.REACT_APP_DEFAULT_LOCALE as Lang) || ('en' as Lang),
    SupportedLocales: (env.REACT_APP_SUPPORTED_LOCALES?.split(',') as Lang[]) || (['en'] as Lang[]),
  },

  // Common Links

  Links: {
    Website: env.REACT_APP_LINKS_WEBSITE,
    Contact: env.REACT_APP_LINKS_CONTACT,
    LetUsKnow: env.REACT_APP_LINKS_LET_US_KNOW,
    Privacy: env.REACT_APP_LINKS_PRIVACY,
    Terms: env.REACT_APP_LINKS_TERMS,
    Cookies: env.REACT_APP_LINKS_COOKIES,

    // Launcher

    LauncherDownload: env.REACT_APP_LINKS_LAUNCHER_DOWNLOAD,
    LauncherRequestDemo: env.REACT_APP_LINKS_LAUNCHER_REQUEST_DEMO,
    LauncherRequestTrial: env.REACT_APP_LINKS_LAUNCHER_REQUEST_TRIAL,
    LauncherGuide: env.REACT_APP_LINKS_LAUNCHER_GUIDE,
    LauncherSupport: env.REACT_APP_LINKS_LAUNCHER_SUPPORT,
    LauncherInAction: env.REACT_APP_LINKS_LAUNCHER_IN_ACTION,

    // Broadcast

    BroadcastInAction: env.REACT_APP_LINKS_BROADCAST_IN_ACTION,
    BroadcastSupport: env.REACT_APP_LINKS_BROADCAST_SUPPORT,

    // Montage

    MontageDownloadWindows: env.REACT_APP_LINKS_MONTAGE_DOWNLOAD_WINDOWS,
    MontageDownloadAndroid: env.REACT_APP_LINKS_MONTAGE_DOWNLOAD_ANDROID,

    MontageSupport: env.REACT_APP_LINKS_MONTAGE_SUPPORT,
    MontageConnectDevice: env.REACT_APP_LINKS_MONTAGE_WEB_SITE,
    MontageInAction: env.REACT_APP_LINKS_MONTAGE_IN_ACTION,
  },

  // Others

  BroadcastWeb: {
    Url: env.REACT_APP_BROADCAST_WEB_URL || 'http://localhost:3000',
  },

  // - Trials

  Trials: {
    Launcher: {
      id: env.REACT_APP_LAUNCHER_TRIAL_ID || '',
      days: env.REACT_APP_LAUNCHER_TRIAL_DAYS || '0',
    },

    Montage: {
      id: env.REACT_APP_MONTAGE_TRIAL_ID || '',
      days: env.REACT_APP_MONTAGE_TRIAL_DAYS || '0',
    },

    BroadcastWeb: {
      id: env.REACT_APP_BROADCAST_WEB_TRIAL_ID || '',
      days: env.REACT_APP_BROADCAST_WEB_TRIAL_DAYS || '0',
    },
  },

  // Third Parties

  ThirdParty: {
    Google: {
      GTM: {
        Id: env.REACT_APP_GOOGLE_TAG_MANAGER_ID || '',
        Env: env.REACT_APP_GOOGLE_TAG_MANAGER_ENV || '',
        Auth: env.REACT_APP_GOOGLE_TAG_MANAGER_AUTH || '',
      },
    },

    LeadDyno: {
      Key: env.REACT_APP_LEAD_DYNO_KEY || '',
    },

    Stripe: {
      PublishableKey: env.REACT_APP_STRIPE_PUBLISHABLE_KEY || '',
    },

    Auth0: {
      Domain: env.REACT_APP_AUTH0_DOMAIN || '',
      ClientId: env.REACT_APP_AUTH0_CLIENT_ID || '',
      Audience: env.REACT_APP_AUTH0_AUDIENCE || '',
      CallbackUrl: `${Origin}${RoutesOld.UserZone}`,
      RefreshTokens: true,
      CacheLocation: 'localstorage', // memory | localstorage
    },
  },
} as const

console.info(String.fromCodePoint(0x002728), Revision)
