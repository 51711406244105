import { ofType, StateObservable } from 'redux-observable'
import { Observable, of } from 'rxjs'
import { mergeMap } from 'rxjs/operators'

import { StoreState } from '../../../../../models/app/model'
import { apiStoreCheckoutCreateSession$ } from '../../../../../services/api-store/checkout/create-session'
import {
  ApiStoreCheckoutAC,
  ApiStoreCheckoutAT,
} from '../../../../actions/api-store/checkout/actions'

import { loadStripe } from '@stripe/stripe-js'
import { Config } from '../../../../../config'
import { ApiUtils } from '../../../utils'
import { ApiStoreCheckoutCreateSessionEpicMC } from './mutators'
import { UtilsLog } from '../../../../../utils/logs'

type Action = ReturnType<typeof ApiStoreCheckoutAC.createCheckoutSession>

export const apiStoreChekcoutCreateSessionEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiStoreCheckoutAT.CREATE_SESSION),

    mergeMap(({ payload }) =>
      apiStoreCheckoutCreateSession$(
        state$.value.currentUser.token,
        payload.productId,
        payload.data,
      ),
    ),

    mergeMap((res) => {
      // Error

      if (!ApiUtils.isNotError(res)) {
        return of(ApiStoreCheckoutCreateSessionEpicMC.error(res.response))
      }

      // Try to load stripe and redirect to checkout portals

      const obs$ = new Observable((observer) => {
        loadStripe(Config.ThirdParty.Stripe.PublishableKey)
          .then((stripe) => {
            if (!stripe) {
              UtilsLog.devLog('RedirectToCheckout', 'ERROR', 'Stripe not present')

              observer.next(
                ApiStoreCheckoutCreateSessionEpicMC.error([
                  { id: 'wildcard', values: { value: 'Stripe not present after loading' } },
                ]),
              )

              observer.complete()
              return
            }

            stripe
              .redirectToCheckout({
                sessionId: res.response.session_id,
              })
              .then((result) => {
                UtilsLog.devLog('RedirectToCheckout', 'SUCCESS', result)

                observer.next(ApiStoreCheckoutCreateSessionEpicMC.ok())
                observer.complete()
              })
              .catch((err: any) => {
                UtilsLog.devLog('RedirectToCheckout', 'ERROR', err)

                observer.next(
                  ApiStoreCheckoutCreateSessionEpicMC.error([
                    { id: 'wildcard', values: { value: 'RedirectToCheckout error' } },
                  ]),
                )

                observer.complete()
              })
          })
          .catch((err) => {
            UtilsLog.devLog('RedirectToCheckout', 'ERROR', err)

            observer.next(
              ApiStoreCheckoutCreateSessionEpicMC.error([
                { id: 'wildcard', values: { value: 'Cannot load stripe' } },
              ]),
            )
          })
      })

      return obs$
    }),
  )
