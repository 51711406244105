const prefix = 'mt-store-card-add-epic'

// ~~~~~~ Mutators Types

export const EpicApiStoreCardAddMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const EpicApiStoreCardAddMC = {
  ok: (data: DN.Models.Card.Api.Add.ResParsed, setDefault: boolean) => ({
    type: EpicApiStoreCardAddMT.OK,
    payload: {
      data,
      setDefault,
    },
  }),

  error: (data: DN.Models.Card.Api.Add.ResError) => ({
    type: EpicApiStoreCardAddMT.ERROR,
    error: true,
    payload: {
      errors: data,
    },
  }),
} as const
