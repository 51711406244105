import { ProductButtonMontage } from '../../../../../components/common/product-button/montage/component'
import { LazySVG } from '../../../../../components/svgs/lazy-svg/component'
import { PageCommonOPStep1CreateAccount } from '../../../../@-common/obtaining-products/step-1-create-account/component'
import { PageTrialsOrFreeMontageTabSteps } from '../../tab-steps/component'

// ~~~~~~ Constants

const Logo = LazySVG('logos/montage-gradient')

// ~~~~~~ Component

export const PageTrialsOrFreeMontageContentStep1CreateAccount = () => {
  // ~~~~~~ Render

  return (
    <PageCommonOPStep1CreateAccount
      Logo={Logo}
      ProductButton={ProductButtonMontage}
      tabSteps={<PageTrialsOrFreeMontageTabSteps kind="trial" />}
    />
  )
}
