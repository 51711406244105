import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UtilsDates } from '@dn/utils'
import { ModalsIds } from '../../../../constants/modals'
import { StoreState } from '../../../../models/app/model'
import { ApiStoreSubscriptionsAC } from '../../../../store/actions/api-store/subscriptions/actions'
import { genAnimatedDialog } from '../../../common/compound-dialog/component'
import { DialogConfirmation } from '../component'

// ~~~~~~ Constants

const Dialog = genAnimatedDialog(ModalsIds.ConfirmReactivatePlan)

// ~~~~~~ Component

export const DialogConfirmReactivatePlan: React.FC<{}> = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const [doClose, setDoClose] = useState(0)

  const [closeOnEnd, setCloseOnEnd] = useState(false)

  const subscription = useSelector((state: StoreState) => state.tmpSubscription)

  // ~~~~~~ Computed

  const isRunning = subscription.uiStatus === 'running'

  const finalDate = UtilsDates.getMediumDate(subscription.current_period_end)

  // ~~~~~~ Handlers

  function onClickClose() {
    setDoClose(performance.now())
  }

  function onClickConfirm() {
    dispatch(ApiStoreSubscriptionsAC.reactivatePlan(subscription.subscription_id))
    setCloseOnEnd(true)
  }

  // ~~~~~~ Effects

  useEffect(() => {
    if (!closeOnEnd || isRunning) return

    setCloseOnEnd(false)
    setDoClose(performance.now())
  }, [isRunning, closeOnEnd, dispatch])

  // ~~~~~~ Render

  return (
    <DialogConfirmation
      data-test="dialog-confirm-reactivate-plan"
      Dialog={Dialog}
      $isDefaultCardModel
      isNoJustifyContent
      title={'v2.pages.plan-details.ResumePlan'}
      contentText={'v2.pages.plan-details.SubscriptionResumeMessage'}
      contentTextValues={{ value: finalDate }}
      actionButtons={{
        okActionIntl: 'v2.pages.plan-details.Resume',
      }}
      isRunning={isRunning}
      doClose={doClose}
      onEndDissapear={() => undefined}
      onClickClose={onClickClose}
      onClickConfirm={onClickConfirm}
    />
  )
}
