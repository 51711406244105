import Scrollbars from 'react-custom-scrollbars'
import { useEffect } from 'react'
import { useDispatch } from 'react-redux'

import { LayoutTopContent } from '../../../components/layouts/top-content/component'
import { LayoutTopContentContent } from '../../../components/layouts/top-content/parts/content/component'
import { LayoutTopContentTopLeft } from '../../../components/layouts/top-content/parts/top/left/component'
import { LayoutTopContentTopRight } from '../../../components/layouts/top-content/parts/top/right/component'
import { PageCommonTopLeft } from '../../@-common/top/left/component'
import { PageCommonTopRight } from '../../@-common/top/right/component'
import { STPageProductBcast } from './style'
import { ProductPageBcastContent } from './content/component'
import { BuyLicenseMC } from '../../../store/actions-mutators/buy-license/mutators'
import { Footer } from '../../../components/common/footer/component'
import { LayoutTopContentFooter } from '../../../components/layouts/top-content/parts/footer/component'

// ~~~~~~ Component

export const PageProductBcast = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ Effects

  // - Clear buy license

  useEffect(() => {
    dispatch(BuyLicenseMC.clearData())
  }, [dispatch])

  // ~~~~~~ Redner

  return (
    <STPageProductBcast>
      <Scrollbars>
        <LayoutTopContent noContentPadding>
          {/* Top */}

          {/* - Left */}

          <LayoutTopContentTopLeft>
            <PageCommonTopLeft />
          </LayoutTopContentTopLeft>

          {/* - Right */}

          <LayoutTopContentTopRight>
            <PageCommonTopRight />
          </LayoutTopContentTopRight>

          {/* Content */}

          <LayoutTopContentContent>
            <ProductPageBcastContent />
          </LayoutTopContentContent>

          {/* Footer */}

          <LayoutTopContentFooter>
            <Footer />
          </LayoutTopContentFooter>

          {/* - */}
        </LayoutTopContent>
      </Scrollbars>
    </STPageProductBcast>
  )
}
