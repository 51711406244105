import { CreditCardBrandSpritePosition } from '../../../style/constants/credit-card-brand'
import { LazySVG } from '../../svgs/lazy-svg/component'
import { ClassName, STCreditCard } from './style'

// ~~~~~~ Constants

const WarningIcon = LazySVG('icons/exclamation-triangle')

// ~~~~~~ Types

type Props = {
  card: DN.Models.Card.ModelFront
  warning?: true
}

// ~~~~~~ Component

export const CreditCard: React.FC<Props> = ({ card, warning }) => {
  const pos = card.brand as keyof typeof CreditCardBrandSpritePosition

  const creditCardXPosition = CreditCardBrandSpritePosition[pos]

  // ~~~~~~ Render

  return (
    <STCreditCard $creditCardXPosition={creditCardXPosition}>
      <div className={`${ClassName}--logo`} />

      <div className={`${ClassName}--code`}>•••• {card.last4}</div>

      {warning ? (
        <div className={`${ClassName}--warn-icon`}>
          <WarningIcon size={16} />
        </div>
      ) : undefined}
    </STCreditCard>
  )
}
