import { AccentColors } from './colors/accent'
import { BronxColors } from './colors/bronx'
import { BrooklynColors } from './colors/brooklyn'
import { EmmetColors } from './colors/emmet'
import { JaroColors } from './colors/jaro'
import { KioColors } from './colors/kio'
import { LisaColors } from './colors/lisa'
import { MorrieColors } from './colors/morrie'
import { OthersColors } from './colors/others'
import { RemyColors } from './colors/remy'
import { RonColors } from './colors/ron'
import { SitoColors } from './colors/sito'
import { WolfColors } from './colors/wolf'

// ~~~~~~

export const Colors = {
  ...AccentColors,
  ...BronxColors,
  ...BrooklynColors,
  ...EmmetColors,
  ...JaroColors,
  ...KioColors,
  ...LisaColors,
  ...MorrieColors,
  ...OthersColors,
  ...RemyColors,
  ...RonColors,
  ...SitoColors,
  ...WolfColors,
} as const
