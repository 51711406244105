import styled from 'styled-components'
import { theme } from '../../../../style/utils/theme'
import { Colors } from '../../../../style/theme/colors'
import { Fonts } from '../../../../style/theme/fonts'
import { Transition } from '../../../../style/animations/css-animations'

// ~~~~~~ Constants

export const ClassName = 'links-back-link'

// ~~~~~~ Component

export const STBackLink = styled.div`
  display: table;

  .${ClassName}--content {
    cursor: pointer;

    display: flex;
    align-items: center;

    .${ClassName}--content--arrow {
      display: flex;

      margin-right: 10px;

      svg {
        width: 20px;
        height: 20px;

        path {
          ${Transition.FillFaster}

          fill: ${theme(Colors.WOLF_90_90)};
        }
      }
    }

    .${ClassName}--content--text {
      ${Fonts.Weight[600]}
      ${Fonts.Scale.BodyBig}

      color: ${theme(Colors.WOLF_90_90)};

      ${Transition.ColorFaster}
    }

    &:hover {
      .${ClassName}--content--text {
        color: ${theme(Colors.BRONX_50_50)};
      }

      .${ClassName}--content--arrow {
        svg path {
          fill: ${theme(Colors.BRONX_50_50)};
        }
      }
    }

    &:active {
      .${ClassName}--content--text {
        color: ${theme(Colors.BRONX_60_60)};
      }

      .${ClassName}--content--arrow {
        svg path {
          fill: ${theme(Colors.BRONX_60_60)};
        }
      }
    }
  }
`
