import React from 'react'
import { ClassName, STPlatformCard } from './style'
import { LazySVG } from '../../svgs/lazy-svg/component'

// ~~~~~~ Constants

const Platform = {
  windows: {
    name: 'Windows',
    Logo: LazySVG('logos/windows'),
  },
  android: {
    name: 'Android',
    Logo: LazySVG('logos/android'),
  },
}

// ~~~~~~ Types

type Props = {
  selected: boolean
  kind: 'windows' | 'android'
  onClick: () => void
}
// ~~~~~~ Component

export const PlatformCard: React.FC<Props> = ({ kind, selected, onClick }) => {
  // ~~~~~~ Computed

  const { name, Logo } = Platform[kind]

  // ~~~~~~ Render

  return (
    <STPlatformCard className={`${selected ? 'selected' : ''}`} onClick={onClick}>
      <div className={`${ClassName}--logo ${kind}`}>
        <Logo size={20} />
      </div>

      <div>{name}</div>

      {/* - */}
    </STPlatformCard>
  )
}
