import styled from 'styled-components'
import { theme } from '../../../../style/utils/theme'
import { Colors } from '../../../../style/theme/colors'
import { Fonts } from '../../../../style/theme/fonts'

// ~~~~~~ Constants

export const ClassName = 'infos-box'

// ~~~~~~ Types

export type STProps = {
  $titleFontWeight?: number
}

// ~~~~~~ Component

export const STInfosBox = styled.div<STProps>`
  padding: 16px;

  border-radius: 6px;

  color: ${theme(Colors.WOLF_90_90)};

  &.blue {
    background-color: ${theme(Colors.BRONX_05_05)};
  }

  &.red {
    background-color: ${theme(Colors.BROOKLYN_05_05)};
  }

  &.yellow {
    background-color: ${theme(Colors.LISA_10_10)};
  }

  .${ClassName}--title {
    display: flex;
    /* align-items: center; */
    padding-bottom: 8px;

    .${ClassName}--title--icon {
      display: flex;
      align-items: flex-start;

      margin-top: 3px;
      margin-right: 8px;

      svg {
        width: 14px;
        height: 14px;

        path {
          fill: ${theme(Colors.WOLF_90_90)};
        }
      }
    }

    .${ClassName}--title--text {
      font-weight: ${({ $titleFontWeight }) => $titleFontWeight || 600};

      ${Fonts.Scale.Body}
    }
  }

  .${ClassName}--content {
    padding-left: 22px; // 14 icon + 8 icon-text

    ${Fonts.Weight[300]}
    ${Fonts.Scale.Body}

    .${ClassName}--content--line {
      margin-bottom: 8px;
    }
  }
`
