import styled from 'styled-components'
import { genSTUserZonePlansRootAddProductCommonStyle } from '../common-style'

// ~~~~~~ Constants

export const ClassName = 'user-zone-plans-root-add-product-launcher'

// ~~~~~~ Component

export const STUserZonePlansRootAddProductLauncher = styled.div`
  ${genSTUserZonePlansRootAddProductCommonStyle(ClassName)}
`
