import { Config } from '../../../config'
import { HttpVerbs } from '../../../constants/http'

const getPath = (path: string) => `${Config.Api.Store.URI}/api${path}`

// const getV1Path = (path: string) => `${Config.Api.Store.URI}/v1${path}`
// const getV2Path = (path: string) => `${Config.Api.Store.URI}/v2${path}`

// ####################################################################################################
// ~~~~~~ Public
// ####################################################################################################

// ~~~~~~ Uses Auth0 token in header, receives our token in current-user response

const CurrentUser = {
  Show: {
    Method: HttpVerbs.GET,
    Path: '/current-user/license-store',
    getUrl: () => getPath(CurrentUser.Show.Path),
  },
}

// ~~~~~~ Products

const Products = {
  List: {
    Method: HttpVerbs.GET,
    Path: '/products/:productId',
    getUrl: (productId: DN.Models.Product.ProductId) =>
      getPath(Products.List.Path).replace(':productId', productId),
  },
}

// ~~~~~~ Checkout

const Checkout = {
  SendEmail: {
    Method: HttpVerbs.POST,
    Path: '/checkout/email',
    getUrl: () => getPath(Checkout.SendEmail.Path),
  },

  SendEmailWithPass: {
    Method: HttpVerbs.POST,
    Path: '/checkout/email-with-password',
    getUrl: () => getPath(Checkout.SendEmailWithPass.Path),
  },

  ValidateEmailCode: {
    Method: HttpVerbs.POST,
    Path: '/checkout/validate-email-code',
    getUrl: () => getPath(Checkout.ValidateEmailCode.Path),
  },

  CreateSession: {
    Method: HttpVerbs.POST,
    Path: '/checkout/create-session',
    getUrl: () => getPath(Checkout.CreateSession.Path),
  },

  ShowSession: {
    Method: HttpVerbs.GET,
    Path: '/checkouts/:sessionId',
    getUrl: (sessionId: string) =>
      getPath(Checkout.ShowSession.Path).replace(':sessionId', sessionId),
  },

  // Private (require token)

  CardIntent: {
    Method: HttpVerbs.GET,
    Path: '/checkout/card-intent',
    getUrl: () => getPath(Checkout.CardIntent.Path),
  },

  SetDefaultCard: {
    Method: HttpVerbs.POST,
    Path: '/checkout/change-payment-method',
    getUrl: () => getPath(Checkout.SetDefaultCard.Path),
  },
}

// ####################################################################################################
// ~~~~~~ Private with token
// ####################################################################################################

// ~~~~~ Subscriptions

const Subscriptions = {
  Create: {
    Method: HttpVerbs.POST,
    Path: '/subscriptions',
    getUrl: () => getPath(Subscriptions.Create.Path),
  },

  ChangePaymentMethod: {
    Method: HttpVerbs.POST,
    Path: '/subscriptions/:id/change-payment-method',
    getUrl: (subscriptionId: string) =>
      getPath(Subscriptions.ChangePaymentMethod.Path).replace(':id', subscriptionId),
  },

  CancelPlan: {
    Method: HttpVerbs.POST,
    Path: '/subscriptions/:id/cancel',
    getUrl: (subscriptionId: string) =>
      getPath(Subscriptions.CancelPlan.Path).replace(':id', subscriptionId),
  },

  ReactivatePlan: {
    Method: HttpVerbs.POST,
    Path: '/subscriptions/:id/reactivate',
    getUrl: (subscriptionId: string) =>
      getPath(Subscriptions.ReactivatePlan.Path).replace(':id', subscriptionId),
  },

  ChangePlan: {
    Method: HttpVerbs.POST,
    Path: '/subscriptions/:id/change-plan',
    getUrl: (subscriptionId: string) =>
      getPath(Subscriptions.ChangePlan.Path).replace(':id', subscriptionId),
  },
}

// ~~~~~~~ Cards

const Cards = {
  AddCard: {
    Method: HttpVerbs.POST,
    Path: '/cards',
    getUrl: () => getPath(Cards.AddCard.Path),
  },

  RemCard: {
    Method: HttpVerbs.DELETE,
    Path: '/cards/:cardId',
    getUrl: (cardId: string) => getPath(Cards.RemCard.Path).replace(':cardId', cardId),
  },

  ChangeCard: {
    Method: HttpVerbs.PATCH,
    Path: '/cards/:cardId',
    getUrl: (cardId: string) => getPath(Cards.ChangeCard.Path).replace(':cardId', cardId),
  },
}

// ~~~~~ Billing

const Billing = {
  Update: {
    Method: HttpVerbs.PATCH,
    Path: '/billing-info',
    getUrl: () => getPath(Billing.Update.Path),
  },
}

// ~~~~~~

export const ApiRoutes = {
  CurrentUser,
  Products,
  Checkout,

  Subscriptions,
  Cards,
  Billing,
} as const
