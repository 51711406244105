import { map, mergeMap, Observable } from 'rxjs'
import { ofType } from 'redux-observable'

import {
  ApiMontageReleaseInfoAC,
  ApiMontageReleaseInfoAT,
} from '../../../../actions/api-montage/release-info/action'
import { Config } from '../../../../../config'
import { apiMontageReleaseInfoGet$ } from '../../../../../services/api-montage/release-info/get'
import { EpicApiMontageReleaseInfoMC } from './mutators'
import { ApiUtils } from '../../../utils'

type Action = ReturnType<typeof ApiMontageReleaseInfoAC.start>

export const apiMontageReleaseInfoGetEpic$ = (action$: Observable<Action>) =>
  action$.pipe(
    ofType(ApiMontageReleaseInfoAT.GET),
    mergeMap(() => apiMontageReleaseInfoGet$(Config.Api.Montage.Headers)),
    map((res) =>
      ApiUtils.isNotError(res)
        ? EpicApiMontageReleaseInfoMC.ok(res.response)
        : EpicApiMontageReleaseInfoMC.error(res.response),
    ),
  )
