import React, { useCallback, useEffect, useRef, useState } from 'react'
import { useDispatch } from 'react-redux'
import { fromEvent, merge } from 'rxjs'
import { debounceTime, filter } from 'rxjs/operators'
import { animate } from 'framer-motion'

import Scrollbars from 'react-custom-scrollbars'

import { STMonDialog, STProps } from './style'
import { AllDialogContext } from './context/dialog-context'

import { Trans } from '../intl/trans'
import { LazySVG } from '../../svgs/lazy-svg/component'
import { ElementEvents } from '../../../constants/element-events'
import { KeyCodes } from '../../../constants/key-codes'
import { ModalsMC } from '../../../store/actions-mutators/modals/mutators'
import { DialogsMargins } from '../../../style/constants/dialogs'
import { WindowEvents } from '../../../constants/window-events'
import { withFadeMove } from '../../../hocs/fade-move'
import { withStackedModal } from '../../../hocs/stacked-modal'

// ~~~~~~ Constants

const IconClose = LazySVG('icons/times')

// ~~~~~~ Types

export type Props = STProps & {
  'data-test'?: string

  children: React.ReactNode

  dialogTitle: IntlMsgId
  dialogTitleValues?: { [key: string]: React.ReactText }

  dialogSubtitle?: IntlMsgId
  dialogSubtitleValues?: { [key: string]: React.ReactText }

  hideCloseBtn?: true
  showESC?: true
  onWillBeClosed?: () => void

  needScroll?: { top: number }
  onScroll?: () => void

  closeOnClickOut?: boolean
  closeDialog?: number

  // From fade-move
  modalId?: string
  isActiveModal?: boolean
  iHaveBeenClickedOut?: number
  iHaveDisappeared?: number
  onContentHeightChanged?: (height: number) => void
  startDissapear?: () => void
  endDissapear?: () => void
}

// ~~~~~~ Component

export const Dialog: React.FC<Props> = ({
  'data-test': dataTest,
  dialogTitle,
  dialogTitleValues,

  dialogSubtitle,
  dialogSubtitleValues,

  hideCloseBtn,
  showESC,

  closeOnClickOut,

  needScroll,
  onScroll,

  closeDialog,
  onWillBeClosed,

  // From React
  children,

  // From fade-move
  modalId,
  isActiveModal,
  iHaveBeenClickedOut,
  iHaveDisappeared,
  onContentHeightChanged,
  startDissapear,
  endDissapear,

  // style
  $isNoJustifyContent: isNoJustifyContent,

  ...restProps
}) => {
  // ~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~ State

  const [closeDialogInit] = useState(closeDialog)

  const [fadeMoveClickedOut] = useState(iHaveBeenClickedOut)

  const [dissapearStarted, setDissapearStarted] = useState(false)

  const scrollbarRef = useRef<Scrollbars>(null)
  const insideScrollRef = useRef<HTMLDivElement>(null)

  const headerRef = useRef<HTMLDivElement>(null)
  const actionsRef = useRef<HTMLDivElement>(null)

  const [scrollBarHeight, setScrollBarHeight] = useState<number | undefined>()

  // ~~~~~ Callbacks

  const doStartDissapear = useCallback(() => {
    setDissapearStarted(true)
    onWillBeClosed && onWillBeClosed()
    startDissapear && startDissapear()
  }, [onWillBeClosed, startDissapear])

  const closeModal = useCallback(() => {
    if (!modalId || !isActiveModal) {
      return
    }

    if (!startDissapear) {
      onWillBeClosed && onWillBeClosed()
      dispatch(ModalsMC.close(modalId))
      return
    }

    doStartDissapear()
  }, [dispatch, doStartDissapear, isActiveModal, modalId, onWillBeClosed, startDissapear])

  // ~~~~~~ Effects

  // Start dissapear from clicked out

  useEffect(() => {
    if (closeOnClickOut === false) return

    if (!startDissapear || dissapearStarted) return

    if (fadeMoveClickedOut !== iHaveBeenClickedOut) {
      doStartDissapear()
    }
  }, [
    closeOnClickOut,
    dissapearStarted,
    doStartDissapear,
    fadeMoveClickedOut,
    iHaveBeenClickedOut,
    onWillBeClosed,
    startDissapear,
  ])

  // Start dissapear from from component declaring this dialog

  useEffect(() => {
    if (
      closeDialog === undefined ||
      closeDialog === closeDialogInit ||
      !startDissapear ||
      dissapearStarted
    ) {
      return
    }

    const activeElement = document.activeElement as any
    activeElement && typeof activeElement.blur === 'function' && activeElement.blur()

    doStartDissapear()
  }, [
    closeDialog,
    closeDialogInit,
    dissapearStarted,
    doStartDissapear,
    onWillBeClosed,
    startDissapear,
  ])

  // Dissapear ended

  useEffect(() => {
    if (!modalId || !iHaveDisappeared) return

    dispatch(ModalsMC.close(modalId))
    endDissapear && endDissapear()
  }, [dispatch, iHaveDisappeared, modalId, endDissapear])

  // ESC pressed

  useEffect(() => {
    if (!showESC) return

    const sub = fromEvent<KeyboardEvent>(document, ElementEvents.KeyDown)
      .pipe(filter((evt) => evt.code === KeyCodes.ESC))
      .subscribe({
        next: () => {
          closeModal()
        },
      })

    return () => {
      sub.unsubscribe()
    }
  }, [closeModal, showESC])

  // Recalculate height: is necessary to recalculate height after every render.

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => {
    const titleHeight = headerRef.current?.clientHeight || 0
    const contentHeight = insideScrollRef.current?.clientHeight || 0
    const actionsHeight = actionsRef.current?.clientHeight || 0

    const maxHeight =
      window.innerHeight - titleHeight - actionsHeight - DialogsMargins.WindowAndDialogs

    const finalContentHeight =
      contentHeight && contentHeight > maxHeight ? maxHeight : contentHeight

    const height = titleHeight + actionsHeight + finalContentHeight

    if (height && onContentHeightChanged) {
      onContentHeightChanged(titleHeight + actionsHeight + finalContentHeight)
    }

    setScrollBarHeight(finalContentHeight)
  })

  // Window Resize: it triggers a change in the state to repaint the component

  useEffect(() => {
    const sub = merge(
      fromEvent(window, WindowEvents.Resize),
      fromEvent(window, WindowEvents.OrientationChange),
    )
      .pipe(debounceTime(100))
      .subscribe({
        next: () => setScrollBarHeight(0),
      })

    return () => {
      sub.unsubscribe()
    }
  }, [])

  // Move the scroll

  useEffect(() => {
    if (needScroll === undefined || !scrollbarRef.current) return

    const fromCurrentScrollPos = scrollbarRef.current.getScrollTop()
    const toTop = needScroll.top

    const controls = animate(fromCurrentScrollPos, toTop, {
      type: 'tween',
      onUpdate: (topPosition) => {
        if (!scrollbarRef.current) return

        scrollbarRef.current.scrollTop(topPosition)
      },
    })

    return controls.stop
  }, [needScroll])

  // ~~~~~ Render

  return (
    <STMonDialog
      data-test={dataTest || 'dialog'}
      $isNoJustifyContent={isNoJustifyContent}
      {...restProps}
    >
      {/* Title + Title buttons */}
      <div ref={headerRef} className="dialog-header">
        {/* Title */}
        <div className="title">
          <div className="text">
            <div>
              <Trans id={dialogTitle} values={dialogTitleValues} />
            </div>
            {dialogSubtitle ? (
              <div className="subtext">
                <Trans id={dialogSubtitle} values={dialogSubtitleValues} />
              </div>
            ) : undefined}
          </div>

          {/* Buttons */}
          <div className="btns-holder">
            {/* Close Button */}
            {!hideCloseBtn ? (
              <div className="btn-close" onClick={closeModal}>
                {/* X */}
                <IconClose size={13} />
              </div>
            ) : undefined}
          </div>
        </div>
      </div>

      {/* Content and Actions */}
      <div className="dialog-content-actions">
        {/* Content */}
        <AllDialogContext.Provider value={{ kind: 'content' }}>
          {children ? (
            <div className="dialog-content">
              <Scrollbars
                ref={scrollbarRef}
                universal
                data-test="dialog-scroller"
                autoHide={true}
                style={{ height: scrollBarHeight }}
                onScroll={onScroll}
              >
                <div className="inside-scroll" ref={insideScrollRef}>
                  {children}
                </div>
              </Scrollbars>
            </div>
          ) : undefined}
        </AllDialogContext.Provider>

        {/* Actions */}
        <div ref={actionsRef}>
          <AllDialogContext.Provider value={{ kind: 'actions' }}>
            {children}
          </AllDialogContext.Provider>
        </div>
      </div>
    </STMonDialog>
  )
}

// ~~~~~~ Generator

export const genAnimatedDialog = (modalId: string) =>
  withStackedModal(withFadeMove(Dialog), modalId)
