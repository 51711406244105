import { Observable, of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { catchError, map } from 'rxjs/operators'
import { ApiRoutes } from '../constants/routes'
import { ApiHeadersUtils } from '../utils/headers'

type Req = DN.Models.Checkout.Api.ShowSession.Req
type Res = DN.Models.Checkout.Api.ShowSession.Res
type ResParsed = DNApiResParsed<DN.Models.Checkout.Api.ShowSession.ResParsed>
type ResError = DNApiError<DN.Models.Checkout.Api.ShowSession.ResError>

const { ShowSession } = ApiRoutes.Checkout

export const apiStoreCheckoutShowSession$ = (data: Req, headers?: AppHeaders) => {
  return ajax<Res>({
    ...ApiHeadersUtils.genHeaders(headers),
    method: ShowSession.Method,
    url: ShowSession.getUrl(data.session_id),
  }).pipe(
    map((res): ResParsed => {
      return {
        status: res.status,
        response: {
          ...res.response,
        },
      }
    }),
    catchError(
      (res: DNApiRes<void>): Observable<ResError> =>
        of({
          status: res.status,
          response: [{ id: 'api.checkout.show-session.Error' }],
        }),
    ),
  )
}
