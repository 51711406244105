import styled from 'styled-components'
import { Layout } from '../../../../../style/theme/layout'

// ~~~~~~ Constants

export const ClassName = 'user-zone-plans-product-detail-bcast'

// ~~~~~~ Component

export const STUserZonePlansProductDetailBcast = styled.div`
  ${Layout.MarginMaxWidth.MiddleContent}
`
