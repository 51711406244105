import { ProductButtonMontage } from '../../../../../components/common/product-button/montage/component'
import { LazySVG } from '../../../../../components/svgs/lazy-svg/component'
import { PageCommonOPStep2VerifyEmail } from '../../../../@-common/obtaining-products/step-2-verify-email/component'
import { PageTrialsOrFreeMontageTabSteps } from '../../tab-steps/component'

// ~~~~~~ Constants

const Logo = LazySVG('logos/montage-gradient')

// ~~~~~~ Component

export const PageTrialsOrFreeMontageContentStep2VerifyEmail = () => {
  // ~~~~~~ Render

  return (
    <PageCommonOPStep2VerifyEmail
      Logo={Logo}
      ProductButton={ProductButtonMontage}
      tabSteps={<PageTrialsOrFreeMontageTabSteps kind="trial" />}
    />
  )
}
