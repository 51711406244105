import { useNavigate } from 'react-router'
import { ClassName, STUserZonePlansRootAddProductAltMontage } from './style'
import { Routes } from '../../../../../../constants/routes'
import { LazySVG } from '../../../../../../components/svgs/lazy-svg/component'
import { Trans } from '../../../../../../components/common/intl/trans'
import { Button } from '../../../../../../components/common/button/component'

// ~~~~~~ Constants

const Logo = LazySVG('logos/montage-gradient')

const ArrowRightIcon = LazySVG('icons/arrow-long-right')

// ~~~~~~ Component

export const UserZonePlansRootAddProductAltMontage = () => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  // ~~~~~~ Handlers

  function onClickAction() {
    navigate(Routes.UserZonePlansMontagePurchase)
  }

  // ~~~~~~ Render

  return (
    <STUserZonePlansRootAddProductAltMontage>
      {/* Logo */}

      <div className={`${ClassName}--logo`}>
        <Logo size={32} />
      </div>

      {/* Title */}

      <div className={`${ClassName}--title`}>
        <Trans
          id="pages.user-zone.plans-tab.ExistSolutionProductTitle"
          values={{ value: 'Montage' }}
        />
      </div>

      {/* Subtitle */}

      <div className={`${ClassName}--subtitle`}>
        <Trans id="pages.user-zone.plans-tab.RequireMoreDevice" values={{ value: 'Montage' }} />
      </div>

      {/* Action */}

      <div className={`${ClassName}--action`}>
        <Button
          colorType="only-link"
          intlId="pages.common.AddProduct"
          intlValues={{ value: 'Montage' }}
          padding="0"
          width="unset"
          IconRight={ArrowRightIcon}
          running={false}
          disabled={false}
          active={true}
          onClick={onClickAction}
        />
      </div>

      {/* - */}
    </STUserZonePlansRootAddProductAltMontage>
  )
}
