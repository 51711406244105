type StatusOnly = { status: number }

// Basic status error
const isErrorResponse = (res: StatusOnly) =>
  res.status === undefined || res.status === 0 || res.status >= 400

// Guard
const isNotError = <T, U>(
  res: DNApiResParsed<T> | DNApiError<U>,
  isErrorFun?: (res: StatusOnly) => boolean,
): res is DNApiResParsed<T> =>
  res.status !== 0 && (isErrorFun ? !isErrorFun(res) : !isErrorResponse(res))

// Guard
const isError = <T, U>(res: DNApiResParsed<T> | DNApiError<U>): res is DNApiError<U> =>
  !isNotError(res)

// ~~~~~~

export const ApiUtils = {
  isNotError,
  isError,
}
