const prefix = 'mt-buy-license'

// ~~~~~~ Mutators Types

export const BuyLicenseMT = {
  SET_LICENSE: `${prefix}-set-license`,
  SET_TRIAL: `${prefix}-set-trial`,
  CLEAR: `${prefix}-clear`,
} as const

// ~~~~~~ Mutators Creators

type LicenseData = {
  productId: DN.Models.Product.ProductId
  platform: DN.Models.Product.Platform
  subId: string
  unitPrice: number
  trial: boolean
  alwaysFree: boolean
  trialPeriodDays: number
}

export const BuyLicenseMC = {
  setLicense: (data: LicenseData) => ({
    type: BuyLicenseMT.SET_LICENSE,
    payload: {
      ...data,
      quantity: '1',
    },
  }),

  clearData: () => ({
    type: BuyLicenseMT.CLEAR,
  }),
} as const
