import styled from 'styled-components'
import { Layout } from '../../../../../style/theme/layout'

// ~~~~~~ Constants

export const ClassName = 'user-zone-plans-detail-launcher'

// ~~~~~~ Component

export const STUserZonePlansDetailLauncher = styled.div`
  ${Layout.MarginMaxWidth.MiddleContent}
`
