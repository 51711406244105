import styled from 'styled-components'
import { Fonts } from '../../../style/theme/fonts'
import { Colors } from '../../../style/theme/colors'
import { theme } from '../../../style/utils/theme'
import { MediaQuery } from '../../../style/constants/media-queries'
import { Layout } from '../../../style/theme/layout'

// ~~~~~~ Constants

export const ClassName = 'page-root-header'

// ~~~~~~ Component

export const STPageRootHeader = styled.div`
  display: flex;
  justify-content: center;

  .${ClassName}--header {
    background-color: ${theme(Colors.WOLF_05_05)};

    max-width: ${MediaQuery.LG};

    padding: 36px ${Layout.Spacing[5]}px 36px ${Layout.Spacing[5]}px;
    width: calc(100% - ${Layout.Spacing[5] * 2}px);

    .${ClassName}--header--content {
      .${ClassName}--header--content--title {
        ${Fonts.Scale.Headline1}
        ${Fonts.Weight[800]}
      }

      .${ClassName}--header--content--subtitle {
        ${Fonts.Scale.Title}
        ${Fonts.Weight[300]}
      }
    }
  }
`
