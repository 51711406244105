import styled from 'styled-components'
import { genSTUserZonePlansRootProductCardCommonStyle } from '../common-style'

// ~~~~~~ Constants

export const ClassName = 'user-zone-plans-root-product-card-bcast'

// ~~~~~~ Component

export const STUserZonePlansRootProductCardBcast = styled.div`
  ${genSTUserZonePlansRootProductCardCommonStyle(ClassName)}
`
