import { useDispatch, useSelector } from 'react-redux'

import { useCustomAuth } from '../../../../hooks/auth/use-custom-auth'
import { StoreState } from '../../../../models/app/model'
import { SideMenuPortal } from '../../../../portals/side-menu'
import { RootMC } from '../../../../store/actions-mutators/root/mutators'
import { Loader } from '../../loader/component'
import { LazySVG } from '../../../svgs/lazy-svg/component'
import { UserMenuSlide } from '../slide/component'
import { STUserMenuMobile } from './style'

// ~~~~~~ Constants

const MenuIcon = LazySVG('icons/menu')

// ~~~~~~ Types

type MenuItemsType = {
  ItemIcon: ReturnType<typeof LazySVG>
  transId: IntlMsgId
  value: string
}

type Props = {
  menuItems: MenuItemsType[]
}

// ~~~~~~ Component

export const UserMenuMobile: React.FC<Props> = ({ menuItems }) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const { isLoading } = useCustomAuth()

  // ~~~~~~ State

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  const showSideMenu = useSelector((store: StoreState) => store.root.showSideMenu)

  // ~~~~~~ Computed

  const isMenuLoading = isLoading || currentUser.uiStatus === 'running'

  // ~~~~~~ Handlers

  function onClickMenuButton() {
    const action = showSideMenu ? RootMC.hideSideMenu() : RootMC.showSideMenu()

    dispatch(action)
  }

  // ~~~~~~ Render

  return (
    <STUserMenuMobile data-test="user-menu-mobile">
      {/* Menu icon */}

      <div onClick={onClickMenuButton} data-test="toggle-user-menu">
        <div className="user-menu-btn">
          <div className="icon">
            {isMenuLoading ? <Loader size={25} /> : <MenuIcon size={25} />}
          </div>
        </div>
      </div>

      {/* Side Menu */}

      <SideMenuPortal>
        <UserMenuSlide menuItems={menuItems} />
      </SideMenuPortal>

      {/* - */}
    </STUserMenuMobile>
  )
}
