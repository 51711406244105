import styled from 'styled-components'

// ~~~~~~ Constants

export const ClassName = 'page-trials-or-free-launcher'

// ~~~~~~ Component

export const STPageTrialsOrFreeLauncher = styled.div`
  height: 100vh;
`
