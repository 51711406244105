import { useNavigate } from 'react-router'
import { useEffect } from 'react'
import { useDispatch, useSelector } from 'react-redux'

import { ClassName, STUserZonePlansProductBcast } from './style'
import { Routes } from '../../../../../constants/routes'
import { MediaQuery } from '../../../../../style/constants/media-queries'
import { Button } from '../../../../../components/common/button/component'
import { Trans } from '../../../../../components/common/intl/trans'
import { ProductButtonBcast } from '../../../../../components/common/product-button/bcast/component'
import { LazySVG } from '../../../../../components/svgs/lazy-svg/component'
import { Config } from '../../../../../config'
import { StoreState } from '../../../../../models/app/model'
import { ApiStoreCheckoutAC } from '../../../../../store/actions/api-store/checkout/actions'
import { ProductPageBcastContentSubCard } from '../../../../1-product/bcast/content/subscribe-card/component'

import backgroundSM from '../../../../../imgs/pngs/broadcast-landing-background-sm.png'
import backgroundMD from '../../../../../imgs/pngs/broadcast-landing-background-md.png'
import backgroundLG from '../../../../../imgs/pngs/broadcast-landing-background.png'
import background2x from '../../../../../imgs/pngs/broadcast-landing-background.png'
import { InfosBox } from '../../../../../components/common/infos/box/component'

// ~~~~~~ Constants

const ArrowLeftIcon = LazySVG('icons/arrow-long-left')

const GradientLogo = LazySVG('logos/broadcast-gradient')

// ~~~~~~ Component

export const UserZonePlansProductBcast = () => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  const dispatch = useDispatch()

  // ~~~~~ State

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  const metaProducts = useSelector((state: StoreState) => state.broadcastSub)

  const subs = useSelector((state: StoreState) => state.subscriptions)

  const buyLicense = useSelector((state: StoreState) => state.buyLicense)

  // ~~~~~~ Computed

  const isLoading = !currentUser.isSessionChecked || metaProducts.uiStatus !== 'completed'

  const buyLicenseIsRunning = buyLicense.uiStatus === 'running'

  const sub = subs.find((sub) => sub.product_id === 'broadcast-subscription')

  const premiumMonthPrice = metaProducts.products.premium.month.price

  const premiumYearPrice = metaProducts.products.premium.year.price

  // ~~~~~~ Handlers

  function onClickGoBack() {
    navigate(Routes.UserZonePlans)
  }

  function onClickSub2Free() {
    const freeProduct = metaProducts.products.free

    dispatch(
      ApiStoreCheckoutAC.startFreeSubOrTrial('broadcast-subscription', {
        email: currentUser.email,
        price_of_license_of_product_id: freeProduct.id,
        code: '',
      }),
    )
  }

  function onClickSub2Premium(interval: 'monthly' | 'yearly') {
    navigate(Routes.genUserZonePlansBcastSub2Premium(interval))
  }

  // ~~~~~~ Effects

  // - If user has some bcast sub
  //   free: Nav to get-free success
  //   other: Return to plans

  useEffect(() => {
    if (!sub) return

    const freeProduct = metaProducts.products.free

    sub.price_id === freeProduct.id
      ? navigate(`${Routes.genUserZonePlansBcastDetail(sub.subscription_id)}?new=true`)
      : navigate(Routes.UserZonePlans)

    //
  }, [sub, navigate, metaProducts.products.free])

  // ~~~~~~ Render

  return (
    <STUserZonePlansProductBcast>
      {/* Info + Sub to Free */}

      <div className={`${ClassName}--top`}>
        {/* Background */}

        <picture className={`${ClassName}--top--bg-pic`}>
          <source media={`(max-width: ${MediaQuery.SM})`} srcSet={backgroundSM} />
          <source media={`(max-width: ${MediaQuery.MD})`} srcSet={backgroundMD} />
          <img srcSet={`${backgroundLG}, ${background2x} 2x`} alt="broadcast" />
        </picture>

        {/* Info */}

        <div className={`${ClassName}--top--left`}>
          {/* Back */}

          <div className={`${ClassName}--top--left--back`}>
            <Button
              className={`${ClassName}--top--left--back--action`}
              colorType="only-link"
              width="unset"
              padding="0"
              intlId="pages.common.Plans"
              active={true}
              disabled={false}
              running={false}
              onClick={onClickGoBack}
              IconLeft={ArrowLeftIcon}
            />
          </div>

          <div style={{ margin: 'auto', maxWidth: 500 }}>
            {/* Product logo */}

            <div className={`${ClassName}--top--left--logo`}>
              <GradientLogo size={32} className={`${ClassName}--top--left--logo--img`} />
            </div>

            {/* Title */}

            <div className={`${ClassName}--top--left--title`}>
              <Trans id="pages.products.broadcast.Title" />
            </div>

            {/* Info list */}

            <div className={`${ClassName}--top--left--info-list`}>
              {/* Title */}

              <div className={`${ClassName}--top--left--info-list--title`}>
                <Trans id="pages.products.common.WhatToKnow" />
              </div>

              {/* Points */}

              <ul className={`${ClassName}--top--left--info-list--points`}>
                {/* 40 Viewers... */}

                <div className={`${ClassName}--top--left--info-list--points--point`}>
                  <span className={`${ClassName}--top--left--info-list--points--point--bullet`}>
                    •
                  </span>
                  <Trans id="pages.products.broadcast.InviteViewer" />
                </div>

                {/* 30 min daily limit... */}

                <div className={`${ClassName}--top--left--info-list--points--point`}>
                  <span className={`${ClassName}--top--left--info-list--points--point--bullet`}>
                    •
                  </span>
                  <Trans id="pages.products.broadcast.DailyLimit" />
                </div>
              </ul>
            </div>

            {/* Info 14 days pro version */}

            <InfosBox
              className={`${ClassName}--top--left--info`}
              kind="yellow"
              icon="gift"
              titleIntl="v2.pages.broadcast-solution.Info"
              titleIntlValues={{
                value: Config.Trials.BroadcastWeb.days,
                c: (chunks: string) => (
                  <span className={`${ClassName}--top--left--info--highlight`}>{chunks}</span>
                ),
              }}
              $titleFontWeight={400}
            />

            {/* Action Trial */}

            <ProductButtonBcast
              intlId="pages.products.common.StartTrial"
              active={!isLoading && !buyLicenseIsRunning}
              disabled={isLoading || buyLicenseIsRunning}
              running={isLoading || buyLicenseIsRunning}
              onClick={onClickSub2Free}
            />
          </div>
        </div>

        {/* Right Space */}

        <div className={`${ClassName}--top--right`} />
      </div>

      {/* Sub to Premium (month/year) */}

      <div className={`${ClassName}--bottom`}>
        {/* Title */}

        <div className={`${ClassName}--bottom--title`}>
          <Trans id="pages.products.broadcast.BuyRemoveLimit" />
        </div>

        {/* Sub cards */}

        <div className={`${ClassName}--bottom--sub-cards`}>
          {/* Monthly  */}

          <ProductPageBcastContentSubCard
            isRunning={isLoading}
            isMonthly={true}
            price={premiumMonthPrice}
            onClick={() => onClickSub2Premium('monthly')}
          />

          {/* Space */}

          <div className={`${ClassName}--bottom--sub-cards--space`} />

          {/* Yearly */}

          <ProductPageBcastContentSubCard
            isRunning={isLoading}
            isMonthly={false}
            price={premiumYearPrice}
            onClick={() => onClickSub2Premium('yearly')}
          />
        </div>

        {/* Get in touch */}

        <div className={`${ClassName}--bottom--info`}>
          <Trans id="v2.pages.broadcast-solution.HelpChangePlan" />
        </div>
      </div>

      {/* - */}
    </STUserZonePlansProductBcast>
  )
}
