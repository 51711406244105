import { Action } from 'redux'

import { StoreStateProps } from '../../models/app/model'
import { billingInfoEditReducer } from './billing-information/edit/reducer'
import { billingInfoReducer } from './billing-information/info/reducer'
import { buyLicenseReducer } from './buy-license/reducer'
import { cardsReducer } from './cards/reducer'
import { checkoutSessionReducer } from './checkout-session/reducer'
import { currentUserReducer } from './current-user/reducer'
import { debuggerReducer } from './debugger/reducer'
import { intlReducer } from './intl/reducer'
import { invoicesReducer } from './invoices/reducer'
import { launcherReleaseInfoReducer } from './launcher-release-info/reducer'
import { modalsReducer } from './modals/reducer'
import { montageReleaseInfoReducer } from './montage-release-info/reducer'
import { BroadcastReducer } from './products/broadcast-sub/reducer'
import { launcherReducer } from './products/launcher/reducer'
import { montageReducer } from './products/montage/reducer'
import { rootReducer } from './root/reducer'
import { subscriptionsReducer } from './subscriptions/list/reducer'
import { tmpSubscriptionReducer } from './subscriptions/tmp/reducer'
import { themeReducer } from './theme/reducer'

type Reducers = {
  [key in StoreStateProps]: (state: any, action: Action) => any
}

const reducers: Reducers = {
  root: rootReducer,
  intl: intlReducer,
  modals: modalsReducer,
  theme: themeReducer,

  montageReleaseInfo: montageReleaseInfoReducer,
  launcherReleaseInfo: launcherReleaseInfoReducer,

  currentUser: currentUserReducer,

  debugger: debuggerReducer,

  subscriptions: subscriptionsReducer,
  tmpSubscription: tmpSubscriptionReducer,

  cards: cardsReducer,
  invoices: invoicesReducer,

  billingInfoShow: billingInfoReducer,
  billingInfoEdit: billingInfoEditReducer,

  launcher: launcherReducer,
  montage: montageReducer,
  broadcastSub: BroadcastReducer,

  buyLicense: buyLicenseReducer,
  checkoutSession: checkoutSessionReducer,
}

export default reducers
