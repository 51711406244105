const initModel: DN.Models.Card.ModelFront = {
  uiStatus: 'init',
  errors: [],

  payment_method_id: '',
  brand: '',
  last4: '',
  exp_month: 0,
  exp_year: 0,
  is_default: false,
}

const initModelList: DN.Models.Card.ModelList = {
  uiStatusAdd: 'init',
  uiStatusChange: 'init',
  errorsAdd: [],
  tmpCardId: '',
  list: [],
}

// ~~~~~~

export const CardModel = {
  initModelList,
  initModel,
}
