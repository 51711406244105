import styled from 'styled-components'

import { Transition } from '../../../style/animations/css-animations'
import { Colors } from '../../../style/theme/colors'
import { Fonts } from '../../../style/theme/fonts'
import { getThemeValue, theme } from '../../../style/utils/theme'

// ~~~~~~ Types

export type STProps = {
  $menuIsOpen?: boolean
}

// ~~~~~~ Component

export const STUserMenu = styled.div<STProps>`
  z-index: 10;

  display: flex;
  align-items: center;

  .account-button {
    cursor: pointer;

    ${Fonts.Weight[600]}
    ${Fonts.Scale.Body}

    text-decoration: none;

    color: ${theme(Colors.WOLF_00_00)};

    display: flex;
    align-items: center;
    justify-content: center;

    .icon-container {
      margin-right: 8px;

      svg {
        margin-top: 5px;

        width: 18px;
        height: 18px;

        path {
          fill: ${({ $menuIsOpen: menuIsOpen }) =>
            theme(menuIsOpen ? Colors.BRONX_30_30 : Colors.WOLF_00_00)};
        }
      }
    }

    ${Transition.ColorFaster}
    ${Transition.BorderColorFaster}

    &:active {
      color: ${theme(Colors.BRONX_30_30)};

      .user-account:after {
        border-bottom: 1px solid ${theme(Colors.BRONX_30_30)};
      }

      svg path {
        fill: ${theme(Colors.BRONX_30_30)};
      }
    }

    &:hover .user-email div:first-child:after {
      transform: scaleX(1);
      border-bottom: 1px solid
        ${(props) =>
          getThemeValue(props, props.$menuIsOpen ? Colors.BRONX_30_30 : Colors.WOLF_00_00)};
    }

    &:active .user-email div:first-child:after {
      border-bottom: 1px solid ${theme(Colors.BRONX_30_30)};
    }

    .user-email {
      display: flex;
      align-items: inherit;

      .user-email-text {
        color: ${({ $menuIsOpen: menuIsOpen }) => (menuIsOpen ? theme(Colors.BRONX_30_30) : '')};

        &:after {
          display: block;

          content: '';

          border-bottom: 1px solid
            ${(props) =>
              getThemeValue(props, props.$menuIsOpen ? Colors.BRONX_30_30 : Colors.WOLF_00_00)};

          transform: scaleX(${(props) => (props.$menuIsOpen ? 1 : 0)});

          ${Transition.BorderTransformFaster}
        }
      }

      .arrow-icon-container {
        width: 25px;
        height: 25px;

        path {
          fill: ${({ $menuIsOpen: menuIsOpen }) =>
            menuIsOpen ? theme(Colors.BRONX_30_30) : theme(Colors.WOLF_00_00)};
        }
      }
    }
  }
`
