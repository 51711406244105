import { UtilsNumber, UtilsString } from '@dn/utils'
import { Dollars } from '../dollars/component'
import { IntlInput } from '../intl-input/component'
import { Trans } from '../intl/trans'
import { ClassName, STDeviceNumberSelector } from './style'
import { LazySVG } from '../../svgs/lazy-svg/component'

// ~~~~~~ Constants

const Max = 1_000_000

const ArrowDownIcon = LazySVG('icons/arrow-down')

// ~~~~~~ Types

type Props = {
  product: 'Launcher' | 'Montage'
  platforms?: string[]
  price: number
  qtty: string

  onChangePlatform?: (platform: string) => void
  onChange: (qtty: string) => void
}

// ~~~~~~ Component

export const DeviceNumberSelector: React.FC<Props> = ({
  product,
  platforms = [],
  price,
  qtty,

  onChangePlatform,
  onChange,
}) => {
  // ~~~~~~ Computed

  const qttyNumm = Number(qtty)

  const total = isNaN(qttyNumm) ? 0 : qttyNumm * price

  // ~~~~~~ Handlers

  function onChangePlatformH(evt: any) {
    onChangePlatform && onChangePlatform(evt.target.value)
  }

  function onChangeH(value: string) {
    let finalValue = UtilsNumber.safeStrOfNumberInt(value)

    const valueNum = UtilsNumber.toNumberNotNaN(finalValue, 1)

    finalValue = valueNum > Max ? '1000000' : finalValue

    onChange(finalValue)
  }

  function onClickDecrement() {
    let value = UtilsNumber.toNumberNotNaN(qtty, 1) - 1

    value = value < 1 ? 1 : value

    onChange(`${value}`)
  }

  function onClickIncrement() {
    let value = UtilsNumber.toNumberNotNaN(qtty, 1) + 1

    value = value > Max ? Max : value

    onChange(`${value}`)
  }

  // ~~~~~~ Render

  return (
    <STDeviceNumberSelector>
      <div className={`${ClassName}--row`}>
        {/* Price yearly */}

        <div className={`${ClassName}--row--left`}>
          <span>{product}</span>
          <span> </span>
          <span className={`${ClassName}--row--left--smooth`}>
            (<Trans id="common.Yearly" />)
          </span>
        </div>

        <div className={`${ClassName}--row--right`}>
          <div className={`${ClassName}--row--right--price`}>
            <Dollars value={price} />
          </div>
        </div>
      </div>

      {/* Platform selector */}

      {platforms.length ? (
        <>
          <hr className={`${ClassName}--separator`} />

          <div className={`${ClassName}--row middle`}>
            <div className={`${ClassName}--row--left`}>
              <Trans id="common.Platform" />
            </div>

            <div className={`${ClassName}--row--right select`}>
              <div className={`${ClassName}--row--right--icon-select`}>
                <ArrowDownIcon size={24} />
              </div>

              <select className={`${ClassName}--row--right--select`} onChange={onChangePlatformH}>
                {platforms.map((platform) => (
                  <option key={platform} value={platform}>
                    {UtilsString.capitalize(platform)}
                  </option>
                ))}
              </select>
            </div>
          </div>
        </>
      ) : undefined}

      <hr className={`${ClassName}--separator`} />

      {/* Device Selector */}

      <div className={`${ClassName}--row middle`}>
        <div className={`${ClassName}--row--left`}>
          <Trans id="v2.pages.order-solution.NumberOfDevices" />
        </div>

        <div className={`${ClassName}--row--right`}>
          <div className={`${ClassName}--row--right--input`}>
            <IntlInput $textAlign="center" value={qtty} $disabled={false} onChange={onChangeH} />
          </div>

          <div className={`${ClassName}--row--right--buttons`}>
            <div
              className={`${ClassName}--row--right--buttons--button left`}
              onClick={onClickDecrement}
            >
              -
            </div>

            <div className={`${ClassName}--row--right--buttons--sep`}>
              <div className={`${ClassName}--row--right--buttons--sep--bar`} />
            </div>

            <div
              className={`${ClassName}--row--right--buttons--button right`}
              onClick={onClickIncrement}
            >
              +
            </div>
          </div>
        </div>
      </div>

      <hr className={`${ClassName}--separator`} />

      {/* Total */}

      <div className={`${ClassName}--row`}>
        <div className={`${ClassName}--row--left`}>
          <Trans id="v2.pages.order-solution.Total" />
        </div>

        <div className={`${ClassName}--row--right`}>
          <Dollars value={total} />
        </div>
      </div>
    </STDeviceNumberSelector>
  )
}
