import { HooksData } from '@dn/hooks'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'

import { LazySVG } from '../../../../../components/svgs/lazy-svg/component'
import { ClassName, STPagePurchaseMontageContentStep4Done } from './style'
import { StoreState } from '../../../../../models/app/model'
import { Trans } from '../../../../../components/common/intl/trans'
import { Config } from '../../../../../config'
import { LinkCard } from '../../../../../components/common/link-card/component'
import { useCustomAuth } from '../../../../../hooks/auth/use-custom-auth'
import { Routes } from '../../../../../constants/routes'
import { DangerTrans } from '../../../../../components/common/intl/danger-trans'
import { ProductButtonMontage } from '../../../../../components/common/product-button/montage/component'
import { PagePurchaseCommonTabSteps } from '../../../@-common/tab-steps/component'

// ~~~~~~ Constants

const Logo = LazySVG('logos/montage-gradient')

const TitleIcon = LazySVG('icons/raise-hands')

const InActionIcon = LazySVG('icons/videos')

const ManageAccountIcon = LazySVG('icons/user-circle-filled')

const SupportIcon = LazySVG('icons/support-call')

const ArrowDownIcon = LazySVG('icons/arrow-long-down')

// ~~~~~~ Component

export const PagePurchaseMontageContentStep4Done = () => {
  // ~~~~~~ Hooks

  const { login } = useCustomAuth()

  const navigate = useNavigate()

  // ~~~~~~ State

  const sessionInfo = useSelector((state: StoreState) => state.checkoutSession)

  const currentUserEmail = useSelector((state: StoreState) => state.currentUser.email)

  // ~~~~~~ Computed

  const prevCurUserEmail = HooksData.PrevValue.useHook(currentUserEmail)

  // ~~~~~~ Handlers

  function onClickLogIn() {
    login()
  }

  function onClickAction() {
    const donwnloadLink =
      sessionInfo.platform === 'android'
        ? Config.Links.MontageDownloadAndroid
        : Config.Links.MontageDownloadWindows

    window.open(donwnloadLink)
  }

  function onClickShowInAction() {
    window.open(Config.Links.MontageInAction, '_blank')
  }

  function onClickGetConnected() {
    window.open(Config.Links.MontageConnectDevice, '_blank')
  }

  function onClickSupport() {
    window.open(Config.Links.MontageSupport, '_blank')
  }

  // ~~~~~~ Effects

  // - From not-logged to logged: Redirect to user-zone

  useEffect(() => {
    if (prevCurUserEmail || !currentUserEmail) return

    navigate(Routes.UserZone)

    //
  }, [currentUserEmail, navigate, prevCurUserEmail])

  // ~~~~~~ Render

  return (
    <STPagePurchaseMontageContentStep4Done>
      {/* Steps */}

      <div className={`${ClassName}--steps`}>
        <PagePurchaseCommonTabSteps kind="license" />
      </div>

      {/* Logo */}

      <div className={`${ClassName}--logo`}>
        <Logo size={32} />
      </div>

      {/* Title */}

      <div className={`${ClassName}--title`}>
        <Trans id="pages.purchase-flow.account-ready-step.ThanksPurchase" />

        <span className={`${ClassName}--title--icon`}>
          <TitleIcon size={56} />
        </span>
      </div>

      {/* Subtitle */}

      <div className={`${ClassName}--subtitle`}>
        <DangerTrans
          template="... on the way to <c>{value}</c>. Be sure to <d>check...</d> ... or <e>log in</e>..."
          id="v2.pages.purchase-flow.account-ready-step.SendLicense"
          values={{
            value: sessionInfo.email,

            c: (chunks: string) => (
              <span className={`${ClassName}--subtitle--acent`}>{chunks}</span>
            ),

            d: (chunks: string) => (
              <span className={`${ClassName}--subtitle--acent`}>{chunks}</span>
            ),

            e: (chunks: string) => (
              <span className={`${ClassName}--subtitle--login`} onClick={onClickLogIn}>
                {chunks}
              </span>
            ),
          }}
        />
      </div>

      {/* Action */}

      <div className={`${ClassName}--action`}>
        <ProductButtonMontage
          intlId="pages.purchase-flow.account-ready-step.DownloadMontage"
          IconRight={ArrowDownIcon}
          running={false}
          disabled={false}
          active={true}
          onClick={onClickAction}
        />
      </div>

      {/* Tip */}

      {sessionInfo.platform === 'windows' ? (
        <div className={`${ClassName}--tip`}>
          <DangerTrans
            template="... <c>Windows</c>..."
            id="pages.purchase-flow.account-ready-step.tip-product.Montage"
            values={{
              c: (chunks: string) => <b>{chunks}</b>,
            }}
          />
        </div>
      ) : undefined}

      {/* Separator */}

      <hr />

      {/* Links */}

      <div className={`${ClassName}--links`}>
        <div className={`${ClassName}--links--title`}>
          <Trans id="pages.purchase-flow.account-ready-step.OtherLinksUseFul" />
        </div>

        {/* Bcast in Action */}

        <div className={`${ClassName}--links--link`}>
          <LinkCard
            Icon={InActionIcon}
            intlId="pages.common.ProductInAction"
            intlValues={{ value: 'Montage' }}
            onClick={onClickShowInAction}
          />
        </div>

        {/* Get Connected */}

        <div className={`${ClassName}--links--link`}>
          <LinkCard
            Icon={ManageAccountIcon}
            intlId="pages.purchase-flow.account-ready-step.GetConnectOnDevice"
            onClick={onClickGetConnected}
          />
        </div>

        {/* Support Center */}

        <div className={`${ClassName}--links--link`}>
          <LinkCard
            Icon={SupportIcon}
            intlId="pages.purchase-flow.account-ready-step.CheckSupport"
            onClick={onClickSupport}
          />
        </div>
      </div>

      {/* - */}
    </STPagePurchaseMontageContentStep4Done>
  )
}
