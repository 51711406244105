import styled from 'styled-components'
import { theme } from '../../../../style/utils/theme'
import { Colors } from '../../../../style/theme/colors'
import { Fonts } from '../../../../style/theme/fonts'
import { MediaQuery } from '../../../../style/constants/media-queries'
import { Layout } from '../../../../style/theme/layout'

// ~~~~~~ Constants

export const ClassName = 'user-zone-billing-root'

// ~~~~~~ Component

export const STUserZoneBillingRoot = styled.div`
  margin-top: 32px;

  display: flex;
  flex-direction: column;

  /* Visible from tablet */

  hr.${ClassName}--separator {
    display: none;

    width: 100%;

    margin-top: 32px;
    margin-bottom: 32px;

    border: none;
    border-top: solid 1px ${theme(Colors.WOLF_10_10)};
  }

  .${ClassName}--top {
    display: flex;

    margin-bottom: 48px;

    /* Billing */

    .${ClassName}--top--billing {
      flex: 1;

      display: flex;
      flex-direction: column;

      .${ClassName}--top--billing--title {
        ${Fonts.Weight[800]}
        ${Fonts.Scale.Title}

        margin-bottom: 16px;
      }

      .${ClassName}--top--billing--line {
        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 8px;

        margin-bottom: 8px;

        .${ClassName}--top--billing--line--left {
          ${Fonts.Weight[300]}
          ${Fonts.Scale.BodyBig}
        }

        .${ClassName}--top--billing--line--right {
          ${Fonts.Weight[600]}
          ${Fonts.Scale.BodyBig}
        }
      }

      .${ClassName}--top--billing--action {
        position: relative;
        height: 30px;

        & > div:nth-of-type(1) {
          position: absolute;
          left: -10px;
        }
      }
    }

    /* Payments (cards) */

    .${ClassName}--top--payment {
      flex: 1;

      padding-left: 40px;

      .${ClassName}--top--payment--title {
        ${Fonts.Weight[800]}
        ${Fonts.Scale.Title}

        margin-bottom: 16px;
      }

      .${ClassName}--top--payment--cards {
        margin-bottom: 8px; // 16 this + last card

        .${ClassName}--top--payment--cards--card {
          margin-bottom: 8px;
        }
      }
    }
  }

  /* Invoices */

  .${ClassName}--invoices {
    padding-bottom: 72px;

    .${ClassName}--invoices--title {
      ${Fonts.Weight[800]}
      ${Fonts.Scale.Title}

      margin-bottom: 16px;
    }

    .${ClassName}--invoices--list {
      .${ClassName}--invoices--list--item {
        ${Fonts.Weight[600]}
        ${Fonts.Scale.BodyBig}

        white-space: nowrap;

        display: grid;
        grid-template-columns: repeat(2, 1fr);
        grid-column-gap: 8px;

        padding: 12px;

        border-radius: 6px;

        &:nth-child(odd) {
          background-color: ${theme(Colors.WOLF_05_05)};
        }

        .${ClassName}--invoices--list--item--a {
          display: grid;
          grid-template-columns: repeat(2, 1fr);

          .${ClassName}--invoices--list--item--date {
            display: flex;
            align-items: center;
          }

          .${ClassName}--invoices--list--item--product {
            display: flex;
            align-items: center;

            .${ClassName}--invoices--list--item--product--interval {
              ${Fonts.Weight[300]}
            }
          }
        }

        .${ClassName}--invoices--list--item--b {
          display: grid;
          grid-template-columns: repeat(2, 1fr);

          .${ClassName}--invoices--list--item--amount {
            display: flex;
            align-items: center;
          }

          .${ClassName}--invoices--list--item--download {
            display: flex;
            align-items: center;

            justify-content: right;
          }
        }
      }
    }
  }

  /* 1280 */

  @media (max-width: ${MediaQuery.MaxWidth2k}) {
    .${ClassName}--top {
      ${Layout.PaddingWidth.Desktop}

      .${ClassName}--top--billing {
        .${ClassName}--top--billing--title {
          ${Fonts.Weight[700]}
          ${Fonts.Scale.OldD}
        }
      }

      .${ClassName}--top--payment {
        .${ClassName}--top--payment--title {
          ${Fonts.Weight[700]}
          ${Fonts.Scale.OldD}
        }
      }
    }

    .${ClassName}--invoices {
      ${Layout.PaddingWidth.Desktop}

      .${ClassName}--invoices--title {
        ${Fonts.Weight[700]}
        ${Fonts.Scale.OldD}
      }
    }
  }

  /* 960 */

  @media (max-width: ${MediaQuery.MD}) {
    hr.${ClassName}--separator {
      display: unset;
    }

    .${ClassName}--top {
      ${Layout.PaddingWidth.Tablet}

      flex-direction: column;

      margin-bottom: unset;

      .${ClassName}--top--payment {
        padding-left: unset;
      }
    }

    .${ClassName}--invoices {
      ${Layout.PaddingWidth.Tablet}

      .${ClassName}--invoices--list {
        .${ClassName}--invoices--list--item {
          ${Fonts.Scale.Body}
        }
      }
    }
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    .${ClassName}--top {
      ${Layout.PaddingWidth.Mobile}

      .${ClassName}--top--billing {
        .${ClassName}--top--billing--title {
          ${Fonts.Scale.Subtitle}
        }

        .${ClassName}--top--billing--line {
          display: flex;
          flex-direction: column;

          margin-bottom: 16px;
        }
      }

      .${ClassName}--top--payment {
        .${ClassName}--top--payment--title {
          ${Fonts.Scale.Subtitle}
        }

        .${ClassName}--top--payment--cards {
          margin-bottom: 16px;
        }
      }
    }

    .${ClassName}--invoices {
      ${Layout.PaddingWidth.Mobile}

      .${ClassName}--invoices--title {
        ${Fonts.Scale.Subtitle}
      }

      .${ClassName}--invoices--list {
        .${ClassName}--invoices--list--item {
          display: flex;
          flex-direction: column;

          .${ClassName}--invoices--list--item--a {
            justify-content: space-between;
          }

          .${ClassName}--invoices--list--item--b {
            justify-content: space-between;
          }
        }
      }
    }
  }
`
