const initModel: DN.Models.LauncherReleaseInfo.ModelFront = {
  uiStatus: 'init',
  errors: [],
  files: [],
  version: null,
}

export const LauncherReleaseInfoModel = {
  initModel,
}
