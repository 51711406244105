import styled from 'styled-components'

import { Transition } from '../../../style/animations/css-animations'
import { Colors } from '../../../style/theme/colors'
import { Fonts } from '../../../style/theme/fonts'
import { Misc } from '../../../style/theme/misc'
import { getThemeValue, theme } from '../../../style/utils/theme'

// ~~~~~~ Types

export type STProps = {
  $disabled: boolean
  $focussed?: boolean
  $hasErrors?: boolean
}

// ~~~~~~ Component

export const STIntlDigitCodeInput = styled.div<STProps>`
  @keyframes blinker {
    to {
      visibility: hidden;
    }
  }

  .label {
    ${Fonts.Scale.OldD};
    ${Fonts.Weight[700]}

    color: ${theme(Colors.WOLF_100_100)};

    padding-bottom: 8px;
  }

  .wrap {
    ${Transition.BorderColorFaster};

    ${(props) => Misc.Border(props)}

    box-sizing: border-box;

    border-radius: 6px;

    width: 100%;

    display: flex;

    position: relative;

    background-color: ${({ $hasErrors, ...restProps }) =>
      getThemeValue(restProps, $hasErrors ? Colors.BROOKLYN_05_05 : Colors.WOLF_05_05)};

    cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'text')};

    &:hover {
      border-color: ${theme(Colors.WOLF_90_90)};
    }

    .display {
      width: 100%;
      height: 56px;

      display: flex;
      align-items: center;
      justify-content: center;

      position: relative;

      ${Fonts.Scale.OldD}

      &:last-child {
        border-right: none;
      }

      .fake-cursor,
      .fake-cursor-end {
        animation: blinker 1s steps(2, start) infinite;

        background-color: black;

        width: 1px;
        height: 80%;
      }

      .fake-cursor-end {
        position: absolute;
        right: 8px;
      }
    }

    .input {
      caret-color: transparent;

      position: absolute;

      border: none;

      ${Fonts.Scale.Headline2};
      text-align: center;

      width: 100%;

      background-color: transparent;

      outline: none;

      top: 0;
      bottom: 0;

      cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'text')};
    }

    .shadows {
      position: absolute;

      left: 0;
      top: 0;
      bottom: 0;
      right: 0;
    }
  }
`
