const prefix = 'mt-store-billing-update-epic'

// ~~~~~~ Mutators Types

export const EpicApiStoreBillingUpdateMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const EpicApiStoreBillingUpdateMC = {
  ok: (data: DN.Models.BillingInfo.Api.Update.ResParsed) => ({
    type: EpicApiStoreBillingUpdateMT.OK,
    payload: data,
  }),

  error: (data: DN.Models.BillingInfo.Api.Update.ResError) => ({
    type: EpicApiStoreBillingUpdateMT.ERROR,
    error: true,
    payload: {
      errors: data,
    },
  }),
} as const
