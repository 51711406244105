const prefix = 'at-api-store-card'

// ~~~~~~ Action Types

export const ApiStoreCardAT = {
  SHOW: `${prefix}-show`,

  ADD: `${prefix}-add`,
  REMOVE: `${prefix}-rem`,
  CHANGE: `${prefix}-change`,
} as const

// ~~~~~~ Action Mutators

export const ApiStoreCardAC = {
  add: (paymentId: string, setDefault: boolean = false) => ({
    type: ApiStoreCardAT.ADD,
    payload: {
      paymentId,
      setDefault,
    },
  }),

  remove: (cardId: string) => ({
    type: ApiStoreCardAT.REMOVE,
    payload: {
      cardId,
    },
  }),

  change: (cardId: string, setupIntentId: string, setDefault = false) => ({
    type: ApiStoreCardAT.CHANGE,
    payload: {
      cardId,
      setupIntentId,
      setDefault,
    },
  }),
} as const
