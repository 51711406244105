import styled from 'styled-components'
import { Fonts } from '../../../style/theme/fonts'
import { theme } from '../../../style/utils/theme'
import { Colors } from '../../../style/theme/colors'
import { Layout } from '../../../style/theme/layout'

// ~~~~~~ Component

export const STBadgeText = styled.div`
  ${Fonts.Weight[400]}
  ${Fonts.Scale.Body}

  color: ${theme(Colors.BROOKLYN_70_70)};
  background-color: ${theme(Colors.BROOKLYN_10_10)};

  ${Layout.Border.Radius.A}

  padding: 2px 4px;
`
