import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useLocation, useNavigate } from 'react-router'
import { HooksData } from '@dn/hooks'

import { StoreState } from '../../models/app/model'
import { RoutesOld } from '../../constants/routes-old'

// ~~~~~~ Component

export const useRedirectUser = () => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  const location = useLocation()

  // ~~~~~~ State

  const { token, errors } = useSelector((store: StoreState) => store.currentUser)

  const oldToken = HooksData.PrevValue.useHook(token)

  // ~~~~~~ Effects

  // - Redirect logged user to private zone if logged

  useEffect(() => {
    if (oldToken || !token) return

    const inHomePage = location.pathname === RoutesOld.Main

    inHomePage && navigate(RoutesOld.UserZone)
  }, [token, navigate, oldToken, location.pathname])

  // - Return back user to home page if currentUser api has errors

  useEffect(() => {
    if (!errors.some((infos) => infos.id === 'api.current-user.show.Error')) return

    const inUserZone = !!~location.pathname.search(RoutesOld.UserZone)

    inUserZone && navigate(RoutesOld.Main)
  }, [errors, navigate, location.pathname])
}
