import styled from 'styled-components'
import { Layout } from '../../../../../style/theme/layout'

// ~~~~~~ Constants

export const ClassName = 'user-zone-plans-detail-montage'

// ~~~~~~ Component

export const STUserZonePlansDetailMontage = styled.div`
  ${Layout.MarginMaxWidth.MiddleContent}
`
