import { AppInitState } from '../../../models/app/model'
import { CurrentUserFieldsMR } from '../../../models/current-user/model'
import { dynReducer } from '../../../utils/store'
import { AuthMT } from '../../actions-mutators/auth/mutators'
import {
  ApiStoreCurrentUserAC,
  ApiStoreCurrentUserAT,
} from '../../actions/api-store/current-user/actions'
import {
  EpicApiStoreCurrentUserShowMC,
  EpicApiStoreCurrentUserShowMT,
} from '../../epics/api-store/current-user/show/mutators'

const initState = AppInitState.currentUser

type State = typeof initState

const reducer: any = {
  ...CurrentUserFieldsMR.dateFormat.Reducer,
}

// ####################################################################################################
// ~~~~~~ Get token - START
// ####################################################################################################

reducer[AuthMT.GET_TOKEN_START] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'running',
  }
}

// ####################################################################################################
// ~~~~~~ Get token - OK
// ####################################################################################################

// Not exists because if ok we trigger current user Show

// ####################################################################################################
// ~~~~~~ Get token - ERROR
// ####################################################################################################

reducer[AuthMT.GET_TOKEN_ERROR] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'init',
    isSessionChecked: true,
  }
}

// ####################################################################################################
// ~~~~~~ Set session checked
// ####################################################################################################

reducer[AuthMT.SET_SESSION_CHECKED] = (state: State): State => {
  return {
    ...state,
    isSessionChecked: true,
  }
}

// ####################################################################################################
// ~~~~~~ Show - START (call to show)
// ####################################################################################################

reducer[ApiStoreCurrentUserAT.SHOW] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreCurrentUserAC.show>,
): State => {
  return {
    ...state,
    uiStatus: 'running',
  }
}

// ####################################################################################################
// ~~~~~~ Show - OK
// ####################################################################################################

reducer[EpicApiStoreCurrentUserShowMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiStoreCurrentUserShowMC.ok>,
): State => {
  return {
    ...state,
    ...payload.user,
    uiStatus: 'completed',
    isSessionChecked: true,
  }
}

// ####################################################################################################
// ~~~~~~ Show - ERROR
// ####################################################################################################

reducer[EpicApiStoreCurrentUserShowMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiStoreCurrentUserShowMC.error>,
): State => {
  return {
    ...state,
    uiStatus: 'init',
    isSessionChecked: true,
    errors: payload.errors,
  }
}

// ~~~~~~

export const currentUserReducer = dynReducer(initState, reducer)
