import { LazySVG } from '../../svgs/lazy-svg/component'
import { Trans } from '../intl/trans'
import { ClassName, STLinkCard } from './style'

// ~~~~~~ Constants

const Arrow = LazySVG('icons/arrow-long-right')

// ~~~~~~ Types

type Props = {
  Icon: ReturnType<typeof LazySVG>
  intlId: IntlMsgId
  intlValues?: { [key: string]: string | number }

  onClick: () => void
}

// ~~~~~~ Component

export const LinkCard: React.FC<Props> = ({ Icon, intlId, intlValues, onClick }) => {
  // ~~~~~~ Render

  return (
    <STLinkCard onClick={onClick}>
      {/* Icon */}

      <div className={`${ClassName}--icon`}>
        <Icon size={18} />
      </div>

      {/* Text */}

      <div className={`${ClassName}--text`}>
        <Trans id={intlId} values={intlValues} />
      </div>

      {/* Arrow */}

      <div className={`${ClassName}--arrow`}>
        <Arrow size={18} />
      </div>
    </STLinkCard>
  )
}
