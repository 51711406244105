import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useSelector, useDispatch } from 'react-redux'

import { ClassName, STUserZoneBillingInfoEdit } from './style'
import { Routes } from '../../../../../constants/routes'
import { Button } from '../../../../../components/common/button/component'
import { Trans } from '../../../../../components/common/intl/trans'
import { StoreState } from '../../../../../models/app/model'
import { BillingInfoFieldMR, BillingInfoModel } from '../../../../../models/billing-info/model'
import { IntlInput } from '../../../../../components/common/intl-input/component'
import { useValidator } from '../../../../../hooks/validators/use-validator'
import { ApiStoreBillingAC } from '../../../../../store/actions/api-store/billing/actions'
import { CountrySelector } from '../../../../../components/common/country-selector/component'
import { PaymentButton } from '../../../../../components/common/gradient-button/component'
import { BackLink } from '../../../../../components/common/links/back-link/component'
import { HooksData } from '@dn/hooks'

// ~~~~~~ Component

export const UserZoneBillingInfoEdit = () => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const validateContactName = useValidator(
    BillingInfoModel.validations.contactName,
    BillingInfoFieldMR.contactName,
  )

  const validateCompany = useValidator(
    BillingInfoModel.validations.company,
    BillingInfoFieldMR.company,
  )

  const validatePhone = useValidator(BillingInfoModel.validations.phone, BillingInfoFieldMR.phone)

  const validateAddress = useValidator(
    BillingInfoModel.validations.address,
    BillingInfoFieldMR.address,
  )

  const validateStateRegion = useValidator(
    BillingInfoModel.validations.stateRegion,
    BillingInfoFieldMR.stateRegion,
  )

  const validateCity = useValidator(BillingInfoModel.validations.city, BillingInfoFieldMR.city)

  const validateCpZip = useValidator(BillingInfoModel.validations.cpZip, BillingInfoFieldMR.cpZip)

  // ~~~~~~ State

  // - Local

  const [isDirty, setIsDirty] = useState(false)

  const [isModelInit, setIsModelInit] = useState(false)

  // - Store

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  const billingInfo = useSelector((state: StoreState) => state.billingInfoShow)

  const billingEdit = useSelector((state: StoreState) => state.billingInfoEdit)

  // ~~~~~~ Computed

  const isLoading = currentUser.uiStatus === 'running'

  const isRunning = isLoading || billingEdit.uiStatus === 'running'

  const prevBillingEditUiStatus = HooksData.PrevValue.useHook(billingEdit.uiStatus)

  // ~~~~~~ Handlers

  function onClickGoBack() {
    navigate(Routes.UserZoneBilling)
  }

  function onChContactName(value: string) {
    isDirty && validateContactName(value)

    dispatch(BillingInfoFieldMR.contactName.MC.change(value))
  }

  function onChCompany(value: string) {
    isDirty && validateCompany(value)

    dispatch(BillingInfoFieldMR.company.MC.change(value))
  }

  function onChPhone(value: string) {
    isDirty && validatePhone(value)

    dispatch(BillingInfoFieldMR.phone.MC.change(value))
  }

  function onChAddress(value: string) {
    isDirty && validateAddress(value)

    dispatch(BillingInfoFieldMR.address.MC.change(value))
  }

  function onChCountry(value: string) {
    dispatch(BillingInfoFieldMR.country.MC.change(value))
  }

  function onChCpZip(value: string) {
    isDirty && validateCpZip(value)

    dispatch(BillingInfoFieldMR.cpZip.MC.change(value))
  }

  function onChStateRegion(value: string) {
    isDirty && validateStateRegion(value)

    dispatch(BillingInfoFieldMR.stateRegion.MC.change(value))
  }

  function onChCity(value: string) {
    isDirty && validateCity(value)

    dispatch(BillingInfoFieldMR.city.MC.change(value))
  }

  function onClickSubmit() {
    setIsDirty(true)

    // Validate

    const errors = [
      validateContactName(billingEdit.contact_name.trim()).length,
      validateCompany(billingEdit.company.trim()).length,
      validatePhone(billingEdit.phone.trim()).length,
      validateAddress(billingEdit.address.trim()).length,
      validateCpZip(billingEdit.cp_zip.trim()).length,
      validateCity(billingEdit.city.trim()).length,
      validateStateRegion(billingEdit.state_region.trim()).length,
    ]

    if (errors.find(Boolean)) return

    setIsDirty(false)

    dispatch(
      ApiStoreBillingAC.update({
        contact_name: billingEdit.contact_name.trim(),
        company: billingEdit.company.trim(),
        phone: billingEdit.phone.trim(),
        address: billingEdit.address.trim(),
        country: billingEdit.country.trim(),
        cp_zip: billingEdit.cp_zip.trim(),
        state_region: billingEdit.state_region.trim(),
        city: billingEdit.city.trim(),
      }),
    )
  }

  // ~~~~~~ Effects

  // - On mount set data

  useEffect(() => {
    if (isModelInit || !currentUser.email) return

    setIsModelInit(true)

    dispatch(BillingInfoFieldMR.__model__.MC.completeClear())

    dispatch(BillingInfoFieldMR.__model__.MC.setModel(billingInfo))

    //
  }, [billingInfo, currentUser.email, dispatch, isModelInit])

  // - On success update redirect

  useEffect(() => {
    if (
      billingEdit.errors.length ||
      prevBillingEditUiStatus !== 'running' ||
      billingEdit.uiStatus !== 'init'
    ) {
      return
    }

    navigate(Routes.UserZoneBilling)

    //
  }, [billingEdit.errors.length, billingEdit.uiStatus, navigate, prevBillingEditUiStatus])

  // ~~~~~~ Render

  if (isLoading) return null

  return (
    <STUserZoneBillingInfoEdit>
      <div className={`${ClassName}--content`}>
        {/* <- Go Back */}

        <BackLink
          className={`${ClassName}--content--go-back`}
          intlId="v2.pages.edit-payment.BackToBilling"
          onClick={onClickGoBack}
        />

        {/* Title */}

        <div className={`${ClassName}--content--title`}>
          <Trans id="v2.pages.billing.ChangeBillingDetails" />
        </div>

        <div className={`${ClassName}--content--form`}>
          {/* Contact Section */}

          <div className={`${ClassName}--content--form--section`}>
            <Trans id="v2.pages.billing.BillingContact" />
          </div>

          {/* - Contact name */}

          <div className={`${ClassName}--content--form--line`}>
            <IntlInput
              label="v2.pages.billing.billing-info.ContactName"
              value={billingEdit.contact_name}
              $disabled={isLoading}
              errors={billingEdit.contact_name_err}
              onChange={onChContactName}
            />
          </div>

          {/* Address section */}

          <div className={`${ClassName}--content--form--section`}>
            <Trans id="v2.pages.billing.BillingAddress" />
          </div>

          {/* - Company */}

          <div className={`${ClassName}--content--form--line`}>
            <IntlInput
              label="v2.pages.billing.billing-info.Company"
              value={billingEdit.company}
              $disabled={isLoading}
              errors={billingEdit.company_err}
              onChange={onChCompany}
            />
          </div>

          {/* - Phone */}

          <div className={`${ClassName}--content--form--line`}>
            <IntlInput
              label="v2.pages.billing.billing-info.Phone"
              value={billingEdit.phone}
              $disabled={isLoading}
              errors={billingEdit.phone_err}
              onChange={onChPhone}
            />
          </div>

          {/* - Address */}

          <div className={`${ClassName}--content--form--line`}>
            <IntlInput
              label="pages.user-zone.payments.billing-info.Address"
              value={billingEdit.address}
              $disabled={isLoading}
              errors={billingEdit.address_err}
              onChange={onChAddress}
            />
          </div>

          {/* - Tuple: City State/Region */}

          <div className={`${ClassName}--content--form--tuple`}>
            {/* -- City */}

            <div className={`${ClassName}--content--form--tuple--item`}>
              <IntlInput
                label="v2.pages.billing.billing-info.City"
                value={billingEdit.city}
                $disabled={isLoading}
                errors={billingEdit.city_err}
                onChange={onChCity}
              />
            </div>

            {/* Space */}

            <div className={`${ClassName}--content--form--tuple--space`} />

            {/* -- State/Region */}

            <div className={`${ClassName}--content--form--tuple--item`}>
              <IntlInput
                label="v2.pages.billing.billing-info.State"
                value={billingEdit.state_region}
                $disabled={isLoading}
                errors={billingEdit.state_region_err}
                onChange={onChStateRegion}
              />
            </div>
          </div>

          {/* - Tuple: Country PC/ZIP */}

          <div className={`${ClassName}--content--form--tuple`}>
            {/* -- Country */}

            <div className={`${ClassName}--content--form--tuple--item`}>
              <CountrySelector
                label="pages.user-zone.payments.billing-info.Country"
                selected={billingEdit.country}
                disabled={isLoading}
                onChange={onChCountry}
              />
            </div>

            {/* Space */}

            <div className={`${ClassName}--content--form--tuple--space`} />

            {/* -- pc/zip */}

            <div className={`${ClassName}--content--form--tuple--item`}>
              <IntlInput
                label="v2.pages.billing.billing-info.CpZip"
                value={billingEdit.cp_zip}
                $disabled={isLoading}
                errors={billingEdit.cp_zip_err}
                onChange={onChCpZip}
              />
            </div>
          </div>
        </div>

        {/* Actions */}

        <div className={`${ClassName}--content--actions`}>
          {/* Update */}

          <PaymentButton
            className={`${ClassName}--content--actions--action first`}
            intlId="v2.pages.billing.billing-details.SaveChanges"
            running={isRunning}
            disabled={isRunning}
            active={!isRunning}
            onClick={onClickSubmit}
          />

          {/* Cancel */}

          <Button
            className={`${ClassName}--content--actions--action`}
            colorType="default"
            intlId="common.Cancel"
            running={false}
            disabled={false}
            active={!isLoading}
            onClick={onClickGoBack}
          />
        </div>

        {/* - */}
      </div>
    </STUserZoneBillingInfoEdit>
  )
}
