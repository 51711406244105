import { ofType, StateObservable } from 'redux-observable'
import { Observable } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'

import { StoreState } from '../../../../../models/app/model'
import { apiStoreCheckoutValidateEmailCode$ } from '../../../../../services/api-store/checkout/validate-email-code'

import {
  ApiStoreCheckoutAC,
  ApiStoreCheckoutAT,
} from '../../../../actions/api-store/checkout/actions'

import { ApiUtils } from '../../../utils'
import { ApiStoreCheckoutValidateEmailCodeEpicMC } from './mutators'

type Action = ReturnType<typeof ApiStoreCheckoutAC.validateEmailCode>

export const apiStoreCheckoutValidateEmailCodeEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiStoreCheckoutAT.VALIDATE_EMAIL_CODE),
    mergeMap(({ payload }) => apiStoreCheckoutValidateEmailCode$(payload)),
    map((res) =>
      ApiUtils.isNotError(res)
        ? ApiStoreCheckoutValidateEmailCodeEpicMC.ok(res.response)
        : ApiStoreCheckoutValidateEmailCodeEpicMC.error(res.response),
    ),
  )
