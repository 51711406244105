import styled from 'styled-components'
import { Colors } from '../../../style/theme/colors'
import { Fonts } from '../../../style/theme/fonts'
import { Misc } from '../../../style/theme/misc'
import { theme } from '../../../style/utils/theme'
import { MediaQuery } from '../../../style/constants/media-queries'

// ~~~~~~ Types

export type STProps = {
  $height?: number
  $disabled: boolean
  $hasErrors?: undefined
}

// ~~~~~~ Component

export const STCountrySelector = styled.div<STProps>`
  cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'default')};

  .label {
    ${Fonts.Weight[700]}
    ${Fonts.Scale.OldD};

    padding-bottom: 4px;

    color: ${theme(Colors.WOLF_100_100)};
  }

  .country-select {
    width: 100%;
    position: relative;

    select {
      cursor: ${({ $disabled }) => ($disabled ? 'not-allowed' : 'pointer')};

      height: 56px;
      width: 100%;

      ${Misc.BorderRadius}

      padding:  12px;

      background-color: ${theme(Colors.WOLF_05_05)};
      color: ${theme(Colors.WOLF_100_100)};

      border: solid 2px ${theme(Colors.WOLF_40_40)};

      appearance: none;

      option {
        color: ${theme(Colors.WOLF_100_100)};
        background-color: ${theme(Colors.WOLF_00_00)};
      }
    }

    .icon {
      pointer-events: none;

      position: absolute;

      top: 20px;
      right: 20px;

      width: 16px;
      height: 16px;

      svg path {
        fill: ${theme(Colors.WOLF_100_100)};
      }
    }
  }

  /* 1280 */

  @media (max-width: ${MediaQuery.MaxWidth2k}) {
    /* Label */

    .label {
      ${Fonts.Scale.Subtitle}
    }
  }

  /* 960 */

  @media (max-width: ${MediaQuery.MD}) {
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    /* Label */

    .label {
      ${Fonts.Scale.BodyBig}
    }
  }
`
