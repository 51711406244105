import { ClassName, STNeedHelp } from './style'
import { Trans } from '../../../../components/common/intl/trans'
import { Config } from '../../../../config'

// ~~~~~~ Types

type Props = {
  className?: string
}

// ~~~~~~ Component

export const NeedHelp: React.FC<Props> = ({ className }) => {
  // ~~~~~~ Render

  return (
    <STNeedHelp className={className}>
      <span className={`${ClassName}--text`}>
        <Trans id="pages.common.NeedHelp" />
      </span>
      <span> </span>
      <span>
        <a href={`mailto: ${Config.Emails.Orders}`}>{Config.Emails.Orders}</a>
      </span>
    </STNeedHelp>
  )
}
