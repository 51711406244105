import { ProductButtonBcast } from '../../../../../components/common/product-button/bcast/component'
import { LazySVG } from '../../../../../components/svgs/lazy-svg/component'
import { PageCommonOPStep1CreateAccount } from '../../../../@-common/obtaining-products/step-1-create-account/component'
import { PagePurchaseCommonTabSteps } from '../../../@-common/tab-steps/component'

// ~~~~~~ Constants

const Logo = LazySVG('logos/broadcast-gradient')

// ~~~~~~ Component

export const PagePurchaseBcastContentStep1CreateAccount = () => {
  // ~~~~~~ Render

  return (
    <PageCommonOPStep1CreateAccount
      Logo={Logo}
      ProductButton={ProductButtonBcast}
      tabSteps={<PagePurchaseCommonTabSteps kind="subscription" />}
    />
  )
}
