import { useNavigate } from 'react-router'
import { useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { UtilsEncDec } from '@dn/utils'

import {
  RouteDataAddPaymentMethod,
  RouteDataBcastUpg2Premium,
  RouteDataBcastPurchaseConfirm,
  Routes,
} from '../../../../../../constants/routes'
import { ClassName, STUserZonePlansPurchaseBcastUpg2Premium } from './style'
import { UserZoneCommonBannerBack } from '../../../../@-common/banner-back/component'
import { Trans } from '../../../../../../components/common/intl/trans'
import { Dollars } from '../../../../../../components/common/dollars/component'
import { StoreState } from '../../../../../../models/app/model'
import { ProductButtonBcast } from '../../../../../../components/common/product-button/bcast/component'
import { Button } from '../../../../../../components/common/button/component'
import { LazySVG } from '../../../../../../components/svgs/lazy-svg/component'
import { useTrans } from '../../../../../../hooks/use-trans'
import { useUserZoneDecDataParam } from '../../../../../../hooks/user-zone-dec-data-param/hook'

// ~~~~~~ Constants

const CheckedIcon = LazySVG('icons/check-full')

// ~~~~~~ Component

export const UserZonePlansPurchaseBcastUpg2Premium = () => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  const data = useUserZoneDecDataParam<RouteDataBcastUpg2Premium>()

  const nextStepBackText = useTrans('v2.pages.upgrade-plan.payment-details.UpgradeOptions', {
    value: 'Premium',
  })

  // ~~~~~~ State

  // - Local

  const [subInterval, setSubInterval] = useState<'monthly' | 'yearly'>('monthly')

  // - Store

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  const subs = useSelector((state: StoreState) => state.subscriptions)

  const metaProducts = useSelector((state: StoreState) => state.broadcastSub)

  const cards = useSelector((state: StoreState) => state.cards)

  // ~~~~~~ Computed

  const isRunning =
    !currentUser.isSessionChecked ||
    currentUser.uiStatus === 'running' ||
    metaProducts.uiStatus === 'running' ||
    metaProducts.uiStatus === 'init'

  const sub = data ? subs.find((sub) => sub.subscription_id === data.subId) : undefined

  const monthPrice = metaProducts.products.premium.month.price

  const yearPrice = metaProducts.products.premium.year.price

  // ~~~~~~ Handlers

  function onClickGoBack() {
    if (!data) return

    navigate(data.backPath)
  }

  function onClickContinuePayment() {
    if (!data || !sub) return

    const defaultCard = cards.list.find((card) => card.is_default)

    const card = cards.list.find((card) => card.payment_method_id === sub.payment_method_id)

    // A.SUCESS has to go to B
    // So prepare B route to add it as success route of A

    const nextDataObj: RouteDataBcastPurchaseConfirm = {
      backPath: window.location.pathname,
      backText: nextStepBackText,
      interval: subInterval,
      subId: data.subId,
      subIdReq: true,
    }

    const nextData = UtilsEncDec.base64UrlEncode(JSON.stringify(nextDataObj))

    const paymentConfirmRoute = Routes.genUserZonePlansBcastPurchaseConfirm(nextData)

    // A) Sub don't have an associated card
    //
    //    NOTE: Backend restriction - we can have cards-not-default
    //          and we cannot call the api with one of these cards
    //          we have first to "set payment method", also design is
    //
    //    Show "Add Payment"

    if (!card && !defaultCard) {
      const nextDataObj: RouteDataAddPaymentMethod = {
        backPath: window.location.pathname,
        backText: nextStepBackText,
        successPath: paymentConfirmRoute,
      }

      const nextData = UtilsEncDec.base64UrlEncode(JSON.stringify(nextDataObj))

      const addPaymentRoute = Routes.genUserZonePlansAddPaymentMethod(nextData)

      navigate(addPaymentRoute)

      return
    }

    // B) Cards exists. Show "Payment Details"

    navigate(paymentConfirmRoute)
  }

  // ~~~~~~ Effects

  // - If no data, go to user-zone plans

  useEffect(() => {
    if (data) return

    navigate(Routes.UserZonePlans)

    //
  }, [data, navigate])

  // - If sub doesn't exist, go to user-zone plans

  useEffect(() => {
    if (sub || isRunning) return

    navigate(Routes.UserZonePlans)

    //
  }, [isRunning, navigate, sub])

  // ~~~~~~ Render

  if (!data || !sub || isRunning) return null

  // ~~~~~~ Render

  return (
    <STUserZonePlansPurchaseBcastUpg2Premium>
      {/* Banner back */}

      <UserZoneCommonBannerBack
        noImage
        kind="bcast"
        backIntl={data.backIntl}
        onClickGoBack={onClickGoBack}
      />

      {/* Content */}

      <div className={`${ClassName}--content`}>
        {/* Title */}

        <div className={`${ClassName}--content--title`}>
          <Trans id="pages.common.UpgradePremium" values={{ value: 'Premium' }} />
        </div>

        {/* Subtitle */}

        <div className={`${ClassName}--content--subtitle`}>
          <Trans id="v2.pages.upgrade-plan.upgrade-premium.UpgradePremiumDescription" />
        </div>

        {/* Title for interval */}

        <div className={`${ClassName}--content--intervals-title`}>
          <Trans id="v2.pages.upgrade-plan.upgrade-premium.ChooseBillingCycle" />
        </div>

        {/* Monthly */}

        <div
          onClick={() => setSubInterval('monthly')}
          className={`${ClassName}--content--interval ${
            subInterval === 'monthly' ? 'selected' : ''
          }`}
        >
          <div className={`${ClassName}--content--interval--a`}>
            {/* Select check */}

            <div className={`${ClassName}--content--interval--a--check`}>
              <CheckedIcon size={28} />

              <div className={`${ClassName}--content--interval--a--check--empty`} />
            </div>

            {/* Interval + Price */}

            <div className={`${ClassName}--content--interval--a--info`}>
              <div className={`${ClassName}--content--interval--a--info--interval`}>
                <Trans id="pages.common.Monthly" />
              </div>

              <div className={`${ClassName}--content--interval--a--info--price`}>
                <Dollars value={monthPrice} />
              </div>
            </div>
          </div>
        </div>

        {/* Yearly */}

        <div
          onClick={() => setSubInterval('yearly')}
          className={`${ClassName}--content--interval last ${
            subInterval === 'yearly' ? 'selected' : ''
          }`}
        >
          <div className={`${ClassName}--content--interval--a`}>
            {/* Select check */}

            <div className={`${ClassName}--content--interval--a--check`}>
              <CheckedIcon size={28} />

              <div className={`${ClassName}--content--interval--a--check--empty`} />
            </div>

            {/* Interval + Price */}

            <div className={`${ClassName}--content--interval--a--info`}>
              <div className={`${ClassName}--content--interval--a--info--interval`}>
                <Trans id="pages.common.Yearly" />
              </div>

              <div className={`${ClassName}--content--interval--a--info--price`}>
                <Dollars value={yearPrice} />
              </div>
            </div>
          </div>

          {/* Save 20% */}

          <div className={`${ClassName}--content--interval--save`}>
            <div className={`${ClassName}--content--interval--save--value`}>
              <Trans id="pages.products.broadcast.SaveTwentyPercent" />
            </div>
          </div>
        </div>

        {/* Actions */}

        <div className={`${ClassName}--content--actions`}>
          {/* Continue to payment */}

          <ProductButtonBcast
            className={`${ClassName}--content--actions--action first`}
            intlId="pages.purchase-flow.common.ProceedToPayment"
            running={false}
            disabled={false}
            active={true}
            onClick={onClickContinuePayment}
          />

          {/* Cancel */}

          <Button
            className={`${ClassName}--content--actions--action`}
            colorType="default"
            intlId="common.Cancel"
            running={false}
            disabled={false}
            active={true}
            onClick={onClickGoBack}
          />
        </div>
      </div>

      {/* - */}
    </STUserZonePlansPurchaseBcastUpg2Premium>
  )
}
