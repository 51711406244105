import { Link } from 'react-router-dom'
import { Routes } from '../../../../constants/routes'
import { ClassName, STPageCommonTopLeft } from './style'
import { LazySVG } from '../../../../components/svgs/lazy-svg/component'

// ~~~~~~ Constants

const DisplayNoteStoreLogo = LazySVG('logos/logo-display-note-store')

// ~~~~~~ Component

export const PageCommonTopLeft = () => {
  // ~~~~~~ Render

  return (
    <STPageCommonTopLeft>
      <Link to={Routes.Root} className={`${ClassName}--link`}>
        <DisplayNoteStoreLogo size={24} className={`${ClassName}--link--logo`} />
      </Link>
    </STPageCommonTopLeft>
  )
}
