import styled from 'styled-components'

import { MediaQuery } from '../../../style/constants/media-queries'
import { Colors } from '../../../style/theme/colors'
import { ColorsUtils } from '../../../style/theme/colors-utils'
import { Fonts } from '../../../style/theme/fonts'
import { getThemeValue } from '../../../style/utils/theme'

// ~~~~~~ Types

export type STProps = {
  $isDefaultCardModel?: true
}

// ~~~~~~ Component

export const STDialogConfirmation = styled.div<STProps>`
  .confirm-button {
    margin-right: 16px;

    ${(props) =>
      props.$isDefaultCardModel
        ? `background-image: linear-gradient(
      97.45deg,
      ${getThemeValue(props, ColorsUtils.getGradientColorType('base')['Payment'][0])} 0%,
      ${getThemeValue(props, ColorsUtils.getGradientColorType('base')['Payment'][1])} 96.89%
    );`
        : ''}

    ${(props) =>
      props.$isDefaultCardModel ? `color: ${getThemeValue(props, Colors.WOLF_90_90)};` : ''}
  }

  .box-content {
    ${Fonts.Scale.BodyBig}
    ${Fonts.Weight[400]}

    .question-style {
      margin-bottom: 12px;
    }

    .highlighted-text {
      ${Fonts.Weight[600]}
    }

    .content-text-description {
      margin: 6px 0;
    }
  }

  @media (max-width: ${MediaQuery.MaxWidthLand}) {
    .box-content {
      ${Fonts.Weight[300]}
    }
  }

  @media (max-width: ${MediaQuery.MaxWidthMiddle}) {
    .confirm-button {
      margin-right: 0;
      margin-bottom: 8px;

      width: 90%;
    }

    .cancel-button {
      width: 90%;
    }
  }
`
