import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { UtilsDates, UtilsString } from '@dn/utils'
import { ModalsIds } from '../../../../constants/modals'
import { StoreState } from '../../../../models/app/model'
import { ApiStoreSubscriptionsAC } from '../../../../store/actions/api-store/subscriptions/actions'
import { genAnimatedDialog } from '../../../common/compound-dialog/component'
import { ActionButtons, DialogConfirmation, IntlValues } from '../component'
import { useTrans } from '../../../../hooks/use-trans'

// ~~~~~~ Constants

const Dialog = genAnimatedDialog(ModalsIds.ConfirmCancelPlan)

const ActionButtonsText: ActionButtons = {
  okActionIntl: 'v2.pages.plan-details.CancelPlan',
  cancelActionIntl: 'v2.pages.plan-details.NotNow',
}

// ~~~~~~ Component

export const DialogConfirmCancelPlan: React.FC<{}> = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const freeText = useTrans('common.Free')

  // ~~~~~~ State

  // - Local

  const [doClose, setDoClose] = useState(0)

  const [closeOnEnd, setCloseOnEnd] = useState(false)

  // - Store

  const sub = useSelector((state: StoreState) => state.tmpSubscription)

  const bcastProducts = useSelector((state: StoreState) => state.broadcastSub.products)

  // ~~~~~~ Computed

  const freeProduct = bcastProducts.free

  const isFreeProduct = sub.price_id === freeProduct.id

  const isRunning = sub.uiStatus === 'running'

  const finalDate = UtilsDates.getMediumDate(sub.current_period_end)

  const premiumText =
    sub.price_id === bcastProducts.premium.month.id ||
    sub.price_id === bcastProducts.premium.year.id
      ? 'Premium'
      : freeText

  const productName = UtilsString.capitalize(
    sub.product_id === 'broadcast-subscription' ? `broadcast ${premiumText}` : sub.product_id,
  )

  const contentTextValues: IntlValues = {
    productName,
    endDate: finalDate,
  }

  // ~~~~~~ Handlers

  function onClickClose() {
    setDoClose(performance.now())
  }

  function onClickConfirm() {
    dispatch(ApiStoreSubscriptionsAC.cancelPlan(sub.subscription_id))
    setCloseOnEnd(true)
  }

  // ~~~~~~ Effects

  useEffect(() => {
    if (!closeOnEnd || isRunning) return

    setCloseOnEnd(false)
    setDoClose(performance.now())
  }, [isRunning, closeOnEnd, dispatch])

  // ~~~~~~ Render

  return (
    <DialogConfirmation
      data-test="dialog-confirm-cancel-plan"
      isNoJustifyContent
      hideCloseBtn
      Dialog={Dialog}
      title="dialogs.confirm.cancel-plan.Title"
      //
      contentTextQuestion="v2.pages.plan-details.AreYouSureWentToCancelProduct"
      contentTextQuestionValues={{
        value: productName,
      }}
      //
      contentText={
        isFreeProduct
          ? 'v2.pages.plan-details.IfCancelFreeBroadcast'
          : 'v2.pages.plan-details.IfCancelPLan'
      }
      //
      actionButtons={ActionButtonsText}
      //
      okButtonColor="quinary"
      contentTextValues={contentTextValues}
      isRunning={isRunning}
      doClose={doClose}
      onEndDissapear={() => undefined}
      onClickClose={onClickClose}
      onClickConfirm={onClickConfirm}
    />
  )
}
