const prefix = 'mt-store-card-rem-epic'

// ~~~~~~ Mutators Types

export const EpicApiStoreCardRemMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const EpicApiStoreCardRemMC = {
  ok: (data: DN.Models.Card.Api.Remove.ResParsed) => ({
    type: EpicApiStoreCardRemMT.OK,
    payload: data,
  }),

  error: (data: DN.Models.Card.Api.Remove.ResError) => ({
    type: EpicApiStoreCardRemMT.ERROR,
    error: true,
    payload: data,
  }),
} as const
