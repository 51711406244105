import styled from 'styled-components'
import { MediaQuery } from '../../../../style/constants/media-queries'
import { Colors } from '../../../../style/theme/colors'
import { Fonts } from '../../../../style/theme/fonts'
import { Layout } from '../../../../style/theme/layout'
import { theme } from '../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'user-zone-common-nav-tabs'

// ~~~~~~ Component

export const STUserZoneCommonNavigationTabs = styled.div`
  margin-top: 32px;

  .${ClassName}--content {
    display: flex;

    .${ClassName}--content--tab {
      cursor: pointer;

      ${Fonts.Weight[600]}
      ${Fonts.Scale.BodyBig}

      color: ${theme(Colors.WOLF_90_90)};

      padding: 8px 12px;

      ${Layout.Border.Radius.A}

      &.active {
        color: ${theme(Colors.BRONX_50_50)};
        background-color: ${theme(Colors.WOLF_05_05)};
      }

      &.help {
        margin-left: auto;
      }
    }
  }

  /* 1280 */

  @media (max-width: ${MediaQuery.MaxWidth2k}) {
  }

  /* Special: 800 */

  @media (max-width: 800px) {
    .${ClassName}--content {
      .${ClassName}--content--tab {
        &.help {
          display: none;
        }
      }
    }
  }

  /* 960 */

  @media (max-width: ${MediaQuery.MD}) {
    margin-top: 24px;

    .${ClassName}--content {
      .${ClassName}--content--tab {
        ${Fonts.Scale.Body}
      }
    }
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    margin-top: 12px;
  }
`
