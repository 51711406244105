import { useSelector } from 'react-redux'
import { Trans } from '../../../../../components/common/intl/trans'
import { PlatformCard } from '../../../../../components/common/platform-card/component'
import { ProductButtonMontage } from '../../../../../components/common/product-button/montage/component'
import { LazySVG } from '../../../../../components/svgs/lazy-svg/component'
import { PageTrialsOrFreeMontageTabSteps } from '../../tab-steps/component'
import { ClassName, STPageTrialsOrFreeMontageContentStep3SelectPlatform } from './style'
import { StoreState } from '../../../../../models/app/model'
import { useDispatch } from 'react-redux'
import { BuyLicenseFieldMR } from '../../../../../models/buy-license/model'

// ~~~~~~ Constants

const Logo = LazySVG('logos/montage-gradient')

// ~~~~~~ Component

export const PageTrialsOrFreeMontageContentStep3SelectPlatform = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const buyLicense = useSelector((state: StoreState) => state.buyLicense)

  const montageProducts = useSelector((state: StoreState) => state.montage.products)

  // ~~~~~~ Computed

  const platformIsValid = buyLicense.platform === 'windows' || buyLicense.platform === 'android'

  // ~~~~~~ Handlers

  function onClickWindows() {
    dispatch(BuyLicenseFieldMR.platform.MC.change('windows'))

    const subId = montageProducts['windows'].year.id

    dispatch(BuyLicenseFieldMR.subId.MC.change(subId))
  }

  function onClickAndroid() {
    dispatch(BuyLicenseFieldMR.platform.MC.change('android'))

    const subId = montageProducts['android'].year.id

    dispatch(BuyLicenseFieldMR.subId.MC.change(subId))
  }

  function onClickContinue() {
    if (!platformIsValid) return

    dispatch(BuyLicenseFieldMR.formStep.MC.change(buyLicense.formStep + 1))
  }

  // ~~~~~~ Render

  return (
    <STPageTrialsOrFreeMontageContentStep3SelectPlatform>
      {/* Steps */}

      <div className={`${ClassName}--steps`}>
        <PageTrialsOrFreeMontageTabSteps kind="trial" />
      </div>

      {/* Logo */}

      <div className={`${ClassName}--logo`}>
        <Logo size={32} />
      </div>

      {/* Title */}

      <div className={`${ClassName}--title`}>
        <Trans id="pages.purchase-flow.create-account-step.SelectPlatform" />
      </div>

      {/* Platforms */}

      <div className={`${ClassName}--platforms`}>
        {/* Windows */}

        <div className={`${ClassName}--platforms--platform`}>
          <PlatformCard
            kind="windows"
            selected={buyLicense.platform === 'windows'}
            onClick={onClickWindows}
          />
        </div>

        {/* Android */}

        <div className={`${ClassName}--platforms--platform last`}>
          <PlatformCard
            kind="android"
            selected={buyLicense.platform === 'android'}
            onClick={onClickAndroid}
          />
        </div>
      </div>

      {/* Action */}

      <ProductButtonMontage
        className={`${ClassName}--action`}
        intlId="pages.purchase-flow.common.Continue"
        running={false}
        disabled={!platformIsValid}
        active={platformIsValid}
        onClick={onClickContinue}
      />

      {/* - */}
    </STPageTrialsOrFreeMontageContentStep3SelectPlatform>
  )
}
