const prefix = 'mt-store-card-change-epic'

// ~~~~~~ Mutators Types

export const EpicApiStoreCardChangeMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const EpicApiStoreCardChangeMC = {
  ok: (data: DN.Models.Card.Api.Change.ResParsed, setDefault: boolean) => ({
    type: EpicApiStoreCardChangeMT.OK,
    payload: {
      data,
      setDefault,
    },
  }),

  error: (data: DN.Models.Card.Api.Change.ResError) => ({
    type: EpicApiStoreCardChangeMT.ERROR,
    error: true,
    payload: data,
  }),
} as const
