import { Observable, of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { catchError, map } from 'rxjs/operators'
import { ProductModel } from '../../../models/products/model'
import { ApiRoutes } from '../constants/routes'
import { ApiHeadersUtils } from '../utils/headers'

type Res = DN.Models.Product.Api.List.Res

type Parsed =
  | DN.Models.Product.Api.List.ResParsedList
  | DN.Models.Product.Api.List.ResParsedBroadcastProducts
  | DN.Models.Product.Api.List.ResParsedMontageProducts

type ResParsed = DNApiResParsed<Parsed>
type ResError = DNApiError<DN.Models.Product.Api.List.ResError>

const { Products } = ApiRoutes

export const apiStoreListProducts$ = (
  productId: DN.Models.Product.ProductId,
  headers?: AppHeaders,
) => {
  return ajax<Res>({
    ...ApiHeadersUtils.genHeaders(headers),
    method: Products.List.Method,
    url: Products.List.getUrl(productId),
  }).pipe(
    map((res): ResParsed => {
      switch (productId) {
        case 'launcher':
          return {
            status: res.status,
            response: res.response,
          }

        case 'montage':
          const montageProducts: DN.Models.Product.Api.List.ResParsedMontageProducts =
            res.response.reduce((acc, cur) => {
              // Windows

              if (cur.platform === 'windows') {
                return {
                  ...acc,
                  windows: {
                    year: cur,
                  },
                }
              }

              // Android

              if (cur.platform === 'android') {
                return {
                  ...acc,
                  android: {
                    year: cur,
                  },
                }
              }

              return acc

              //
            }, ProductModel.initMontageModel.products)

          return {
            status: res.status,
            response: montageProducts,
          }

        case 'broadcast-subscription':
          const broadcastProducts: DN.Models.Product.Api.List.ResParsedBroadcastProducts =
            res.response.reduce((acc, cur) => {
              // Free

              if (cur.order === 1) {
                return {
                  ...acc,
                  free: cur,
                }
              }

              // Premium

              if (cur.interval === 'month') {
                return {
                  ...acc,
                  premium: {
                    ...acc.premium,
                    month: cur,
                  },
                }
              }

              if (cur.interval === 'year') {
                return {
                  ...acc,
                  premium: {
                    ...acc.premium,
                    year: cur,
                  },
                }
              }

              return acc
            }, ProductModel.initBroadcastModel.products)

          return {
            status: res.status,
            response: broadcastProducts,
          }
      }
    }),
    catchError((res: DNApiRes<void>): Observable<ResError> => {
      return of({
        status: res.status,
        response: [{ id: 'api.products.Error' }],
      })
    }),
  )
}
