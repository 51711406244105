import React from 'react'
import { ClassName, STPagePurchaseCommonTabSteps } from './style'
import { Trans } from '../../../../components/common/intl/trans'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../../models/app/model'
import { LazySVG } from '../../../../components/svgs/lazy-svg/component'

// ~~~~~~ Constants

const CheckedIcon = LazySVG('icons/check-full')

// ~~~~~~ Types

type Props = {
  kind: 'license' | 'subscription'
}

// ~~~~~~ Component

export const PagePurchaseCommonTabSteps: React.FC<Props> = ({ kind }) => {
  // ~~~~~~ State

  const buyLicenseStep = useSelector((state: StoreState) => state.buyLicense.formStep)

  // ~~~~~~ Render

  return (
    <STPagePurchaseCommonTabSteps>
      {/* 1 Create Account */}

      <div className={`${ClassName}--step ${buyLicenseStep === 1 ? 'active' : ''}`}>
        {buyLicenseStep > 1 ? (
          <CheckedIcon size={18} />
        ) : (
          <div className={`${ClassName}--step--num`}>1</div>
        )}

        <div className={`${ClassName}--step--name`}>
          <Trans id="pages.purchase-flow.create-account-step.CreateAccount" />
        </div>
      </div>

      {/* 2 Verify your email */}

      <div className={`${ClassName}--step ${buyLicenseStep === 2 ? 'active' : ''}`}>
        {buyLicenseStep > 2 ? (
          <CheckedIcon size={18} />
        ) : (
          <div className={`${ClassName}--step--num`}>2</div>
        )}

        <div className={`${ClassName}--step--name`}>
          <Trans id="pages.purchase-flow.verify-email-step.VerifyEmail" />
        </div>
      </div>

      {/* 3 Payment */}

      <div className={`${ClassName}--step ${buyLicenseStep === 3 ? 'active' : ''}`}>
        {buyLicenseStep > 3 ? (
          <CheckedIcon size={18} />
        ) : (
          <div className={`${ClassName}--step--num`}>3</div>
        )}

        <div className={`${ClassName}--step--name`}>
          <Trans id="pages.purchase-flow.payment-step.Payment" />
        </div>
      </div>

      {/* 4 Account Ready / Download */}

      <div className={`${ClassName}--step ${buyLicenseStep === 4 ? 'active' : ''}`}>
        <div className={`${ClassName}--step--num`}>4</div>

        <div className={`${ClassName}--step--name`}>
          {kind === 'license' ? (
            <Trans id="pages.purchase-flow.account-ready-step.Download" />
          ) : (
            <Trans id="pages.purchase-flow.account-ready-step.AccountReady" />
          )}
        </div>
      </div>

      {/* - */}
    </STPagePurchaseCommonTabSteps>
  )
}
