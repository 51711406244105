import styled from 'styled-components'
import { genSTUserZonePlansRootAddProductCommonStyle } from '../common-style'

// ~~~~~~ Constants

export const ClassName = 'user-zone-plans-root-add-product-bcast'

// ~~~~~~ Component

export const STUserZonePlansRootAddProductBcast = styled.div`
  ${genSTUserZonePlansRootAddProductCommonStyle(ClassName)}
`
