import { ofType, StateObservable } from 'redux-observable'
import { EMPTY, Observable } from 'rxjs'
import { mergeMap, tap } from 'rxjs/operators'
import { ToastUtil } from '../../../../components/common/toast/util'

import { StoreState } from '../../../../models/app/model'
import {
  EpicApiStoreBillingUpdateMC,
  EpicApiStoreBillingUpdateMT,
} from '../../api-store/billing/update/mutators'
import { EpicApiStoreCardAddMC, EpicApiStoreCardAddMT } from '../../api-store/card/add/mutators'
import {
  EpicApiStoreCardChangeMC,
  EpicApiStoreCardChangeMT,
} from '../../api-store/card/change/mutators'
import { EpicApiStoreCardRemMC, EpicApiStoreCardRemMT } from '../../api-store/card/rem/mutators'
import {
  ApiStoreCheckoutSetDefaultCardEpicMC,
  ApiStoreCheckoutSetDefaultCardEpicMT,
} from '../../api-store/checkout/set-default-card/mutators'
import {
  ApiStoreCheckoutCreateSessionEpicMC,
  ApiStoreCheckoutCreateSessionEpicMT,
} from '../../api-store/checkout/create-session/mutators'
import { ApiStoreCheckoutSendEmailPassEpicMT } from '../../api-store/checkout/send-email-pass/mutators'
import {
  ApiStoreCheckoutShowSessionEpicMC,
  ApiStoreCheckoutShowSessionEpicMT,
} from '../../api-store/checkout/show-session/mutators'
import {
  ApiStoreCheckoutValidateEmailCodeEpicMC,
  ApiStoreCheckoutValidateEmailCodeEpicMT,
} from '../../api-store/checkout/validate-email-code/mutators'
import {
  EpicApiStoreCurrentUserShowMC,
  EpicApiStoreCurrentUserShowMT,
} from '../../api-store/current-user/show/mutators'
import {
  ApiStoreSubscriptionsCancelPlanEpicMC,
  ApiStoreSubscriptionsCancelPlanEpicMT,
} from '../../api-store/subscriptions/cancel-plan/mutators'
import {
  ApiStoreSubscriptionsChangePaymentMethodEpicMC,
  ApiStoreSubscriptionsChangePaymentMethodEpicMT,
} from '../../api-store/subscriptions/change-payment-method/mutators'
import {
  ApiStoreSubscriptionsChangePlanEpicMC,
  ApiStoreSubscriptionsChangePlanEpicMT,
} from '../../api-store/subscriptions/change-plan/mutators'
import {
  ApiStoreSubscriptionsCreateEpicMC,
  ApiStoreSubscriptionsCreateEpicMT,
} from '../../api-store/subscriptions/create/mutators'
import {
  ApiSubscriptionsReactivatePlanEpicMC,
  ApiStoreSubscriptionsReactivatePlanEpicMT,
} from '../../api-store/subscriptions/reactivate-plan/mutators'
import { ApiStoreCheckoutSendEmailPassEpicMC } from './../../api-store/checkout/send-email-pass/mutators'

type Action =
  // Billing
  | ReturnType<typeof EpicApiStoreBillingUpdateMC.error>

  // Card
  | ReturnType<typeof EpicApiStoreCardAddMC.error>
  | ReturnType<typeof EpicApiStoreCardChangeMC.error>
  | ReturnType<typeof EpicApiStoreCardRemMC.error>

  // Checkout
  | ReturnType<typeof ApiStoreSubscriptionsCreateEpicMC.error>
  | ReturnType<typeof ApiStoreCheckoutSetDefaultCardEpicMC.error>
  | ReturnType<typeof ApiStoreCheckoutCreateSessionEpicMC.error>
  | ReturnType<typeof ApiStoreCheckoutSendEmailPassEpicMC.error>
  | ReturnType<typeof ApiStoreCheckoutShowSessionEpicMC.error>
  | ReturnType<typeof ApiStoreCheckoutValidateEmailCodeEpicMC.error>

  // CurrentUser
  | ReturnType<typeof EpicApiStoreCurrentUserShowMC.error>

  // Products
  // Error in front

  // Subscriptions
  | ReturnType<typeof ApiStoreSubscriptionsCancelPlanEpicMC.error>
  | ReturnType<typeof ApiSubscriptionsReactivatePlanEpicMC.error>
  | ReturnType<typeof ApiStoreSubscriptionsChangePaymentMethodEpicMC.error>
  | ReturnType<typeof ApiStoreSubscriptionsChangePlanEpicMC.error>

export const toastErrorsEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(
      // Billing

      EpicApiStoreBillingUpdateMT.ERROR,

      // Card

      EpicApiStoreCardAddMT.ERROR,
      EpicApiStoreCardChangeMT.ERROR,
      EpicApiStoreCardRemMT.ERROR,

      // Checkout

      ApiStoreSubscriptionsCreateEpicMT.ERROR,
      ApiStoreCheckoutSetDefaultCardEpicMT.ERROR,
      ApiStoreCheckoutCreateSessionEpicMT.ERROR,
      ApiStoreCheckoutSendEmailPassEpicMT.ERROR,
      ApiStoreCheckoutShowSessionEpicMT.ERROR,
      ApiStoreCheckoutValidateEmailCodeEpicMT.ERROR,

      // CurrentUser

      EpicApiStoreCurrentUserShowMT.ERROR,

      // Subscriptions

      ApiStoreSubscriptionsCancelPlanEpicMT.ERROR,
      ApiStoreSubscriptionsReactivatePlanEpicMT.ERROR,
      ApiStoreSubscriptionsChangePaymentMethodEpicMT.ERROR,
      ApiStoreSubscriptionsChangePlanEpicMT.ERROR,
    ),

    tap(({ type, payload }) => {
      // Skip Broadcast (Get free) 412 error, the flow is show to the user a mmodal
      if (
        type === ApiStoreSubscriptionsCreateEpicMT.ERROR &&
        payload.errors.some((err) => err.id === 'api.subscriptions.create.Broadcast412')
      ) {
        return
      }

      payload.errors.forEach((error) => {
        let values = { value: '' }

        if (error.values && typeof error.values === 'object') {
          values = {
            ...values,
            ...error.values,
          }
        }

        ToastUtil.basic({
          kind: 'error',
          intlIds: [{ id: error.id, values }],
          duration: 7000,
        })
      })
    }),

    mergeMap(() => EMPTY),
  )
