import { useEffect } from 'react'
import { useTrans } from './use-trans'

// ~~~~~~ Hook

export const useDocTitle = (id: IntlMsgId) => {
  // ~~~~~~ Hooks

  const title = useTrans(id)

  // ~~~~~~ Effects

  useEffect(() => {
    document.title = title
  })
}
