import styled from 'styled-components'
import { Colors } from '../../../../../../style/theme/colors'
import { Fonts } from '../../../../../../style/theme/fonts'
import { theme } from '../../../../../../style/utils/theme'
import { Layout } from '../../../../../../style/theme/layout'
import { MediaQuery } from '../../../../../../style/constants/media-queries'

// ~~~~~~ Constants

export const ClassName = 'user-zone-plans-purchase-bcast-sub-2-premium'

// ~~~~~~ Component

export const STUserZonePlansPurchaseBcastSub2Premium = styled.div`
  ${Layout.MarginMaxWidth.MiddleContent}

  .${ClassName}--content {
    .${ClassName}--content--title {
      ${Fonts.Weight[800]}
      ${Fonts.Scale.Headline2}

      margin-bottom: 8px;
    }

    .${ClassName}--content--subtitle {
      ${Fonts.Weight[300]}
      ${Fonts.Scale.BodyBig}

      margin-bottom: 24px;
    }

    .${ClassName}--content--payment-method {
      .${ClassName}--content--payment-method--title {
        ${Fonts.Weight[700]}
        ${Fonts.Scale.BodyBig}

        margin-bottom: 8px;
      }

      .${ClassName}--content--payment-method--card {
        padding: 24px 16px;

        border: solid 1px ${theme(Colors.WOLF_10_10)};

        border-radius: 6px;

        display: flex;
        justify-content: space-between;
        align-items: center;

        margin-bottom: 8px;
      }
    }

    .${ClassName}--content--price-info {
      padding: 16px 0;

      display: flex;
      justify-content: space-between;
      align-items: center;

      margin-bottom: 4px;

      .${ClassName}--content--price-info--text {
        ${Fonts.Weight[700]}
        ${Fonts.Scale.BodyBig}

        .${ClassName}--content--price-info--text--interval {
          ${Fonts.Weight[300]}
          ${Fonts.Scale.BodyBig}
        }
      }

      .${ClassName}--content--price-info--value {
        ${Fonts.Weight[700]}
        ${Fonts.Scale.BodyBig}
      }
    }

    hr.${ClassName}--content--separator {
      border: none;
      border-top: solid 1px ${theme(Colors.WOLF_10_10)};

      margin-bottom: 24px;
    }

    .${ClassName}--content--actions {
      display: flex;

      margin-bottom: 24px;

      .${ClassName}--content--actions--action {
        &.first {
          margin-right: 16px;
        }
      }
    }

    .${ClassName}--content--info {
      ${Fonts.Weight[300]}
      ${Fonts.Scale.Body}

      margin-bottom: 16px;

      a {
        ${Fonts.Weight[600]}
        ${Fonts.Scale.Body}

        color: ${theme(Colors.BRONX_50_50)};
      }
    }
  }

  /* 1280 */

  @media (max-width: ${MediaQuery.MaxWidth2k}) {
    .${ClassName}--content {
      ${Layout.PaddingWidth.Desktop}

      .${ClassName}--content--go-back {
        margin-bottom: 24px;
      }

      .${ClassName}--content--title {
        ${Fonts.Scale.Headline2}
      }
    }
  }

  /* 960 */

  @media (max-width: ${MediaQuery.MD}) {
    .${ClassName}--content {
      ${Layout.PaddingWidth.Tablet}
    }
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    .${ClassName}--content {
      ${Layout.PaddingWidth.Mobile}

      .${ClassName}--content--title {
        ${Fonts.Scale.Title}
      }

      .${ClassName}--content--subtitle {
        ${Fonts.Scale.Subtitle}
      }

      .${ClassName}--content--actions {
        flex-direction: column;

        .${ClassName}--content--actions--action {
          &.first {
            margin-right: 0;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
`
