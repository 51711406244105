import { css } from 'styled-components'
import { Colors } from '../../../../../style/theme/colors'
import { HexAlpha } from '../../../../../style/theme/colors/alpha'
import { Layout } from '../../../../../style/theme/layout'
import { theme } from '../../../../../style/utils/theme'
import { Fonts } from '../../../../../style/theme/fonts'
import { MediaQuery } from '../../../../../style/constants/media-queries'

// ~~~~~~

export const genSTUserZonePlansRootAddProductAltCommonStyle = (ClassName: string) => css`
  max-width: 702px;

  padding: 16px;

  box-shadow: 0px 4px 16px ${theme(Colors.WOLF_90_90)}${HexAlpha[10]};

  border: solid 1px ${theme(Colors.WOLF_10_10)};

  background-color: ${theme(Colors.WOLF_05_05)};

  ${Layout.Border.Radius.B}

  .${ClassName}--logo {
    margin-bottom: 8px;

    svg {
      width: 79px;
    }
  }

  .${ClassName}--title {
    ${Fonts.Weight[700]}
    ${Fonts.Scale.Subtitle}

    margin-bottom: 8px;
  }

  .${ClassName}--subtitle {
    ${Fonts.Weight[400]}
    ${Fonts.Scale.Body}

    margin-bottom: 8px;
  }

  .${ClassName}--action {
    display: flex;
    justify-content: flex-end;
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    .${ClassName}--subtitle {
      margin-bottom: 24px;
    }
  }
`
