const prefix = 'mt-store-api-subscriptions-change-payment-method-epic'

// ~~~~~~ Mutators Types

export const ApiStoreSubscriptionsChangePaymentMethodEpicMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const ApiStoreSubscriptionsChangePaymentMethodEpicMC = {
  ok: (
    data: DN.Models.Subscription.Api.ChangePaymentMethod.ResParsed,
    card: DN.Models.Card.ModelFront,
  ) => ({
    type: ApiStoreSubscriptionsChangePaymentMethodEpicMT.OK,
    payload: {
      data,
      card,
    },
  }),

  error: (data: DN.Models.Subscription.Api.ChangePaymentMethod.ResError) => ({
    type: ApiStoreSubscriptionsChangePaymentMethodEpicMT.ERROR,
    error: true,
    payload: data,
  }),
} as const
