import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

import { StoreState } from '../../../../models/app/model'
import { PageTrialsOrFreeBcastContentStep3Ready } from './step-3-ready/component'
import { PageTrialsOrFreeBcastContentStep1CreateAccount } from './step-1-create-account/component'
import { PageTrialsOrFreeBcastContentStep2VerifyEmail } from './step-2-verify-email/component'
import { BuyLicenseMC } from '../../../../store/actions-mutators/buy-license/mutators'
import { ProductIds } from '../../../../constants/product-ids'
import { ApiStoreProductsAC } from '../../../../store/actions/api-store/products/actions'

// ~~~~~~ Component

export const PageTrialsOrFreeBcastContent = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const metaProduct = useSelector((state: StoreState) => state.broadcastSub)

  const buyLicense = useSelector((state: StoreState) => state.buyLicense)

  // ~~~~~~ Computed

  const curStep = buyLicense.formStep

  // ~~~~~~ Effects

  // - For reload: if we don't have product bcast, call the api

  useEffect(() => {
    if (metaProduct.uiStatus !== 'init') return

    dispatch(ApiStoreProductsAC.listProducts(ProductIds.BroadcastSub))

    //
  }, [dispatch, metaProduct.uiStatus])

  // - For reload: if buy license is not setted, set as freeProduct to
  // start the "create account" flow

  useEffect(() => {
    if (metaProduct.uiStatus !== 'completed') return

    if (buyLicense.subId) return

    const freeProduct = metaProduct.products.free

    dispatch(
      BuyLicenseMC.setLicense({
        productId: ProductIds.BroadcastSub,
        platform: 'web',
        subId: freeProduct.id,
        unitPrice: freeProduct.price,
        trial: false,
        alwaysFree: true,
        trialPeriodDays: -1,
      }),
    )

    //
  }, [buyLicense.subId, dispatch, metaProduct.products.free, metaProduct.uiStatus])

  // ~~~~~~ Render

  switch (curStep) {
    case 1:
      return <PageTrialsOrFreeBcastContentStep1CreateAccount />

    case 2:
      return <PageTrialsOrFreeBcastContentStep2VerifyEmail />

    case 3:
      return <PageTrialsOrFreeBcastContentStep3Ready />

    default:
      return null
  }
}
