import styled from 'styled-components'

import { Transition } from '../../../style/animations/css-animations'
import { Colors } from '../../../style/theme/colors'
import { Fonts } from '../../../style/theme/fonts'
import { getThemeValue, theme } from '../../../style/utils/theme'
import { Layout } from '../../../style/theme/layout'
import { MediaQuery } from '../../../style/constants/media-queries'

// ~~~~~~ Types

type ColorType =
  | 'default'
  | 'default-link'
  | 'default-transparent'
  | 'primary'
  | 'primary-link'
  | 'secondary-link'
  | 'tertiary-link'
  | 'quaternary-link'
  | 'quinary'
  | 'quinary-link'
  | 'only-link'

export type ButtonColorType = ColorType

type ColorState = 'base' | 'hover' | 'active'

export type Size = 's' | 'm' | 'l'

// ~~~~~~ Helpers

const getPaddingBySize = ({ size }: { size?: Size }) => {
  switch (size) {
    case 's':
      return '4px 8px;'

    case 'm':
      return '8px 16px;'

    case 'l':
      return '16px 32px;'
  }
}

const getPaddingBySizeWidth2k = ({ size, running }: { size?: Size; running: boolean }) => {
  switch (size) {
    case 's':
      return '4px 8px;'

    case 'm':
      return '8px 16px;'

    case 'l':
      return '12px 24px;'
  }
}

const getFontSize = ({ size }: { size?: Size }) => {
  switch (size) {
    case 's':
      return Fonts.Scale.Body

    case 'm':
      return Fonts.Scale.BodyBig

    case 'l':
      return Fonts.Scale.Subtitle
  }
}

const getFontSizeMaxWidth2k = ({ size }: { size?: Size }) => {
  switch (size) {
    case 's':
      return Fonts.Scale.Body

    case 'm':
      return Fonts.Scale.BodyBig

    case 'l':
      return Fonts.Scale.BodyBig
  }
}

export const getIconSize = ({ size }: { size?: Size }) => {
  switch (size || 'l') {
    case 's':
      return 16

    case 'm':
      return 18

    case 'l':
      return 20
  }
}

const paddingSize = (props: STProps) => `padding: ${getPaddingBySize(props)}`

// ~~~~~~

const getDefaultColor = (colorState: ColorState) => {
  switch (colorState) {
    case 'base':
      return {
        border: Colors.WOLF_05_05,
        bg: Colors.WOLF_05_05,
        font: Colors.WOLF_90_90,
      }

    case 'hover':
      return {
        border: Colors.WOLF_05_05,
        bg: Colors.WOLF_05_05,
        font: Colors.WOLF_90_90,
      }

    case 'active':
      return {
        border: Colors.WOLF_05_05,
        bg: Colors.WOLF_05_05,
        font: Colors.BRONX_50_50,
      }
  }
}

const getDefaultLinkColor = (colorState: ColorState) => {
  switch (colorState) {
    case 'base':
      return {
        border: Colors.TRANSPARENT,
        bg: Colors.TRANSPARENT,
        font: Colors.WOLF_90_90,
      }
    case 'hover':
      return {
        border: Colors.TRANSPARENT,
        bg: Colors.WOLF_05_05,
        font: Colors.WOLF_90_90,
      }
    case 'active':
      return {
        border: Colors.TRANSPARENT,
        bg: Colors.WOLF_05_05,
        font: Colors.BRONX_50_50,
      }
  }
}

const getDefaultTransparentColor = () => {
  return {
    border: Colors.TRANSPARENT,
    bg: Colors.TRANSPARENT,
    font: Colors.WOLF_90_90,
  }
}

const getTertiaryColor = (colorState: ColorState) => {
  switch (colorState) {
    case 'base':
      return {
        border: Colors.TRANSPARENT,
        bg: Colors.TRANSPARENT,
        font: Colors.WOLF_00_00,
      }
    case 'hover':
      return {
        border: Colors.TRANSPARENT,
        bg: Colors.WOLF_05_05,
        font: Colors.WOLF_00_00,
      }
    case 'active':
      return {
        border: Colors.TRANSPARENT,
        bg: Colors.WOLF_05_05,
        font: Colors.BRONX_50_50,
      }
  }
}

const getQuaternaryLinkColor = (colorState: ColorState) => {
  switch (colorState) {
    case 'base':
      return {
        border: Colors.TRANSPARENT,
        bg: Colors.TRANSPARENT,
        font: Colors.BRONX_50_50,
      }

    case 'hover':
      return {
        border: Colors.TRANSPARENT,
        bg: Colors.TRANSPARENT,
        font: Colors.BRONX_50_50,
      }

    case 'active':
      return {
        border: Colors.TRANSPARENT,
        bg: Colors.TRANSPARENT,
        font: Colors.BRONX_50_50,
      }
  }
}

const getPrimaryColor = (colorState: ColorState) => {
  switch (colorState) {
    case 'base':
      return {
        border: Colors.BRONX_50_50,
        bg: Colors.BRONX_50_50,
        font: Colors.WOLF_00_00,
      }
    case 'hover':
      return {
        border: Colors.BRONX_60_60,
        bg: Colors.BRONX_60_60,
        font: Colors.WOLF_00_00,
      }
    case 'active':
      return {
        border: Colors.BRONX_70_70,
        bg: Colors.BRONX_70_70,
        font: Colors.WOLF_00_00,
      }
  }
}

const getPrimaryLinkColor = (colorState: ColorState) => {
  switch (colorState) {
    case 'base':
      return {
        border: Colors.WOLF_90_90,
        bg: Colors.TRANSPARENT,
        font: Colors.WOLF_90_90,
      }
    case 'hover':
      return {
        border: Colors.WOLF_90_90,
        bg: Colors.WOLF_00_00,
        font: Colors.WOLF_90_90,
      }
    case 'active':
      return {
        border: Colors.WOLF_90_90,
        bg: Colors.WOLF_00_00,
        font: Colors.WOLF_90_90,
      }
  }
}

const getSecondaryLinkColor = (colorState: ColorState) => {
  switch (colorState) {
    case 'base':
      return {
        border: Colors.WOLF_00_00,
        bg: Colors.TRANSPARENT,
        font: Colors.WOLF_00_00,
      }
    case 'hover':
      return {
        border: Colors.TRANSPARENT,
        bg: Colors.WOLF_00_00,
        font: Colors.WOLF_90_90,
      }
    case 'active':
      return {
        border: Colors.TRANSPARENT,
        bg: Colors.WOLF_00_00,
        font: Colors.WOLF_90_90,
      }
  }
}

const getQuinaryColor = (colorState: ColorState) => {
  switch (colorState) {
    case 'base':
      return {
        border: Colors.BROOKLYN_60_60,
        bg: Colors.BROOKLYN_60_60,
        font: Colors.WOLF_00_00,
      }

    case 'hover':
      return {
        border: Colors.BROOKLYN_60_60,
        bg: Colors.BROOKLYN_60_60,
        font: Colors.WOLF_00_00,
      }

    case 'active':
      return {
        border: Colors.BROOKLYN_60_60,
        bg: Colors.BROOKLYN_60_60,
        font: Colors.WOLF_00_00,
      }
  }
}

const getQuinaryLinkColor = (colorState: ColorState) => {
  switch (colorState) {
    case 'base':
      return {
        border: Colors.TRANSPARENT,
        bg: Colors.TRANSPARENT,
        font: Colors.BROOKLYN_60_60,
      }

    case 'hover':
      return {
        border: Colors.TRANSPARENT,
        bg: Colors.TRANSPARENT,
        font: Colors.BROOKLYN_60_60,
      }

    case 'active':
      return {
        border: Colors.TRANSPARENT,
        bg: Colors.TRANSPARENT,
        font: Colors.BROOKLYN_60_60,
      }
  }
}

const getOnlyLink = (colorState: ColorState) => {
  switch (colorState) {
    case 'base':
      return {
        border: Colors.TRANSPARENT,
        bg: Colors.TRANSPARENT,
        font: Colors.WOLF_90_90,
      }

    case 'hover':
      return {
        border: Colors.TRANSPARENT,
        bg: Colors.TRANSPARENT,
        font: Colors.BRONX_50_50,
      }

    case 'active':
      return {
        border: Colors.TRANSPARENT,
        bg: Colors.TRANSPARENT,
        font: Colors.BRONX_60_60,
      }
  }
}

export const getColor = (colorType: ColorType, colorState: ColorState) => {
  switch (colorType) {
    case 'default':
      return getDefaultColor(colorState)

    case 'default-link':
      return getDefaultLinkColor(colorState)

    case 'default-transparent':
      return getDefaultTransparentColor()

    case 'primary':
      return getPrimaryColor(colorState)

    case 'primary-link':
      return getPrimaryLinkColor(colorState)

    case 'secondary-link':
      return getSecondaryLinkColor(colorState)

    case 'tertiary-link':
      return getTertiaryColor(colorState)

    case 'quaternary-link':
      return getQuaternaryLinkColor(colorState)

    case 'quinary':
      return getQuinaryColor(colorState)

    case 'quinary-link':
      return getQuinaryLinkColor(colorState)

    case 'only-link':
      return getOnlyLink(colorState)
  }
}

const backgroundColor = (colorState: ColorState) => (props: STProps) =>
  `background-color: ${getThemeValue(
    props,
    getColor(props.colorType, props.running || !props.active ? 'base' : colorState).bg,
  )}`

const border = (colorState: ColorState) => (props: STProps) =>
  `border: solid 1px ${getThemeValue(
    props,
    getColor(props.colorType, props.running || !props.active ? 'base' : colorState).border,
  )}`

const color = (colorState: ColorState) => (props: STProps) =>
  getThemeValue(props, getColor(props.colorType, props.active ? colorState : 'base').font)

// ~~~~~~ Types

export type STProps = {
  colorType: ColorType
  active: boolean
  running: boolean

  backgroundColor?: ThemeColor
  size?: Size
  width?: number | string
  height?: number | string
  align?: 'left' | 'center' | 'right'
  forceNoMarginLeft?: boolean
  fontWeight?: keyof typeof Fonts.Weight
  fontColor?: ThemeColor
  padding?: string
  iconOnly?: boolean
  leftIconMarginRight?: number
  rightIconMarginLeft?: number
  noBorder?: true
}

// ~~~~~~ Component

export const STButton = styled('div').withConfig({
  shouldForwardProp: (prop, _elementToBeCreated) => {
    return ['data-test-id', 'className', 'children', 'onClick'].includes(prop)
  },
})<STProps>`
  ${(props) => (props.forceNoMarginLeft ? 'margin-left: 0 !important;' : '')};

  ${(props) =>
    props.width
      ? `width: ${typeof props.width === 'string' ? props.width : `${props.width}px`}`
      : ''};

  ${(props) =>
    props.height
      ? `height: ${typeof props.height === 'string' ? props.height : `${props.height}px`}`
      : ''};

  display: inline-block;
  user-select: none;

  ${Layout.Border.Radius.A}

  text-align: ${({ align: $align }) => ($align ? $align : 'center')};

  ${({ fontWeight: $fontWeight }) => ($fontWeight ? Fonts.Weight[$fontWeight] : Fonts.Weight[500])};

  ${getFontSize}

  cursor: ${({ active, running }) => (!active || running ? 'not-allowed' : 'pointer')};

  ${(props) => (props.padding ? `padding: ${props.padding}` : paddingSize(props))};

  opacity: ${({ active }) => (active ? '1' : '.7')};

  ${Transition.ColorAndBackgroundFaster};

  color: ${(props) => (props.fontColor ? theme(props.fontColor)(props) : color('base')(props))};

  ${backgroundColor('base')};
  ${border('base')};

  border-width: ${({ noBorder: $noBorder }) => ($noBorder ? 0 : 1)}px;

  .icon-text {
    display: flex;
    align-items: center;
    justify-content: center;

    ${({ fontWeight: $fontWeight }) => Fonts.Weight[$fontWeight || 700]}

    .button-icon {
      width: ${getIconSize}px;
      height: ${getIconSize}px;

      display: flex;
      align-items: center;

      &.leftx {
        margin-right: ${({ iconOnly: $iconOnly, leftIconMarginRight: $leftIconMarginRight }) =>
          $iconOnly ? 0 : $leftIconMarginRight || 14}px;
      }

      &.rightx {
        margin-left: ${({ iconOnly: $iconOnly, rightIconMarginLeft: $rightIconMarginLeft }) =>
          $iconOnly ? 0 : $rightIconMarginLeft || 14}px;
      }

      svg path {
        ${Transition.FillFaster};
        fill: ${color('base')};
      }
    }

    .text {
      display: ${({ iconOnly: $iconOnly }) => ($iconOnly ? 'none' : 'initial')};
    }
  }

  &:active {
    color: ${color('active')};

    ${backgroundColor('active')};
    ${border('active')};

    border-width: ${({ noBorder: $noBorder }) => ($noBorder ? 0 : 1)}px;

    .button-icon svg path {
      fill: ${color('active')};
    }
  }

  /* 1280 */

  @media (max-width: ${MediaQuery.MaxWidth2k}) {
    ${getFontSizeMaxWidth2k}
    ${getPaddingBySizeWidth2k}
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    box-sizing: border-box;

    width: ${(props) =>
      props.width
        ? `${typeof props.width === 'string' ? props.width : `${props.width}px`}`
        : '100%'};
  }

  /* Ignore :hover css style on touch devices  */

  @media (hover: hover) and (pointer: fine) {
    &:hover {
      color: ${color('hover')};

      ${backgroundColor('hover')};
      ${border('hover')};

      border-width: ${({ noBorder: $noBorder }) => ($noBorder ? 0 : 1)}px;

      .button-icon svg path {
        fill: ${color('hover')};
      }
    }
  }
`
