import { Countries } from '../../../constants/countries'
import { LazySVG } from '../../svgs/lazy-svg/component'
import { Trans } from '../intl/trans'
import { STCountrySelector } from './style'

// ~~~~~~ Constants

type Country = typeof Countries

type CounryKey = keyof Country

const countryKeys = Object.keys(Countries) as CounryKey[]

const ArrowIcon = LazySVG('icons/arrow-down')

// ~~~~~~ Types

type Props = {
  'data-test'?: string
  label?: IntlMsgId
  disabled: boolean
  selected: string

  onChange: (value: string) => void
}

// ~~~~~~ Component

export const CountrySelector: React.FC<Props> = ({
  'data-test': dataTest,
  label,
  disabled,
  selected,
  onChange,
}) => {
  // ~~~~~~ Handlers

  function onChangeH(evt: any) {
    if (disabled) return

    onChange(evt.target.value)
  }

  // ~~~~~~ Render

  return (
    <STCountrySelector data-test="country-selector" $disabled={disabled}>
      {label ? (
        <div className="label">
          <Trans id={label} />
        </div>
      ) : undefined}

      <div className="country-select">
        <select data-test={dataTest} value={selected} disabled={disabled} onChange={onChangeH}>
          {countryKeys.map((countryKey) => (
            <option key={countryKey || 'none'} value={countryKey}>
              {Countries[countryKey]}
            </option>
          ))}
        </select>

        <div className="icon">
          <ArrowIcon size={16} />
        </div>
      </div>
    </STCountrySelector>
  )
}
