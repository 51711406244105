import React, { useRef, useState } from 'react'

import { useIsMounted } from '../../../hooks/lifecycle/use-mounted'
import { LazySVG } from '../../svgs/lazy-svg/component'
import { Trans } from '../intl/trans'
import { Loader } from '../loader/component'
import { getColor, getIconSize, STButton, STProps } from './style'

type Icon = ReturnType<typeof LazySVG>

// ~~~~~~ Types

export type ButtonProps = STProps & {
  'data-test-id'?: string

  children?: React.ReactNode
  intlId: IntlMsgId
  intlValues?: { [key: string]: string | number }
  disabled: boolean
  className?: string

  IconLeft?: Icon
  IconRight?: Icon
  iconLoadColor?: ThemeColor

  onClick: (evt?: React.MouseEvent<HTMLDivElement, MouseEvent>) => void
  onMouseEnter?: () => void
  onMouseLeave?: () => void
}

// ~~~~~~ Component

export const Button: React.FC<ButtonProps> = ({
  'data-test-id': dataTestId,
  children,
  intlId,
  intlValues,
  disabled,
  className,

  IconLeft,
  IconRight,
  iconLoadColor,

  onClick,
  onMouseEnter,
  onMouseLeave,

  // Style

  running,
  active,
  width,
  height,
  align,
  noBorder,
  padding,
  forceNoMarginLeft,
  colorType,
  size,
  fontWeight,
  fontColor,

  leftIconMarginRight,
  rightIconMarginLeft,

  iconOnly,
}) => {
  // ~~~~~~ Hooks

  const isMounted = useIsMounted()

  // ~~~~~~ State

  const buttonRef = useRef<HTMLDivElement>(null)
  const [calcWidth /*, setCalcWidth*/] = useState<number | undefined>(undefined)

  // ~~~~~~ Computed

  const finalSize = size || 'l'

  let loaderSize = 0

  switch (finalSize) {
    case 's':
      loaderSize = 28
      break

    case 'm':
      loaderSize = 23
      break

    case 'l':
      loaderSize = 24
      break
  }

  const textColor = getColor(colorType, 'base').font

  // ~~~~~~ Handlers

  function onClickButton() {
    if (running || disabled) return

    onClick()
  }

  // ~~~~~~ Render

  return (
    <STButton
      data-test-id={dataTestId || 'button'}
      ref={buttonRef}
      className={className}
      size={finalSize}
      colorType={colorType}
      fontColor={fontColor}
      active={active}
      running={running}
      width={width || calcWidth}
      height={height}
      align={align}
      fontWeight={fontWeight}
      forceNoMarginLeft={forceNoMarginLeft}
      noBorder={noBorder}
      padding={padding}
      iconOnly={iconOnly}
      leftIconMarginRight={leftIconMarginRight}
      rightIconMarginLeft={rightIconMarginLeft}
      onClick={onClickButton}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
    >
      {running ? (
        <Loader color={textColor} size={loaderSize} />
      ) : (
        <div className="icon-text">
          {/* Icon left */}

          {isMounted && IconLeft ? (
            <div className="button-icon leftx">
              <IconLeft size={getIconSize({ size: finalSize })} color={iconLoadColor} />
            </div>
          ) : undefined}

          {/* Text */}

          <div className="text">
            <Trans id={intlId} values={intlValues} />
          </div>

          {/* Icon right */}

          {isMounted && IconRight ? (
            <div className="button-icon rightx">
              <IconRight size={getIconSize({ size: finalSize })} color={iconLoadColor} />
            </div>
          ) : undefined}
        </div>
      )}

      {children}
    </STButton>
  )
}
