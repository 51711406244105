import styled from 'styled-components'
import { Colors } from '../../../../../style/theme/colors'
import { Fonts } from '../../../../../style/theme/fonts'
import { theme } from '../../../../../style/utils/theme'
import { MediaQuery } from '../../../../../style/constants/media-queries'
import { Layout } from '../../../../../style/theme/layout'

// ~~~~~~ Constants

export const ClassName = 'user-zone-plans-product-bcast'

// ~~~~~~ Component

export const STUserZonePlansProductBcast = styled.div`
  height: auto;
  min-height: 100%;

  margin-left: auto;
  margin-right: auto;

  display: flex;
  flex-direction: column;
  justify-content: space-between;

  padding-bottom: 72px;

  /* Top */

  .${ClassName}--top {
    position: relative;
    overflow: hidden;

    ${Layout.PaddingWidth.Default}

    display: flex;

    padding-bottom: 64px;

    /* Background when tablet\mobile */

    .${ClassName}--top--bg-pic {
      position: absolute;

      z-index: 0;

      inset: 0;
      overflow: hidden;

      left: 35%;

      img {
        width: 100%;
        height: 100%;
        object-fit: cover;
      }
    }

    /* Info */

    .${ClassName}--top--left {
      flex: 1;

      z-index: 1;

      padding-top: 32px;

      /* Go back */

      .${ClassName}--top--left--back {
        margin-bottom: 32px;

        .${ClassName}--top--left--back--action {
          ${Fonts.Weight[600]}
          ${Fonts.Scale.BodyBig}
        }
      }

      /* Logo */

      .${ClassName}--top--left--logo {
        .${ClassName}--top--left--logo--img {
          width: 100px;

          margin-bottom: 8px;
        }
      }

      /* Title */

      .${ClassName}--top--left--title {
        ${Fonts.Weight[800]}
        ${Fonts.Scale.OldA}

        margin-bottom: 16px;
      }

      /* Info list */

      .${ClassName}--top--left--info-list {
        .${ClassName}--top--left--info-list--title {
          ${Fonts.Weight[300]}
          ${Fonts.Scale.Title}

          color: ${theme(Colors.WOLF_90_90)};

          margin-bottom: 12px;
        }

        /* Points */

        .${ClassName}--top--left--info-list--points {
          ${Fonts.Weight[600]}
          ${Fonts.Scale.OldD}

          margin-bottom: 24px;

          .${ClassName}--top--left--info-list--points--point {
            margin-bottom: 8px;

            .${ClassName}--top--left--info-list--points--point--bullet {
              margin-right: 8px;
            }

            .${ClassName}--top--left--info-list--points--point--caption {
              ${Fonts.Weight[300]}
            }
          }
        }
      }

      /* Info box */

      .${ClassName}--top--left--info {
        .${ClassName}--top--left--info--highlight {
          ${Fonts.Weight[600]}
        }

        margin-bottom: 24px;
      }
    }

    /* Right: Image when big */

    .${ClassName}--top--right {
      flex: 1;

      padding-top: 32px;

      .${ClassName}--top--right--pic {
        img {
          width: 100%;
        }
      }
    }
  }

  /* Bottom */

  .${ClassName}--bottom {
    ${Layout.PaddingWidth.Default}

    .${ClassName}--bottom--title {
      ${Fonts.Weight[700]}
      ${Fonts.Scale.Title}

      margin-bottom: 16px;
    }

    .${ClassName}--bottom--sub-cards {
      display: flex;
      width: 100%;

      margin-bottom: 48px;

      .${ClassName}--bottom--sub-cards--space {
        width: 24px;
        height: 16px;
      }
    }

    .${ClassName}--bottom--get-in-touch {
      padding: 40px;

      ${Layout.Border.Radius.B}

      background-color: ${theme(Colors.WOLF_05_05)};

      display: flex;
      justify-content: space-between;

      margin-bottom: 72px;

      .${ClassName}--bottom--get-in-touch--left {
        display: flex;

        .${ClassName}--bottom--get-in-touch--left--left {
          margin-right: 16px;

          svg {
            width: 56px;
            height: 56px;

            path {
              fill: ${theme(Colors.WOLF_90_90)};
            }

            circle {
              fill: ${theme(Colors.WOLF_00_00)};
            }
          }
        }

        .${ClassName}--bottom--get-in-touch--left--right {
          .${ClassName}--bottom--get-in-touch--left--right--title {
            ${Fonts.Weight[800]}
            ${Fonts.Scale.Title}

            margin-bottom: 8px;
          }

          .${ClassName}--bottom--get-in-touch--left--right--subtitle {
            ${Fonts.Weight[400]}
            ${Fonts.Scale.Subtitle}
          }
        }
      }

      .${ClassName}--bottom--get-in-touch--right {
        min-width: 300px;

        display: flex;
        align-items: center;
        justify-content: flex-end;

        .${ClassName}--bottom-get-in-touch--right--action {
          ${Fonts.Scale.Subtitle}
          padding: 16px 32px;
        }
      }
    }
  }

  /* 1280 */

  @media (max-width: ${MediaQuery.MaxWidth2k}) {
    justify-content: unset;

    /* Top */

    .${ClassName}--top {
      ${Layout.PaddingWidth.Desktop}

      /* Left */

      .${ClassName}--top--left {
        .${ClassName}--top--left--back {
          margin-bottom: 24px;
        }

        .${ClassName}--top--left--logo {
          display: flex;

          .${ClassName}--top--left--logo--img {
            width: 80px;
          }
        }

        .${ClassName}--top--left--title {
          ${Fonts.Scale.OldB}
        }

        .${ClassName}--top--left--info-list {
          .${ClassName}--top--left--info-list--title {
            ${Fonts.Scale.OldD}
          }

          .${ClassName}--top--left--info-list--points {
            ${Fonts.Scale.Subtitle}
          }
        }
      }
    }

    /* Bottom */

    .${ClassName}--bottom {
      ${Layout.PaddingWidth.Desktop}

      .${ClassName}--bottom--title {
        ${Fonts.Scale.OldD}
      }

      .${ClassName}--bottom--get-in-touch {
        .${ClassName}--bottom--get-in-touch--left {
          .${ClassName}--bottom--get-in-touch--left--right {
            .${ClassName}--bottom--get-in-touch--left--right--title {
              ${Fonts.Scale.OldD}
            }
          }
        }

        .${ClassName}--bottom--get-in-touch--right {
          .${ClassName}--bottom-get-in-touch--right--action {
            ${Fonts.Scale.Subtitle}
          }
        }
      }
    }
  }

  /* 960 */

  @media (max-width: ${MediaQuery.MD}) {
    /* Top */

    .${ClassName}--top {
      ${Layout.PaddingWidth.Tablet}

      justify-content: center;

      margin-bottom: 48px;

      /* Background */

      .${ClassName}--top--bg-pic {
        left: 0;
      }

      /* Left */

      .${ClassName}--top--left {
        padding-top: 24px;

        .${ClassName}--top--left--back {
          margin-bottom: 32px;

          .${ClassName}--top--left--back--action {
            ${Fonts.Scale.Body}
          }
        }

        .${ClassName}--top--left--title {
          ${Fonts.Scale.Headline1}
        }

        .${ClassName}--top--left--info-list {
          .${ClassName}--top--left--info-list--title {
            ${Fonts.Scale.Subtitle}
          }

          .${ClassName}--top--left--info-list--points {
            ${Fonts.Scale.BodyBig}
          }
        }
      }

      /* Right */

      .${ClassName}--top--right {
        display: none;
      }
    }

    /* Bottom */

    .${ClassName}--bottom {
      ${Layout.PaddingWidth.Clear}

      text-align: center;

      .${ClassName}--bottom--sub-cards {
        ${Layout.PaddingWidth.Tablet}

        flex-direction: column;
      }

      .${ClassName}--bottom--get-in-touch {
        border-radius: 0;

        flex-direction: column;

        margin-bottom: 0;

        .${ClassName}--bottom--get-in-touch--left {
          flex-direction: column;

          margin-bottom: 24px;

          .${ClassName}--bottom--get-in-touch--left--left {
            svg {
              width: 48px;
              height: 48px;
            }

            margin-bottom: 16px;
          }

          .${ClassName}--bottom--get-in-touch--left--right {
            .${ClassName}--bottom--get-in-touch--left--right--subtitle {
              ${Fonts.Scale.BodyBig}
            }
          }
        }

        .${ClassName}--bottom--get-in-touch--right {
          justify-content: center;

          .${ClassName}--bottom-get-in-touch--right--action {
            ${Fonts.Scale.BodyBig}
            padding: 12px 24px;
          }
        }
      }
    }
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    /* Top */

    .${ClassName}--top {
      ${Layout.PaddingWidth.Mobile}

      padding-left: 16px;
      padding-right: 16px;

      padding-bottom: 40px;

      .${ClassName}--top--left {
        .${ClassName}--top--left--logo {
          .${ClassName}--top--left--logo--img {
            margin-bottom: 8px;
          }
        }

        .${ClassName}--top--left--title {
          ${Fonts.Scale.Headline2}
        }

        .${ClassName}--top--left--info-list {
          .${ClassName}--top--left--info-list--title {
            ${Fonts.Scale.BodyBig}
          }
        }
      }
    }

    /* Bottom */

    .${ClassName}--bottom {
      .${ClassName}--bottom--get-in-touch {
        padding-left: 16px;
        padding-right: 16px;

        .${ClassName}--bottom--get-in-touch--left {
          .${ClassName}--bottom--get-in-touch--left--right {
            .${ClassName}--bottom--get-in-touch--left--right--title {
              ${Fonts.Scale.Subtitle}
            }
          }
        }

        .${ClassName}--bottom--get-in-touch--right {
          width: 100%;

          .${ClassName}--bottom-get-in-touch--right--action {
            width: 100%;

            padding-top: 10px;
            padding-bottom: 10px;

            ${Fonts.Scale.Subtitle}
          }
        }
      }
    }
  }
`
