import styled from 'styled-components'

// ~~~~~~ Constants

export const ClassName = 'page-product-bcast'

// ~~~~~~ Component

export const STPageProductBcast = styled.div`
  height: 100vh;
`
