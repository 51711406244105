import { useCallback, useEffect, useState } from 'react'
import { useSelector } from 'react-redux'
import { ProductButtonBcast } from '../../../../components/common/product-button/bcast/component'
import { LazySVG } from '../../../../components/svgs/lazy-svg/component'
import { StoreState } from '../../../../models/app/model'
import { ClassName, STPageTrialsOrFreeCommonStep2VerifyEmail } from './style'
import { Trans } from '../../../../components/common/intl/trans'
import { DangerTrans } from '../../../../components/common/intl/danger-trans'
import { useDispatch } from 'react-redux'
import { BuyLicenseFieldMR, BuyLicenseModel } from '../../../../models/buy-license/model'
import { ApiStoreCheckoutAC } from '../../../../store/actions/api-store/checkout/actions'
import { useValidator } from '../../../../hooks/validators/use-validator'
import { KeyCodes } from '../../../../constants/key-codes'
import { IntlDigitCodeInput } from '../../../../components/common/intl-digit-code/component'
import { HooksData } from '@dn/hooks'

// ~~~~~~ Constants

const CodeLength = 6

const EmailIcon = LazySVG('icons/email')

// ~~~~~~ Types

type Props = {
  Logo: ReturnType<typeof LazySVG>
  ProductButton: typeof ProductButtonBcast
  tabSteps: React.ReactNode
}

// ~~~~~~ Component

export const PageCommonOPStep2VerifyEmail: React.FC<Props> = ({
  Logo,
  ProductButton,
  tabSteps,
}) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const validateCode = useValidator(BuyLicenseModel.validations.Code, BuyLicenseFieldMR.code)

  // ~~~~~~ State

  // - Local

  const [isDirty, setIsDirty] = useState(false)

  // - Store

  const buyLicense = useSelector((state: StoreState) => state.buyLicense)

  // ~~~~~~ Computed

  const isRunning = buyLicense.uiStatus === 'running'

  const prevBuyLicenseCode = HooksData.PrevValue.useHook(buyLicense.code)

  // ~~~~~~ Handlers

  function onClickChangeEmail() {
    // Return to previous step without modifying data

    dispatch(BuyLicenseFieldMR.formStep.MC.change(buyLicense.formStep - 1))
  }

  function onChCode(value: string) {
    const finalValue = value.slice(0, CodeLength)

    isDirty && validateCode(finalValue)

    dispatch(BuyLicenseFieldMR.code.MC.change(finalValue))
  }

  function onClickResendCode() {
    if (isRunning) return

    dispatch(
      ApiStoreCheckoutAC.sendEmailPass({
        email: buyLicense.email,
        password: buyLicense.password,
      }),
    )
  }

  function onKeyDown(key: string) {
    if (isRunning || key !== KeyCodes.ENTER) return

    onSubmit()
  }

  const onSubmit = useCallback(() => {
    setIsDirty(true)

    // Validate

    const errors = [validateCode(buyLicense.code).length]

    if (errors.find(Boolean)) return

    // Clean dirty

    setIsDirty(false)

    // Submit

    dispatch(
      ApiStoreCheckoutAC.validateEmailCode({
        code: buyLicense.code,
        email: buyLicense.email,
      }),
    )
  }, [buyLicense.code, buyLicense.email, dispatch, validateCode])

  // ~~~~~~ Effects

  useEffect(() => {
    if (prevBuyLicenseCode === buyLicense.code || buyLicense.code.length !== CodeLength) {
      return
    }

    onSubmit()

    //
  }, [buyLicense.code, onSubmit, prevBuyLicenseCode])

  // ~~~~~~ Render

  return (
    <STPageTrialsOrFreeCommonStep2VerifyEmail>
      {/* Steps */}

      <div className={`${ClassName}--steps`}>{tabSteps}</div>

      {/* Logo */}

      <div className={`${ClassName}--logo`}>
        <Logo size={32} />
      </div>

      {/* Title */}

      <div className={`${ClassName}--title`}>
        <Trans id="pages.purchase-flow.verify-email-step.VerifyEmail" />

        <span className={`${ClassName}--title--icon`}>
          <EmailIcon size={56} />
        </span>
      </div>

      {/* Subtitle */}

      <div className={`${ClassName}--subtitle`}>
        <DangerTrans
          template="...to <c>{value}</c> (<d>Change email)</d>). This..."
          id="pages.purchase-flow.verify-email-step.SentVerification"
          values={{
            value: buyLicense.email,

            c: (chunks: string) => (
              <span className={`${ClassName}--subtitle--email`}>{chunks}</span>
            ),

            d: (chunks: string) => (
              <span className={`${ClassName}--subtitle--ch-email`} onClick={onClickChangeEmail}>
                {chunks}
              </span>
            ),
          }}
        />
      </div>

      {/* Code input */}

      <div className={`${ClassName}--field code last`}>
        <IntlDigitCodeInput
          label="pages.purchase-flow.verify-email-step.VerificationCode"
          value={buyLicense.code}
          errors={buyLicense.code_err}
          doFocus={1}
          $disabled={isRunning}
          numberLength={CodeLength}
          onChange={onChCode}
          onKeyDown={onKeyDown}
        />
      </div>

      {/* Action */}

      <ProductButton
        className={`${ClassName}--action`}
        intlId="pages.purchase-flow.verify-email-step.CreateMyAccount"
        running={isRunning}
        disabled={isRunning}
        active={!isRunning}
        onClick={onSubmit}
      />

      {/* Footer: Resend code */}

      <div className={`${ClassName}--footer`}>
        <span>
          <Trans id="pages.purchase-flow.verify-email-step.ResendVerificationCode1" />
        </span>

        <span className={`${ClassName}--footer--link`} onClick={onClickResendCode}>
          <Trans id="pages.purchase-flow.verify-email-step.ResendVerificationCode2" />
        </span>
      </div>

      {/* - */}
    </STPageTrialsOrFreeCommonStep2VerifyEmail>
  )
}
