import styled from 'styled-components'
import { Button } from '../../button/component'
import { ColorsUtils } from '../../../../style/theme/colors-utils'
import { getThemeValue } from '../../../../style/utils/theme'
import { Transition } from '../../../../style/animations/css-animations'
import { Layout } from '../../../../style/theme/layout'

// ~~~~~~ Constants

export const ClassName = 'product-button-montage'

// ~~~~~~ Component

export const STProductButtonMontage = styled(Button)`
  position: relative;
  z-index: 0;

  background-image: ${(props) => `
      linear-gradient(
        103.51deg,
        ${getThemeValue(props, ColorsUtils.getGradientColorType('base').Montage[0])} 0%,
        ${getThemeValue(props, ColorsUtils.getGradientColorType('base').Montage[1])} 147.41%
        )`};

  &::before {
    ${Transition.OpacityFast}

    ${Layout.Border.Radius.A}

    position: absolute;
    z-index: -1;

    content: '';
    inset: 0;

    opacity: 0;

    background-image: ${(props) => `
      linear-gradient(
        103.51deg,
        ${getThemeValue(props, ColorsUtils.getGradientColorType('base').Montage[0])} 0%,
        ${getThemeValue(props, ColorsUtils.getGradientColorType('base').Montage[0])} 147.41%
        )`};
  }

  &:hover::before {
    opacity: 1;
  }

  &:active::before {
    background-image: ${(props) => `
      linear-gradient(
        103.51deg,
        ${getThemeValue(props, ColorsUtils.getGradientColorType('base').Montage[1])} 0%,
        ${getThemeValue(props, ColorsUtils.getGradientColorType('base').Montage[1])} 147.41%
        )`};
  }
`
