import { AppInitState } from '../../../../models/app/model'
import { BillingInfoFieldMR } from '../../../../models/billing-info/model'
import { dynReducer } from '../../../../utils/store'
import { ApiStoreBillingAT } from '../../../actions/api-store/billing/actions'
import {
  EpicApiStoreBillingUpdateMC,
  EpicApiStoreBillingUpdateMT,
} from '../../../epics/api-store/billing/update/mutators'

const initState = AppInitState.billingInfoEdit

type State = typeof initState

const reducer: any = {
  ...BillingInfoFieldMR.__model__.Reducer,

  ...BillingInfoFieldMR.contactName.Reducer,
  ...BillingInfoFieldMR.company.Reducer,
  ...BillingInfoFieldMR.phone.Reducer,
  ...BillingInfoFieldMR.address.Reducer,
  ...BillingInfoFieldMR.country.Reducer,
  ...BillingInfoFieldMR.cpZip.Reducer,
  ...BillingInfoFieldMR.city.Reducer,
  ...BillingInfoFieldMR.stateRegion.Reducer,
}

// ####################################################################################################
// ~~~~~~ Update - START
// ####################################################################################################

reducer[ApiStoreBillingAT.UPDATE] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'running',
    errors: [],
  }
}

// ####################################################################################################
// ~~~~~~ Update - OK
// ####################################################################################################

reducer[EpicApiStoreBillingUpdateMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiStoreBillingUpdateMC.ok>,
): State => {
  return {
    ...state,
    uiStatus: 'init',
    errors: [],
  }
}

// ####################################################################################################
// ~~~~~~ Update - ERROR
// ####################################################################################################

reducer[EpicApiStoreBillingUpdateMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiStoreBillingUpdateMC.error>,
): State => {
  return {
    ...state,
    uiStatus: 'init',
    errors: payload.errors,
  }
}

// ~~~~~~

export const billingInfoEditReducer = dynReducer(initState, reducer)
