import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { UtilsDates, UtilsEncDec } from '@dn/utils'

import { Button } from '../../../../../../components/common/button/component'
import { Dollars } from '../../../../../../components/common/dollars/component'
import { Trans } from '../../../../../../components/common/intl/trans'
import { LazySVG } from '../../../../../../components/svgs/lazy-svg/component'
import { ClassName, STUserZonePlansRootProductCardMontage } from './style'
import { StoreState } from '../../../../../../models/app/model'
import { CreditCardBrand } from '../../../../../../components/common/credit-card-brand/component'
import { CreditCardBrandSpritePosition } from '../../../../../../style/constants/credit-card-brand'
import { RouteDataAddPaymentMethod, Routes } from '../../../../../../constants/routes'
import { BadgeText } from '../../../../../../components/common/badge-text/component'
import { DangerTrans } from '../../../../../../components/common/intl/danger-trans'
import { useTrans } from '../../../../../../hooks/use-trans'

// ~~~~~~ Constants

const Logo = LazySVG('logos/montage-gradient')

const ArrowRightIcon = LazySVG('icons/arrow-long-right')

const AddPaymentIcon = LazySVG('icons/plus-circle')

const CalendarIcon = LazySVG('icons/calendar')

// ~~~~~~ Types

type Props = {
  sub: DN.Models.Subscription.ModelFront
}

// ~~~~~~ Component

export const UserZonePlansRootProductCardMontage: React.FC<Props> = ({ sub }) => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  const backText = useTrans('pages.common.Plans')

  // ~~~~~~ State

  const cards = useSelector((state: StoreState) => state.cards)

  // ~~~~~~ Computed

  const nextBillingDate = UtilsDates.getMediumDate(sub.current_period_end)

  const card = cards.list.find((card) => card.payment_method_id === sub.payment_method_id)

  const isCanceled = !!sub.cancel_at

  // NOTE: "Remaining days..."
  //   Only relate with the Trial ending
  //
  //   blue => has card, inform about the first charge
  //   yellow => no card, warn about on trial end the product will stop working

  const diffRemainingDays =
    sub.trial_end && UtilsDates.getDiffDays(sub.trial_end) >= 0
      ? UtilsDates.getDiffDays(sub.trial_end)
      : 0

  const remainingDays = UtilsDates.getRemainingDays(diffRemainingDays)

  const isTrial = sub
    ? (!!sub.trial_end && !sub.converted_to_paid) || sub.subscription_status === 'trialing'
    : false

  const showNextBillingDate = !isCanceled && (isTrial ? sub.converted_to_paid : true)

  const isUnpaid = sub.subscription_status === 'past_due' || sub.subscription_status === 'unpaid'

  // ~~~~~~ Handlers

  function onClickAddCard() {
    const curPath = `${window.location.pathname}${window.location.search}`

    const nextDataObj: RouteDataAddPaymentMethod = {
      backPath: curPath,
      backText: backText,
      successPath: curPath,
    }

    const nextData = UtilsEncDec.base64UrlEncode(JSON.stringify(nextDataObj))

    const addCardRoute = Routes.genUserZonePlansAddPaymentMethod(nextData)

    navigate(addCardRoute)
  }

  function onClickManagePlan() {
    navigate(Routes.genUserZonePlansMontageDetail(sub.subscription_id))
  }

  // ~~~~~~ Render

  return (
    <STUserZonePlansRootProductCardMontage>
      {/* Normal blocks */}

      <div className={`${ClassName}--a`}>
        {/* Logo + Price */}

        <div className={`${ClassName}--a--logo-price`}>
          <div className={`${ClassName}--a--logo-price--logo`}>
            <Logo size={24} />

            {isTrial ? <div className={`${ClassName}--a--logo-price--text`}>Trial</div> : undefined}
          </div>

          {/* Price (not visible on mobile) */}

          <div className={`${ClassName}--a--logo-price--price`}>
            <span>
              <Dollars value={sub.amount} />
            </span>
            <span> </span>
            <span className={`${ClassName}--a--logo-price--price--interval`}>
              (
              {sub.interval === 'month' ? (
                <Trans id="pages.common.Monthly" />
              ) : (
                <Trans id="pages.common.Yearly" />
              )}
              )
            </span>
          </div>

          {/* Canceled (only visible on mobile) */}

          {isCanceled ? (
            <div className={`${ClassName}--a--logo-price--canceled`}>
              <BadgeText intl="pages.user-zone.plans-tab.status.Canceled" />
            </div>
          ) : undefined}
        </div>

        {/* Sub + price (only visible on mobile) */}

        <div className={`${ClassName}--a--sub-price`}>
          <div className={`${ClassName}--a--sub-price--title`}>
            <Trans id="pages.user-zone.plans-tab.Subscription" />
          </div>

          <div className={`${ClassName}--a--sub-price--value`}>
            <span>
              <Dollars value={sub.amount} />
            </span>
            <span> </span>
            <span className={`${ClassName}--a--sub-price--value--interval`}>
              (
              {sub.interval === 'month' ? (
                <Trans id="pages.common.Monthly" />
              ) : (
                <Trans id="pages.common.Yearly" />
              )}
              )
            </span>
          </div>
        </div>

        {/* Next billing date */}

        <div className={`${ClassName}--a--next-billing`}>
          <div className={`${ClassName}--a--next-billing--title`}>
            <Trans id="pages.user-zone.plans-tab.NextBillingDate" />
          </div>

          <div className={`${ClassName}--a--next-billing--value`}>
            {showNextBillingDate ? nextBillingDate : '-'}
          </div>
        </div>

        {/* Payment Method */}

        <div className={`${ClassName}--a--payment-method`}>
          <div className={`${ClassName}--a--payment-method--title`}>
            <Trans id="pages.common.PaymentMethod" />
          </div>

          <div className={`${ClassName}--a--payment-method--value`}>
            {isCanceled ? (
              '-'
            ) : card ? (
              <CreditCardBrand
                cardCode={card.last4}
                $creditCardXPosition={
                  CreditCardBrandSpritePosition[
                    card.brand as keyof typeof CreditCardBrandSpritePosition
                  ]
                }
              />
            ) : (
              <Button
                colorType="quaternary-link"
                intlId="pages.common.AddPaymentMethod"
                leftIconMarginRight={4}
                IconLeft={AddPaymentIcon}
                size="s"
                fontWeight="600"
                padding="0"
                running={false}
                disabled={false}
                active={true}
                onClick={onClickAddCard}
              />
            )}
          </div>
        </div>

        {/* Action */}

        <div className={`${ClassName}--a--action`}>
          {/* Cancel badge */}

          {isCanceled ? (
            <BadgeText
              className={`${ClassName}--a--action--canceled`}
              intl="pages.user-zone.plans-tab.status.Canceled"
            />
          ) : undefined}

          {/* Action: Manage */}

          <Button
            colorType="only-link"
            padding="0"
            intlId="pages.user-zone.plans-tab.ManagePlan"
            IconRight={ArrowRightIcon}
            size="s"
            running={false}
            disabled={false}
            active={true}
            onClick={onClickManagePlan}
          />
        </div>
      </div>

      {/* Remaining days (only trials) */}
      {/* Note: the bottom bar in each (trial) product card */}

      {isTrial ? (
        <div
          className={`${ClassName}--b ${isCanceled ? 'is-canceled' : ''} ${card ? 'has-card' : ''}`}
        >
          {isUnpaid ? undefined : (
            <div className={`${ClassName}--b--icon`}>
              <CalendarIcon size={16} />
            </div>
          )}

          <div className={`${ClassName}--b--text`}>
            {isUnpaid ? (
              <DangerTrans
                template="<c>...</c> ... {value}..."
                id="pages.user-zone.plans-tab.SubscriptionTrialAlertEnded"
                values={{
                  value: 'Montage',
                  c: (chunks: string) => (
                    <span className={`${ClassName}--b--text--highlight`}>{chunks}</span>
                  ),
                }}
              />
            ) : isCanceled ? (
              <DangerTrans
                template="<c>{remainingDays} ...</c> ... canceled... {productName}..."
                id="pages.user-zone.plans-tab.SubscriptionTrialAlertCanceled"
                values={{
                  remainingDays: remainingDays,
                  productName: 'Montage',
                  c: (chunks: string) => (
                    <span className={`${ClassName}--b--text--highlight`}>{chunks}</span>
                  ),
                }}
              />
            ) : card ? (
              <DangerTrans
                template="<c>{remainingDays} ...</c> Your {productName} sub... charged <d>{productPrice}</d> on <e>{billingDate}</e>"
                id="pages.user-zone.plans-tab.SubscriptionTrialAlert"
                values={{
                  remainingDays: remainingDays,
                  productName: 'Montage',
                  productPrice: sub.amount,
                  billingDate: nextBillingDate,
                  c: (chunks: string) => (
                    <span className={`${ClassName}--b--text--highlight`}>{chunks}</span>
                  ),
                  d: (chunks: string) => <Dollars value={Number(chunks)} />,
                  e: (chunks: string) => <span>{chunks}</span>,
                }}
              />
            ) : (
              <DangerTrans
                template="<c>{remainingDays} ...</c> To continue..."
                id="pages.user-zone.plans-tab.SubscriptionTrialAlertNoCard"
                values={{
                  remainingDays: remainingDays,
                  productName: 'Montage',
                  c: (chunks: string) => (
                    <span className={`${ClassName}--b--text--highlight`}>{chunks}</span>
                  ),
                }}
              />
            )}
          </div>
        </div>
      ) : undefined}

      {/* Action reduce size */}

      <div className={`${ClassName}--c`}>
        <div className={`${ClassName}--c--action`}>
          <Button
            colorType="default-link"
            intlId="pages.user-zone.plans-tab.ManagePlan"
            IconRight={ArrowRightIcon}
            size="s"
            running={false}
            disabled={false}
            active={true}
            onClick={onClickManagePlan}
          />
        </div>
      </div>

      {/* - */}
    </STUserZonePlansRootProductCardMontage>
  )
}
