import styled from 'styled-components'
import { theme } from '../../../../../style/utils/theme'
import { Colors } from '../../../../../style/theme/colors'
import { Fonts } from '../../../../../style/theme/fonts'
import { MediaQuery } from '../../../../../style/constants/media-queries'

// ~~~~~~ Constants

export const ClassName = 'user-zone-billing-root-card'

// ~~~~~~ Component

export const STUserZoneBillingRootCard = styled.div`
  padding: 8px 16px;

  border: solid 1px ${theme(Colors.WOLF_10_10)};

  border-radius: 6px;

  display: flex;
  align-items: center;
  justify-content: space-between;

  /* Skeleton */

  .${ClassName}--loading {
    min-width: 100%;
    height: 44px;

    display: flex;
    align-items: center;

    .${ClassName}--loading--bar {
      height: 80%;
    }
  }

  .${ClassName}--info {
    display: flex;
    align-items: center;

    .${ClassName}--info--a {
      display: flex;
      align-items: center;

      .${ClassName}--default {
        ${Fonts.Weight[400]}
        ${Fonts.Scale.Body}

        margin-left: 8px;
        padding: 2px 4px;

        border-radius: 6px;

        color: ${theme(Colors.BRONX_60_60)};
        background-color: ${theme(Colors.BRONX_10_10)};

        text-transform: uppercase;

        &.fake-hidden {
          color: transparent;
          background-color: transparent;
        }
      }
    }

    .${ClassName}--info--b {
      display: flex;
      width: 100%;

      .${ClassName}--expires {
        ${Fonts.Weight[300]}
        ${Fonts.Scale.Body}

        margin-left: 60px;
      }
    }
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    .${ClassName}--info {
      flex-direction: column;

      .${ClassName}--info--a {
        margin-bottom: 10px;
      }

      .${ClassName}--info--b {
        .${ClassName}--expires {
          margin-left: unset;
        }
      }
    }
  }
`
