import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import { useSelector } from 'react-redux'

import { ClassName, STUserZonePlansPurchaseBcastSub2Premium } from './style'
import { Routes } from '../../../../../../constants/routes'
import { UserZoneCommonBannerBack } from '../../../../@-common/banner-back/component'
import { Trans } from '../../../../../../components/common/intl/trans'
import { Dollars } from '../../../../../../components/common/dollars/component'
import { DangerTrans } from '../../../../../../components/common/intl/danger-trans'
import { ProductButtonBcast } from '../../../../../../components/common/product-button/bcast/component'
import { useTrans } from '../../../../../../hooks/use-trans'
import { StoreState } from '../../../../../../models/app/model'
import { LazySVG } from '../../../../../../components/svgs/lazy-svg/component'
import { Button } from '../../../../../../components/common/button/component'
import { Config } from '../../../../../../config'
import { useDispatch } from 'react-redux'
import { ApiStoreCheckoutAC } from '../../../../../../store/actions/api-store/checkout/actions'

// ~~~~~~ Constants

const SubscribeIcon = LazySVG('icons/lock')

// ~~~~~~ Component

export const UserZonePlansPurchaseBcastSub2Premium = () => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  const interval = useParams<{ interval: 'monthly' | 'yearly' }>().interval

  const intervalText = (
    useTrans(
      interval ? (interval === 'monthly' ? 'common.Month' : 'common.Year') : 'wildcard',
    ) as string
  ).toLowerCase()

  const dispatch = useDispatch()

  // ~~~~~~ State

  // - Store

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  const broadcastSub = useSelector((state: StoreState) => state.broadcastSub)

  // Used only for runing status
  const buyLicense = useSelector((state: StoreState) => state.buyLicense)

  // ~~~~~~ Computed

  const isLoading = !currentUser.isSessionChecked || currentUser.uiStatus === 'running'

  const isRunning = buyLicense.uiStatus === 'running'

  const monthlyPrice = broadcastSub.products.premium.month.price

  const yearlyPrice = broadcastSub.products.premium.year.price

  const price = interval ? (interval === 'monthly' ? monthlyPrice : yearlyPrice) : 0

  // ~~~~~~ Handlers

  function onClickGoBack() {
    navigate(Routes.UserZonePlansBcast)
  }

  function onClickSubscribe() {
    const product =
      interval === 'monthly'
        ? broadcastSub.products.premium.month
        : broadcastSub.products.premium.year

    dispatch(
      ApiStoreCheckoutAC.createCheckoutSession('broadcast-subscription', {
        email: currentUser.email,
        code: '',

        price_of_license_of_product_id: product.id,
        quantity: 1,

        // Remove the :stripeSessionId (stripe will add this at the end of this route)
        success_path: Routes.UserZonePlansBcastSub2PremiumSuccess.split('/').slice(0, -1).join('/'),
        cancel_path: window.location.pathname,
      }),
    )
  }

  // ~~~~~~ Effects

  useEffect(() => {
    if (interval === 'monthly' || interval === 'yearly') return

    navigate(Routes.UserZonePlans)

    //
  }, [interval, navigate])

  // ~~~~~~ Render

  if (isLoading) return null

  return (
    <STUserZonePlansPurchaseBcastSub2Premium>
      {/* Banner back */}

      <UserZoneCommonBannerBack
        noImage
        backIntl="pages.common.AddProduct"
        backIntlValues={{ value: 'Broadcast' }}
        kind="bcast"
        onClickGoBack={onClickGoBack}
      />

      {/* Content */}

      <div className={`${ClassName}--content`}>
        {/* Title */}

        <div className={`${ClassName}--content--title`}>
          <Trans
            id="v2.pages.upgrade-plan.payment-details.PaymentDetails"
            values={{ value: 'Premium' }}
          />
        </div>

        {/* Subtitle -- have no sense */}

        <div className={`${ClassName}--content--subtitle`}>
          {interval === 'monthly' ? (
            <Trans id="v2.pages.upgrade-plan.payment-details.PaymentDetailsDescriptionMonthly" />
          ) : (
            <Trans id="v2.pages.upgrade-plan.payment-details.PaymentDetailsDescriptionYearly" />
          )}
        </div>

        {/* Price info */}

        <div className={`${ClassName}--content--price-info`}>
          <div className={`${ClassName}--content--price-info--text`}>
            <DangerTrans
              template="Due Today <c>(then every {value})</c>"
              id="v2.pages.upgrade-plan.payment-details.DueToday"
              values={{
                value: intervalText,
                c: (chunks: string) => (
                  <span className={`${ClassName}--content--price-info--text--interval`}>
                    {chunks}
                  </span>
                ),
              }}
            />
          </div>

          <div className={`${ClassName}--content--price-info--value`}>
            <Dollars value={price} />
          </div>
        </div>

        {/* Space */}

        <hr className={`${ClassName}--content--separator`} />

        {/* Action */}

        <div className={`${ClassName}--content--actions`}>
          {/* Subscribe */}

          <ProductButtonBcast
            className={`${ClassName}--content--actions--action first`}
            intlId="v2.pages.upgrade-plan.payment-details.Subscribe"
            IconRight={undefined}
            IconLeft={SubscribeIcon}
            leftIconMarginRight={4}
            running={isRunning}
            disabled={isRunning}
            active={!isRunning}
            onClick={onClickSubscribe}
          />

          {/* Cancel */}

          <Button
            className={`${ClassName}--content--actions--action`}
            colorType="default"
            intlId="common.Cancel"
            running={false}
            disabled={false}
            active={true}
            onClick={onClickGoBack}
          />
        </div>

        {/* Info about rights */}

        <div className={`${ClassName}--content--info`}>
          <Trans id="v2.pages.upgrade-plan.payment-details.ConfirmDescription" />
        </div>

        {/* Links to terms and privacy */}

        <div className={`${ClassName}--content--info`}>
          <DangerTrans
            template="... <c>Terms</c> ... <d>Privacy</d>"
            id="v2.pages.upgrade-plan.payment-details.TermsAndConditions"
            values={{
              // Terms

              c: (chunks: string) => (
                <a target="_blank" rel="noreferrer" href={Config.Links.Terms}>
                  {chunks}
                </a>
              ),

              // Privacy

              d: (chunks: string) => (
                <a target="_blank" rel="noreferrer" href={Config.Links.Privacy}>
                  {chunks}
                </a>
              ),
            }}
          />
        </div>

        {/* - */}
      </div>
    </STUserZonePlansPurchaseBcastSub2Premium>
  )
}
