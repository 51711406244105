import { UtilsSort } from '@dn/utils'
import { LogoutMT } from '../store/actions-mutators/logout/actions'

// ####################################################################################################
// ~~~~~~ Reducers
// ####################################################################################################

type Reducer<T> = {
  [reducer: string]: (state: T, action?: Action) => T
}

type Action = {
  type: string
  [key: string]: any
}

export const dynReducer = <T>(
  initState: T,
  reducer: Reducer<T>,
  outFun?: (state: T, action: any) => T,
  immutableFun?: (state: T, action: Action) => T,
) => {
  const outReducer: Reducer<T> = {
    [LogoutMT.DO_IT_NOW]: typeof outFun === 'function' ? outFun : () => initState,
  }

  const finalReduction = (state = initState, action: Action): T => {
    switch (true) {
      case !!reducer[action.type]:
        return reducer[action.type](state, action)

      case !!outReducer[action.type]:
        return outReducer[action.type](state, action)

      case !!immutableFun:
        return typeof immutableFun == 'function' ? immutableFun(state, action) : state

      default:
        return state
    }
  }

  return finalReduction as (state: T, action: Action) => T
}

// ####################################################################################################
// ~~~~~~ Complete clear model
// ####################################################################################################

export const genModelMR = <T>(prefix: string, initialModel: T) => {
  // ~~~~~~ Mutator types

  const MT = {
    SET_MODEL: `${prefix}-set-model`,
    COMPLETE_CLEAR_MODEL: `${prefix}-complete-clear-model`,
  }

  // ~~~~~~ Mutator creators

  const MC = {
    setModel: (value: Partial<T>) => ({
      type: MT.SET_MODEL,
      payload: value,
    }),

    completeClear: () => ({
      type: MT.COMPLETE_CLEAR_MODEL,
    }),
  }

  // ~~~~~~ Reducer

  const Reducer = {
    [MT.SET_MODEL]: (state: T, { payload }: ReturnType<typeof MC.setModel>): T => {
      return {
        ...state,
        ...payload,
      }
    },

    [MT.COMPLETE_CLEAR_MODEL]: (state: T): T => {
      return initialModel
    },
  }

  // ~~~~~~

  return {
    MT,
    MC,
    Reducer,
  }
}

// ####################################################################################################
// ~~~~~~ Fields with validations
// ####################################################################################################

export const genFieldMR = <T, U>(
  prefix: string,
  modelInitValue: T,
  fieldName: keyof T,
  fieldInitValue: U,
) => {
  // ~~~~~~ Mutator types

  const MT = {
    CHANGE: `${prefix}-ch-${String(fieldName)}`,
    SET_VALID: `${prefix}-set-valid-${String(fieldName)}`,
    SET_INVALID: `${prefix}-set-invalid-${String(fieldName)}`,
    CLEAR: `${prefix}-clear-field`,
  }

  // ~~~~~~ Mutator creators

  const MC = {
    change: (value: U) => ({
      type: MT.CHANGE,
      payload: value,
    }),

    setValid: () => ({
      type: MT.SET_VALID,
    }),

    setInvalid: (value: Infos[]) => ({
      type: MT.SET_INVALID,
      payload: value,
    }),

    clear: () => ({
      type: MT.CLEAR,
      payload: fieldInitValue,
    }),
  } as const

  // ~~~~~~ Reducer

  const Reducer = {
    [MT.CHANGE]: (state: T, { payload }: ReturnType<typeof MC.change>): T => {
      return {
        ...state,
        [fieldName]: payload,
      }
    },

    [MT.SET_VALID]: (state: U): U => {
      return {
        ...state,
        [`${String(fieldName)}_err`]: [],
      }
    },

    [MT.SET_INVALID]: (state: T, { payload }: ReturnType<typeof MC.setInvalid>): T => {
      return {
        ...state,
        [`${String(fieldName)}_err`]: payload,
      }
    },

    [MT.CLEAR]: (state: T): T => {
      return {
        ...state,
        [`${String(fieldName)}_err`]: [],
        [fieldName]: fieldInitValue,
      }
    },
  }

  // ~~~~~~

  return {
    MT,
    MC,
    Reducer,
  }
}

export type GenFieldMR<T, U> = {
  MT: {
    CHANGE: string
    SET_VALID: string
    SET_INVALID: string
    CLEAR: string
  }

  MC: {
    readonly change: (value: U) => {
      type: string
      payload: U
    }
    readonly setValid: () => {
      type: string
    }
    readonly setInvalid: (value: Infos[]) => {
      type: string
      payload: Infos[]
    }
    readonly clear: () => {
      type: string
      payload: U
    }
  }

  Reducer: {
    [x: string]:
      | ((
          state: T,
          {
            payload,
          }: {
            type: string
            payload: U
          },
        ) => T)
      | ((state: U) => U)
      | ((
          state: T,
          {
            payload,
          }: {
            type: string
            payload: Infos[]
          },
        ) => T)
  }
}

// ####################################################################################################
// ~~~~~~ Fields without validations. Changing the special field 'formPid' will reset the model
// ####################################################################################################

export const genBasicFieldMR = <T, U>(
  prefix: string,
  modelInitValue: T,
  fieldName: keyof T,
  fieldInitValue: U,
  optionalReset?: (newState: T) => T,
) => {
  const MT = {
    CHANGE: `${prefix}-ch-${String(fieldName)}`,
    CLEAR: `${prefix}-clear-field`,
  }

  const MC = {
    change: (value: U) => ({
      type: MT.CHANGE,
      payload: value,
    }),
    clear: () => ({
      type: MT.CLEAR,
    }),
  }

  const Reducer = {
    [MT.CHANGE]: (state: T, { payload }: ReturnType<typeof MC.change>): T => {
      const newState: T = {
        ...state,
        [fieldName]: payload,
      }

      if (fieldName === 'formPid') {
        const clearState = {
          ...modelInitValue,
          [fieldName]: payload,
        }

        return optionalReset ? optionalReset(clearState) : clearState
      }

      return newState
    },
    [MT.CLEAR]: (state: T): T => {
      return {
        ...state,
        [`${String(fieldName)}_err`]: [],
        [fieldName]: fieldInitValue,
      }
    },
  }

  return {
    MT,
    MC,
    Reducer,
  }
}

// ####################################################################################################
// ~~~~~~ Update element in list
// ####################################################################################################

export const updateElementInList = <T>(
  list: T[],
  patch: Partial<T>,
  filter: (item: T) => boolean,
  sort?: {
    sortBy: keyof T
    order: 'ASC' | 'DESC'
  },
) => {
  const newList: T[] = list.map((item) => {
    if (!filter(item)) return item

    const newItem: T = {
      ...item,
      ...patch,
    }

    return newItem
  })

  return sort ? UtilsSort.sortBy(newList, sort.sortBy, sort.order) : newList
}

// ####################################################################################################
// ~~~~~~ Add element to list
// ####################################################################################################

export const addElementToList = <T>(
  list: T[],
  element: T,
  sort?: {
    sortBy: keyof T
    order: 'ASC' | 'DESC'
  },
) => {
  const newList: T[] = [...list, element]

  return sort ? UtilsSort.sortBy(newList, sort.sortBy, sort.order) : newList
}

// ####################################################################################################
// ~~~~~~ Rem elment in list
// ####################################################################################################

export const remElementInList = <T>(
  list: T[],
  filter: (item: T) => boolean,
  sort?: {
    sortBy: keyof T
    order: 'ASC' | 'DESC'
  },
) => {
  const newList: T[] = list.filter((el) => !filter(el))

  return sort ? UtilsSort.sortBy(newList, sort.sortBy, sort.order) : newList
}
