import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

import { StoreState } from '../../../../models/app/model'
import { BuyLicenseMC } from '../../../../store/actions-mutators/buy-license/mutators'
import { ProductIds } from '../../../../constants/product-ids'
import { ApiStoreProductsAC } from '../../../../store/actions/api-store/products/actions'
import { useNavigate, useParams } from 'react-router'
import { Routes } from '../../../../constants/routes'
import { PagePurchaseBcastContentStep2VerifyEmail } from './step-2-verify-email/component'
import { PagePurchaseBcastContentStep1CreateAccount } from './step-1-create-account/component'
import { PagePurchaseBcastStep3Payment } from './step-3-payment/component'
import { PagePurchaseBcastContentStep4Done } from './step-4-done/component'

// ~~~~~~ Component

export const PagePurchaseBcastContent = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const interval = useParams<{ interval?: string }>().interval

  const navigate = useNavigate()

  // ~~~~~~ State

  const metaProduct = useSelector((state: StoreState) => state.broadcastSub)

  const buyLicense = useSelector((state: StoreState) => state.buyLicense)

  // ~~~~~~ Computed

  const curStep = buyLicense.formStep

  // ~~~~~~ Effects

  // - For reload: if we don't have product bcast, call the api

  useEffect(() => {
    if (metaProduct.uiStatus !== 'init') return

    dispatch(ApiStoreProductsAC.listProducts(ProductIds.BroadcastSub))

    //
  }, [dispatch, metaProduct.uiStatus])

  // - For reload: if buy license is not setted, set as premiumProduct to
  // start the "create account" flow

  useEffect(() => {
    if (metaProduct.uiStatus !== 'completed') return

    if (buyLicense.subId) return

    // This will happen only if an user manually
    // modify the url

    if (!interval || !['monthly', 'yearly'].includes(interval)) {
      navigate(Routes.Bcast)
      return
    }

    const product = interval === 'monthly' ? 'month' : 'year'

    const premiumProduct = metaProduct.products.premium[product]

    dispatch(
      BuyLicenseMC.setLicense({
        productId: ProductIds.BroadcastSub,
        platform: 'web',
        subId: premiumProduct.id,
        unitPrice: premiumProduct.price,
        trial: false,
        alwaysFree: false,
        trialPeriodDays: -1,
      }),
    )

    //
  }, [
    buyLicense.subId,
    dispatch,
    interval,
    metaProduct.products.premium,
    metaProduct.uiStatus,
    navigate,
  ])

  // ~~~~~~ Render

  switch (curStep) {
    case 1:
      return <PagePurchaseBcastContentStep1CreateAccount />

    case 2:
      return <PagePurchaseBcastContentStep2VerifyEmail />

    case 3:
      return <PagePurchaseBcastStep3Payment />

    case 4:
      return <PagePurchaseBcastContentStep4Done />

    default:
      return null
  }
}
