import Skeleton from 'react-loading-skeleton'

import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { Trans } from '../../../../components/common/intl/trans'
import { ClassName, STUserZoneBillingRoot } from './style'
import { StoreState } from '../../../../models/app/model'
import { Countries } from '../../../../constants/countries'
import { Dollars } from '../../../../components/common/dollars/component'
import { Button } from '../../../../components/common/button/component'
import { LazySVG } from '../../../../components/svgs/lazy-svg/component'
import { UserZoneBillingRootCard } from './payment-method/component'
import { Routes } from '../../../../constants/routes'
import { Footer } from '../../../../components/common/footer/component'
import { UtilsDates, UtilsString } from '@dn/utils'

// ~~~~~ Constants

const EditBillingInfoIcon = LazySVG('icons/pencil')

const AddPaymentIcon = LazySVG('icons/plus-circle')

const DownloadIcon = LazySVG('icons/arrow-down-circle')

const FakeCard: DN.Models.Card.ModelFront = {
  uiStatus: 'running',
  errors: [],
  payment_method_id: '',
  brand: '',
  last4: '',
  exp_month: 0,
  exp_year: 0,
  is_default: false,
}

// ~~~~~~ Component

export const UserZoneBillingRoot = () => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  // ~~~~~~ State

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  const billingInfo = useSelector((state: StoreState) => state.billingInfoShow)

  const cards = useSelector((state: StoreState) => state.cards)

  const invoices = useSelector((state: StoreState) => state.invoices)

  // ~~~~~~ Computed

  const currentUserIsRunning = currentUser.uiStatus === 'running'

  // ~~~~~~ Handlers

  function onClickEditBillingInfo() {
    navigate(Routes.UserZoneBillinEditBillingInfo)
  }

  function onClickAddPayment() {
    navigate(Routes.UserZoneBillinAddPaymentMethod)
  }

  // ~~~~~~ Render

  return (
    <STUserZoneBillingRoot>
      {/* Top: Billing + Cards */}

      <div className={`${ClassName}--top`}>
        {/* Billing details */}

        <div className={`${ClassName}--top--billing`}>
          {/* Title */}

          <div className={`${ClassName}--top--billing--title`}>
            <Trans id="v2.pages.billing.BillingDetails" />
          </div>

          {/* Email Address */}

          <div className={`${ClassName}--top--billing--line`}>
            <div className={`${ClassName}--top--billing--line--left`}>
              <Trans id="v2.pages.billing.EmailAddress" />
            </div>

            <div className={`${ClassName}--top--billing--line--right`}>
              {currentUserIsRunning ? <Skeleton /> : currentUser.email}
            </div>
          </div>

          {/* Billing Contact */}

          <div className={`${ClassName}--top--billing--line`}>
            <div className={`${ClassName}--top--billing--line--left`}>
              <Trans id="v2.pages.billing.BillingContact" />
            </div>

            <div className={`${ClassName}--top--billing--line--right`}>
              {currentUserIsRunning ? <Skeleton /> : billingInfo.contact_name || '-'}
            </div>
          </div>

          {/* Address */}

          <div className={`${ClassName}--top--billing--line`}>
            <div className={`${ClassName}--top--billing--line--left`}>
              <Trans id="v2.pages.billing.BillingAddress" />
            </div>

            <div className={`${ClassName}--top--billing--line--right`}>
              {currentUserIsRunning ? <Skeleton /> : undefined}

              {/* Company */}

              {billingInfo.company ? <div>{billingInfo.company}</div> : undefined}

              {/* Address */}

              {billingInfo.address ? <div>{billingInfo.address}</div> : undefined}

              {/* City */}

              {billingInfo.city ? <div>{billingInfo.city}</div> : undefined}

              {/* State/Region */}

              {billingInfo.state_region ? <div>{billingInfo.state_region}</div> : undefined}

              {/* Cp/Zip */}

              {billingInfo.cp_zip ? <div>{billingInfo.cp_zip}</div> : undefined}

              {/* Country */}

              {billingInfo.country ? (
                <div>{Countries[billingInfo.country as keyof typeof Countries]}</div>
              ) : undefined}
            </div>
          </div>

          {/* Action */}

          <div className={`${ClassName}--top--billing--action`}>
            <Button
              colorType="quaternary-link"
              intlId="v2.pages.billing.ChangeBillingDetails"
              leftIconMarginRight={4}
              size="s"
              fontWeight="600"
              IconLeft={EditBillingInfoIcon}
              running={false}
              disabled={false}
              active={true}
              onClick={onClickEditBillingInfo}
            />
          </div>
        </div>

        {/* Separator */}

        <hr className={`${ClassName}--separator`} />

        {/* Payment method (Cards) */}

        <div className={`${ClassName}--top--payment`}>
          {/* Title */}

          <div className={`${ClassName}--top--payment--title`}>
            <Trans id="pages.common.PaymentMethod" />
          </div>

          {/* List */}

          <div className={`${ClassName}--top--payment--cards`}>
            {cards.list.map((card) => (
              <div
                key={card.payment_method_id}
                className={`${ClassName}--top--payment--cards--card`}
              >
                <UserZoneBillingRootCard card={card} />
              </div>
            ))}

            {currentUserIsRunning || cards.uiStatusAdd === 'running' ? (
              <div className={`${ClassName}--top--payment--cards--card`}>
                <UserZoneBillingRootCard card={FakeCard} />
              </div>
            ) : undefined}
          </div>

          {/* Action */}

          <div className={`${ClassName}--top--payment--action`}>
            <Button
              colorType="quaternary-link"
              intlId="pages.common.AddPaymentMethod"
              leftIconMarginRight={4}
              IconLeft={AddPaymentIcon}
              size="s"
              fontWeight="600"
              running={false}
              disabled={false}
              active={true}
              onClick={onClickAddPayment}
            />
          </div>
        </div>
      </div>

      {/* Separator */}

      <hr className={`${ClassName}--separator`} />

      {/* Invoices */}

      <div className={`${ClassName}--invoices`}>
        {/* Title */}

        <div className={`${ClassName}--invoices--title`}>
          <Trans id="v2.pages.billing.BillingHistory" />
        </div>

        {/* List */}

        <div className={`${ClassName}--invoices--list`}>
          {currentUser.uiStatus === 'running' ? (
            <Skeleton containerClassName={`${ClassName}--invoices--list--item`} />
          ) : undefined}

          {invoices.map((invoice) => {
            const productIdSubscription =
              invoice.product_id === 'broadcast-subscription' ? 'broadcast' : invoice.product_id

            const isBroadcastPremium =
              invoice.product_id === 'broadcast-subscription' && invoice.amount > 0

            return (
              <div key={invoice.invoice_number} className={`${ClassName}--invoices--list--item`}>
                {/* Date & Product */}

                <div className={`${ClassName}--invoices--list--item--a`}>
                  {/* Date */}

                  <div className={`${ClassName}--invoices--list--item--date`}>
                    {UtilsDates.getMediumDate(invoice.payment_date)}
                  </div>

                  {/* Product */}

                  <div className={`${ClassName}--invoices--list--item--product`}>
                    <span>
                      {UtilsString.capitalize(
                        isBroadcastPremium
                          ? `${productIdSubscription} Premium`
                          : productIdSubscription,
                      )}
                    </span>
                    &nbsp;
                    <span className={`${ClassName}--invoices--list--item--product--interval`}>
                      (
                      {invoice.interval === 'month' ? (
                        <Trans id="pages.common.Monthly" />
                      ) : invoice.interval === 'year' ? (
                        <Trans id="pages.common.Yearly" />
                      ) : undefined}
                      )
                    </span>
                  </div>
                </div>

                {/* Amount & download */}

                <div className={`${ClassName}--invoices--list--item--b`}>
                  {/* Amount */}

                  <div className={`${ClassName}--invoices--list--item--amount`}>
                    <Dollars value={invoice.amount} />
                  </div>

                  {/* Donwload invoice */}

                  <div className={`${ClassName}--invoices--list--item--download`}>
                    <Button
                      colorType="quaternary-link"
                      intlId="v2.pages.plan-details.DownloadInvoice"
                      leftIconMarginRight={4}
                      size="s"
                      fontWeight="600"
                      IconLeft={DownloadIcon}
                      running={false}
                      disabled={false}
                      active={true}
                      onClick={() => window.open(invoice.invoice_pdf)}
                    />
                  </div>
                </div>
              </div>
            )
          })}
        </div>
      </div>

      {/* Footer */}

      <Footer />

      {/* - */}
    </STUserZoneBillingRoot>
  )
}
