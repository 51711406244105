import styled from 'styled-components'
import { theme } from '../../../../../style/utils/theme'
import { Colors } from '../../../../../style/theme/colors'
import { Fonts } from '../../../../../style/theme/fonts'

// ~~~~~~ Constant

export const ClassName = 'page-common-op-top-left'

// ~~~~~~ Component

export const STPageCommonOPTopLeft = styled.div`
  cursor: pointer;

  display: flex;
  align-items: center;

  .${ClassName}--icon {
    margin-right: 8px;
    display: flex;

    svg {
      width: 20px;
      height: 20px;

      path {
        fill: ${theme(Colors.WOLF_00_00)};
      }
    }
  }

  .${ClassName}--text {
    ${Fonts.Weight[600]}
    ${Fonts.Scale.BodyBig}

    color: ${theme(Colors.WOLF_00_00)};
  }
`
