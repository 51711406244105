import { genBasicFieldMR, genFieldMR } from '../../utils/store'
import { ValidateUtils } from '../../utils/validations'

const initModel: DN.Models.BuyLicense.Model = {
  uiStatus: 'init',
  errors: [],

  productId: '',
  platform: '',
  subId: '',
  unitPrice: 0,
  formStep: 1,

  trialPeriodDays: null,
  trial: false,
  alwaysFree: false,

  quantity: '1',
  quantity_err: [],

  email: '',
  email_err: [],

  password: '',
  password_err: [],

  code: '',
  code_err: [],

  emailValidated: false,
}

// ~~~~~~

const prefix = 'buy-license'

export const BuyLicenseFieldMR = {
  platform: genBasicFieldMR(prefix, initModel, 'platform', initModel.platform),
  subId: genFieldMR(prefix, initModel, 'subId', initModel.subId),
  quantity: genFieldMR(prefix, initModel, 'quantity', initModel.quantity),
  email: genFieldMR(prefix, initModel, 'email', initModel.email),
  password: genFieldMR(prefix, initModel, 'password', initModel.password),
  code: genFieldMR(prefix, initModel, 'code', initModel.code),
  formStep: genBasicFieldMR(prefix, initModel, 'formStep', initModel.formStep),
  productId: genBasicFieldMR(prefix, initModel, 'productId', initModel.productId),
}

// ~~~~~~ Validations

const { Validations } = ValidateUtils

const validations = {
  Quantity: [{ error: Validations.Required }, { error: Validations.Min, value: 1 }],

  Email: [{ error: Validations.Required }, { error: Validations.Email }],

  Password: [{ error: Validations.Required }, { error: Validations.Password, value: 8 }],

  Code: [
    { error: Validations.Required },
    { error: Validations.MinLength, value: 6 },
    { error: Validations.MaxLength, value: 6 },
  ],
}

// ~~~~~~

export const BuyLicenseModel = {
  initModel,
  validations,
} as const
