import { Trans } from '../../../../../components/common/intl/trans'
import { LazySVG } from '../../../../../components/svgs/lazy-svg/component'
import { ClassName, STPageCommonOPTopLeft } from './style'

// ~~~~~~ Constants

const ArrowBackIcon = LazySVG('icons/arrow-long-left')

// ~~~~~~ Types

type Props = {
  onClickGoBack: () => void
}

// ~~~~~~ Component

export const PageCommonOPTopLeft: React.FC<Props> = ({ onClickGoBack }) => {
  // ~~~~~~ Render

  return (
    <STPageCommonOPTopLeft onClick={onClickGoBack}>
      <div className={`${ClassName}--icon`}>
        <ArrowBackIcon size={20} />
      </div>

      <div className={`${ClassName}--text`}>
        <Trans id="common.Back" />
      </div>
    </STPageCommonOPTopLeft>
  )
}
