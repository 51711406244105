import { useNavigate } from 'react-router'
import { STUserZonePlansSubAddPaymentMethod } from './style'
import { RouteDataAddPaymentMethod } from '../../../../constants/routes'
import { useUserZoneDecDataParam } from '../../../../hooks/user-zone-dec-data-param/hook'
import { UserZoneCommonPaymentMethodNew } from '../../@-common/payment-method/new/component'

// ~~~~~~ Component

export const UserZonePlansSubAddPaymentMethod = () => {
  // ~~~~~~ Hooks

  const data = useUserZoneDecDataParam<RouteDataAddPaymentMethod>()

  const navigate = useNavigate()

  // ~~~~~~ Handlers

  function onAddNewCardSuccess() {
    if (!data) return

    navigate(data.successPath)
  }

  // ~~~~~~ Render

  if (!data) return

  return (
    <STUserZonePlansSubAddPaymentMethod>
      {/* Go back */}

      <UserZoneCommonPaymentMethodNew
        backIntl="wildcard"
        backIntlValues={{ value: data.backText }}
        backPath={data.backPath}
        onSuccess={onAddNewCardSuccess}
      />

      {/* - */}
    </STUserZonePlansSubAddPaymentMethod>
  )
}
