import styled from 'styled-components'

import creditCardsLogos from '../../../imgs/pngs/credit-cards-logos.png'
import { Fonts } from '../../../style/theme/fonts'
import { getThemeValue } from '../../../style/utils/theme'

// ~~~~~~ Types

export type STProps = {
  $creditCardXPosition?: number
  $creditCardDangerIconColor?: ThemeColor
}

// ~~~~~~ Component

export const STCreditCardBrand = styled.div<STProps>`
  display: flex;
  align-items: center;

  .credit-card-logo {
    background-image: url(${creditCardsLogos});
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: center;

    ${({ $creditCardXPosition }) =>
      $creditCardXPosition ? `background-position-x: ${$creditCardXPosition}px` : ''};

    width: 24px;
    height: 14px;

    padding: 4px;
    border-radius: 2px;
    margin-right: 8px;
  }

  .credit-card-code {
    ${Fonts.Scale.BodyBig}
    ${Fonts.Weight[600]}

    white-space: nowrap;
  }

  svg {
    margin-left: 4px;

    width: 16px;

    path {
      ${(props) =>
        props.$creditCardDangerIconColor
          ? `fill: ${getThemeValue(props, props.$creditCardDangerIconColor)};`
          : ''};
    }
  }
`
