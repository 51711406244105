import { useSelector } from 'react-redux'

import { StoreState } from '../../../models/app/model'
import { LazySVG } from '../../svgs/lazy-svg/component'
import { STCommonProductLogo, STProps } from './style'
import { UtilsLog } from '../../../utils/logs'

// ~~~~~~ Constants

const LauncherLightLogo = LazySVG('logos/launcher-light')
const LauncherDarkLogo = LazySVG('logos/launcher-dark')
const LauncherLightSmallLogo = LazySVG('logos/launcher-light-small')
const LauncherDarkSmallLogo = LazySVG('logos/launcher-dark-small')
const LauncherGradientLogo = LazySVG('logos/launcher-gradient')

const MontageLightLogo = LazySVG('logos/montage-light')
const MontageDarkLogo = LazySVG('logos/montage-dark')
const MontageLightSmallLogo = LazySVG('logos/montage-light-small')
const MontageDarkSmallLogo = LazySVG('logos/montage-dark-small')
const MontageGradientLogo = LazySVG('logos/montage-gradient')

const BroadcastLightLogo = LazySVG('logos/broadcast-sub-light')
const BroadcastDarkLogo = LazySVG('logos/broadcast-sub-dark')
const BroadcastLightSmallLogo = LazySVG('logos/broadcast-sub-light-small')
const BroadcastDarkSmallLogo = LazySVG('logos/broadcast-sub-dark-small')
const BroadcastGradientLogo = LazySVG('logos/broadcast-gradient')

const Logos = {
  light: {
    normal: {
      launcher: LauncherLightLogo,
      montage: MontageLightLogo,
      'broadcast-subscription': BroadcastLightLogo,
    },
    small: {
      launcher: LauncherLightSmallLogo,
      montage: MontageLightSmallLogo,
      'broadcast-subscription': BroadcastLightSmallLogo,
    },
    gradient: {
      launcher: LauncherGradientLogo,
      montage: MontageGradientLogo,
      'broadcast-subscription': BroadcastGradientLogo,
    },
  },
  dark: {
    normal: {
      launcher: LauncherDarkLogo,
      montage: MontageDarkLogo,
      'broadcast-subscription': BroadcastDarkLogo,
    },
    small: {
      launcher: LauncherDarkSmallLogo,
      montage: MontageDarkSmallLogo,
      'broadcast-subscription': BroadcastDarkSmallLogo,
    },
    gradient: {
      launcher: LauncherGradientLogo,
      montage: MontageGradientLogo,
      'broadcast-subscription': BroadcastGradientLogo,
    },
  },
}

// ~~~~~~ Types

type Props = STProps & {
  productId: DN.Models.Product.ProductId
  gradientImage?: true
  imageSmall?: true
}

// ~~~~~~ Component

export const ProductLogo: React.FC<Props> = ({
  productId,
  gradientImage,
  $productLogoWidth: productLogoWidth,
  imageSmall,
  $fullWidthLogo: fullWidthLogo,
  $marginBottom: marginBottom,
}) => {
  // ~~~~~~~ Hooks

  const theme = useSelector((store: StoreState) => 'light' as Theme)

  // ~~~~~~~ Computed

  const logoType = gradientImage ? 'gradient' : imageSmall ? 'small' : 'normal'

  const ProductLogo = Logos[theme][logoType][productId]

  // ~~~~~~~ Render

  if (!ProductLogo) {
    UtilsLog.devWarn('No product logo for', productId)
    return null
  }

  return (
    <STCommonProductLogo
      data-test="common-product-logo"
      $marginBottom={marginBottom}
      $productLogoWidth={productLogoWidth}
      $fullWidthLogo={fullWidthLogo}
    >
      <div className="product-logo">
        <ProductLogo size={30} />
      </div>
    </STCommonProductLogo>
  )
}
