import { AppInitState } from '../../../models/app/model'
import { dynReducer } from '../../../utils/store'
import { RootMT } from '../../actions-mutators/root/mutators'
import {
  ApiStoreCurrentUserAC,
  ApiStoreCurrentUserAT,
} from '../../actions/api-store/current-user/actions'

const initState = AppInitState.root

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ Token
// ####################################################################################################

reducer[ApiStoreCurrentUserAT.SHOW] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreCurrentUserAC.show>,
): State => {
  return {
    ...state,
    token: payload.auth0Token,
  }
}

// ####################################################################################################
// ~~~~~~ Side menu
// ####################################################################################################

reducer[RootMT.SHOW_SIDE_MENU] = (state: State): State => {
  return {
    ...state,
    showSideMenu: true,
  }
}

reducer[RootMT.HIDE_SIDE_MENU] = (state: State): State => {
  return {
    ...state,
    showSideMenu: false,
  }
}

// ~~~~~~

export const rootReducer = dynReducer(initState, reducer)
