const Brooklyn = {
  80: '#E03131',
  70: '#A73735',
  60: '#FA5252',
  50: '#FF6B6B',
  40: '#F87272',
  30: '#FFA8A8',
  20: '#FFC9C9',
  10: '#F9C8C7',
  5: '#FDEDED',
} as const

// ~~~~~~

const BROOKLYN_05_05: ThemeColor = {
  light: Brooklyn[5],
  dark: Brooklyn[5],
}

const BROOKLYN_10_10: ThemeColor = {
  light: Brooklyn[10],
  dark: Brooklyn[10],
}

const BROOKLYN_40_40: ThemeColor = {
  light: Brooklyn[40],
  dark: Brooklyn[40],
}

const BROOKLYN_50_50: ThemeColor = {
  light: Brooklyn[50],
  dark: Brooklyn[50],
}

const BROOKLYN_60_60: ThemeColor = {
  light: Brooklyn[60],
  dark: Brooklyn[60],
}

const BROOKLYN_70_70: ThemeColor = {
  light: Brooklyn[70],
  dark: Brooklyn[70],
}

const BROOKLYN_80_80: ThemeColor = {
  light: Brooklyn[80],
  dark: Brooklyn[80],
}

// ~~~~~~

export const BrooklynColors = {
  BROOKLYN_05_05,
  BROOKLYN_10_10,
  BROOKLYN_40_40,
  BROOKLYN_50_50,
  BROOKLYN_60_60,
  BROOKLYN_70_70,
  BROOKLYN_80_80,
} as const
