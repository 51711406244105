import { useDispatch, useSelector } from 'react-redux'
import { UtilsDates, UtilsEncDec } from '@dn/utils'

import { Button } from '../../../../../../components/common/button/component'
import { Dollars } from '../../../../../../components/common/dollars/component'
import { InfosBox } from '../../../../../../components/common/infos/box/component'
import { DangerTrans } from '../../../../../../components/common/intl/danger-trans'
import { Trans } from '../../../../../../components/common/intl/trans'
import { LazySVG } from '../../../../../../components/svgs/lazy-svg/component'
import { Config } from '../../../../../../config'
import { ClassName, STUserZonePlansProductDetailBcastPremium } from './style'
import { ModalsIds } from '../../../../../../constants/modals'
import { ModalsMC } from '../../../../../../store/actions-mutators/modals/mutators'
import { SubscriptionMC } from '../../../../../../store/actions-mutators/subscriptions/mutators'
import { CreditCard } from '../../../../../../components/common/credit-card/component'
import { StoreState } from '../../../../../../models/app/model'
import {
  RouteDataAddPaymentMethod,
  RouteDataChangePaymentMethod,
  Routes,
} from '../../../../../../constants/routes'
import { useNavigate } from 'react-router'
import { useTrans } from '../../../../../../hooks/use-trans'

// ~~~~~~ Constants

const StartBcastingIcon = LazySVG('icons/arrow-right-up')

const EditIcon = LazySVG('icons/pencil')

const AddIcon = LazySVG('icons/plus-circle')

const DownloadIcon = LazySVG('icons/arrow-down-circle')

const ResumeIcon = LazySVG('icons/arrow-circle')

const ErrorIcon = LazySVG('icons/exclamation-triangle')

const StripeGracePeriodDays = 5

// ~~~~~~ Types

type Props = {
  isNew: boolean
  sub: DN.Models.Subscription.ModelFront
}

// ~~~~~~ Component

export const UserZonePlansProductDetailBcastPremium: React.FC<Props> = ({ isNew, sub }) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const navigate = useNavigate()

  const backText = useTrans('v2.pages.plan-details.PlanDetails')

  // ~~~~~~ State

  const cards = useSelector((state: StoreState) => state.cards)

  // ~~~~~~ Computed

  const nextBillingDate = UtilsDates.getMediumDate(sub.current_period_end)

  const lastBillingDate = sub.last_invoice
    ? UtilsDates.getMediumDate(sub.last_invoice.payment_date)
    : '-'

  const curPeriodEnd = new Date(sub.current_period_end)

  const card = cards.list.find((card) => card.payment_method_id === sub.payment_method_id)

  const isCanceled = !!sub.cancel_at

  const isUnpaid = sub.subscription_status === 'past_due' || sub.subscription_status === 'unpaid'

  curPeriodEnd.setDate(curPeriodEnd.getDate() + StripeGracePeriodDays)

  const maxUnpaidBillingDate = UtilsDates.getMediumDate(curPeriodEnd.toISOString())

  const showNextBillingDate = !isCanceled

  // ~~~~~~ Handlers

  function onClickStartBcasting() {
    window.open(Config.BroadcastWeb.Url, '_blank')
  }

  function onClickResumePlan() {
    dispatch(SubscriptionMC.setTmpSubscription(sub))

    dispatch(ModalsMC.open(ModalsIds.ConfirmReactivatePlan))
  }

  function onClickChangePlan() {
    navigate(Routes.genUserZonePlansBcastDetailChangePlan(sub.subscription_id))
  }

  function onClickChangePaymentMethod() {
    const curPath = `${window.location.pathname}${window.location.search}`

    const nextDataObj: RouteDataChangePaymentMethod = {
      backPath: curPath,
      backText: backText,
      successPath: curPath,
      subId: sub.subscription_id,
    }

    const nextData = UtilsEncDec.base64UrlEncode(JSON.stringify(nextDataObj))

    const changeCardRoute = Routes.genUserZonePlansChangePaymentMethod(nextData)

    navigate(changeCardRoute)
  }

  function onClickAddPaymentMethod() {
    const hasCards = !!cards.list.length

    if (hasCards) {
      onClickChangePaymentMethod()
      return
    }

    const curPath = `${window.location.pathname}${window.location.search}`

    const nextDataObj: RouteDataAddPaymentMethod = {
      backPath: curPath,
      backText: backText,
      successPath: curPath,
    }

    const nextData = UtilsEncDec.base64UrlEncode(JSON.stringify(nextDataObj))

    const addCardRoute = Routes.genUserZonePlansAddPaymentMethod(nextData)

    navigate(addCardRoute)
  }

  function onClickCancelPlan() {
    if (!sub) return

    dispatch(SubscriptionMC.setTmpSubscription(sub))

    dispatch(ModalsMC.open(ModalsIds.ConfirmCancelPlan))
  }

  // ~~~~~~ Render

  return (
    <STUserZonePlansProductDetailBcastPremium>
      {/* Title */}

      <div className={`${ClassName}--title`}>
        <Trans id="v2.pages.plan-details.PlanDetails" />
      </div>

      {/* ----- INFO - START ----- */}

      {/* Info: New Plan */}

      {isNew && !isCanceled ? (
        <InfosBox
          kind="blue"
          className={`${ClassName}--info`}
          titleIntl="v2.pages.plan-details.new-subscription-description.broadcast.Title"
          contentText={[
            {
              id:
                sub.interval === 'month'
                  ? 'v2.pages.plan-details.new-subscription-description.broadcast.premium-broadcast-description.MonthlyPremium'
                  : 'v2.pages.plan-details.new-subscription-description.broadcast.premium-broadcast-description.YearlyPremium',
            },
            {
              id: 'v2.pages.plan-details.new-subscription-description.broadcast.premium-broadcast-description.TimeToStartBroadcast',
            },
          ]}
        >
          <div style={{ marginTop: 8 }}>
            <Button
              colorType="quaternary-link"
              intlId="common.StartBroadcasting"
              IconRight={StartBcastingIcon}
              rightIconMarginLeft={4}
              noBorder
              padding="0"
              size="s"
              fontWeight="500"
              running={false}
              disabled={false}
              active={true}
              onClick={onClickStartBcasting}
            />
          </div>
        </InfosBox>
      ) : undefined}

      {/* Info: Unpaid */}

      {isUnpaid ? (
        <InfosBox
          kind="yellow"
          className={`${ClassName}--info`}
          $titleFontWeight={300}
          titleIntl="v2.pages.plan-details.ProblemWithPaymentAlert"
          titleIntlValues={{
            value: maxUnpaidBillingDate,
          }}
        />
      ) : undefined}

      {/* Info: Canceled */}

      {isCanceled ? (
        <InfosBox
          kind="red"
          className={`${ClassName}--info`}
          titleIntl="v2.pages.plan-details.CanceledCommonTitle"
          contentText={[
            {
              id: 'v2.pages.plan-details.canceled-paid-plan.Content',
              values: {
                productName: 'Broadcast',
                endDate: nextBillingDate,
              },
            },
          ]}
        >
          <div style={{ marginTop: 8 }}>
            <Button
              colorType="quaternary-link"
              intlId="v2.pages.plan-details.ResumePlan"
              IconLeft={ResumeIcon}
              leftIconMarginRight={4}
              noBorder
              padding="0"
              size="s"
              fontWeight="500"
              running={false}
              disabled={false}
              active={true}
              onClick={onClickResumePlan}
            />
          </div>
        </InfosBox>
      ) : undefined}

      {/* Info: no-Canceled & no-Card */}

      {!isCanceled && !card ? (
        <InfosBox
          kind="red"
          className={`${ClassName}--info`}
          titleIntl="v2.pages.plan-details.unpaid-payment-removed-warning.Title"
          contentText={[
            {
              id: 'v2.pages.plan-details.unpaid-payment-removed-warning.Content',
              values: {
                productName: 'Broadcast Premium',
                endDate: nextBillingDate,
              },
            },
          ]}
        >
          <div style={{ marginTop: 8 }}>
            <Button
              colorType="quaternary-link"
              intlId="v2.pages.plan-details.ResumePlan"
              IconLeft={ResumeIcon}
              leftIconMarginRight={4}
              noBorder
              padding="0"
              size="s"
              fontWeight="500"
              running={false}
              disabled={false}
              active={true}
              onClick={onClickAddPaymentMethod}
            />
          </div>
        </InfosBox>
      ) : undefined}

      {/* ----- INFO - END ----- */}

      {/* Details */}

      <div className={`${ClassName}--details`}>
        {/* Subscription */}

        <div className={`${ClassName}--details--row`}>
          {/* Title */}

          <div className={`${ClassName}--details--row--title`}>
            <Trans id="pages.user-zone.plans-tab.Subscription" />
          </div>

          {/* Price/Change */}

          <div>
            <div className={`${ClassName}--details--row--highlight`}>
              <Dollars value={sub.amount} />

              <div className={`${ClassName}--details--row--highlight--interval`}>
                (<Trans id={sub.interval === 'month' ? 'common.Monthly' : 'common.Yearly'} />)
              </div>
            </div>

            <div>
              <Button
                colorType="quaternary-link"
                intlId="v2.pages.plan-details.ChangePlan"
                intlValues={{ value: 'Premium' }}
                IconLeft={EditIcon}
                leftIconMarginRight={4}
                padding="0"
                size="s"
                fontWeight="500"
                running={false}
                disabled={false}
                active={true}
                onClick={onClickChangePlan}
              />
            </div>
          </div>
        </div>

        {/* Payment Method */}

        <div className={`${ClassName}--details--row`}>
          {/* Title */}

          <div className={`${ClassName}--details--row--title`}>
            <Trans id="pages.common.PaymentMethod" />
          </div>

          {/* Card: A) Card -> change B) No Card -> Add */}

          <div>
            <div className={`${ClassName}--details--row--highlight ${card ? '' : 'smooth'}`}>
              {card ? (
                <>
                  <CreditCard card={card} />

                  {isUnpaid ? (
                    <div className={`${ClassName}--details--row--highlight--error`}>
                      <ErrorIcon size={14} />
                    </div>
                  ) : undefined}
                </>
              ) : (
                <Trans id="v2.pages.plan-details.NoneAdd" />
              )}
            </div>

            <div>
              {card ? (
                <Button
                  colorType="quaternary-link"
                  intlId="pages.common.ChangePayment"
                  IconLeft={EditIcon}
                  leftIconMarginRight={4}
                  padding="0"
                  size="s"
                  fontWeight="500"
                  running={false}
                  disabled={false}
                  active={true}
                  onClick={onClickChangePaymentMethod}
                />
              ) : (
                <Button
                  colorType="quaternary-link"
                  intlId="pages.common.AddPaymentMethod"
                  IconLeft={AddIcon}
                  leftIconMarginRight={4}
                  padding="0"
                  size="s"
                  fontWeight="500"
                  running={false}
                  disabled={false}
                  active={true}
                  onClick={onClickAddPaymentMethod}
                />
              )}
            </div>
          </div>
        </div>

        {/* Next Billing Date */}

        <div className={`${ClassName}--details--row`}>
          <div className={`${ClassName}--details--row--title`}>
            <Trans id="pages.user-zone.plans-tab.NextBillingDate" />
          </div>

          <div className={`${ClassName}--details--row--highlight only`}>
            {showNextBillingDate ? nextBillingDate : '-'}
          </div>
        </div>

        {/* Last Billing Date */}

        <div className={`${ClassName}--details--row`}>
          <div className={`${ClassName}--details--row--title`}>
            <Trans id="pages.common.LastBillingDate" />
          </div>

          <div>
            <div className={`${ClassName}--details--row--highlight`}>{lastBillingDate}</div>

            {sub.last_invoice ? (
              <div>
                <Button
                  colorType="quaternary-link"
                  intlId="v2.pages.plan-details.DownloadInvoice"
                  intlValues={{ value: 'Premium' }}
                  IconLeft={DownloadIcon}
                  leftIconMarginRight={4}
                  padding="0"
                  size="s"
                  fontWeight="500"
                  running={false}
                  disabled={false}
                  active={true}
                  onClick={() => sub.last_invoice && window.open(sub.last_invoice.invoice_pdf)}
                />
              </div>
            ) : undefined}
          </div>
        </div>
      </div>

      {/* Cancel Plan */}

      {!isCanceled ? (
        <>
          <div className={`${ClassName}--title`}>
            <Trans id="v2.pages.plan-details.CancelPlan" />
          </div>

          <div className={`${ClassName}--cancel`}>
            <div className={`${ClassName}--cancel--line`}>
              <DangerTrans
                template="... <c>{value}</c>..."
                id="v2.pages.plan-details.CancelHelp"
                values={{
                  value: Config.Emails.Orders,
                  c: (chunks: string) => <b>{chunks}</b>,
                }}
              />
            </div>

            <div className={`${ClassName}--cancel--line`}>
              <Trans
                id="v2.pages.plan-details.IfCancelPLan"
                values={{
                  productName: 'Broadcast',
                  endDate: nextBillingDate,
                }}
              />
            </div>

            <div className={`${ClassName}--cancel--action`}>
              <Button
                colorType="quinary-link"
                intlId="dialogs.confirm.cancel-plan.Title"
                rightIconMarginLeft={4}
                padding="0"
                size="s"
                fontWeight="500"
                running={false}
                disabled={false}
                active={true}
                onClick={onClickCancelPlan}
              />
            </div>
          </div>
        </>
      ) : undefined}

      {/* - */}
    </STUserZonePlansProductDetailBcastPremium>
  )
}
