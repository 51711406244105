import { css, keyframes } from 'styled-components'

export const KFSpinner = keyframes`
  to { transform: rotate(360deg) }
`

const Time = {
  Fast: '300ms',
  Faster: '200ms',
} as const

const Type = {
  Linear: 'linear',
  Ease: 'ease',
  EaseOut: 'ease-out',
} as const

const Transform = {
  Scale: (scaleX = 1.07, scaleY?: number) => css`
    transform: ${scaleY ? `scale(${scaleX}, ${scaleY})` : `scale(${scaleX})`};
  `,
}

export const Transition = {
  AllFastEaseOut: css`
    transition: ${Time.Fast} ${Type.EaseOut};
  `,
  Color: css`
    transition: color ${Time.Fast} ${Type.Ease};
  `,
  ColorFaster: css`
    transition: color ${Time.Faster} ${Type.Ease};
  `,

  BorderColor: css`
    transition: border-color ${Time.Fast} ${Type.Ease};
  `,
  BorderColorFaster: css`
    transition: border-color ${Time.Faster} ${Type.Ease};
  `,

  BackgroundColor: css`
    transition: background-color ${Time.Fast} ${Type.Ease};
  `,
  BackgroundColorFaster: css`
    transition: background-color ${Time.Faster} ${Type.Ease};
  `,

  ColorAndBackgroundFaster: css`
    transition:
      color ${Time.Faster} ${Type.Ease},
      background-color ${Time.Faster} ${Type.Ease};
  `,

  ColorBorderAndBackgroundFaster: css`
    transition:
      color ${Time.Faster} ${Type.Ease},
      background-color ${Time.Faster} ${Type.Ease},
      border-color ${Time.Faster} ${Type.Ease};
  `,

  FillFaster: css`
    transition: fill ${Time.Faster} ${Type.Ease};
  `,
  StrokeFaster: css`
    transition: stroke ${Time.Faster} ${Type.Ease};
  `,

  FillStrokeFaster: css`
    transition:
      fill ${Time.Faster} ${Type.Ease},
      stroke ${Time.Faster} ${Type.Ease};
  `,

  TransformFast: css`
    transition: transform ${Time.Fast} ${Type.Ease};
  `,

  BorderTransformFaster: css`
    transition:
      border-color ${Time.Faster} ${Type.Ease},
      transform ${Time.Faster} ${Type.Ease};
  `,

  OpacityFast: css`
    transition: opacity ${Time.Fast} ${Type.Ease};
  `,
}

const Animation = {
  spinner: css`
    animation: ${KFSpinner} 0.6s ${Type.Linear} infinite;
  `,
}

export const CSSAnimation = {
  Time,
  Type,
  Animation,
  Transform,
  Transition,
}
