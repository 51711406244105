import { useNavigate } from 'react-router'
import { ClassName, STUserZonePlansRootAddProductLauncher } from './style'
import { Routes } from '../../../../../../constants/routes'
import { Trans } from '../../../../../../components/common/intl/trans'
import { LazySVG } from '../../../../../../components/svgs/lazy-svg/component'
import { Button } from '../../../../../../components/common/button/component'

import LauncherImageSquare from '../../../../../../imgs/pngs/launcher-card-md.png'
import LauncherImageRectangle from '../../../../../../imgs/pngs/launcher-card-xl.png'
import { useDispatch, useSelector } from 'react-redux'
import { BuyLicenseMC } from '../../../../../../store/actions-mutators/buy-license/mutators'
import { StoreState } from '../../../../../../models/app/model'

// ~~~~~~ Constants

const Logo = LazySVG('logos/launcher-gradient')

const ArrowRightIcon = LazySVG('icons/arrow-long-right')

// ~~~~~~ Component

export const UserZonePlansRootAddProductLauncher = () => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  const dispatch = useDispatch()

  // ~~~~~~ State

  const invoices = useSelector((state: StoreState) => state.invoices)

  // ~~~~~~ Computed

  const notFirstTime = invoices.some((invoice) => invoice.product_id === 'launcher')

  // ~~~~~~ Handlers

  function onClickAction() {
    if (notFirstTime) {
      navigate(Routes.UserZonePlansLauncherPurchase)
      return
    }

    dispatch(BuyLicenseMC.clearData())

    navigate(Routes.UserZonePlansLauncher)
  }

  // ~~~~~~ Render

  return (
    <STUserZonePlansRootAddProductLauncher>
      <div className={`${ClassName}--left`}>
        <img
          className={`${ClassName}--left--img-square`}
          src={LauncherImageSquare}
          alt="launcher square"
        />

        <img
          className={`${ClassName}--left--img-rectangle`}
          src={LauncherImageRectangle}
          alt="launcher rectangle"
        />
      </div>

      <div className={`${ClassName}--right`}>
        <div className={`${ClassName}--right--logo`}>
          <Logo size={24} />
        </div>

        <div className={`${ClassName}--right--title`}>
          <Trans id="pages.main.cards.launcher.Title" />
        </div>

        <div className={`${ClassName}--right--subtitle`}>
          <Trans id="pages.main.cards.launcher.Description" />
        </div>

        <div className={`${ClassName}--right--action`}>
          <Button
            colorType="only-link"
            intlId="pages.common.AddProduct"
            intlValues={{ value: 'Launcher' }}
            padding="0"
            width="unset"
            IconRight={ArrowRightIcon}
            disabled={false}
            running={false}
            active={true}
            onClick={onClickAction}
          />
        </div>
      </div>
    </STUserZonePlansRootAddProductLauncher>
  )
}
