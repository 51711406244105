export const Lisa = {
  90: '#69550f',
  80: '#896f14',
  70: '#b18f1a',
  60: '#e3b821',
  50: '#f9ca24',
  40: '#fad550',
  30: '#fbdb6c',
  20: '#fce79a',
  10: '#fdefbb',
  5: '#fefae9',
} as const

// ~~~~~~

const LISA_05_05: ThemeColor = {
  light: Lisa[5],
  dark: Lisa[5],
}

const LISA_10_10: ThemeColor = {
  light: Lisa[10],
  dark: Lisa[10],
}

const LISA_20_20: ThemeColor = {
  light: Lisa[20],
  dark: Lisa[20],
}

const LISA_40_40: ThemeColor = {
  light: Lisa[40],
  dark: Lisa[40],
}

const LISA_50_50: ThemeColor = {
  light: Lisa[50],
  dark: Lisa[50],
}

const LISA_60_60: ThemeColor = {
  light: Lisa[60],
  dark: Lisa[60],
}

const LISA_70_70: ThemeColor = {
  light: Lisa[70],
  dark: Lisa[70],
}

const LISA_80_80: ThemeColor = {
  light: Lisa[80],
  dark: Lisa[80],
}

// ~~~~~~

export const LisaColors = {
  LISA_05_05,
  LISA_10_10,
  LISA_20_20,
  LISA_40_40,
  LISA_50_50,
  LISA_60_60,
  LISA_70_70,
  LISA_80_80,
} as const
