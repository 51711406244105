import { useEffect } from 'react'
import { ApiStoreCheckoutAC } from '../../../../../store/actions/api-store/checkout/actions'
import { useDispatch } from 'react-redux'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../../../models/app/model'
import { Routes } from '../../../../../constants/routes'
import { PagePurchaseCommonTabSteps } from '../../../@-common/tab-steps/component'
import { Loader } from '../../../../../components/common/loader/component'
import { useParams } from 'react-router'
import { ClassName, STPagePurchaseBcastStep3Payment } from './style'

export const PagePurchaseBcastStep3Payment = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const interval = useParams<{ interval?: string }>().interval

  // ~~~~~~ State

  const buyLicense = useSelector((state: StoreState) => state.buyLicense)

  // ~~~~~~ Effects

  // - On mount create Stripe checkout session

  useEffect(() => {
    if (!buyLicense.productId) return

    if (!interval || !['monthly', 'yearly'].includes(interval)) return

    dispatch(
      ApiStoreCheckoutAC.createCheckoutSession('broadcast-subscription', {
        email: buyLicense.email,
        code: buyLicense.code,

        price_of_license_of_product_id: buyLicense.subId,
        quantity: 1,

        // Remove the :stripeSessionId (stripe will add this at the end of this route)
        success_path: Routes.BcastSub2PremiumSuccess.split('/').slice(0, -1).join('/'),
        cancel_path: Routes.genBcastSub2Premium(interval as 'monthly' | 'yearly'),
      }),
    )
  }, [
    buyLicense.code,
    buyLicense.email,
    buyLicense.productId,
    buyLicense.subId,
    dispatch,
    interval,
  ])

  // ~~~~~~ Render

  return (
    <STPagePurchaseBcastStep3Payment>
      {/* Steps */}

      <div className={`${ClassName}--steps`}>
        <PagePurchaseCommonTabSteps kind="subscription" />
      </div>

      {/* Loader */}

      <div className={`${ClassName}--loader`}>
        <Loader size={100} />
      </div>

      {/* - */}
    </STPagePurchaseBcastStep3Payment>
  )
}
