import { Fragment } from 'react'
import { Button, Menu, MenuItem, Wrapper, WrapperState } from 'react-aria-menubutton'
import { useNavigate } from 'react-router'

import { Config } from '../../../../config'
import { RoutesOld } from '../../../../constants/routes-old'
import { useCustomAuth } from '../../../../hooks/auth/use-custom-auth'
import { Trans } from '../../intl/trans'
import { STUserMenuDesktop } from './style'
import { LazySVG } from '../../../svgs/lazy-svg/component'

// ~~~~~~ Types

type MenuItemsType = {
  ItemIcon: ReturnType<typeof LazySVG>
  transId: IntlMsgId
  value: string
}

type Props = {
  onMenuToggle?(evt: WrapperState): void
  menuItems: MenuItemsType[]
  children: React.ReactNode
}

// ~~~~~~ Component

export const UserMenuDesktop = ({ children, menuItems, onMenuToggle }: Props) => {
  // ~~~~~~ Hooks

  const { logout } = useCustomAuth()

  const navigate = useNavigate()

  // ~~~~~~ Handlers

  function onSelection(itemSelected: 'manage-account' | 'sign-out' | 'visit-store') {
    switch (itemSelected) {
      case 'manage-account':
        navigate(RoutesOld.UserZone)
        break

      case 'visit-store':
        navigate(RoutesOld.Main)
        break

      case 'sign-out':
        logout({
          logoutParams: {
            returnTo: Config.Origin,
          },
        })
        break

      default:
        return
    }
  }

  // ~~~~~~ Render

  return (
    <STUserMenuDesktop data-test="user-menu-desktop">
      <Wrapper id="user-menu-desktop" onMenuToggle={onMenuToggle} onSelection={onSelection}>
        {/* Menu button */}

        <Button data-test="toggle-user-menu" tag="div">
          {children}
        </Button>

        {/* Menu */}

        <div className="dropdown">
          <Menu>
            <div className="items">
              {/* Menu items */}

              {menuItems.map((ItemMenu, index) => (
                <Fragment key={ItemMenu.value}>
                  <MenuItem data-test={ItemMenu.value} value={ItemMenu.value} className="item">
                    <div className="icon">
                      <ItemMenu.ItemIcon size={16} />
                    </div>

                    <div className="item-text">
                      <Trans id={ItemMenu.transId} />
                    </div>
                  </MenuItem>

                  {menuItems.length - 1 > index ? <hr /> : undefined}
                </Fragment>
              ))}
            </div>
          </Menu>
        </div>
      </Wrapper>
    </STUserMenuDesktop>
  )
}
