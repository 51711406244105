import { Observable, of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { catchError, map } from 'rxjs/operators'
import { BillingInfoModel } from '../../../models/billing-info/model'
import { ApiRoutes } from '../constants/routes'
import { ApiHeadersUtils } from '../utils/headers'

const { Billing } = ApiRoutes

// ####################################################################################################
// ~~~~~~ Update billing info
// ####################################################################################################

type Req = DN.Models.BillingInfo.Api.Update.Req
type Res = DN.Models.BillingInfo.Api.Update.Res
type ResParsed = DNApiResParsed<DN.Models.BillingInfo.Api.Update.ResParsed>
type ResError = DNApiError<DN.Models.BillingInfo.Api.Update.ResError>

export const apiStoreBillingUpdate$ = (token: string, data: Req, headers?: AppHeaders) => {
  const body: Req = data

  return ajax<Res>({
    ...ApiHeadersUtils.genHeadersWithAuth(token, headers),
    method: Billing.Update.Method,
    url: Billing.Update.getUrl(),
    body,
  }).pipe(
    map((res): ResParsed => {
      return {
        status: res.status,
        response: BillingInfoModel.fromBackToFront(res.response),
      }
    }),
    catchError((res: DNApiRes<void>): Observable<ResError> => {
      return of({
        status: res.status,
        response: [{ id: 'api.billing.update.Error' }],
      })
    }),
  )
}
