import styled from 'styled-components'
import { Layout } from '../../../../../style/theme/layout'
import { MediaQuery } from '../../../../../style/constants/media-queries'
import { Fonts } from '../../../../../style/theme/fonts'

export const ClassName = 'user-zone-plans-root-loading'

// ~~~~~~ Component

export const STUserZonePlansRootLoading = styled.div`
  padding-top: 16px;
  ${Layout.PaddingWidth.Default}

  .${ClassName}--title {
    ${Fonts.Weight[800]}
    ${Fonts.Scale.Headline2}

    margin-bottom: 16px;
  }

  .${ClassName}--block-2 {
    .${ClassName}--block-2--card {
      height: 80px;
      margin-bottom: 16px;
    }
  }

  /* 1280 */

  @media (max-width: ${MediaQuery.MaxWidth2k}) {
    ${Layout.PaddingWidth.Desktop}
  }

  /* 960 */

  @media (max-width: ${MediaQuery.MD}) {
    ${Layout.PaddingWidth.Tablet}
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    ${Layout.PaddingWidth.Mobile}
  }
`
