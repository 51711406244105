const Accent = {
  5: '#D23D14',
} as const

// ~~~~~~

const ACCENT_05_05: ThemeColor = {
  light: Accent[5],
  dark: Accent[5],
}

// ~~~~~~

export const AccentColors = {
  ACCENT_05_05,
} as const
