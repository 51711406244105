const prefix = `mt-logout`

export const LogoutMT = {
  DO_IT_NOW: `${prefix}-do-it-now`,
} as const

export const LogoutMC = {
  doItNow: () => ({
    type: LogoutMT.DO_IT_NOW,
  }),
} as const
