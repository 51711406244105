const prefix = 'at-api-launcher-release-info'

// ~~~~~~ Action Types

export const ApiLauncherReleaseInfoAT = {
  GET: `${prefix}-get`,
} as const

// ~~~~~~ Action Mutators

export const ApiLauncherReleaseInfoAC = {
  start: () => ({
    type: ApiLauncherReleaseInfoAT.GET,
  }),
} as const
