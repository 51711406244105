import styled from 'styled-components'

import { Transition } from '../../../../style/animations/css-animations'
import { Colors } from '../../../../style/theme/colors'
import { Fonts } from '../../../../style/theme/fonts'
import { Misc } from '../../../../style/theme/misc'
import { theme } from '../../../../style/utils/theme'

export const STUserMenuSlide = styled.div`
  .side-menu-motion {
    width: 100%;
    height: 100%;

    position: absolute;
    z-index: 2;

    .side-menu-content {
      background-color: ${theme(Colors.WOLF_90_90)};
      width: 100%;
      height: 100%;

      padding-top: 24px;

      .side-menu-close {
        margin-right: 24px;
        display: flex;
        justify-content: flex-end;

        .button {
          display: flex;
          align-items: center;

          .close-text {
            color: ${theme(Colors.WOLF_00_00)};
            ${Fonts.Weight[600]}
            ${Fonts.Scale.Body}
          }

          .icon {
            width: 30px;
            height: 30px;

            svg path {
              fill: ${theme(Colors.WOLF_00_00)};
            }
          }
        }
      }

      .side-menu-items {
        padding-top: 150px;

        display: flex;
        flex-direction: column;
        align-items: center;

        .item {
          opacity: 1;

          ${Transition.OpacityFast};

          width: 260px;

          padding-left: 12px;
          padding-right: 12px;

          display: flex;
          align-items: center;

          ${Misc.BorderRadius};

          ${Fonts.Scale.Body};

          height: 40px;
          cursor: pointer;
          color: ${theme(Colors.WOLF_00_00)};

          .icon {
            width: 16px;
            height: 14px;
            margin-right: 12px;

            svg path {
              fill: ${theme(Colors.WOLF_00_00)};
            }
          }

          .theme {
            width: 100%;
            display: flex;
            justify-content: space-between;
          }

          &:hover {
            opacity: 0.5;

            &.disabled {
              cursor: default;
              opacity: 1;
            }
          }
        }

        .item-email {
          display: flex;
          flex-direction: column;
          align-items: flex-start;

          .item-label {
            opacity: 0.5;

            ${Fonts.Weight[300]}
            ${Fonts.Scale.Body}
          }

          .mailto-link {
            text-decoration: none;

            color: ${theme(Colors.WOLF_00_00)};
          }
        }

        hr {
          border: none;
          border-top: solid 1px ${theme(Colors.WOLF_60_60)};
          width: 71%;
        }
      }
    }
  }
`
