const prefix = 'at-intl'

// ~~~~~~ Action Types

export const IntlAT = {
  CHANGE_LANG: `${prefix}-change-lang`,
} as const

// ~~~~~~ Action Mutators

export const IntlAC = {
  changeLang: (lang: Lang) => ({
    type: IntlAT.CHANGE_LANG,
    payload: lang,
  }),
} as const
