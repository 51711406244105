import styled from 'styled-components'
import creditCardsLogos from '../../../imgs/pngs/credit-cards-logos.png'
import { Fonts } from '../../../style/theme/fonts'
import { theme } from '../../../style/utils/theme'
import { Colors } from '../../../style/theme/colors'

// ~~~~~~ Constants

export const ClassName = 'credit-card'

// ~~~~~~ Types

export type STProps = {
  $creditCardXPosition?: number
}

// ~~~~~~ Component

export const STCreditCard = styled.div<STProps>`
  display: flex;
  align-items: center;

  .${ClassName}--logo {
    background-image: url(${creditCardsLogos});
    background-size: cover;
    background-repeat: no-repeat;
    background-position-y: center;

    ${({ $creditCardXPosition }) =>
      $creditCardXPosition ? `background-position-x: ${$creditCardXPosition}px` : ''};

    width: 24px;
    height: 14px;

    padding: 4px;
    border-radius: 2px;
    margin-right: 8px;
  }

  .${ClassName}--code {
    ${Fonts.Scale.BodyBig}
    ${Fonts.Weight[600]}

    white-space: nowrap;
  }

  .${ClassName}--warn-icon {
    display: flex;

    margin-left: 4px;

    svg {
      width: 16px;
      height: 16px;

      path {
        fill: ${theme(Colors.LISA_70_70)};
      }
    }
  }
`
