const initModel: DN.Models.Checkout.SessionModelFront = {
  uiStatus: 'init',
  errors: [],
  price: 0,
  quantity: 0,
  email: '',
  product_id: null,
  platform: '',
  trial_period_days: null,
  is_free: false,
  current_period_end: '',
  interval: 'month',
}

export const CheckoutModel = {
  initModel,
} as const
