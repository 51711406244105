const prefix = 'mt-launcher-latest-version-epic'

// ~~~~~~ Mutators Types

export const EpicApiLauncherReleaseInfoMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const EpicApiLauncherReleaseInfoMC = {
  ok: (data: DN.Models.LauncherReleaseInfo.Api.Get.ResParsed) => ({
    type: EpicApiLauncherReleaseInfoMT.OK,
    payload: data,
  }),

  error: (data: DN.Models.LauncherReleaseInfo.Api.Get.ResError) => ({
    type: EpicApiLauncherReleaseInfoMT.ERROR,
    error: true,
    payload: {
      errors: data,
    },
  }),
} as const
