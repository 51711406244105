import isEmail from 'validator/lib/isEmail'

type ValidationsT = {
  NotFound: IntlMsgId

  Unknown: IntlMsgId
  Required: IntlMsgId
  Unique: IntlMsgId
  MaxLength: IntlMsgId
  MinLength: IntlMsgId
  Min: IntlMsgId
  Max: IntlMsgId
  Invalid: IntlMsgId
  InvalidChars: IntlMsgId
  Date: IntlMsgId
  Number: IntlMsgId
  AlreadyOccurred: IntlMsgId
  MaxAmount: IntlMsgId
  Email: IntlMsgId
  Password: IntlMsgId
}

export const Validations: ValidationsT = {
  NotFound: 'validations.not-found',

  Unknown: 'validations.unknown',
  Required: 'validations.required',
  Unique: 'validations.unique',
  MaxLength: 'validations.max-length',
  MinLength: 'validations.min-length',
  Min: 'validations.min',
  Max: 'validations.max',
  Invalid: 'validations.invalid',
  InvalidChars: 'validations.invalid-chars',
  Date: 'validations.date',
  Number: 'validations.number',
  AlreadyOccurred: 'validations.already-occurred',
  MaxAmount: 'validations.max-amount',
  Email: 'validations.email',
  Password: 'validations.Password',
}

// ~~~~~~

const regexEscape = /["&'<>`]/g
const escapeMap = {
  '"': '&quot;',
  '&': '&amp;',
  "'": '&#x27;',
  '<': '&lt;',
  '>': '&gt;',
  '`': '&#x60;',
} as const

const htmlEscape = (str: string): string => str.replace(regexEscape, ($0) => (escapeMap as any)[$0])

const hasInvalidChars = (value: string, opts: { noNumbers?: boolean } = {}): boolean => {
  if (htmlEscape(value) !== value) {
    return false
  }

  // eslint-disable-next-line no-bitwise
  if (opts.noNumbers && !!~value.search(/[0-9]/)) {
    return false
  }

  return true
}

// ~~~~~~

export type Validator = Readonly<{
  error: IntlMsgId
  value?: any
  validator?: (values: [string, ...any[]]) => boolean
}>

const passValidator = (
  values: [string, ...any[]],
  { error, value, validator }: Validator,
): boolean => {
  const fieldValue = values[0]

  switch (error) {
    case Validations.Required:
      return !!fieldValue

    case Validations.MinLength:
      return validator ? validator(values) : fieldValue.length >= value

    case Validations.MaxLength:
      return validator ? validator(values) : fieldValue.length <= value

    case Validations.InvalidChars:
      return validator ? validator(values) : hasInvalidChars(fieldValue)

    case Validations.Number:
      return validator ? validator(values) : !isNaN(Number(fieldValue))

    case Validations.Min:
      return validator
        ? validator(values)
        : fieldValue !== '' && !isNaN(Number(fieldValue)) && Number(fieldValue) >= value

    case Validations.Max:
      return validator
        ? validator(values)
        : fieldValue !== '' && !isNaN(Number(fieldValue)) && Number(fieldValue) <= value

    case Validations.MaxAmount:
      return validator
        ? validator(values)
        : fieldValue !== '' && !isNaN(Number(fieldValue)) && Number(fieldValue) <= value

    case Validations.Email:
      return validator ? validator(values) : isEmail(fieldValue)

    case Validations.Password:
      return validator ? validator(values) : fieldValue.length >= value

    default:
      return typeof validator === 'function' ? validator(values) : false
  }
}

// ~~~~~~

export type Validators = Readonly<Validator[]>

const validate = (value: [string, ...any[]], validatiors: Validators) => {
  const initValue: Infos[] = []

  return validatiors.reduce(
    (acc, cur) =>
      passValidator(value, cur) ? acc : [...acc, { id: cur.error, values: { value: cur.value } }],
    initValue,
  )
}

// ~~~~~~

export const ValidateUtils = {
  Validations,
  validate,
}
