import styled from 'styled-components'
import { theme } from '../../../../style/utils/theme'
import { Colors } from '../../../../style/theme/colors'
import { Fonts } from '../../../../style/theme/fonts'
import { Transition } from '../../../../style/animations/css-animations'

// ~~~~~~ Constants

export const ClassName = 'page-common-top-right'

// ~~~~~~ Component

export const STPageCommonTopRight = styled.div`
  ${Fonts.Weight[600]}
  ${Fonts.Scale.Body}

  .${ClassName}--not-logged {
    cursor: pointer;

    display: flex;

    svg {
      width: 20px;
      height: 20px;

      margin-right: 8px;

      path {
        ${Transition.FillFaster}

        fill: ${theme(Colors.WOLF_00_00)};
      }
    }

    .${ClassName}--not-logged--text {
      ${Transition.ColorFaster}

      color: ${theme(Colors.WOLF_00_00)};

      &:after {
        display: block;
        content: '';
        border-bottom: 1px solid ${theme(Colors.WOLF_00_00)};

        ${Transition.BorderTransformFaster}

        transform: scaleX(0);
      }
    }

    &:hover {
      .${ClassName}--not-logged--text:after {
        transform: scaleX(1);
      }
    }

    &:active {
      svg path {
        fill: ${theme(Colors.BRONX_30_30)};
      }

      .${ClassName}--not-logged--text {
        color: ${theme(Colors.BRONX_30_30)};

        &:after {
          border-bottom: 1px solid ${theme(Colors.BRONX_30_30)};
        }
      }
    }
  }

  .${ClassName}--logged {
    display: flex;
    align-items: center;
  }
`
