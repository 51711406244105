import styled from 'styled-components'
import { theme } from '../../../../../style/utils/theme'
import { Colors } from '../../../../../style/theme/colors'
import { Layout } from '../../../../../style/theme/layout'
import { Fonts } from '../../../../../style/theme/fonts'
import { MediaQuery } from '../../../../../style/constants/media-queries'
import { HexAlpha } from '../../../../../style/theme/colors/alpha'

// ~~~~~~ Constants

export const ClassName = 'product-page-bcast-content-sub-card'

// ~~~~~~ Component

export const STProductPageBcastContentSubCard = styled.div`
  text-align: left;

  width: 100%;

  padding: 24px 32px;

  border: solid 1px ${theme(Colors.WOLF_10_10)};
  box-sizing: border-box;
  box-shadow: 0px 4px 16px ${theme(Colors.WOLF_90_90)}${HexAlpha[10]};

  ${Layout.Border.Radius.B}

  display: flex;
  justify-content: space-between;

  .${ClassName}--left {
    color: ${theme(Colors.WOLF_80_80)};

    .${ClassName}--left--interval {
      ${Fonts.Weight[300]}
      ${Fonts.Scale.BodyBig}

      display: flex;

      margin-bottom: 8px;

      .${ClassName}--left--interval--badge {
        margin-left: 8px;
        padding: 2px 4px;

        background-color: ${theme(Colors.WOLF_05_05)};

        ${Layout.Border.Radius.A}
      }
    }

    .${ClassName}--left--price {
      ${Fonts.Weight[800]}
      ${Fonts.Scale.OldB}
    }
  }

  .${ClassName}--right {
    display: flex;
    align-items: center;
  }

  /* 1280 */

  @media (max-width: ${MediaQuery.MaxWidth2k}) {
    padding: 16px 24px;

    .${ClassName}--left {
      .${ClassName}--left--price {
        ${Fonts.Scale.Headline1}
      }
    }
  }

  @media (max-width: ${MediaQuery.MD}) {
    width: unset;
  }
`
