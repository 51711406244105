import { styled } from 'styled-components'
import { Fonts } from '../../../../../style/theme/fonts'
import { MediaQuery } from '../../../../../style/constants/media-queries'

// ~~~~~~ Constants

export const ClassName = 'page-trials-or-free-montage-content-step-3'

// ~~~~~~ Component

export const STPageTrialsOrFreeMontageContentStep3SelectPlatform = styled.div`
  max-width: 622px;
  margin-left: auto;
  margin-right: auto;

  margin-top: 32px;

  /* Steps */

  .${ClassName}--steps {
    margin-bottom: 56px;
  }

  /* Logo */

  .${ClassName}--logo {
    width: 98px;

    margin-bottom: 8px;
  }

  /* Title */

  .${ClassName}--title {
    ${Fonts.Weight[800]}
    ${Fonts.Scale.OldA}

    margin-bottom: 24px;
  }

  /* Platforms */

  .${ClassName}--platforms {
    margin-bottom: 32px;

    display: flex;

    .${ClassName}--platforms--platform {
      &.last {
        margin-left: 16px;
      }
    }
  }

  /* 1280 */

  @media (max-width: ${MediaQuery.MaxWidth2k}) {
    /* Title */

    .${ClassName}--title {
      ${Fonts.Scale.OldB}
    }
  }

  /* 960 */

  @media (max-width: ${MediaQuery.MD}) {
    /* Steps */

    .${ClassName}--steps {
      margin-bottom: 50px;
    }

    /* Logo */

    .${ClassName}--logo {
      width: 86px;
    }

    /* Title */

    .${ClassName}--title {
      ${Fonts.Scale.Headline1}
    }

    /* Action */

    .${ClassName}--action {
      margin-bottom: 24px;
    }
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    /* Steps */

    .${ClassName}--steps {
      margin-bottom: 32px;
    }

    /* Title */

    .${ClassName}--title {
      ${Fonts.Scale.Headline2}
    }

    /* Platforms */

    .${ClassName}--platforms {
      flex-direction: column;

      .${ClassName}--platforms--platform {
        margin-bottom: 16px;

        &.last {
          margin-left: unset;
          margin-bottom: 0;
        }
      }
    }

    /* Action */

    .${ClassName}--action {
      margin-bottom: 16px;
    }
  }
`
