import { ProductIds } from '../../../constants/product-ids'
import { Routes } from '../../../constants/routes'
import { ClassName, STPageRootContent } from './style'

import broadcastCardLG from '../../../imgs/pngs/broadcast-card-lg.png'
import broadcastCardMD from '../../../imgs/pngs/broadcast-card-md.png'
import broadcastCardXL from '../../../imgs/pngs/broadcast-card-xl.png'
import launcherCardLG from '../../../imgs/pngs/launcher-card-lg.png'
import launcherCardMD from '../../../imgs/pngs/launcher-card-md.png'
import launcherCardXL from '../../../imgs/pngs/launcher-card-xl.png'
import montageCardLG from '../../../imgs/pngs/montage-card-lg.png'
import montageCardMD from '../../../imgs/pngs/montage-card-md.png'
import montageCardXL from '../../../imgs/pngs/montage-card-xl.png'
import { ProductCard } from '../../../components/common/product-card/component'

// ~~~~~~ Component

export const PageRootContent = () => {
  // ~~~~~~ Render

  return (
    <STPageRootContent>
      {/* Product cards */}

      <div className={`${ClassName}--products`}>
        {/* - Montage */}

        <div className={`${ClassName}--products--card`}>
          <ProductCard
            landingUrl={Routes.Montage}
            imageUrl={montageCardXL}
            imageUrlLG={montageCardLG}
            imageUrlMD={montageCardMD}
            productId={ProductIds.Montage}
            title="pages.main.cards.montage.Title"
            description="pages.main.cards.montage.Description"
          />
        </div>

        {/* - Launcher */}

        <div className={`${ClassName}--products--card launcher`}>
          <ProductCard
            landingUrl={Routes.Launcher}
            imageUrl={launcherCardXL}
            imageUrlLG={launcherCardLG}
            imageUrlMD={launcherCardMD}
            productId={ProductIds.Launcher}
            title="pages.main.cards.launcher.Title"
            description="pages.main.cards.launcher.Description"
          />
        </div>

        {/* - Bcast */}

        <div className={`${ClassName}--products--card`}>
          <ProductCard
            landingUrl={Routes.Bcast}
            imageUrl={broadcastCardXL}
            imageUrlLG={broadcastCardLG}
            imageUrlMD={broadcastCardMD}
            productId={ProductIds.BroadcastSub}
            title="pages.main.cards.broadcast.Title"
            description="pages.main.cards.broadcast.Description"
          />
        </div>
      </div>
    </STPageRootContent>
  )
}
