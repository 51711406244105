const prefix = 'at-api-store-current-user'

// ~~~~~~ Action Types

export const ApiStoreCurrentUserAT = {
  SHOW: `${prefix}-show`,
} as const

// ~~~~~~ Action Mutators

export const ApiStoreCurrentUserAC = {
  show: (auth0Token: string) => ({
    type: ApiStoreCurrentUserAT.SHOW,
    payload: {
      auth0Token,
    },
  }),
} as const
