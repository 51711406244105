import { ProductButtonLauncher } from '../../../../../components/common/product-button/launcher/component'
import { LazySVG } from '../../../../../components/svgs/lazy-svg/component'
import { PageCommonOPStep1CreateAccount } from '../../../../@-common/obtaining-products/step-1-create-account/component'
import { PageTrialsOrFreeCommonTabSteps } from '../../../@-common/tab-steps/component'

// ~~~~~~ Constants

const Logo = LazySVG('logos/launcher-gradient')

// ~~~~~~ Component

export const PageTrialsOrFreeLauncherContentStep1CreateAccount = () => {
  // ~~~~~~ Render

  return (
    <PageCommonOPStep1CreateAccount
      Logo={Logo}
      ProductButton={ProductButtonLauncher}
      tabSteps={<PageTrialsOrFreeCommonTabSteps kind="trial" />}
    />
  )
}
