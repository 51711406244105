import styled from 'styled-components'
import { Fonts } from '../../../../../style/theme/fonts'
import { Layout } from '../../../../../style/theme/layout'
import { MediaQuery } from '../../../../../style/constants/media-queries'
import { Colors } from '../../../../../style/theme/colors'
import { theme } from '../../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'user-zone-plans-get-trial-select-platform-montage'

// ~~~~~~ Component

export const STUserZonePlansGetTrialSelectPlatformMontage = styled.div`
  ${Layout.MarginMaxWidth.MiddleContent}

  .${ClassName}--content {
    /* Title */

    .${ClassName}--content--title {
      ${Fonts.Weight[800]}
      ${Fonts.Scale.Headline2}

      margin-bottom: 24px;
    }

    /* Platforms */

    .${ClassName}--content--platforms {
      margin-bottom: 32px;

      display: flex;

      .${ClassName}--content--platforms--platform {
        &.last {
          margin-left: 16px;
        }
      }
    }

    /* Separator */

    hr.${ClassName}--content--separator {
      border: none;
      border-top: solid 1px ${theme(Colors.WOLF_10_10)};

      margin-bottom: 24px;
    }

    /* Actions */

    .${ClassName}--content--actions {
      display: flex;

      margin-bottom: 24px;

      .${ClassName}--content--actions--action {
        &.first {
          margin-right: 16px;
        }
      }
    }
  }

  /* 1280 */

  @media (max-width: ${MediaQuery.MaxWidth2k}) {
    .${ClassName}--content {
      ${Layout.PaddingWidth.Desktop}

      .${ClassName}--content--title {
        ${Fonts.Scale.Headline2}
      }
    }
  }

  /* 960 */

  @media (max-width: ${MediaQuery.MD}) {
    .${ClassName}--content {
      ${Layout.PaddingWidth.Tablet}
    }
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    .${ClassName}--content {
      ${Layout.PaddingWidth.Mobile}

      /* Title */

      .${ClassName}--content--title {
        ${Fonts.Scale.Title}
      }

      /* Platforms */

      .${ClassName}--content--platforms {
        flex-direction: column;

        .${ClassName}--content--platforms--platform {
          margin-bottom: 16px;

          &.last {
            margin-left: 0;
            margin-bottom: 0;
          }
        }
      }

      /* Actions */

      .${ClassName}--content--actions {
        flex-direction: column;

        .${ClassName}--content--actions--action {
          &.first {
            margin-right: 0;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
`
