import { ofType, StateObservable } from 'redux-observable'
import { Observable } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'

import { StoreState } from '../../../../../models/app/model'
import {
  ApiStoreCheckoutAC,
  ApiStoreCheckoutAT,
} from '../../../../actions/api-store/checkout/actions'

import { ApiUtils } from '../../../utils'
import { ApiStoreSubscriptionsCreateEpicMC } from './mutators'
import { apiStoreSubscriptionsCreate$ } from '../../../../../services/api-store/subscriptions/create'

type Action = ReturnType<typeof ApiStoreCheckoutAC.startFreeSubOrTrial>

export const apiStoreSubscriptionsCreateEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiStoreCheckoutAT.START_FREE_SUB_OR_TRIAL),
    mergeMap(({ payload }) =>
      apiStoreSubscriptionsCreate$(state$.value.currentUser.token, payload.productId, payload.data),
    ),
    map((res) =>
      ApiUtils.isNotError(res)
        ? ApiStoreSubscriptionsCreateEpicMC.ok(!!state$.value.currentUser.token, res.response)
        : ApiStoreSubscriptionsCreateEpicMC.error(res.response),
    ),
  )
