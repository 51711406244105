import styled from 'styled-components'

import { Colors } from '../../../style/theme/colors'
import { Fonts } from '../../../style/theme/fonts'
import { getThemeValue, theme } from '../../../style/utils/theme'

// ~~~~~~ Types

type STProps = {
  $kind: 'success' | 'error'
}

// ~~~~~~ Component

export const STToast = styled.div<STProps>`
  ${Fonts.Scale.Body};
  ${Fonts.Weight[600]};

  display: flex;
  align-items: center;

  background-color: ${theme(Colors.WOLF_90_90)};

  margin: 0;
  padding: 6px 12px;

  .toast-kind-line {
    .toast-icon {
      width: 16px;
      height: 100%;

      margin-right: 3px;

      svg {
        display: flex;

        width: 18px;
        height: 18px;

        path {
          fill: ${(props) =>
            props.$kind === 'success'
              ? getThemeValue(props, Colors.SITO_70_70)
              : getThemeValue(props, Colors.ACCENT_05_05)};
        }
      }
    }
  }

  .toast-text-lines {
    .toast-text-line {
      color: ${theme(Colors.WOLF_00_00)};
    }
  }
`
