import { ChangePlanKind } from '../../../../actions/api-store/subscriptions/actions'

const prefix = 'mt-store-api-subscriptions-change-plan-epic'

// ~~~~~~ Mutators Types

export const ApiStoreSubscriptionsChangePlanEpicMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const ApiStoreSubscriptionsChangePlanEpicMC = {
  ok: (kind: ChangePlanKind, data: DN.Models.Subscription.Api.ChangePlan.ResParsed) => ({
    type: ApiStoreSubscriptionsChangePlanEpicMT.OK,
    payload: {
      kind,
      data,
    },
  }),

  error: (data: DN.Models.Subscription.Api.ChangePlan.ResError) => ({
    type: ApiStoreSubscriptionsChangePlanEpicMT.ERROR,
    error: true,
    payload: data,
  }),
} as const
