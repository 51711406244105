import React, { useEffect, useRef, useState } from 'react'

import { useTrans } from '../../../hooks/use-trans'
import { LazySVG } from '../../svgs/lazy-svg/component'
import { InfosList } from '../infos/list/component'
import { Trans } from '../intl/trans'
import { ClassName, STIntlInput, STProps } from './style'

// ~~~~~~ Constants

const EyeViewIcon = LazySVG('icons/filled-eye')
const EyeNoViewIcon = LazySVG('icons/eye-off')

const InfoIcon = LazySVG('icons/info')

// ~~~~~~ Types

type Icon = ReturnType<typeof LazySVG>

type Props = Omit<STProps, '$hasErrors' | '$isPassword'> & {
  className?: string
  label?: IntlMsgId
  sublabel?: IntlMsgId
  placeholder?: IntlMsgId
  placeholderValues?: { [key: string]: any }
  type?: string
  value: string | number
  errors?: Infos[]
  showErrorsInBox?: true
  pattern?: string
  Icon?: Icon
  doFocus?: number
  onChange: (value: string) => void
  onFocus?: (ref: HTMLInputElement) => void
  onBlur?: (value: string) => void
  onKeyDown?: (key: string) => void
}

export const IntlInput: React.FC<Props> = ({
  className,
  label,
  sublabel,
  placeholder,
  placeholderValues,
  type = 'text',
  value,
  pattern,
  errors,
  showErrorsInBox,
  Icon,
  doFocus,

  // Shared

  $disabled,

  // Style

  $hideBorder,
  $height,
  $iconWidth,
  $textAlign,

  onChange,
  onFocus,
  onBlur,
  onKeyDown,
}) => {
  // ~~~~~~ Hooks

  const trans = useTrans(placeholder || 'wildcard', placeholder ? placeholderValues : { value: '' })

  const placeholderText = placeholder ? trans : ''

  // ~~~~~~ State

  const [storedDoFocus, setStoredDoFocus] = useState(0)

  const [showPass, setShowPass] = useState(false)

  const inputRef = useRef<HTMLInputElement>(null)

  // ~~~~~~ Computed

  const finalType = type === 'password' ? (showPass ? 'text' : 'password') : type

  // ~~~~~~ Handlers

  function onClickTogglePass() {
    setShowPass(!showPass)
  }

  function onChangeH(evt: any) {
    onChange(evt.target.value)
  }

  function onFocusH() {
    onFocus && inputRef.current && onFocus(inputRef.current)
  }

  function onBlurH() {
    onBlur && onBlur(`${value}`)
  }

  function onClickH() {
    onFocus && inputRef.current && onFocus(inputRef.current)
  }

  function onKeyDownH(evt: any) {
    onKeyDown && onKeyDown(evt.key)
  }

  // ~~~~~~ Effects

  useEffect(() => {
    if (!doFocus || !inputRef.current || doFocus === storedDoFocus) return

    setStoredDoFocus(doFocus)

    inputRef.current.focus()
  }, [doFocus, storedDoFocus])

  // ~~~~~~ Render

  return (
    <STIntlInput
      className={className}
      $hideBorder={$hideBorder}
      $hasErrors={!!errors?.length}
      $height={$height}
      $hasIcon={!!Icon}
      $disabled={$disabled}
      $iconWidth={$iconWidth}
      $isPassword={type === 'password'}
      $textAlign={$textAlign}
    >
      {label ? (
        <div className={`${ClassName}--label`}>
          <Trans id={label} />
        </div>
      ) : undefined}

      {sublabel ? (
        <div className={`${ClassName}--sublabel`}>
          <Trans id={sublabel} />
        </div>
      ) : undefined}

      {Icon ? (
        <div className={`${ClassName}--icon-container`}>
          <div className="intl-icon">
            <Icon size={16} />
          </div>
        </div>
      ) : undefined}

      <div className={`${ClassName}--input`}>
        {type === 'password' ? (
          <div className={`${ClassName}--input--show-pass`} onClick={onClickTogglePass}>
            {showPass ? <EyeNoViewIcon size={16} /> : <EyeViewIcon size={16} />}
          </div>
        ) : undefined}

        <input
          ref={inputRef}
          type={finalType}
          placeholder={placeholderText}
          disabled={$disabled}
          value={value}
          pattern={pattern}
          onChange={onChangeH}
          onFocus={onFocusH}
          onBlur={onBlurH}
          onClick={onClickH}
          onKeyDown={onKeyDownH}
          autoComplete="off"
        />
      </div>

      {errors?.length ? (
        showErrorsInBox ? (
          <div className={`${ClassName}--errors`}>
            <div className={`${ClassName}--errors--icon`}>
              <InfoIcon size={16} />
            </div>

            <div>
              <InfosList infos={errors} />
            </div>
          </div>
        ) : (
          <InfosList infos={errors} />
        )
      ) : undefined}
    </STIntlInput>
  )
}
