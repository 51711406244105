const prefix = 'mt-store-api-checkout-validate-email-code-epic'

// ~~~~~~ Mutators Types

export const ApiStoreCheckoutValidateEmailCodeEpicMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const ApiStoreCheckoutValidateEmailCodeEpicMC = {
  ok: (data: DN.Models.Checkout.Api.ValidateEmailCode.ResParsed) => ({
    type: ApiStoreCheckoutValidateEmailCodeEpicMT.OK,
    payload: data,
  }),

  error: (errors: DN.Models.Checkout.Api.ValidateEmailCode.ResError) => ({
    type: ApiStoreCheckoutValidateEmailCodeEpicMT.ERROR,
    error: true,
    payload: {
      errors,
    },
  }),
} as const
