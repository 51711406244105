export const MouseButtons = {
  // Main button pressed, usually the left button or the un-initialized state
  MAIN: 0,

  // Auxiliary button pressed, usually the wheel button or the middle button (if present)
  AUXILIARY: 1,

  // Secondary button pressed, usually the right button
  SECONDARY: 2,

  // Fourth button, typically the Browser Back button
  FOURTH: 3,

  // Fifth button, typically the Browser Forward button
  FIFTH: 4,
} as const
