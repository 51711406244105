import React from 'react'
import { STPaymentButton } from './style'
import { ButtonProps } from '../button/component'

// ~~~~~~ Types

type Props = Omit<ButtonProps, 'colorType' | 'IconRight'>

// ~~~~~~ Component

export const PaymentButton: React.FC<Props> = (props) => {
  // ~~~~~~ Render

  return <STPaymentButton colorType="default" {...props} />
}
