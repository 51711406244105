import { LazySVG } from '../../svgs/lazy-svg/component'
import { STProps, STCreditCardBrand } from './style'

// ~~~~~~ Types

type Props = STProps & {
  cardCode: string | null
  subscriptionStatus?: DN.Models.Subscription.SubscriptionStatus
}

// ~~~~~~ Constants

const DangerIcon = LazySVG('icons/danger')

// ~~~~~~ Component

export const CreditCardBrand: React.FC<Props> = ({
  cardCode,
  $creditCardXPosition: creditCardXPosition,
  subscriptionStatus,

  // style

  $creditCardDangerIconColor: creditCardDangerIconColor,
}) => {
  // ~~~~~~ Render

  return (
    <STCreditCardBrand
      data-test="credit-card-brand"
      $creditCardXPosition={creditCardXPosition}
      $creditCardDangerIconColor={creditCardDangerIconColor}
    >
      <div className="credit-card-logo" />
      <span className="credit-card-code">•••• {cardCode}</span>
      {subscriptionStatus === 'unpaid' ? <DangerIcon size={16} /> : undefined}
    </STCreditCardBrand>
  )
}
