import { AppInitState } from '../../../../models/app/model'
import { BillingInfoModel } from '../../../../models/billing-info/model'
import { dynReducer } from '../../../../utils/store'
import { ApiStoreBillingAT } from '../../../actions/api-store/billing/actions'
import {
  EpicApiStoreBillingUpdateMC,
  EpicApiStoreBillingUpdateMT,
} from '../../../epics/api-store/billing/update/mutators'
import {
  EpicApiStoreCurrentUserShowMC,
  EpicApiStoreCurrentUserShowMT,
} from '../../../epics/api-store/current-user/show/mutators'

const initState = AppInitState.billingInfoShow

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ Show current user - OK
// ####################################################################################################

reducer[EpicApiStoreCurrentUserShowMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiStoreCurrentUserShowMC.ok>,
): State => {
  return {
    ...state,
    ...BillingInfoModel.fromBackToFront(payload.billing_info),
  }
}

// ####################################################################################################
// ~~~~~~ Update - START
// ####################################################################################################

reducer[ApiStoreBillingAT.UPDATE] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'running',
    errors: [],
  }
}

// ####################################################################################################
// ~~~~~~ Update - OK
// ####################################################################################################

reducer[EpicApiStoreBillingUpdateMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiStoreBillingUpdateMC.ok>,
): State => {
  return {
    ...payload,
    uiStatus: 'init',
    errors: [],
  }
}

// ####################################################################################################
// ~~~~~~ Update - ERROR
// ####################################################################################################

reducer[EpicApiStoreBillingUpdateMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiStoreBillingUpdateMC.error>,
): State => {
  return {
    ...state,
    uiStatus: 'init',
    errors: payload.errors,
  }
}

// ~~~~~~

export const billingInfoReducer = dynReducer(initState, reducer)
