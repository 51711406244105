const Sito = {
  70: '#4B7D36',
  40: '#88C070',
  10: '#D1E7C8',
} as const

// ~~~~~~

const SITO_70_70: ThemeColor = {
  light: Sito[70],
  dark: Sito[70],
}

const SITO_40_40: ThemeColor = {
  light: Sito[40],
  dark: Sito[40],
}

const SITO_10_10: ThemeColor = {
  light: Sito[10],
  dark: Sito[10],
}

// ~~~~~~

export const SitoColors = {
  SITO_70_70,
  SITO_40_40,
  SITO_10_10,
} as const
