import { AppInitState } from '../../../models/app/model'
import { BuyLicenseFieldMR } from '../../../models/buy-license/model'
import { dynReducer } from '../../../utils/store'
import { BuyLicenseMC, BuyLicenseMT } from '../../actions-mutators/buy-license/mutators'
import { ApiStoreCheckoutAT } from '../../actions/api-store/checkout/actions'
import {
  ApiStoreCheckoutCreateSessionEpicMC,
  ApiStoreCheckoutCreateSessionEpicMT,
} from '../../epics/api-store/checkout/create-session/mutators'
import {
  ApiStoreCheckoutValidateEmailCodeEpicMC,
  ApiStoreCheckoutValidateEmailCodeEpicMT,
} from '../../epics/api-store/checkout/validate-email-code/mutators'
import {
  ApiStoreSubscriptionsCreateEpicMC,
  ApiStoreSubscriptionsCreateEpicMT,
} from '../../epics/api-store/subscriptions/create/mutators'
import {
  ApiStoreCheckoutSendEmailPassEpicMC,
  ApiStoreCheckoutSendEmailPassEpicMT,
} from './../../epics/api-store/checkout/send-email-pass/mutators'

const initState = AppInitState.buyLicense

type State = typeof initState

const reducer: any = {
  ...BuyLicenseFieldMR.platform.Reducer,
  ...BuyLicenseFieldMR.subId.Reducer,
  ...BuyLicenseFieldMR.email.Reducer,
  ...BuyLicenseFieldMR.password.Reducer,
  ...BuyLicenseFieldMR.quantity.Reducer,
  ...BuyLicenseFieldMR.code.Reducer,
  ...BuyLicenseFieldMR.formStep.Reducer,
}

// ####################################################################################################
// ~~~~~~ Set license
// ####################################################################################################

reducer[BuyLicenseMT.SET_LICENSE] = (
  state: State,
  { payload }: ReturnType<typeof BuyLicenseMC.setLicense>,
): State => {
  return {
    ...initState,
    ...payload,
  }
}

// ####################################################################################################
// ~~~~~~ Clear data
// ####################################################################################################

reducer[BuyLicenseMT.CLEAR] = (state: State): State => {
  return initState
}

// ####################################################################################################
// ~~~~~~ Checkout Change Email With Password
// ####################################################################################################

reducer[ApiStoreCheckoutAT.CHANGE_EMAIL_WITH_PASS] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'init',
    errors: [],
  }
}

// ####################################################################################################
// ~~~~~~ Checkout Send Email With Password
// ####################################################################################################

reducer[ApiStoreCheckoutAT.SEND_EMAIL_PASS] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'running',
    errors: [],
  }
}

// ####################################################################################################
// ~~~~~~ Checkout Send Email With Password ok
// ####################################################################################################

reducer[ApiStoreCheckoutSendEmailPassEpicMT.OK] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'init',
    formStep: 2, // state.formStep + 1 will go next on resend!!!
  }
}

// ####################################################################################################
// ~~~~~~ Checkout Send Email With Password error
// ####################################################################################################

reducer[ApiStoreCheckoutSendEmailPassEpicMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreCheckoutSendEmailPassEpicMC.error>,
): State => {
  return {
    ...state,
    uiStatus: 'init',
    errors: payload.errors,
  }
}

// ####################################################################################################
// ~~~~~~ Checkout Validate Email Code
// ####################################################################################################

reducer[ApiStoreCheckoutAT.VALIDATE_EMAIL_CODE] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'running',
    errors: [],
    emailValidated: false,
  }
}

// ####################################################################################################
// ~~~~~~ Checkout Validate Email Code - OK
// ####################################################################################################

reducer[ApiStoreCheckoutValidateEmailCodeEpicMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreCheckoutValidateEmailCodeEpicMC.ok>,
): State => {
  return {
    ...state,
    uiStatus: 'init',
    code: payload.portal_code,
    emailValidated: true,
    formStep: state.formStep + 1,
  }
}

// ####################################################################################################
// ~~~~~~ Checkout Validate Email Code - ERROR
// ####################################################################################################

reducer[ApiStoreCheckoutValidateEmailCodeEpicMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreCheckoutValidateEmailCodeEpicMC.error>,
): State => {
  return {
    ...state,
    uiStatus: 'init',
    errors: payload.errors,
    emailValidated: false,
  }
}

// ####################################################################################################
// ~~~~~~ Checkout Create Session
// ####################################################################################################

reducer[ApiStoreCheckoutAT.CREATE_SESSION] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'running',
    errors: [],
  }
}

// ####################################################################################################
// ~~~~~~ Checkout Create Session - OK
// ####################################################################################################

// TODO(Cuenca): Maybe redirecting (step 4) if redirection fail
reducer[ApiStoreCheckoutCreateSessionEpicMT.OK] = (state: State): State => {
  return initState
}

// ####################################################################################################
// ~~~~~~ Checkout Create Session - ERROR
// ####################################################################################################

reducer[ApiStoreCheckoutCreateSessionEpicMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreCheckoutCreateSessionEpicMC.error>,
): State => {
  return {
    ...state,
    uiStatus: 'init',
    errors: payload.errors,
  }
}

// ####################################################################################################
// ~~~~~~ Checkout Buy Trial
// ####################################################################################################

reducer[ApiStoreCheckoutAT.START_FREE_SUB_OR_TRIAL] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'running',
    errors: [],
  }
}

// ####################################################################################################
// ~~~~~~ Checkout Buy Trial - OK
// ####################################################################################################

reducer[ApiStoreSubscriptionsCreateEpicMT.OK] = (state: State): State => {
  return {
    ...state,
    uiStatus: 'completed',
  }
}

// ####################################################################################################
// ~~~~~~ Checkout Buy Trial - ERROR
// ####################################################################################################

reducer[ApiStoreSubscriptionsCreateEpicMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreSubscriptionsCreateEpicMC.error>,
): State => {
  return {
    ...state,
    uiStatus: 'init',
    errors: payload.errors,
  }
}

// ~~~~~~

export const buyLicenseReducer = dynReducer(initState, reducer)
