import { AnimatePresence, motion } from 'framer-motion'
import { Fragment } from 'react'
import { MenuItem, Wrapper } from 'react-aria-menubutton'
import { useDispatch, useSelector } from 'react-redux'
import { useLocation } from 'react-router'
import { useNavigate } from 'react-router'

import { Config } from '../../../../config'
import { RoutesOld } from '../../../../constants/routes-old'
import { useCustomAuth } from '../../../../hooks/auth/use-custom-auth'
import { StoreState } from '../../../../models/app/model'
import { RootMC } from '../../../../store/actions-mutators/root/mutators'
import { Trans } from '../../intl/trans'
import { LazySVG } from '../../../svgs/lazy-svg/component'
import { STUserMenuSlide } from './style'

// ~~~~~~ Constants

const CloseIcon = LazySVG('icons/close')

// ~~~~~~ Types

type MenuItemsType = {
  ItemIcon: ReturnType<typeof LazySVG>
  transId: IntlMsgId
  value: string
}

type Props = {
  menuItems: MenuItemsType[]
}

// ~~~~~~ Component

export const UserMenuSlide: React.FC<Props> = ({ menuItems }) => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  const { logout } = useCustomAuth()

  const navigate = useNavigate()

  const location = useLocation()

  // ~~~~~~ State

  const showSideMenu = useSelector((store: StoreState) => store.root.showSideMenu)

  const currentUser = useSelector((store: StoreState) => store.currentUser)

  // ~~~~~~ Computed

  const isUserZoneLocation = location.pathname === RoutesOld.UserZone

  // ~~~~~~ Handlers

  function onClickClose() {
    dispatch(RootMC.hideSideMenu())
  }

  function onSelection(itemSelected: 'login' | 'visit-store' | 'manage-account' | 'sign-out') {
    dispatch(RootMC.hideSideMenu())

    switch (itemSelected) {
      case 'manage-account':
        navigate(RoutesOld.UserZone)
        break

      case 'visit-store':
        navigate(RoutesOld.Main)
        break

      case 'sign-out':
        logout({
          logoutParams: {
            returnTo: Config.Origin,
          },
        })
        break

      default:
        return
    }
  }

  // ~~~~~~ Render

  return (
    <STUserMenuSlide data-test="user-menu-slide">
      <AnimatePresence>
        {showSideMenu ? (
          <motion.div
            key="side-menu"
            className="side-menu-motion"
            transition={{
              // duration: 1,
              type: 'tween',
            }}
            initial={{
              x: 420,
              opacity: 0,
            }}
            animate={{
              x: 0,
              opacity: 1,
            }}
            exit={{
              x: 420,
              opacity: 0,
            }}
          >
            <Wrapper onSelection={onSelection} className="side-menu-content">
              {/* Close */}

              <div className="side-menu-close">
                <div className="button" onClick={onClickClose}>
                  <div className="close-text">
                    <Trans id="navigation.mobile-menu.close" />
                  </div>
                  <div className="icon">
                    <CloseIcon size={30} />
                  </div>
                </div>
              </div>

              {/* Items */}

              <div className="side-menu-items">
                {/* User or login */}

                <div className="item disabled item-email">
                  <div className="item-label">
                    <Trans id="navigation.mobile-menu.logged-label" />
                  </div>
                  <div>{currentUser.email}</div>
                </div>

                <hr />

                {/* Support order email for user zone page */}

                {isUserZoneLocation ? (
                  <>
                    <div className="item disabled item-email">
                      <div className="item-label">
                        <Trans id="pages.user-zone.NeedHelp" />
                      </div>
                      <a className="mailto-link" href={`mailto:${Config.Emails.Orders}`}>
                        {Config.Emails.Orders}
                      </a>
                    </div>

                    <hr />
                  </>
                ) : undefined}

                {/* Manage account */}

                {menuItems.map(({ ItemIcon, value, transId }, index) => (
                  <Fragment key={value}>
                    <MenuItem data-test={value} value={value} className="item">
                      <div className="icon">
                        <ItemIcon size={16} />
                      </div>

                      <Trans id={transId} />
                    </MenuItem>

                    {menuItems.length - 1 > index ? <hr /> : undefined}
                  </Fragment>
                ))}
              </div>
            </Wrapper>
          </motion.div>
        ) : undefined}
      </AnimatePresence>
    </STUserMenuSlide>
  )
}
