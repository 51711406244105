import styled from 'styled-components'
import { Fonts } from '../../../../../style/theme/fonts'
import { MediaQuery } from '../../../../../style/constants/media-queries'
import { Layout } from '../../../../../style/theme/layout'

// ~~~~~~ Constants

export const ClassName = 'user-zone-billing-info-edit'

// ~~~~~~ Component

export const STUserZoneBillingInfoEdit = styled.div`
  margin-top: 24px;

  ${Layout.MarginMaxWidth.MiddleContent}

  padding-bottom: 72px;

  .${ClassName}--content {
    .${ClassName}--content--go-back {
      ${Fonts.Weight[600]}
      ${Fonts.Scale.BodyBig}

        margin-bottom: 32px;
    }

    .${ClassName}--content--title {
      ${Fonts.Weight[800]}
      ${Fonts.Scale.Headline1}

      margin-bottom: 8px;
    }

    .${ClassName}--content--form {
      margin-bottom: 32px;

      .${ClassName}--content--form--section {
        ${Fonts.Weight[700]}
        ${Fonts.Scale.OldD}

        margin-bottom: 16px;
      }

      .${ClassName}--content--form--line {
        margin-bottom: 16px;
      }

      .${ClassName}--content--form--tuple {
        display: flex;
        justify-content: space-between;

        margin-bottom: 16px;

        .${ClassName}--content--form--tuple--item {
          flex: 1;
        }

        .${ClassName}--content--form--tuple--space {
          width: 16px;
        }
      }
    }

    .${ClassName}--content--actions {
      display: flex;

      padding-bottom: 72px;

      .${ClassName}--content--actions--action {
        &.first {
          margin-right: 16px;
        }
      }
    }
  }

  /* 1280 */

  @media (max-width: ${MediaQuery.MaxWidth2k}) {
    .${ClassName}--content {
      ${Layout.PaddingWidth.Desktop}

      .${ClassName}--content--go-back {
        margin-bottom: 16px;
      }

      .${ClassName}--content--title {
        ${Fonts.Scale.Headline2}
      }
    }
  }

  /* 960 */

  @media (max-width: ${MediaQuery.MD}) {
    .${ClassName}--content {
      ${Layout.PaddingWidth.Tablet}
    }
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    .${ClassName}--content {
      ${Layout.PaddingWidth.Mobile}

      .${ClassName}--content--title {
        ${Fonts.Scale.Title}
      }

      .${ClassName}--content--form--section {
        ${Fonts.Scale.Subtitle}
      }

      .${ClassName}--content--actions {
        flex-direction: column;

        .${ClassName}--content--actions--action {
          &.first {
            margin-right: 0;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
`
