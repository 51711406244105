const Morrie = {
  80: '#7A5800',
  70: '#A37600',
  60: '#CC9300',
  50: '#F9B300',
  40: '#F9CC00',
  30: '#FFE570',
  20: '#FFF0A8',
  10: '#FFF6CC',
  5: '#FFFAE0',
} as const

const MORRIE_50_50: ThemeColor = {
  light: Morrie[50],
  dark: Morrie[50],
}

const MORRIE_40_40: ThemeColor = {
  light: Morrie[40],
  dark: Morrie[40],
}

const MORRIE_30_30: ThemeColor = {
  light: Morrie[30],
  dark: Morrie[30],
}

const MORRIE_20_20: ThemeColor = {
  light: Morrie[20],
  dark: Morrie[20],
}

const MORRIE_5_5: ThemeColor = {
  light: Morrie[5],
  dark: Morrie[5],
}

export const MorrieColors = {
  MORRIE_50_50,
  MORRIE_40_40,
  MORRIE_30_30,
  MORRIE_20_20,
  MORRIE_5_5,
} as const
