import { Config } from '../config'

const { ProjectName } = Config

export const StorageKeys = {
  Lang: `${ProjectName}-lang`,
  SkipNewVersionMessage: `${ProjectName}-skip-new-version-message`,
  Theme: `${ProjectName}-theme`,
  Debug: `${ProjectName}-debug`,
  DebugLang: 'debug-lang',
  BroadcastOptions: 'broadcast-options',
} as const
