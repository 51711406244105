import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { useDispatch } from 'react-redux'

import { StoreState } from '../../../../models/app/model'
import { BuyLicenseMC } from '../../../../store/actions-mutators/buy-license/mutators'
import { ProductIds } from '../../../../constants/product-ids'
import { ApiStoreProductsAC } from '../../../../store/actions/api-store/products/actions'
import { PagePurchaseLauncherContentStep2VerifyEmail } from './step-2-verify-email/component'
import { PagePurchaseLauncherContentStep1CreateAccount } from './step-1-create-account/component'
import { PagePurchaseLauncherStep3Payment } from './step-3-payment/component'
import { PagePurchaseLauncherContentStep4Done } from './step-4-done/component'

// ~~~~~~ Component

export const PagePurchaseLauncherContent = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const metaProduct = useSelector((state: StoreState) => state.launcher)

  const buyLicense = useSelector((state: StoreState) => state.buyLicense)

  // ~~~~~~ Computed

  const curStep = buyLicense.formStep

  // ~~~~~~ Effects

  // - For reload: if we don't have product, call the api

  useEffect(() => {
    if (metaProduct.uiStatus !== 'init') return

    dispatch(ApiStoreProductsAC.listProducts(ProductIds.Launcher))

    //
  }, [dispatch, metaProduct.uiStatus])

  // - For reload: if buy license is not setted, set as premiumProduct to
  // start the "create account" flow

  useEffect(() => {
    if (metaProduct.uiStatus !== 'completed') return

    if (buyLicense.subId) return

    const premiumProduct = metaProduct.list[0]

    dispatch(
      BuyLicenseMC.setLicense({
        productId: ProductIds.Launcher,
        platform: 'windows',
        subId: premiumProduct.id,
        unitPrice: premiumProduct.price,
        trial: false,
        alwaysFree: false,
        trialPeriodDays: -1,
      }),
    )

    //
  }, [buyLicense.subId, dispatch, metaProduct.list, metaProduct.uiStatus])

  // ~~~~~~ Render

  switch (curStep) {
    case 1:
      return <PagePurchaseLauncherContentStep1CreateAccount />

    case 2:
      return <PagePurchaseLauncherContentStep2VerifyEmail />

    case 3:
      return <PagePurchaseLauncherStep3Payment />

    case 4:
      return <PagePurchaseLauncherContentStep4Done />

    default:
      return null
  }
}
