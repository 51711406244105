import { UtilsStorageSession } from '@dn/utils'
import { StorageKeys } from '../../../constants/storage'

// ####################################################################################################
// ~~~~~~ Get
// ####################################################################################################

const get = () => {
  const item = UtilsStorageSession.getItem(StorageKeys.Debug)

  return item === 'on' || item === 'qa' ? item : undefined
}

// ####################################################################################################
// ~~~~~~ Set
// ####################################################################################################

const set = (kind: 'on' | 'qa') => {
  UtilsStorageSession.setItem(StorageKeys.Debug, kind)
}

// ####################################################################################################
// ~~~~~~ Clear
// ####################################################################################################

const clear = () => {
  UtilsStorageSession.removeItem(StorageKeys.Debug)
}

// ~~~~~~

export const SessionStorageDebug = {
  get,
  set,
  clear,
} as const
