import { AppInitState } from '../../../models/app/model'
import { dynReducer } from '../../../utils/store'
import { IntlMC, IntlMT } from '../../actions-mutators/intl/mutators'

const initState = AppInitState.intl

type State = typeof initState

const reducer: any = {}

// ################################################################################
// ~~~~~~ Lang
// ################################################################################

reducer[IntlMT.CHANGE_LANG] = (
  state: State,
  { payload }: ReturnType<typeof IntlMC.changeLang>,
): State => {
  return {
    lang: payload.lang,
    messages: {
      ...state.messages,
      ...payload.messages,
    },
  }
}

// ~~~~~~

export const intlReducer = dynReducer(initState, reducer, (state) => state)
