import styled from 'styled-components'
import { Fonts } from '../../../style/theme/fonts'
import { theme } from '../../../style/utils/theme'
import { Colors } from '../../../style/theme/colors'

// ~~~~~~ Constants

export const ClassName = 'safe-payments'

// ~~~~~~ Component

export const STSafePayments = styled.div`
  display: flex;
  align-items: center;

  ${Fonts.Scale.Body}
  ${Fonts.Weight[400]}

  .${ClassName}--lock {
    margin-top: 4px;
    margin-right: 4px;

    svg {
      width: 20px;

      path {
        fill: ${theme(Colors.WOLF_90_90)};
      }
    }
  }

  .${ClassName}--stripe {
    margin-top: 4px;
    margin-left: 4px;

    svg {
      width: 124px;
      height: 28px;

      path {
        fill: ${theme(Colors.WOLF_90_90)};
      }
    }
  }
`
