import { Fonts } from './fonts'
import { Colors } from './colors'
import { Misc } from './misc'

export const Theme = {
  mode: 'light' as Theme,
  Fonts,
  Colors,
  Misc,
}
