import { PaymentElement, useElements, useStripe } from '@stripe/react-stripe-js'
import { SetupIntent } from '@stripe/stripe-js'
import { useEffect, useState } from 'react'
import { HooksData } from '@dn/hooks'

import { InfosList } from '../../../../../../components/common/infos/list/component'
import { ClassName, STUserZoneCommonPaymentMethodFormInnerForm } from './style'
import { LazySVG } from '../../../../../../components/svgs/lazy-svg/component'
import { Trans } from '../../../../../../components/common/intl/trans'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../../../../models/app/model'
import { UtilsLog } from '../../../../../../utils/logs'

// ~~~~~ Constants

const CheckBoxIcon = LazySVG('icons/check-square')

// ~~~~~~ Types

type Props = {
  stripeReturnUrl?: string
  isDefaultCard: boolean

  doSubmit: number

  onSubmitStart: () => void
  onSubmitEnd: () => void
  onSubmit: (intent: SetupIntent, setAsDefault: boolean) => void

  onStripeIsReady: () => void
}

// ~~~~~~ Component

export const UserZoneCommonPaymentMethodFormInnerForm: React.FC<Props> = ({
  stripeReturnUrl,
  isDefaultCard,

  doSubmit,

  onSubmitStart,
  onSubmitEnd,
  onSubmit,

  onStripeIsReady,
}) => {
  // ~~~~~~ Hooks

  const stripe = useStripe()

  const elements = useElements()

  // ~~~~~~ State

  // - Local

  const [stripeFormIsReady, setStripeFormIsReady] = useState(false)

  const [isDefault, setIsDefault] = useState(isDefaultCard)

  const [errors, setErrors] = useState<Infos[]>([])

  const [isSubmitting, setIsSubmitting] = useState(false)

  // - Store

  const cards = useSelector((state: StoreState) => state.cards.list)

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  // ~~~~~~ Computed

  const isLoading = !currentUser.isSessionChecked || currentUser.uiStatus === 'running'

  const prevDoSubmit = HooksData.PrevValue.useHook(doSubmit)

  const defaultCardExists = cards.some((card) => card.is_default)

  // ~~~~~~ Handlers

  function onStripeIsReadyH() {
    setStripeFormIsReady(true)

    onStripeIsReady()
  }

  function onClickIsDefault() {
    if (!defaultCardExists) return

    setIsDefault(!isDefault)
  }

  // ~~~~~~ Effects

  // - If No def card, this card must be default (it will be in backend)

  useEffect(() => {
    if (isLoading || defaultCardExists || isDefault) return

    setIsDefault(true)

    //
  }, [defaultCardExists, isDefault, isLoading])

  // - Submit to stripe and inform to call our api

  useEffect(() => {
    if (!stripe || !elements) {
      UtilsLog.devLog('no stripe or no elements', !!stripe, !!elements)

      return
    }

    if (isSubmitting || prevDoSubmit === undefined || prevDoSubmit >= doSubmit) return

    setIsSubmitting(true)
    onSubmitStart()

    stripe
      .confirmSetup({
        elements,
        confirmParams: {
          return_url: stripeReturnUrl,
        },
        redirect: 'if_required',
      })
      .then((result) => {
        // No catch, always returns error or setupIntent

        const { error, setupIntent } = result

        if (error) {
          UtilsLog.devLog('Card Errors', error)

          setErrors([{ id: 'wildcard', values: { value: error.message } }])

          return
        }

        if (!setupIntent) return

        // On Sumbit

        onSubmit(setupIntent, isDefault)
      })
      .catch((error) => {
        UtilsLog.devLog('Card Errors', error)

        setErrors([{ id: 'api.checkout.intent.Error' }])
      })
      .finally(() => {
        setIsSubmitting(false)
        onSubmitEnd()
      })

    //
  }, [
    doSubmit,
    elements,
    isDefault,
    isSubmitting,
    onSubmit,
    onSubmitEnd,
    onSubmitStart,
    prevDoSubmit,
    stripe,
    stripeReturnUrl,
  ])

  // ~~~~~~ Render

  return (
    <STUserZoneCommonPaymentMethodFormInnerForm>
      {/* Errors */}

      <InfosList infos={errors} />

      {/* Card Form */}

      <div className={`${ClassName}--stripe-form`}>
        <PaymentElement onReady={onStripeIsReadyH} />
      </div>

      {/* Set as default */}

      {stripeFormIsReady ? (
        <label
          className={`${ClassName}--set-as-default ${defaultCardExists ? '' : 'disabled'}`}
          htmlFor={`${ClassName}-set-as-default--input`}
        >
          <input
            type="checkbox"
            id={`${ClassName}-set-as-default--input`}
            disabled={isSubmitting}
            checked={isDefault}
            onChange={onClickIsDefault}
            hidden
          />

          {/* Check Box */}

          <span className={`${ClassName}--set-as-default--checkbox ${isDefault ? 'checked' : ''}`}>
            <CheckBoxIcon size={20} />
          </span>

          {/* Check Box label */}

          <span className={`${ClassName}--set-as-default--label`}>
            <Trans id="v2.pages.billing.UseAsDefaultPayment" />
          </span>
        </label>
      ) : undefined}
    </STUserZoneCommonPaymentMethodFormInnerForm>
  )
}
