import { css } from 'styled-components'
import { MediaQuery } from '../../../../../style/constants/media-queries'
import { Colors } from '../../../../../style/theme/colors'
import { HexAlpha } from '../../../../../style/theme/colors/alpha'
import { Fonts } from '../../../../../style/theme/fonts'
import { Layout } from '../../../../../style/theme/layout'
import { theme } from '../../../../../style/utils/theme'

// ~~~~~~

export const genSTUserZonePlansRootProductCardCommonStyle = (ClassName: string) => css`
  max-width: 892px;

  box-shadow: 0px 4px 16px ${theme(Colors.WOLF_90_90)}${HexAlpha[10]};

  border: solid 1px ${theme(Colors.WOLF_10_10)};

  ${Layout.Border.Radius.B}

  overflow: hidden;

  .${ClassName}--a {
    padding: 16px;

    display: grid;
    grid-template-columns: repeat(4, 1fr);
    grid-column-gap: 8px;

    .${ClassName}--a--logo-price {
      .${ClassName}--a--logo-price--logo {
        display: flex;
        align-items: center;

        svg {
          width: 58px;
          height: 24px;
        }

        .${ClassName}--a--logo-price--text {
          ${Fonts.Weight[600]}
          ${Fonts.Scale.Body}

          margin-left: 4px;
        }
      }

      .${ClassName}--a--logo-price--price {
        ${Fonts.Weight[700]}
        ${Fonts.Scale.OldD}

      .${ClassName}--a--logo-price--price--interval {
          ${Fonts.Weight[300]}
          ${Fonts.Scale.Body}
        }
      }

      .${ClassName}--a--logo-price--canceled {
        display: none;
      }
    }

    .${ClassName}--a--sub-price {
      display: none;

      .${ClassName}--a--sub-price--title {
        ${Fonts.Weight[300]}
        ${Fonts.Scale.Body}
      }

      .${ClassName}--a--sub-price--value {
        ${Fonts.Weight[700]}
        ${Fonts.Scale.Subtitle}

      .${ClassName}--a--sub-price--value--interval {
          ${Fonts.Weight[300]}
          ${Fonts.Scale.Body}
        }
      }
    }

    .${ClassName}--a--next-billing {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .${ClassName}--a--next-billing--title {
        ${Fonts.Weight[300]}
        ${Fonts.Scale.Body}
      }

      .${ClassName}--a--next-billing--value {
        ${Fonts.Weight[600]}
        ${Fonts.Scale.BodyBig}
      }
    }

    .${ClassName}--a--payment-method {
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      .${ClassName}--a--payment-method--title {
        ${Fonts.Weight[300]}
        ${Fonts.Scale.Body}
      }

      .${ClassName}--a--payment-method--value {
        ${Fonts.Weight[600]}
        ${Fonts.Scale.BodyBig}
      }
    }

    .${ClassName}--a--action {
      display: flex;
      align-items: center;
      justify-content: flex-end;

      white-space: nowrap;

      .${ClassName}--a--action--canceled {
        margin-right: 4px;
      }
    }
  }

  .${ClassName}--b {
    padding: 10px 16px;
    background-color: ${theme(Colors.LISA_10_10)};

    display: flex;
    align-items: center;

    &.is-canceled {
      background-color: ${theme(Colors.BROOKLYN_05_05)};
    }

    &.has-card {
      background-color: ${theme(Colors.BRONX_05_05)};
    }

    .${ClassName}--b--icon {
      margin-right: 4px;

      display: flex;

      svg {
        width: 15px;
        height: 15px;

        path {
          fill: ${theme(Colors.WOLF_90_90)};
        }
      }
    }

    .${ClassName}--b--text {
      ${Fonts.Weight[400]}
      ${Fonts.Scale.Body}

      .${ClassName}--b--text--highlight {
        ${Fonts.Weight[600]}
      }
    }
  }

  .${ClassName}--c {
    display: none;
  }

  /* 1280 */

  @media (max-width: ${MediaQuery.MaxWidth2k}) {
  }

  /* 960 */

  @media (max-width: ${MediaQuery.MD}) {
    .${ClassName}--a {
      grid-template-columns: repeat(3, 1fr);

      .${ClassName}--a--payment-method {
        display: none;
      }
    }
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    .${ClassName}--a {
      display: flex;
      flex-direction: column;
      justify-content: unset;

      .${ClassName}--a--logo-price {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        margin-bottom: 16px;

        .${ClassName}--a--logo-price--price {
          display: none;
        }

        .${ClassName}--a--logo-price--canceled {
          display: unset;
        }
      }

      .${ClassName}--a--sub-price {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        margin-bottom: 16px;
      }

      .${ClassName}--a--next-billing {
        display: flex;
        flex-direction: row;
        justify-content: space-between;

        margin-bottom: 16px;
      }

      .${ClassName}--a--payment-method {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
      }

      .${ClassName}--a--action {
        display: none;
      }
    }

    .${ClassName}--c {
      border-top: solid 1px ${theme(Colors.WOLF_10_10)};

      display: flex;
      justify-content: center;

      .${ClassName}--c--action {
        padding: 16px;
      }
    }
  }
`
