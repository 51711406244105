import { useEffect, useState } from 'react'
import { fromEvent, merge } from 'rxjs'
import { debounceTime } from 'rxjs/operators'
import { AnimatePresence, motion } from 'framer-motion'
import { UtilsHoc, UtilsText } from '@dn/utils'
import { BreakPoint, BreakPointMap } from '../constants/breakpoints'
import { WindowEvents } from '../constants/window-events'
import { DialogsMargins } from '../style/constants/dialogs'

// ~~~~~~ Types

export type FadeMoveProps = {
  modalSize: BreakPoint
  positionY?: 'center' | 'top'

  // From stacked-modal
  modalClickedOut?: number
  modalId?: string
  isActiveModal?: boolean
  onModalDissapeared?: () => void
}

// ~~~~~~ HOC

function createComponent<P extends object>(WrappedComponent: React.FC<P>) {
  const WithFadeMove: React.FC<FadeMoveProps & P> = ({
    modalSize,
    positionY,

    // From stacked-modal
    modalClickedOut,
    modalId,
    isActiveModal,
    onModalDissapeared,

    // ~>
    ...restProps
  }) => {
    // ~~~~~~ State

    const [, setUpdate] = useState(0)

    const [initialModalClickedOut] = useState(modalClickedOut)

    const [iHaveBeenClickedOut, setIHaveBeenClickedOut] = useState(0)
    const [iHaveDisappeared, setIHaveDisappeared] = useState(0)

    const [contentHeight, setContentHeight] = useState(0)

    const [dissapear, setDissapear] = useState(false)

    // ~~~~~~ Computed

    const modalWidth = Number(BreakPointMap[modalSize])

    const finalModalWidth =
      window.innerWidth <= modalWidth + DialogsMargins.Window.Side * 2
        ? window.innerWidth - DialogsMargins.Window.Side * 2
        : modalWidth

    const modalCenterX = finalModalWidth / 2

    const initialX = window.innerWidth / 2 - modalCenterX

    const toCenterY = window.innerHeight / 2 - contentHeight / 1.2

    const finalY =
      !positionY || positionY === 'center'
        ? toCenterY > DialogsMargins.Window.Top
          ? toCenterY
          : DialogsMargins.Window.Top
        : DialogsMargins.Window.Top

    const initialY =
      finalY === DialogsMargins.Window.Top
        ? DialogsMargins.Window.Top - DialogsMargins.Dialogs.InitialYDiff
        : finalY - DialogsMargins.Dialogs.InitialYDiff

    const maxHeight = window.innerHeight - DialogsMargins.WindowAndDialogs

    // ~~~~~~ Handlers

    function startDissapear() {
      setDissapear(true)
      onModalDissapeared && onModalDissapeared()
      UtilsText.clearSelection()
    }

    // ~~~~~~ Effects

    // Check if Dialog has been clicked out
    useEffect(() => {
      if (!isActiveModal) {
        return
      }

      if (initialModalClickedOut !== modalClickedOut) {
        setIHaveBeenClickedOut(performance.now())
      }
    }, [initialModalClickedOut, isActiveModal, modalClickedOut])

    // Window Resize: it triggers a change in the state to repaint the component
    useEffect(() => {
      const sub = merge(
        fromEvent(window, WindowEvents.Resize),
        fromEvent(window, WindowEvents.OrientationChange),
      )
        .pipe(debounceTime(100))
        .subscribe({
          next: () => setUpdate(performance.now()),
        })

      return () => {
        sub.unsubscribe()
      }
    }, [])

    // Clear selected text (hack for browsers bug)
    useEffect(() => {
      UtilsText.clearSelection()

      // Remove the possible focus of a trigger button
      const activeElement = document.activeElement as any
      activeElement && typeof activeElement.blur === 'function' && activeElement.blur()
    }, [])

    // ~~~~~~ Render

    // Add the element to the document in a hidden position to get its measurements
    if (!contentHeight && !dissapear) {
      return (
        <div style={{ position: 'absolute', top: -2000, width: finalModalWidth }}>
          <WrappedComponent
            {...(restProps as P)}
            onContentHeightChanged={(height: number) => {
              contentHeight !== height && setContentHeight(height)
            }}
          />
        </div>
      )
    }

    return (
      <AnimatePresence
        onExitComplete={() => {
          setIHaveDisappeared(performance.now())
        }}
      >
        {/* Ummount triggers the exit animation */}
        {!dissapear ? (
          <motion.div
            key={modalId}
            transition={{
              // duration: 1,
              type: 'tween',
            }}
            initial={{
              x: initialX,
              y: initialY,
              opacity: 0,
            }}
            animate={{
              x: initialX,
              y: finalY,
              opacity: 1,
            }}
            exit={{
              x: initialX,
              y: initialY,
              opacity: 0,
            }}
          >
            <div style={{ width: finalModalWidth, position: 'fixed', maxHeight: maxHeight }}>
              {/* Component visible, ready to be used */}
              <WrappedComponent
                {...(restProps as P)}
                // Injected
                modalId={modalId}
                isActiveModal={isActiveModal}
                iHaveBeenClickedOut={iHaveBeenClickedOut}
                startDissapear={startDissapear}
                onContentHeightChanged={(height: number) => {
                  contentHeight !== height && setContentHeight(height)
                }}
              />
            </div>
          </motion.div>
        ) : (
          <div style={{ display: 'none', width: finalModalWidth, maxHeight: maxHeight }}>
            {/* Component invisible, ready to be removed */}
            <WrappedComponent
              {...(restProps as P)}
              // Injected
              modalId={modalId}
              isActiveModal={isActiveModal}
              iHaveDisappeared={iHaveDisappeared}
              onContentHeightChanged={(height: number) => {
                contentHeight !== height && setContentHeight(height)
              }}
            />
          </div>
        )}
      </AnimatePresence>
    )
  }

  // ~~~~~~ Display name

  ;(WithFadeMove as any).displayName = `WithFadeMove(${UtilsHoc.getDisplayName(WrappedComponent)})`

  // ~~~~~~ Component

  return WithFadeMove
}

// ~~~~~~ Exposed HOC

export const withFadeMove = <P extends object>(component: React.FC<P>) => createComponent(component)
