import { WrapperState } from 'react-aria-menubutton'
import { useSelector } from 'react-redux'

import { StoreState } from '../../../models/app/model'
import { LazySVG } from '../../svgs/lazy-svg/component'
import { UserMenuDesktop } from './desktop/component'
import { UserMenuMobile } from './mobile/component'
import { STProps, STUserMenu } from './style'
import { UtilsMobile } from '@dn/utils'
import { useLocation } from 'react-router'

// ~~~~~~ Constants

const UserCircleIcon = LazySVG('icons/user-circle')

const ArrowDownIcon = LazySVG('icons/arrow-down')

const isMobile = UtilsMobile.calcIsMobile()

const Items: MenuItems[] = [
  {
    ItemIcon: LazySVG('icons/setting'),
    transId: 'navigation.desktop-menu.manage-account',
    value: 'manage-account',
    showInUserZone: false,
  },
  {
    ItemIcon: LazySVG('icons/log-out'),
    transId: 'common.sign.Out',
    value: 'sign-out',
    showInUserZone: true,
  },
]

// ~~~~~~ Types

type MenuItems = {
  ItemIcon: ReturnType<typeof LazySVG>
  transId: IntlMsgId
  value: string
  showInUserZone: boolean
}

type Props = STProps & {
  onMenuToggle?(evt: WrapperState): void
  menuItems?: any
}

// ~~~~~~ Component

export const UserMenu = ({ $menuIsOpen: menuIsOpen, onMenuToggle }: Props) => {
  // ~~~~~~ Hooks

  const { email } = useSelector((state: StoreState) => state.currentUser)

  const path = useLocation().pathname

  const inUserZone = !!~path.search('/user-zone')

  // ~~~~~~ Computed

  const finalMenuItems = Items.filter((item) => (inUserZone ? item.showInUserZone : true))

  // ~~~~~~ Render

  return (
    <STUserMenu data-test="user-menu" $menuIsOpen={menuIsOpen}>
      {isMobile ? (
        <UserMenuMobile menuItems={finalMenuItems} />
      ) : (
        <UserMenuDesktop menuItems={finalMenuItems} onMenuToggle={onMenuToggle}>
          <div data-test="account-button" className="account-button">
            <div className="icon-container">
              <UserCircleIcon size={20} />
            </div>

            <div data-test="user-email" className="user-email">
              <div className="user-email-text">{email}</div>
              <div className="arrow-icon-container">
                <ArrowDownIcon size={5} />
              </div>
            </div>
          </div>
        </UserMenuDesktop>
      )}

      {/* - */}
    </STUserMenu>
  )
}
