import { Observable, of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { catchError, map } from 'rxjs/operators'
import { ApiRoutes } from '../constants/routes'
import { ApiHeadersUtils } from '../utils/headers'

// NOTE: This call is used in components before stripe calls

type Res = { intent: { client_secret: string } }
type ResParsed = DNApiResParsed<Res>
type ResError = DNApiError<Infos[]>

const { CardIntent } = ApiRoutes.Checkout

export const apiStoreCheckoutCardIntent$ = (token: string, headers?: AppHeaders) => {
  return ajax<Res>({
    ...ApiHeadersUtils.genHeadersWithAuth(token, headers),
    method: CardIntent.Method,
    url: CardIntent.getUrl(),
  }).pipe(
    map((res): ResParsed => {
      return {
        status: res.status,
        response: res.response,
      }
    }),
    catchError(
      (res: DNApiRes<void>): Observable<ResError> =>
        of({
          status: res.status,
          response: [{ id: 'api.checkout.intent.Error' }],
        }),
    ),
  )
}
