import styled from 'styled-components'
import { Fonts } from '../../../../style/theme/fonts'
import { MediaQuery } from '../../../../style/constants/media-queries'

// ~~~~~~ Constants

export const ClassName = 'user-zone-common-header'

// ~~~~~~ Component

export const STUserZoneCommonHeader = styled.div`
  margin-left: auto;
  margin-right: auto;

  .${ClassName}--header {
    height: 120px;

    display: flex;
    align-items: center;

    .${ClassName}--header--content {
      max-width: ${MediaQuery.LG};
      width: 100%;
      margin-left: auto;
      margin-right: auto;

      ${Fonts.Weight[800]}
      ${Fonts.Scale.OldB}
    }
  }

  /* 1280 */

  @media (max-width: ${MediaQuery.MaxWidth2k}) {
    .${ClassName}--header {
      height: 96px;

      .${ClassName}--header--content {
        ${Fonts.Scale.Headline1}
      }
    }
  }

  /* 960 */

  @media (max-width: ${MediaQuery.MD}) {
    .${ClassName}--header {
      height: 88px;

      .${ClassName}--header--content {
        ${Fonts.Scale.Headline2}
      }
    }
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    .${ClassName}--header {
      height: 64px;

      .${ClassName}--header--content {
        ${Fonts.Scale.Title}
      }
    }
  }
`
