const Ron = {
  80: '#F08C00',
  70: '#F59F00',
  60: '#FAB005',
  50: '#FCC419',
  40: '#FFD43B',
  30: '#FFE066',
  20: '#FFEC99',
  10: '#FFF3BF',
  5: '#FFF9DB',
} as const

// ~~~~~~

const RON_05_05: ThemeColor = {
  light: Ron[5],
  dark: Ron[5],
}

const RON_40_40: ThemeColor = {
  light: Ron[40],
  dark: Ron[40],
}

const RON_50_50: ThemeColor = {
  light: Ron[50],
  dark: Ron[50],
}

const RON_60_60: ThemeColor = {
  light: Ron[60],
  dark: Ron[60],
}

// ~~~~~~

export const RonColors = {
  RON_05_05,
  RON_40_40,
  RON_50_50,
  RON_60_60,
} as const
