const prefix = 'mt-store-api-subscriptions-create'

// ~~~~~~ Mutators Types

export const ApiStoreSubscriptionsCreateEpicMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const ApiStoreSubscriptionsCreateEpicMC = {
  ok: (isLogged: boolean, subscription: DN.Models.Subscription.Api.Create.ResParsed) => ({
    type: ApiStoreSubscriptionsCreateEpicMT.OK,
    payload: {
      isLogged,
      subscription,
    },
  }),

  error: (errors: DN.Models.Subscription.Api.Create.ResError) => ({
    type: ApiStoreSubscriptionsCreateEpicMT.ERROR,
    error: true,
    payload: {
      errors,
    },
  }),
} as const
