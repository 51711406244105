const initModel: DN.Models.Product.ListModelFront = {
  uiStatus: 'init',
  list: [],
  errors: [],
}

const initItemModel: DN.Models.Product.ModelFront = {
  id: '',
  name: '',
  description: '',
  trial_period_days: null,
  price: 0,
  platform: '',

  // Broadcast only
  // edition: BroadcastProductEdition
  interval: 'month',
  order: 0,
  connections: 0,
  quality: '720p',
  fps: 0,
  min_limit: null,
}

const initBroadcastModel: DN.Models.Product.BroadcastModelFront = {
  uiStatus: 'init',
  products: {
    free: initItemModel,
    premium: {
      month: initItemModel,
      year: initItemModel,
    },
  },
  errors: [],
}

const initMontageModel: DN.Models.Product.MontageModelFront = {
  uiStatus: 'init',
  products: {
    windows: {
      year: initItemModel,
    },
    android: {
      year: initItemModel,
    },
  },
  errors: [],
}

// ~~~~~~

export const ProductModel = {
  initModel,
  initBroadcastModel,
  initMontageModel,
} as const
