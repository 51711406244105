const Kio = {
  80: '#146666',
  70: '#1B8888',
  60: '#22A9AA',
  50: '#36BEBF',
  40: '#44D9DA',
  30: '#79F6E7',
  20: '#B2FAF1',
  10: '#D9FCF8',
  5: '#ECFEFC',
} as const

// ~~~~~~

const KIO_05_05: ThemeColor = {
  light: Kio[5],
  dark: Kio[5],
} as const

const KIO_30_30: ThemeColor = {
  light: Kio[30],
  dark: Kio[30],
} as const

const KIO_40_40: ThemeColor = {
  light: Kio[40],
  dark: Kio[40],
} as const

// ~~~~~~

export const KioColors = {
  KIO_30_30,
  KIO_40_40,
  KIO_05_05,
} as const
