import styled from 'styled-components'

// ~~~~~~ Constants

export const ClassName = 'page-common-top-left'

// ~~~~~~ Component

export const STPageCommonTopLeft = styled.div`
  display: flex;
  align-items: center;

  .${ClassName}--link {
    display: flex;
    align-items: center;

    margin-right: 32px;

    .${ClassName}--link--logo {
      height: 24px;
      width: 130px;
    }
  }
`
