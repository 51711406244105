import { ProductButtonLauncher } from '../../../../../components/common/product-button/launcher/component'
import { LazySVG } from '../../../../../components/svgs/lazy-svg/component'
import { PageCommonOPStep2VerifyEmail } from '../../../../@-common/obtaining-products/step-2-verify-email/component'
import { PageTrialsOrFreeCommonTabSteps } from '../../../@-common/tab-steps/component'

// ~~~~~~ Constants

const Logo = LazySVG('logos/launcher-gradient')

// ~~~~~~ Component

export const PageTrialsOrFreeLauncherContentStep2VerifyEmail = () => {
  // ~~~~~~ Render

  return (
    <PageCommonOPStep2VerifyEmail
      Logo={Logo}
      ProductButton={ProductButtonLauncher}
      tabSteps={<PageTrialsOrFreeCommonTabSteps kind="trial" />}
    />
  )
}
