import Skeleton from 'react-loading-skeleton'

import { Button } from '../../../../../components/common/button/component'
import { Dollars } from '../../../../../components/common/dollars/component'
import { Trans } from '../../../../../components/common/intl/trans'
import { LazySVG } from '../../../../../components/svgs/lazy-svg/component'
import { ClassName, STProductPageBcastContentSubCard } from './style'

// ~~~~~~ Constants

const ArrowRightIcon = LazySVG('icons/arrow-long-right')

// ~~~~~~ Types

type Props = {
  isRunning: boolean
  isMonthly: boolean
  price: number

  onClick: () => void
}

// ~~~~~~ Component

export const ProductPageBcastContentSubCard: React.FC<Props> = ({
  isRunning,
  isMonthly,
  price,
  onClick,
}) => {
  // ~~~~~~ Computed

  const isYearly = !isMonthly

  const intervalIntl: IntlMsgId = isMonthly
    ? 'pages.products.broadcast.PayMonthly'
    : 'pages.common.Yearly'

  // ~~~~~~ Render

  return (
    <STProductPageBcastContentSubCard>
      {/* Info */}

      <div className={`${ClassName}--left`}>
        {/* Interval */}

        <div className={`${ClassName}--left--interval`}>
          <Trans id={intervalIntl} />

          {isYearly ? (
            <div className={`${ClassName}--left--interval--badge`}>
              <Trans id="pages.products.broadcast.SaveTwentyPercent" />
            </div>
          ) : undefined}
        </div>

        {/* Price */}

        <div className={`${ClassName}--left--price`}>
          {isRunning ? <Skeleton /> : <Dollars value={price} />}
        </div>
      </div>

      {/* Action */}

      <div className={`${ClassName}--right`}>
        <Button
          colorType="only-link"
          padding="0"
          intlId="pages.products.broadcast.BuyNow"
          IconRight={ArrowRightIcon}
          running={isRunning}
          disabled={isRunning}
          active={!isRunning}
          onClick={onClick}
        />
      </div>
    </STProductPageBcastContentSubCard>
  )
}
