const Wolf = {
  100: '#0B0D17',
  90: '#1F2333',
  80: '#292D3D',
  70: '#333747',
  60: '#424657',
  50: '#737682',
  40: '#8A8D97',
  30: '#A0A1A7',
  20: '#CACACC',
  10: '#E0E0E1',
  5: '#F5F5F5',
  0: '#FFFFFF',
} as const

// ~~~~~~

const WOLF_00_00: ThemeColor = {
  light: Wolf[0],
  dark: Wolf[0],
}

const WOLF_05_05: ThemeColor = {
  light: Wolf[5],
  dark: Wolf[5],
}

const WOLF_10_10: ThemeColor = {
  light: Wolf[10],
  dark: Wolf[10],
}

const WOLF_30_30: ThemeColor = {
  light: Wolf[30],
  dark: Wolf[30],
}

const WOLF_20_20: ThemeColor = {
  light: Wolf[20],
  dark: Wolf[20],
}

const WOLF_40_40: ThemeColor = {
  light: Wolf[40],
  dark: Wolf[40],
}

const WOLF_50_50: ThemeColor = {
  light: Wolf[50],
  dark: Wolf[50],
}

const WOLF_60_60: ThemeColor = {
  light: Wolf[60],
  dark: Wolf[60],
}

const WOLF_70_70: ThemeColor = {
  light: Wolf[70],
  dark: Wolf[70],
}

const WOLF_80_80: ThemeColor = {
  light: Wolf[80],
  dark: Wolf[80],
}

const WOLF_90_90: ThemeColor = {
  light: Wolf[90],
  dark: Wolf[90],
}

const WOLF_100_100: ThemeColor = {
  light: Wolf[100],
  dark: Wolf[100],
}

// ~~~~~~

export const WolfColors = {
  WOLF_00_00,
  WOLF_05_05,
  WOLF_10_10,
  WOLF_20_20,
  WOLF_30_30,
  WOLF_40_40,
  WOLF_50_50,
  WOLF_60_60,
  WOLF_70_70,
  WOLF_80_80,
  WOLF_90_90,
  WOLF_100_100,
} as const
