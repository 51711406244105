import { useEffect } from 'react'
import { useSelector, useDispatch } from 'react-redux'
import { UtilsEncDec } from '@dn/utils'

import { UserZoneCommonBannerBack } from '../../../../@-common/banner-back/component'
import { ClassName, STUserZonePlansProductDetailBcastChangePlan } from './style'
import { StoreState } from '../../../../../../models/app/model'
import { useNavigate, useParams } from 'react-router'
import { RouteDataChangePaymentMethod, Routes } from '../../../../../../constants/routes'
import { Trans } from '../../../../../../components/common/intl/trans'
import { CreditCard } from '../../../../../../components/common/credit-card/component'
import { Button } from '../../../../../../components/common/button/component'
import { LazySVG } from '../../../../../../components/svgs/lazy-svg/component'
import { useTrans } from '../../../../../../hooks/use-trans'
import { Dollars } from '../../../../../../components/common/dollars/component'
import { ProductButtonBcast } from '../../../../../../components/common/product-button/bcast/component'
import {
  ApiStoreSubscriptionsAC,
  ChangePlanKind,
} from '../../../../../../store/actions/api-store/subscriptions/actions'
import { HooksData } from '@dn/hooks'

// ~~~~~~ Constants

const ChangeCardIcon = LazySVG('icons/pencil')

// ~~~~~~ Component

export const UserZonePlansProductDetailBcastChangePlan = () => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  const subId = useParams<{ subId: string }>().subId

  const dispatch = useDispatch()

  // ~~~~~~ State

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  const bcastSub = useSelector((state: StoreState) => state.broadcastSub)

  const subs = useSelector((state: StoreState) => state.subscriptions)

  const cards = useSelector((state: StoreState) => state.cards)

  // ~~~~~~ Computed

  const isLoading = !currentUser.isSessionChecked || currentUser.uiStatus === 'running'

  const sub = subs.find((sub) => sub.subscription_id === subId)

  const isRunning = sub?.uiStatus === 'running'

  const prevPriceId = HooksData.PrevValue.useHook(sub?.price_id)

  const subIsFree = sub ? sub.price_id === bcastSub.products.free.id : false

  const card = sub
    ? cards.list.find((card) => card.payment_method_id === sub.payment_method_id)
    : undefined

  const titleIntlId: IntlMsgId = sub
    ? sub.interval === 'month'
      ? 'v2.pages.upgrade-plan.payment-details.change-plan.ToYearly'
      : 'v2.pages.upgrade-plan.payment-details.change-plan.ToMonthly'
    : 'wildcard'

  const backText = useTrans(titleIntlId)

  const amount = sub
    ? sub.interval === 'month'
      ? bcastSub.products.premium.year.price - bcastSub.products.premium.month.price
      : 0
    : -1

  // ~~~~~~ Handlers

  function onClickGoBack() {
    if (!subId) return

    navigate(Routes.genUserZonePlansBcastDetail(subId))
  }

  function onClickChangeCard() {
    if (!subId) return

    const nextDataObj: RouteDataChangePaymentMethod = {
      backPath: window.location.pathname,
      backText: backText,
      successPath: window.location.pathname,
      subId,
    }

    const nextData = UtilsEncDec.base64UrlEncode(JSON.stringify(nextDataObj))

    const changeCardRoute = Routes.genUserZonePlansChangePaymentMethod(nextData)

    navigate(changeCardRoute)
  }

  function onClickUpdate() {
    if (!sub) return

    const [priceId, kind]: [string, ChangePlanKind] =
      sub.interval === 'month'
        ? [bcastSub.products.premium.year.id, 'month-to-year']
        : [bcastSub.products.premium.month.id, 'year-to-month']

    dispatch(ApiStoreSubscriptionsAC.changePlan(kind, sub.subscription_id, priceId))
  }

  // ~~~~~~ Effects

  // - If no sub return to plans

  useEffect(() => {
    if ((sub && !subIsFree) || isLoading) return

    navigate(Routes.UserZonePlans)

    //
  }, [isLoading, navigate, sub, subIsFree])

  // - Go to product detail if price_id change (on success)

  useEffect(() => {
    if (!sub || prevPriceId === undefined || prevPriceId === sub.price_id) {
      return
    }

    navigate(Routes.genUserZonePlansBcastDetail(sub.subscription_id))

    //
  }, [navigate, prevPriceId, sub])

  // ~~~~~~ Render

  if (isLoading || !sub) return null

  return (
    <STUserZonePlansProductDetailBcastChangePlan>
      {/* Banner back */}

      <UserZoneCommonBannerBack
        noImage
        backIntl="v2.pages.plan-details.PlanDetails"
        kind="bcast"
        onClickGoBack={onClickGoBack}
      />

      <div className={`${ClassName}--content`}>
        {/* Title */}

        <div className={`${ClassName}--content--title`}>
          <Trans id={titleIntlId} />
        </div>

        {/* Subtitle */}

        <div className={`${ClassName}--content--subtitle`}>
          <Trans
            id={
              sub.interval === 'month'
                ? 'v2.pages.upgrade-plan.payment-details.confirm-plan.confirmYear'
                : 'v2.pages.upgrade-plan.payment-details.confirm-plan.confirmMonth'
            }
          />
        </div>

        {/* Payment method */}

        <div className={`${ClassName}--content--payment-method`}>
          {/* Title */}

          <div className={`${ClassName}--content--payment-method--title`}>
            <Trans id="pages.common.PaymentMethod" />
          </div>

          {/* Current card for pay the product */}

          <div className={`${ClassName}--content--payment-method--card`}>
            {/* Card logo-last4 */}

            <div className={`${ClassName}--content--payment-method--card--last4`}>
              {card ? <CreditCard card={card} /> : undefined}
            </div>

            {/* Action: change card */}

            <div className={`${ClassName}--content--payment--method--card--change`}>
              <Button
                colorType="quaternary-link"
                intlId="pages.common.ChangePayment"
                IconLeft={ChangeCardIcon}
                leftIconMarginRight={4}
                size="s"
                fontWeight="600"
                running={false}
                disabled={false}
                active={true}
                onClick={onClickChangeCard}
              />
            </div>
          </div>
        </div>

        {/* Price info */}

        <div className={`${ClassName}--content--price-info`}>
          <div className={`${ClassName}--content--price-info--text`}>
            <Trans id="common.DueToday" />
          </div>

          <div className={`${ClassName}--content--price-info--value`}>
            <Dollars value={amount} />
          </div>
        </div>

        {/* Space */}

        <hr className={`${ClassName}--content--separator`} />

        {/* Action */}

        <div className={`${ClassName}--content--actions`}>
          {/* Subscribe */}

          <ProductButtonBcast
            className={`${ClassName}--content--actions--action first`}
            intlId="pages.products.common.UpdatePlan"
            IconRight={undefined}
            active={!isRunning}
            disabled={isRunning}
            running={isRunning}
            onClick={onClickUpdate}
          />

          {/* Cancel */}

          <Button
            colorType="default"
            intlId="common.Cancel"
            running={false}
            disabled={false}
            active={true}
            onClick={onClickGoBack}
          />
        </div>
      </div>

      {/* - */}
    </STUserZonePlansProductDetailBcastChangePlan>
  )
}
