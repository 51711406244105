import { HooksData } from '@dn/hooks'
import { Navigate, Route, Routes as Switch, useLocation } from 'react-router'
import { useEffect, useRef } from 'react'
import { useSelector } from 'react-redux'
import Scrollbars from 'react-custom-scrollbars'

import { useCustomAuth } from '../../hooks/auth/use-custom-auth'
import { STUserZone } from './style'
import { LayoutTopContent } from '../../components/layouts/top-content/component'
import { LayoutTopContentTopLeft } from '../../components/layouts/top-content/parts/top/left/component'
import { PageCommonTopLeft } from '../@-common/top/left/component'
import { LayoutTopContentTopRight } from '../../components/layouts/top-content/parts/top/right/component'
import { PageCommonTopRight } from '../@-common/top/right/component'
import { LayoutTopContentContent } from '../../components/layouts/top-content/parts/content/component'
import { StoreState } from '../../models/app/model'
import { useNavigate } from 'react-router'
import { Routes } from '../../constants/routes'
import { UserZoneCommonHeader } from './@-common/header/component'
import { UserZoneBilling } from './2-billing/component'
import { DialogConfirmRemCard } from '../../components/dialogs/confirmation/rem-card/component'
import { DialogConfirmChangeDefaultCard } from '../../components/dialogs/confirmation/change-default-card/component'
import { DialogConfirmCancelPlan } from '../../components/dialogs/confirmation/cancel-plan/component'
import { DialogConfirmReactivatePlan } from '../../components/dialogs/confirmation/reactivate-plan/component'
import { LayoutTopContentHeader } from '../../components/layouts/top-content/parts/header/component'
import { LayoutTopContentNavigation } from '../../components/layouts/top-content/parts/navigation/component'
import { UserZoneCommonNavigationTabs } from './@-common/navigation-tabs/component'
import { UserZonePlans } from './1-plans/component'
import { useListProduct } from '../../hooks/api/use-list-product'

// ~~~~~~ Component

export const UserZone = () => {
  // ~~~~~~ Hooks

  const { isLoading } = useCustomAuth()

  useListProduct('broadcast-subscription')
  useListProduct('montage')
  useListProduct('launcher')

  const navigate = useNavigate()

  const prevIsLoading = HooksData.PrevValue.useHook(isLoading)

  const path = useLocation().pathname

  const prevPath = HooksData.PrevValue.useHook(path)

  // ~~~~~~ State

  // - Store

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  // - Local

  const scrollRef = useRef<Scrollbars>(null)

  // ~~~~~~ Effects

  // -

  useEffect(() => {
    if (!prevPath || prevPath === path) return

    if (!scrollRef.current) return

    scrollRef.current.scrollToTop()

    //
  }, [path, prevPath])

  // - Exit user-zone if no user data after load

  useEffect(() => {
    if (isLoading || currentUser.uiStatus === 'running' || currentUser.email) return

    // Fill the gap between isLoading and cur user running

    if (prevIsLoading) return

    navigate(Routes.Root)

    //
  }, [currentUser.email, currentUser.uiStatus, isLoading, navigate, prevIsLoading])

  // ~~~~~~ Render

  return (
    <STUserZone>
      {/* Dialogs */}

      {/* Dialog: Confirm remove card */}

      <DialogConfirmRemCard />

      {/* Dialog: Confirm change default card */}

      <DialogConfirmChangeDefaultCard />

      {/* Dialog: Cancel Plan */}

      <DialogConfirmCancelPlan />

      {/* Dialog: Reactivate Plan */}

      <DialogConfirmReactivatePlan />

      {/* Content */}

      <Scrollbars ref={scrollRef}>
        <LayoutTopContent noContentPadding>
          {/* Top */}

          {/* - Left */}

          <LayoutTopContentTopLeft>
            <PageCommonTopLeft />
          </LayoutTopContentTopLeft>

          {/* - Right */}

          <LayoutTopContentTopRight>
            <PageCommonTopRight />
          </LayoutTopContentTopRight>

          {/* Header */}

          <LayoutTopContentHeader>
            <UserZoneCommonHeader />
          </LayoutTopContentHeader>

          {/* Navigation */}

          <LayoutTopContentNavigation>
            <UserZoneCommonNavigationTabs />
          </LayoutTopContentNavigation>

          {/* Content */}

          <LayoutTopContentContent>
            {/* <STUserZoneContent> */}
            <Switch>
              {/* Root: Plans */}

              <Route path="/*" element={<UserZonePlans />} />

              {/* Plans */}

              <Route path={Routes.UserZonePlansNested} element={<UserZonePlans />} />

              {/* Billing */}

              <Route path={Routes.UserZoneBillingNested} element={<UserZoneBilling />} />

              {/* Unknown routes */}

              <Route path="*" element={<Navigate replace to={Routes.Root} />} />

              {/* - */}
            </Switch>
            {/* </STUserZoneContent> */}
          </LayoutTopContentContent>

          {/* - */}
        </LayoutTopContent>
      </Scrollbars>
    </STUserZone>
  )
}
