import styled from 'styled-components'

import { Colors } from '../../../style/theme/colors'
import { theme } from '../../../style/utils/theme'
import { Theme } from '../../../style/theme'
import { Fonts } from '../../../style/theme/fonts'
import { MediaQuery } from '../../../style/constants/media-queries'

const { Misc } = Theme

export type STProps = {
  $borderColor?: ThemeColor
  $isNoJustifyContent?: true
}

export const STMonDialog = styled.div<STProps>`
  position: relative;

  background-color: ${theme(Colors.WOLF_00_00)};
  color: ${theme(Colors.WOLF_100_100)};

  ${Misc.BorderRadius};

  h1,
  h2,
  h3,
  h4,
  h5,
  h6 {
    color: ${theme(Colors.WOLF_100_100)};
  }

  .dialog-header {
    .title {
      user-select: none;

      display: flex;
      justify-content: space-between;

      .text {
        padding: 24px 24px 16px 24px;
        overflow: hidden;
        text-overflow: ellipsis;

        .subtext {
          color: ${theme(Colors.WOLF_70_70)};
          ${Fonts.Scale.OldE};
        }
      }

      ${Fonts.Scale.OldD};
      ${Fonts.Weight[700]}

      .btns-holder {
        cursor: pointer;
        min-height: 44px;
        top: 0;
        right: 0;

        .btn-close,
        .btn-reload {
          min-width: 44px;
          min-height: 44px;
          height: 100%;
          padding-left: 24px;

          svg {
            margin-top: 25px;
            margin-right: 28px;
            width: 24px;
            height: 24px;

            path {
              transition: fill 200ms ease;
              fill: ${theme(Colors.WOLF_100_100)};
            }
          }

          &:hover {
            svg path {
              fill: ${theme(Colors.WOLF_100_100)};
            }
          }

          &:active {
            svg path {
              fill: ${theme(Colors.WOLF_100_100)};
            }
          }

          .esc {
            ${Fonts.Scale.OldE};

            color: ${theme(Colors.WOLF_100_100)};
            user-select: none;
            width: 100%;
          }
        }
      }
    }
  }

  .inside-scroll {
    padding-left: 24px;
    padding-right: 24px;
  }

  .dialog-content-actions {
    padding-bottom: ${Misc.PaddingBox};

    .dialog-content {
      text-align: left;
      margin-bottom: 24px;
    }

    .dialog-actions {
      padding-left: ${Misc.PaddingBox};
      padding-right: ${Misc.PaddingBox};

      display: flex;
      align-items: center;
    }
  }

  @media (max-width: ${MediaQuery.MaxWidthMiddle}) {
    .dialog-content-actions .dialog-actions {
      flex-direction: column;
    }
  }
`
