export const ProductIds = {
  Launcher: 'launcher',
  Montage: 'montage',
  BroadcastSub: 'broadcast-subscription',
} as const

export const ProductIdsToName = {
  [ProductIds.Launcher]: 'Launcher',
  [ProductIds.Montage]: 'Montage',
  [ProductIds.BroadcastSub]: 'Broadcast',
}

export const StoreProductIds = {
  launcher: 'launcher',
  montage: 'montage',
  'broadcast-subscription': 'broadcastSub',
} as const
