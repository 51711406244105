const prefix = 'at-api-store-billing'

// ~~~~~~ Action Types

export const ApiStoreBillingAT = {
  UPDATE: `${prefix}-update`,
} as const

// ~~~~~~ Action Mutators

export const ApiStoreBillingAC = {
  update: (data: DN.Models.BillingInfo.Api.Update.Req) => ({
    type: ApiStoreBillingAT.UPDATE,
    payload: data,
  }),
} as const
