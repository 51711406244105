import { LazySVG } from '../../svgs/lazy-svg/component'
import { Trans } from '../intl/trans'
import { ClassName, STSafePayments } from './style'

// ~~~~~~ Constants

const LockIcon = LazySVG('icons/lock')

const StripeIcon = LazySVG('icons/stripe')

// ~~~~~~ Component

export const SafePayments = () => {
  // ~~~~~~ Render

  return (
    <STSafePayments>
      <div className={`${ClassName}--lock`}>
        <LockIcon size={20} />
      </div>

      <Trans id="pages.purchase-flow.payment-step.SecurePayment" />

      <div className={`${ClassName}--stripe`}>
        <StripeIcon size={20} />
      </div>
    </STSafePayments>
  )
}
