import { ChangeEvent, KeyboardEvent, useEffect, useRef, useState } from 'react'

import { KeyCodes } from '../../../constants/key-codes'
import { InfosList } from '../infos/list/component'
import { Trans } from '../intl/trans'
import { STProps, STIntlDigitCodeInput } from './style'

// ~~~~~~ Types

type Props = STProps & {
  numberLength: number
  label?: IntlMsgId
  errors?: Infos[]
  value: string
  doFocus?: number
  onChange: (value: string) => void
  onKeyDown?: (key: string) => void
}

// ~~~~~~ Component

export const IntlDigitCodeInput: React.FC<Props> = ({
  numberLength,
  label,
  errors,
  value,
  doFocus,

  onChange,
  onKeyDown,

  // style
  $disabled: disabled,
}) => {
  // ~~~~~~ Hooks

  const inputRef = useRef<HTMLInputElement>(null)

  // ~~~~~~ State

  const [focused, setFocused] = useState(false)

  const [storedDoFocus, setStoredDoFocus] = useState(0)

  // ~~~~~~ Computed

  const values = value.split('')

  // ~~~~~~ Handlers

  function onInputClick() {
    inputRef.current?.focus()
  }

  function onCodeChange(evt: ChangeEvent<HTMLInputElement>) {
    const internalValue = evt.target.value

    if (value.length >= numberLength) return

    return onChange((value + internalValue).slice(0, numberLength))
  }

  function onKeyUp(evt: KeyboardEvent<HTMLInputElement>) {
    if (evt.key !== KeyCodes.BACKSPACE) return

    return onChange(value.slice(0, value.length - 1))
  }

  function onFocus() {
    setFocused(true)
  }

  function onBlur() {
    setFocused(false)
  }

  function onKeyDownEvt(evt: KeyboardEvent<HTMLInputElement>) {
    onKeyDown?.(evt.key)
  }

  // ~~~~~~ Effects

  // - Autofocus input

  useEffect(() => {
    if (!doFocus || !inputRef.current) return

    setStoredDoFocus(doFocus)

    setFocused(doFocus === storedDoFocus)

    //
  }, [doFocus, storedDoFocus])

  // ~~~~~~ Render

  return (
    <STIntlDigitCodeInput
      data-test="intl-digit-code-input"
      $hasErrors={!!errors?.length}
      $focussed={focused}
      $disabled={disabled}
    >
      {/* Label input */}

      {label ? (
        <div className="label">
          <Trans id={label} />
        </div>
      ) : undefined}

      <div className="wrap" onClick={onInputClick}>
        {/* Element to display values entered */}

        {[...Array(numberLength)].map((_, idx) => {
          const next = values.length === idx

          const filled = idx + 1 === numberLength

          return (
            <div key={idx} className="display">
              {values[idx] ? (
                <>
                  <div>{values[idx]}</div>
                  {filled ? <div className="fake-cursor-end"></div> : undefined}
                </>
              ) : (
                <>
                  {next ? <div className="fake-cursor"></div> : undefined}

                  <div>-</div>
                </>
              )}
            </div>
          )
        })}

        {/* Input transformed between the represented Element of values where the focus is */}

        <input
          data-test="digit-code"
          value=""
          className="input"
          ref={inputRef}
          disabled={disabled}
          onChange={onCodeChange}
          onKeyUp={onKeyUp}
          onFocus={onFocus}
          onBlur={onBlur}
          onKeyDown={onKeyDownEvt}
          autoFocus
        />
      </div>

      {/* Errors */}

      {errors && <InfosList data-test="errors" infos={errors} />}

      {/* - */}
    </STIntlDigitCodeInput>
  )
}
