const prefix = 'mt-debugger'

export const DebuggerMT = {
  ENABLE: `${prefix}-enable-debug`,
  DISABLE: `${prefix}-disable-debug`,
} as const

export const DebuggerMC = {
  enable: (kind: 'on' | 'qa') => ({
    type: DebuggerMT.ENABLE,
    payload: {
      kind,
    },
  }),

  disable: () => ({
    type: DebuggerMT.DISABLE,
  }),
} as const
