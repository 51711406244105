import { ofType, StateObservable } from 'redux-observable'
import { Observable } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import { StoreState } from '../../../models/app/model'
import { getMessages } from '../../../intl/get-messages'
import { IntlMC } from '../../actions-mutators/intl/mutators'
import { IntlAC, IntlAT } from '../../actions/intl/actions'

type Action = ReturnType<typeof IntlAC.changeLang>

type GetMessagesObs = {
  lang: Lang
  messages: Messages
}

export const changeLangEpic$ = (action$: Observable<Action>, state$: StateObservable<StoreState>) =>
  action$.pipe(
    ofType(IntlAT.CHANGE_LANG),
    mergeMap(({ payload }) => {
      const obs$ = new Observable<GetMessagesObs>((observer) => {
        getMessages(payload, true)
          .then(({ messages }) => {
            observer.next({
              lang: payload,
              messages: messages as any,
            })
          })
          .catch(() => {
            observer.complete()
          })
      })

      return obs$
    }),
    map(({ lang, messages }) => IntlMC.changeLang(lang, messages)),
  )
