const OVERLAY: ThemeColor = {
  light: 'rgba(27, 23, 43, 0.8)',
  dark: 'rgba(255, 255, 255, 0.2)',
}

const OVERLAY_2: ThemeColor = {
  light: 'rgba(27, 23, 43, 0.8)',
  dark: 'rgba(27, 23, 43, 0.8)',
}

const TRANSPARENT: ThemeColor = {
  light: 'transparent',
  dark: 'transparent',
}

const BRIGHT_PINK: ThemeColor = {
  light: 'rgb(248, 117, 234)',
  dark: 'rgb(248, 117, 234)',
}

// ~~~~~~

export const OthersColors = {
  OVERLAY,
  OVERLAY_2,
  TRANSPARENT,
  BRIGHT_PINK,
} as const
