import styled from 'styled-components'

import { Transition } from '../../../../../../style/animations/css-animations'
import { Colors } from '../../../../../../style/theme/colors'
import { Fonts } from '../../../../../../style/theme/fonts'
import { theme } from '../../../../../../style/utils/theme'
import { HexAlpha } from '../../../../../../style/theme/colors/alpha'

// ~~~~~~ Constants

export const ClassName = 'user-zone-billing-root-card-menu'

// ~~~~~~ Component

export const STUserZoneBillingRootCardMenu = styled.div`
  position: relative;

  .${ClassName}--main-btn {
    cursor: pointer;

    display: flex;
    justify-content: center;
    align-items: center;

    padding: 10px;
    border-radius: 6px;

    ${Transition.AllFastEaseOut}

    svg {
      width: 24px;

      path {
        fill: ${theme(Colors.WOLF_90_90)};
      }
    }

    &:hover,
    &:active,
    &.opened {
      background-color: ${theme(Colors.WOLF_05_05)};
    }
  }

  .${ClassName}--dropdown {
    position: relative;

    .${ClassName}--dropdown--content {
      z-index: 100;

      margin-top: 0px;

      position: absolute;
      right: 0;

      padding: 12px 0px;

      background: ${theme(Colors.WOLF_00_00)};

      box-shadow: 0px 4px 12px ${theme(Colors.WOLF_90_90)}${HexAlpha[10]};

      border: 1px solid ${theme(Colors.WOLF_10_10)};
      border-radius: 8px;

      box-sizing: border-box;

      .${ClassName}--dropdown--content--item {
        cursor: pointer;

        white-space: nowrap;

        padding: 8px 16px;

        background-color: ${theme(Colors.WOLF_00_00)};

        ${Transition.AllFastEaseOut}
        ${Fonts.Scale.Body}
        ${Fonts.Weight[600]}

        &:hover {
          background-color: ${theme(Colors.WOLF_05_05)};
        }

        &.highlight {
          color: ${theme(Colors.BRONX_50_50)};
        }

        &.danger {
          color: ${theme(Colors.BROOKLYN_60_60)};
        }
      }
    }
  }
`
