import styled from 'styled-components'
import { Colors } from '../../../../style/theme/colors'
import { Fonts } from '../../../../style/theme/fonts'
import { theme } from '../../../../style/utils/theme'

// ~~~~~~ Constants

export const ClassName = 'user-zone-common-need-help'

// ~~~~~~ Component

export const STNeedHelp = styled.div`
  .${ClassName}--text {
    ${Fonts.Weight[300]}
    ${Fonts.Scale.Body}
  }

  a {
    ${Fonts.Weight[600]}

    color: ${theme(Colors.WOLF_90_90)};
    text-decoration: none;
  }
`
