import { ConstMisc } from '@dn/constants'

export const MediaQueryNumbers = {
  XL: 1920,
  LG: 1280,
  MD: 960,
  SM: ConstMisc.MaxMobileWidth,
  XS: 375,
}

export const MediaQuery = {
  // Old

  MaxWidth2k: '1280px',
  MaxWidthMedium: '1023px',
  MaxWidthLand: '769px',

  // New

  MaxWidth1k: '1024px',
  MaxWidthMiddle: '480px',
  MaxWidthMin: `${ConstMisc.MaxMobileWidth}px`,

  XL: `${MediaQueryNumbers.XL}px`,
  LG: `${MediaQueryNumbers.LG}px`,
  MD: `${MediaQueryNumbers.MD}px`,
  SM: `${MediaQueryNumbers.SM}px`,
  XS: `${MediaQueryNumbers.XS}px`,
} as const
