import { LayoutTopContentContext } from './context/component'
import { STLayoutTopContent } from './style'

// ~~~~~~ Types

type Props = {
  'data-testid'?: string
  children: React.ReactNode

  noContentPadding?: true
}

// ~~~~~~ Component

export const LayoutTopContent: React.FC<Props> = ({
  'data-testid': dataTestId = 'layout-top-content',

  children,

  noContentPadding,
}) => {
  // ~~~~~~ Render

  return (
    <STLayoutTopContent data-testid={dataTestId}>
      {/* Top */}

      <div className="layout-top-content-top">
        <div className="layout-top-content-top-content">
          {/* Left */}

          <div className="left">
            <LayoutTopContentContext.Provider value={{ kind: 'top-left' }}>
              {children}
            </LayoutTopContentContext.Provider>
          </div>

          {/* Right */}

          <div className="right">
            <LayoutTopContentContext.Provider value={{ kind: 'top-right' }}>
              {children}
            </LayoutTopContentContext.Provider>
          </div>

          {/* -- */}
        </div>
      </div>

      {/* Header */}

      <LayoutTopContentContext.Provider value={{ kind: 'header' }}>
        <div className="layout-top-header">{children}</div>
      </LayoutTopContentContext.Provider>

      {/* Navigation */}

      <LayoutTopContentContext.Provider value={{ kind: 'navigation' }}>
        <div className="layout-top-nav">{children}</div>
      </LayoutTopContentContext.Provider>

      {/* Content */}

      <div className={`layout-top-content-content ${noContentPadding ? 'no-padding' : ''}`}>
        <LayoutTopContentContext.Provider value={{ kind: 'content' }}>
          {children}
        </LayoutTopContentContext.Provider>
      </div>

      {/* Footer */}

      <LayoutTopContentContext.Provider value={{ kind: 'footer' }}>
        <div className="layout-top-footer">{children}</div>
      </LayoutTopContentContext.Provider>

      {/* - */}
    </STLayoutTopContent>
  )
}
