import { UtilsMobile } from '@dn/utils'
import { BreakPoint } from '../../../constants/breakpoints'
import { Button } from '../../common/button/component'
import { ButtonColorType } from '../../common/button/style'
import { Actions } from '../../common/compound-dialog/actions/component'
import { genAnimatedDialog } from '../../common/compound-dialog/component'
import { Content } from '../../common/compound-dialog/content/component'
import { Trans } from '../../common/intl/trans'
import { STDialogConfirmation, STProps } from './style'

// ~~~~~~ Types

export type ActionButtons = {
  okActionIntl: IntlMsgId
  cancelActionIntl?: IntlMsgId
}

export type IntlValues = { [key: string]: React.ReactText }

type Props = STProps & {
  'data-test'?: string
  Dialog: ReturnType<typeof genAnimatedDialog>
  isNoJustifyContent?: true
  hideCloseBtn?: true

  title: IntlMsgId
  titleValues?: IntlValues

  okButtonColor?: ButtonColorType

  contentTextQuestionValues?: IntlValues
  contentTextQuestion?: IntlMsgId
  contentTextQuestionComponent?: JSX.Element
  contentText?: IntlMsgId
  contentTextComponent?: JSX.Element
  contentTextValues?: IntlValues
  actionButtons?: ActionButtons

  isRunning: boolean
  doClose: number

  onEndDissapear: () => void
  onClickClose: () => void
  onClickConfirm: () => void
}

// ~~~~~~ Component

export const DialogConfirmation: React.FC<Props> = ({
  'data-test': dataTest,
  Dialog,
  isNoJustifyContent,
  hideCloseBtn,

  title,
  titleValues,

  okButtonColor,

  // errors,
  contentText,
  contentTextComponent,
  contentTextQuestionValues,
  contentTextQuestion,
  contentTextQuestionComponent,
  contentTextValues,
  actionButtons,

  isRunning,
  doClose,

  onEndDissapear,
  onClickClose,
  onClickConfirm,

  $isDefaultCardModel: isDefaultCardModel,
}) => {
  // ~~~~~~ Hooks

  const isMobile = UtilsMobile.calcIsMobile()

  // ~~~~~~ Render

  return (
    <Dialog
      data-test={dataTest}
      $isNoJustifyContent={isNoJustifyContent}
      modalSize={BreakPoint.S}
      dialogTitle={title}
      dialogTitleValues={titleValues}
      closeDialog={doClose}
      endDissapear={onEndDissapear}
      hideCloseBtn={hideCloseBtn}
    >
      <STDialogConfirmation
        data-test="dialog-confirmation"
        $isDefaultCardModel={isDefaultCardModel}
      >
        {/* Content */}

        <Content>
          <div className={'box-content'}>
            {contentTextQuestionComponent ? (
              contentTextQuestionComponent
            ) : contentTextQuestion ? (
              <div className="question-style">
                <Trans id={contentTextQuestion} values={contentTextQuestionValues} />
              </div>
            ) : undefined}

            {contentTextComponent ? (
              contentTextComponent
            ) : contentText ? (
              <Trans id={contentText} values={contentTextValues} />
            ) : undefined}
          </div>
        </Content>

        {/* Actions */}

        <Actions>
          {/* Confirm */}

          <Button
            data-test-id="confirm"
            className="confirm-button"
            intlId={actionButtons?.okActionIntl || 'common.Confirm'}
            colorType={okButtonColor || 'default-transparent'}
            fontWeight={isMobile ? '800' : undefined}
            active={true}
            running={isRunning}
            disabled={isRunning}
            onClick={onClickConfirm}
          />

          {/* Cancel */}

          <Button
            data-test-id="close"
            className="cancel-button"
            intlId={actionButtons?.cancelActionIntl || 'common.Cancel'}
            colorType="default"
            fontWeight={isMobile ? '800' : undefined}
            active={true}
            disabled={false}
            running={false}
            onClick={onClickClose}
          />
        </Actions>
      </STDialogConfirmation>
    </Dialog>
  )
}
