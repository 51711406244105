import { ofType, StateObservable } from 'redux-observable'
import { Observable } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import { StoreState } from '../../../../../models/app/model'
import { apiStoreCardRem$ } from '../../../../../services/api-store/card/remove'
import { ApiStoreCardAC, ApiStoreCardAT } from '../../../../actions/api-store/card/actions'
import { ApiUtils } from '../../../utils'
import { EpicApiStoreCardRemMC } from './mutators'

type Action = ReturnType<typeof ApiStoreCardAC.remove>

export const apiStoreCardRemEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiStoreCardAT.REMOVE),
    mergeMap(({ payload }) => apiStoreCardRem$(state$.value.currentUser.token, payload.cardId)),
    map((res) =>
      ApiUtils.isNotError(res)
        ? EpicApiStoreCardRemMC.ok(res.response)
        : EpicApiStoreCardRemMC.error(res.response),
    ),
  )
