import { ofType, StateObservable } from 'redux-observable'
import { Observable } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'

import { StoreState } from '../../../../../models/app/model'
import {
  ApiStoreCheckoutAC,
  ApiStoreCheckoutAT,
} from '../../../../actions/api-store/checkout/actions'

import { ApiUtils } from '../../../utils'
import { ApiStoreCheckoutSetDefaultCardEpicMC } from './mutators'
import { apiStoreCheckoutSetDefaultCard$ } from '../../../../../services/api-store/checkout/set-default-card'

type Action = ReturnType<typeof ApiStoreCheckoutAC.setDefaultCard>

export const apiStoreCheckoutSetDefaultCardEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiStoreCheckoutAT.SET_DEFAULT_CARD),

    mergeMap(({ payload }) =>
      apiStoreCheckoutSetDefaultCard$(state$.value.currentUser.token, payload.paymentMethodId),
    ),

    map((res) => {
      if (ApiUtils.isError(res)) {
        return ApiStoreCheckoutSetDefaultCardEpicMC.error(res.response)
      }

      const card = state$.value.cards.list.find(
        (card) => card.payment_method_id === res.response.paymentMethodId,
      )

      // This should not happen

      if (!card) {
        return ApiStoreCheckoutSetDefaultCardEpicMC.error([
          { id: 'api.checkout.set-default-card.Error' },
        ])
      }

      return ApiStoreCheckoutSetDefaultCardEpicMC.ok(res.response, card)
    }),
  )
