import { useNavigate } from 'react-router'

import { RoutesOld } from '../../../constants/routes-old'
import { MediaQuery } from '../../../style/constants/media-queries'
import { Button } from '../button/component'
import { Trans } from '../intl/trans'
import { ProductLogo } from '../product-logo/component'
import { LazySVG } from '../../svgs/lazy-svg/component'
import { ClassName, STProductCard } from './style'
import { ProductIdsToName } from '../../../constants/product-ids'
import { UtilsMobile } from '@dn/utils'

// ~~~~~ Constants

const ArrowRightIcon = LazySVG('icons/arrow-long-right')

// ~~~~~~ Types

type Props = {
  'data-test-id'?: string
  productId: DN.Models.Product.ProductId
  title: IntlMsgId
  description: IntlMsgId

  landingUrl?: string
  imageUrl: string
  imageUrlLG: string
  imageUrlMD: string
}

// ~~~~~~ Component

export const ProductCard: React.FC<Props> = ({
  // Component Props

  'data-test-id': dataTest,
  landingUrl = RoutesOld.Main,
  productId,
  title,
  description,
  imageUrl,
  imageUrlLG,
  imageUrlMD,
}) => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  const isMobile = UtilsMobile.calcIsMobile()

  // ~~~~~~ Computed

  const ProductName = ProductIdsToName[productId]

  // ~~~~~~ Handlers

  function onClickCardLink() {
    navigate(landingUrl)
  }

  // ~~~~~~ Render

  return (
    <STProductCard data-test-id={dataTest}>
      {/* Left  */}

      <div className={`${ClassName}--left`}>
        {/* Image */}

        <picture className={`${ClassName}--left--image`}>
          <source media={`(max-width: ${MediaQuery.MaxWidthMiddle})`} srcSet={imageUrl} />
          <source media={`(max-width: ${MediaQuery.MD})`} srcSet={imageUrlMD} />
          <img className="image-container" src={imageUrlLG} alt={productId} />
        </picture>

        {/* - */}
      </div>

      {/* Right */}

      <div className={`${ClassName}--right`}>
        {/* Logo */}

        <div className={`${ClassName}--right--logo`}>
          <ProductLogo gradientImage productId={productId} $productLogoWidth={74} />
        </div>

        {/* Text */}

        <div className={`${ClassName}--right--text`}>
          {/* Title */}

          <div className={`${ClassName}--right--text--title`}>
            <Trans id={title} />
          </div>

          {/* Description */}

          <p className={`${ClassName}--right--text--subtitle`}>
            <Trans id={description} />
          </p>
        </div>

        {/* Action: Go to product detail */}

        <div className={`${ClassName}--right--action`}>
          <Button
            intlId="pages.main.cards.common.GetProduct"
            colorType="only-link"
            width="unset"
            padding="0"
            intlValues={{ value: ProductName }}
            size={isMobile ? 's' : 'm'}
            fontWeight="600"
            IconRight={ArrowRightIcon}
            running={false}
            disabled={false}
            active={true}
            onClick={onClickCardLink}
          />
        </div>
      </div>
    </STProductCard>
  )
}
