import styled from 'styled-components'
import { Fonts } from '../../../../style/theme/fonts'
import { theme } from '../../../../style/utils/theme'
import { Colors } from '../../../../style/theme/colors'
import { Layout } from '../../../../style/theme/layout'
import { MediaQuery } from '../../../../style/constants/media-queries'

// ~~~~~~ Constants

export const ClassName = 'page-common-op-step1'

// ~~~~~~ Component

export const STPageCommonOPStep1CreateAccount = styled.div`
  max-width: 622px;
  margin-left: auto;
  margin-right: auto;

  margin-top: 32px;

  /* Steps */

  .${ClassName}--steps {
    margin-bottom: 56px;
  }

  /* Logo */

  .${ClassName}--logo {
    width: 98px;

    margin-bottom: 8px;
  }

  /* Title */

  .${ClassName}--title {
    ${Fonts.Weight[800]}
    ${Fonts.Scale.OldA}

    margin-bottom: 16px;
  }

  /* Subtitle */

  .${ClassName}--subtitle {
    ${Fonts.Weight[300]}
    ${Fonts.Scale.OldD}

    margin-bottom: 24px;

    .${ClassName}--subtitle--has-account {
      display: flex;

      padding: 8px;

      ${Layout.Border.Radius.A}

      background-color: ${theme(Colors.RON_05_05)};

      .${ClassName}--subtitle--has-account--icon {
        svg {
          width: 16px;
          height: 16px;

          margin-right: 4px;

          path {
            fill: ${theme(Colors.RON_60_60)};
          }
        }
      }

      .${ClassName}--subtitle--has-account--action {
        cursor: pointer;

        ${Fonts.Weight[600]}
        border-bottom: solid 2px ${theme(Colors.WOLF_90_90)};
      }
    }

    .${ClassName}--subtitle--action {
      cursor: pointer;

      ${Fonts.Weight[600]}
      border-bottom: solid 2px ${theme(Colors.WOLF_90_90)};
    }
  }

  /* Field */

  .${ClassName}--field {
    margin-bottom: 24px;

    &.email {
      max-width: 466px;
    }

    &.pass {
      max-width: 346px;
    }

    &.last {
      margin-bottom: 32px;
    }
  }

  /* Action */

  .${ClassName}--action {
    margin-bottom: 32px;
  }

  /* Privacy and Terms */

  .${ClassName}--footer {
    ${Fonts.Scale.BodyBig}

    padding-bottom: 108px;

    .${ClassName}--footer--link {
      cursor: pointer;

      ${Fonts.Weight[700]}
      border-bottom: solid 1px ${theme(Colors.WOLF_90_90)};
    }
  }

  /* 1280 */

  @media (max-width: ${MediaQuery.MaxWidth2k}) {
    /* Title */

    .${ClassName}--title {
      ${Fonts.Scale.OldB}
    }

    /* Privacy and Terms */

    .${ClassName}--footer {
      ${Fonts.Scale.Body}
    }
  }

  /* 960 */

  @media (max-width: ${MediaQuery.MD}) {
    /* Steps */

    .${ClassName}--steps {
      margin-bottom: 50px;
    }

    /* Logo */

    .${ClassName}--logo {
      width: 86px;
    }

    /* Title */

    .${ClassName}--title {
      ${Fonts.Scale.Headline1}
    }

    /* Subtitle */

    .${ClassName}--subtitle {
      ${Fonts.Scale.Subtitle}
    }

    /* Action */

    .${ClassName}--action {
      margin-bottom: 24px;
    }
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    /* Steps */

    .${ClassName}--steps {
      margin-bottom: 32px;
    }

    /* Title */

    .${ClassName}--title {
      ${Fonts.Scale.Headline2}
    }

    /* Subtitle */

    .${ClassName}--subtitle {
      ${Fonts.Scale.BodyBig}
    }

    /* Field */

    .${ClassName}--field {
      &.pass {
        max-width: unset;
      }

      &.last {
        margin-bottom: 24px;
      }
    }

    /* Action */

    .${ClassName}--action {
      margin-bottom: 16px;
    }

    /* Privacy and Terms */

    .${ClassName}--footer {
      text-align: center;
    }
  }
`
