import { Observable, of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { catchError, map } from 'rxjs/operators'
import { ApiRoutes } from '../constants/routes'
import { ApiHeadersUtils } from '../utils/headers'

type Req = DN.Models.Subscription.Api.ChangePlan.Req
type Res = DN.Models.Subscription.Api.ChangePlan.Res
type ResParsed = DNApiResParsed<DN.Models.Subscription.Api.ChangePlan.ResParsed>
type ResError = DNApiError<DN.Models.Subscription.Api.ChangePlan.ResError>

const { ChangePlan } = ApiRoutes.Subscriptions

export const apiStoreSubscriptionsChangePlan$ = (
  token: string,
  subscriptionId: string,
  priceId: string,
  headers?: AppHeaders,
) => {
  const body: Req = {
    price_id: priceId,
  }

  return ajax<Res>({
    ...ApiHeadersUtils.genHeadersWithAuth(token, headers),
    method: ChangePlan.Method,
    url: ChangePlan.getUrl(subscriptionId),
    body,
  }).pipe(
    map((res): ResParsed => {
      return {
        status: res.status,
        response: res.response,
      }
    }),
    catchError(
      (res: DNApiRes<void>): Observable<ResError> =>
        of({
          status: res.status,
          response: {
            errors: [{ id: 'api.subscriptions.change-plan.Error' }],
            subscriptionId,
          },
        }),
    ),
  )
}
