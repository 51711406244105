import { ofType, StateObservable } from 'redux-observable'
import { Observable, of, zip } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'

import { StoreState } from '../../../../../models/app/model'
import { apiStoreCardAdd$ } from '../../../../../services/api-store/card/add'
import { ApiStoreCardAC, ApiStoreCardAT } from '../../../../actions/api-store/card/actions'

import { ApiUtils } from '../../../utils'
import { EpicApiStoreCardAddMC } from './mutators'

type Action = ReturnType<typeof ApiStoreCardAC.add>

export const apiStoreCardAddEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiStoreCardAT.ADD),

    mergeMap(({ payload }) =>
      zip(of(payload), apiStoreCardAdd$(state$.value.currentUser.token, payload.paymentId)),
    ),

    map(([payload, res]) => {
      if (!ApiUtils.isNotError(res)) {
        return EpicApiStoreCardAddMC.error(res.response)
      }

      const defaultCard = state$.value.cards.list.find((card) => card.is_default)

      // Creating a card when no default card exists
      // will set the card as default (BUG: backend returns card.is_default: false)
      if (!defaultCard) {
        res.response.card.is_default = true
      }

      return EpicApiStoreCardAddMC.ok(res.response, payload.setDefault)
    }),
  )
