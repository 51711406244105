import { ofType, StateObservable } from 'redux-observable'
import { Observable } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'

import { StoreState } from '../../../../../models/app/model'
import { apiStoreCheckoutShowSession$ } from '../../../../../services/api-store/checkout/show-session'
import {
  ApiStoreCheckoutAC,
  ApiStoreCheckoutAT,
} from '../../../../actions/api-store/checkout/actions'
import { ApiUtils } from '../../../utils'
import { ApiStoreCheckoutShowSessionEpicMC } from './mutators'

type Action = ReturnType<typeof ApiStoreCheckoutAC.showSession>

export const apiStoreCheckoutShowSessionEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiStoreCheckoutAT.SHOW_SESSION),

    mergeMap(({ payload }) => apiStoreCheckoutShowSession$(payload)),

    map((res) =>
      ApiUtils.isNotError(res)
        ? ApiStoreCheckoutShowSessionEpicMC.ok(res.response)
        : ApiStoreCheckoutShowSessionEpicMC.error(res.response),
    ),
  )
