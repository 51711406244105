import { ofType, StateObservable } from 'redux-observable'
import { Observable } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'

import { StoreState } from '../../../../../models/app/model'
import { apiStoreCurrentUserShow$ } from '../../../../../services/api-store/current-user/get'
import {
  ApiStoreCurrentUserAC,
  ApiStoreCurrentUserAT,
} from '../../../../actions/api-store/current-user/actions'
import { ApiUtils } from '../../../utils'
import { EpicApiStoreCurrentUserShowMC } from './mutators'

type Action = ReturnType<typeof ApiStoreCurrentUserAC.show>

export const apiStoreCurrentUserShowEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiStoreCurrentUserAT.SHOW),

    mergeMap(({ payload }) => apiStoreCurrentUserShow$(payload.auth0Token)),

    map((res) =>
      ApiUtils.isNotError(res)
        ? EpicApiStoreCurrentUserShowMC.ok(res.response)
        : EpicApiStoreCurrentUserShowMC.error(res.response),
    ),
  )
