import styled from 'styled-components'
import { Fonts } from '../../../../../../style/theme/fonts'
import { theme } from '../../../../../../style/utils/theme'
import { Colors } from '../../../../../../style/theme/colors'
import { Layout } from '../../../../../../style/theme/layout'
import { Transition } from '../../../../../../style/animations/css-animations'
import { MediaQuery } from '../../../../../../style/constants/media-queries'

// ~~~~~~ Constants

export const ClassName = 'user-zone-plans-purchase-bcast-upg-2-premium'

// ~~~~~~ Component

export const STUserZonePlansPurchaseBcastUpg2Premium = styled.div`
  ${Layout.MarginMaxWidth.MiddleContent}

  .${ClassName}--content {
    .${ClassName}--content--title {
      ${Fonts.Weight[800]}
      ${Fonts.Scale.Headline1}

      margin-bottom: 8px;
    }

    .${ClassName}--content--subtitle {
      ${Fonts.Weight[300]}
      ${Fonts.Scale.BodyBig}

      margin-bottom: 24px;
    }

    .${ClassName}--content--intervals-title {
      ${Fonts.Weight[700]}
      ${Fonts.Scale.BodyBig}

      margin-bottom: 16px;
    }

    .${ClassName}--content--interval {
      cursor: pointer;

      max-width: 480px;
      padding: 16px;
      width: calc(100% - 32px);

      border: solid 1px ${theme(Colors.WOLF_40_40)};

      ${Layout.Border.Radius.B}

      display: flex;
      justify-content: space-between;

      margin-bottom: 16px;

      ${Transition.ColorBorderAndBackgroundFaster};

      &.last {
        margin-bottom: 24px;
      }

      &.selected {
        border-color: ${theme(Colors.BRONX_50_50)};
        background-color: ${theme(Colors.BRONX_05_05)};

        .${ClassName}--content--interval--a {
          .${ClassName}--content--interval--a--check {
            svg {
              display: unset;
            }

            .${ClassName}--content--interval--a--check--empty {
              display: none;
            }
          }
        }
      }

      .${ClassName}--content--interval--a {
        display: flex;

        .${ClassName}--content--interval--a--check {
          display: flex;
          align-items: center;

          margin-right: 16px;

          svg {
            display: none;

            width: 28px;
            height: 28px;

            path {
              fill: ${theme(Colors.BRONX_50_50)};
            }
          }

          .${ClassName}--content--interval--a--check--empty {
            width: 20px;
            height: 20px;

            margin: 2px;

            border-radius: 50%;

            border: solid 2px ${theme(Colors.WOLF_40_40)};
          }
        }

        .${ClassName}--content--interval--a--info {
          .${ClassName}--content--interval--a--info--interval {
            ${Fonts.Weight[300]}
            ${Fonts.Scale.Subtitle}
          }

          .${ClassName}--content--interval--a--info--price {
            ${Fonts.Weight[800]}
            ${Fonts.Scale.Headline1}
          }
        }
      }

      .${ClassName}--content--interval--save {
        display: flex;
        align-items: center;

        .${ClassName}--content--interval--save--value {
          ${Fonts.Weight[400]}
          ${Fonts.Scale.Body}

          ${Layout.Border.Radius.A}

          padding: 2px 4px;

          color: ${theme(Colors.BRONX_60_60)};
          background-color: ${theme(Colors.BRONX_10_10)};
        }
      }
    }

    .${ClassName}--content--actions {
      display: flex;

      .${ClassName}--content--actions--action {
        &.first {
          margin-right: 16px;
        }
      }
    }
  }

  /* 1280 */

  @media (max-width: ${MediaQuery.MaxWidth2k}) {
    .${ClassName}--content {
      ${Layout.PaddingWidth.Desktop}

      .${ClassName}--content--go-back {
        margin-bottom: 24px;
      }

      .${ClassName}--content--title {
        ${Fonts.Scale.Headline2}
      }
    }
  }

  /* 960 */

  @media (max-width: ${MediaQuery.MD}) {
    .${ClassName}--content {
      ${Layout.PaddingWidth.Tablet}
    }
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    .${ClassName}--content {
      ${Layout.PaddingWidth.Mobile}

      .${ClassName}--content--title {
        ${Fonts.Scale.Title}
      }

      .${ClassName}--content--subtitle {
        ${Fonts.Scale.Subtitle}
      }

      .${ClassName}--content--actions {
        flex-direction: column;

        .${ClassName}--content--actions--action {
          &.first {
            margin-right: 0;
            margin-bottom: 16px;
          }
        }
      }
    }
  }
`
