const Scale = {
  // Old

  OldA: 'font-size: 56px;line-height: 64px;',
  OldB: 'font-size: 48px;line-height: 56px;',
  OldC: 'font-size: 28px;line-height: 36px;',
  OldD: 'font-size: 20px;line-height: 28px;',
  OldE: 'font-size: 12px;line-height: 16px;',

  // New

  Headline1: 'font-size: 40px;line-height: 48px;',
  Headline2: 'font-size: 32px;line-height: 40px;',
  Title: 'font-size: 24px;line-height: 32px;',
  Subtitle: 'font-size: 18px;line-height: 24px;',
  BodyBig: 'font-size: 16px;line-height: 24px;',
  Body: 'font-size: 14px;line-height: 20px;',
  Label: 'font-size: 12px;line-height: 18px;',
  Helper: 'font-size: 10px;line-height: 16px;',
} as const

// TODO(Cuenca): Reasearch what is this
const ScaleOnlySize = {
  Display: '32px',
  Headline1: '28px',
  Title: '24px',
  Body1: '18px',
  Body2: '16px',
  Body3: '14px',
  Caption: '12px',
  Helper: '12px',
  BigA: '54px',
  BigB: '140px',
  BigC: '48px',
} as const

const Weight = {
  // 100
  '100': 'font-weight: 100;',
  // 300
  '300': 'font-weight: 300;',
  // 400 - Regular
  '400': 'font-weight: 400;',
  // 500
  '500': 'font-weight: 500;',
  // 600
  '600': 'font-weight: 600;',
  // 700
  '700': 'font-weight: 700;',
  // 800
  '800': 'font-weight: 800;',
  // 900
  '900': 'font-weight: 900;',
} as const

const Family = 'font-family: Inter, Arial, sans-serif;'

export const Fonts = {
  Scale,
  Weight,
  Family,
  ScaleOnlySize,
} as const
