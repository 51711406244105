import { useNavigate, useParams } from 'react-router'
import { UserZoneCommonBannerBack } from '../../../@-common/banner-back/component'
import { STUserZonePlansProductDetailBcast } from './style'
import { Routes } from '../../../../../constants/routes'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../../../models/app/model'
import { useEffect } from 'react'
import { UserZonePlansProductDetailBcastFree } from './free/component'
import { UserZonePlansProductDetailBcastPremium } from './premium/component'
import { useTrans } from '../../../../../hooks/use-trans'

// ~~~~~~ Component

export const UserZonePlansProductDetailBcast = () => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  const subId = useParams<{ subId: string }>().subId

  const isNew = new URLSearchParams(window.location.search).get('new') === 'true'

  const freeText = useTrans('common.Free')

  // ~~~~~~ State

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  const subs = useSelector((state: StoreState) => state.subscriptions)

  const bcastProducts = useSelector((state: StoreState) => state.broadcastSub)

  // ~~~~~~ Computed

  const isLoading = !currentUser.isSessionChecked || currentUser.uiStatus === 'running'

  const sub = subId ? subs.find((sub) => sub.subscription_id === subId) : undefined

  const isBcastFree = sub?.price_id === bcastProducts.products.free.id

  // ~~~~~~ Handlers

  function onClickGoBack() {
    navigate(Routes.UserZonePlans)
  }

  // ~~~~~~ Effects

  // - If no sub return to user-zone plans

  useEffect(() => {
    if (isLoading || sub) return

    navigate(Routes.UserZonePlans)
    //
  }, [isLoading, navigate, sub])

  // ~~~~~~ Render

  if (!sub) return null

  return (
    <STUserZonePlansProductDetailBcast>
      {/* Banner back */}

      <UserZoneCommonBannerBack
        kind="bcast"
        text={isBcastFree ? freeText : 'Premium'}
        onClickGoBack={onClickGoBack}
      />

      {/* Content */}

      {isBcastFree ? (
        <UserZonePlansProductDetailBcastFree isNew={isNew} sub={sub} />
      ) : (
        <UserZonePlansProductDetailBcastPremium isNew={isNew} sub={sub} />
      )}

      {/* - */}
    </STUserZonePlansProductDetailBcast>
  )
}
