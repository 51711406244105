import styled from 'styled-components'
import { MediaQuery } from '../../../../style/constants/media-queries'

// ~~~~~~ Constants

export const ClassName = 'page-purchase-license-success-content'

// ~~~~~~ Component

export const STPagePurchaseLicenseSuccessContent = styled.div`
  max-width: 622px;
  margin-left: auto;
  margin-right: auto;

  margin-top: 32px;

  /* Steps */

  .${ClassName}--steps {
    margin-bottom: 56px;
  }

  /* Loader */

  .${ClassName}--loader {
    margin-left: auto;
    margin-right: auto;
  }

  /* 1280 */

  @media (max-width: ${MediaQuery.MaxWidth2k}) {
    padding-left: 120px;
    padding-right: 120px;
  }

  /* 960 */

  @media (max-width: ${MediaQuery.MD}) {
    padding-left: 40px;
    padding-right: 40px;

    /* Steps */

    .${ClassName}--steps {
      margin-bottom: 50px;
    }
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    padding-left: 16px;
    padding-right: 16px;

    /* Steps */

    .${ClassName}--steps {
      margin-bottom: 32px;
    }
  }
`
