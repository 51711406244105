import styled from 'styled-components'
import { theme } from '../../../../style/utils/theme'
import { Colors } from '../../../../style/theme/colors'
import { Fonts } from '../../../../style/theme/fonts'
import { MediaQuery } from '../../../../style/constants/media-queries'

// ~~~~~~ Constants

export const ClassName = 'page-purchase-common-tab-steps'

// ~~~~~~ Component

export const STPagePurchaseCommonTabSteps = styled.div`
  display: flex;
  justify-content: space-between;

  .${ClassName}--step {
    display: flex;
    align-items: center;

    svg {
      width: 22px;
      height: 22px;

      margin-right: 4px;

      path {
        fill: ${theme(Colors.WOLF_90_90)};
      }
    }

    .${ClassName}--step--num {
      ${Fonts.Weight[700]}
      ${Fonts.Scale.Body}

      width: 18px;
      height: 18px;

      border-radius: 2px;

      margin-right: 4px;

      background-color: ${theme(Colors.WOLF_90_90)};
      color: white;

      display: flex;
      align-items: center;
      justify-content: center;
    }

    .${ClassName}--step--name {
      ${Fonts.Weight[300]}
      ${Fonts.Scale.BodyBig}
    }

    &.active {
      .${ClassName}--step--num {
        background-color: ${theme(Colors.BRONX_60_60)};
      }

      .${ClassName}--step--name {
        ${Fonts.Weight[700]}
        color: ${theme(Colors.BRONX_60_60)};
      }
    }
  }

  /* 960 */

  @media (max-width: ${MediaQuery.MD}) {
    .${ClassName}--step {
      .${ClassName}--step--name {
        ${Fonts.Scale.Body}
      }
    }
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    .${ClassName}--step {
      flex-direction: column;

      .${ClassName}--step--num {
        margin-bottom: 4px;
      }

      .${ClassName}--step--name {
        ${Fonts.Scale.Label}
      }
    }
  }
`
