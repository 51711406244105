const Remy = {
  80: '#166911',
  70: '#1E8C17',
  60: '#25AF1D',
  50: '#2DD223',
  40: '#47DF3E',
  30: '#B6F254',
  20: '#D6F8A0',
  10: '#E7FBC6',
  5: '#F7FEEC',
} as const

// ~~~~~~

const REMY_5_5: ThemeColor = {
  light: Remy[5],
  dark: Remy[5],
} as const

const REMY_30_30: ThemeColor = {
  light: Remy[30],
  dark: Remy[30],
} as const

const REMY_40_40: ThemeColor = {
  light: Remy[40],
  dark: Remy[40],
} as const

// ~~~~~~

export const RemyColors = {
  REMY_5_5,
  REMY_30_30,
  REMY_40_40,
} as const
