import React from 'react'
import { ClassName, STPageTrialsOrFreeCommonTabSteps } from './style'
import { Trans } from '../../../../components/common/intl/trans'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../../models/app/model'
import { LazySVG } from '../../../../components/svgs/lazy-svg/component'

// ~~~~~~ Constants

const CheckedIcon = LazySVG('icons/check-full')

const StepCreateAccount = 1

const StepVerifyEmail = 2

const StepReady = 3

// ~~~~~~ Types

type Props = {
  kind: 'trial' | 'account'
}

// ~~~~~~ Component

export const PageTrialsOrFreeCommonTabSteps: React.FC<Props> = ({ kind }) => {
  // ~~~~~~ State

  const buyLicenseStep = useSelector((state: StoreState) => state.buyLicense.formStep)

  // ~~~~~~ Render

  return (
    <STPageTrialsOrFreeCommonTabSteps>
      {/* 1 Create Account */}

      <div className={`${ClassName}--step ${buyLicenseStep === StepCreateAccount ? 'active' : ''}`}>
        {buyLicenseStep > StepCreateAccount ? (
          <CheckedIcon size={18} />
        ) : (
          <div className={`${ClassName}--step--num`}>{StepCreateAccount}</div>
        )}

        <div className={`${ClassName}--step--name`}>
          <Trans id="pages.purchase-flow.create-account-step.CreateAccount" />
        </div>
      </div>

      {/* 2 Verify your email */}

      <div className={`${ClassName}--step ${buyLicenseStep === StepVerifyEmail ? 'active' : ''}`}>
        {buyLicenseStep > StepVerifyEmail ? (
          <CheckedIcon size={18} />
        ) : (
          <div className={`${ClassName}--step--num`}>{StepVerifyEmail}</div>
        )}

        <div className={`${ClassName}--step--name`}>
          <Trans id="pages.purchase-flow.verify-email-step.VerifyEmail" />
        </div>
      </div>

      {/* 3 Trial/Account ready */}

      <div className={`${ClassName}--step ${buyLicenseStep === StepReady ? 'active' : ''}`}>
        <div className={`${ClassName}--step--num`}>{StepReady}</div>

        <div className={`${ClassName}--step--name`}>
          {kind === 'trial' ? (
            <Trans id="pages.purchase-flow.account-ready-step.TrialReady" />
          ) : (
            <Trans id="pages.purchase-flow.account-ready-step.AccountReady" />
          )}
        </div>
      </div>

      {/* - */}
    </STPageTrialsOrFreeCommonTabSteps>
  )
}
