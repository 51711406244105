import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useNavigate } from 'react-router'
import { HooksData } from '@dn/hooks'
import { SetupIntent } from '@stripe/stripe-js'

import { ApiStoreCardAC } from '../../../../../store/actions/api-store/card/actions'
import { Routes } from '../../../../../constants/routes'
import { UserZoneCommonPaymentMethodForm } from '../form/component'
import { ClassName, STUserZoneCommonPaymentMethodNew } from './style'
import { Trans } from '../../../../../components/common/intl/trans'
import { Button } from '../../../../../components/common/button/component'
import { StoreState } from '../../../../../models/app/model'
import { PaymentButton } from '../../../../../components/common/gradient-button/component'
import { BackLink } from '../../../../../components/common/links/back-link/component'

// ~~~~~~ Constants

type Props = {
  backIntl: IntlMsgId
  backIntlValues?: { [key: string]: string | number }
  backPath: string

  onSuccess: () => void
}

// ~~~~~~ Component

export const UserZoneCommonPaymentMethodNew: React.FC<Props> = ({
  backIntl,
  backIntlValues,
  backPath,

  onSuccess,
}) => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  const dispatch = useDispatch()

  // ~~~~~~ State

  // - Local

  const [stripeIsReady, setStripeIsReady] = useState(false)

  const [submitIsRunning, setSubmitIsRunning] = useState(false)

  const [doSubmit, setDoSubmit] = useState(0)

  const [apiCalled, setApiCalled] = useState(false)

  // - Store

  const metaCards = useSelector((state: StoreState) => state.cards)

  // ~~~~~~ Computed

  const isRunning = submitIsRunning || metaCards.uiStatusAdd === 'running'

  const prevCardQtty = HooksData.PrevValue.useHook(metaCards.list.length)

  // ~~~~~~ Handlers

  function onClickGoBack() {
    navigate(backPath)
  }

  function onStripeIsReady() {
    setStripeIsReady(true)
  }

  function onClickAdd() {
    setDoSubmit(performance.now())
  }

  function onErrorLoading() {
    navigate(Routes.UserZoneBilling)
  }

  function onSubmitStart() {
    setSubmitIsRunning(true)
  }

  function onSubmitEnd() {
    setSubmitIsRunning(false)
  }

  function onSubmit(intent: SetupIntent, isDefault: boolean) {
    dispatch(ApiStoreCardAC.add(intent.id, isDefault))

    setApiCalled(true)
  }

  // ~~~~~~ Effects

  useEffect(() => {
    if (!apiCalled) return

    if (prevCardQtty === undefined || prevCardQtty === metaCards.list.length) {
      return
    }

    onSuccess()

    //
  }, [apiCalled, metaCards.list.length, onSuccess, prevCardQtty])

  // ~~~~~~ Render

  return (
    <STUserZoneCommonPaymentMethodNew>
      <div className={`${ClassName}--content`}>
        {/* <- Go Back */}

        <BackLink
          className={`${ClassName}--content--go-back`}
          intlId={backIntl}
          intlValues={backIntlValues}
          onClick={onClickGoBack}
        />

        {/* Title */}

        <div className={`${ClassName}--content--title`}>
          <Trans id="pages.common.AddPaymentMethod" />
        </div>

        {/* Subtitle */}

        <div className={`${ClassName}--content--subtitle`}>
          <Trans id="v2.pages.add-payment-method.FillInDetailsPayment" />
        </div>

        {/* Stripe form */}

        <div className={`${ClassName}--content--form`}>
          <UserZoneCommonPaymentMethodForm
            onErrorLoading={onErrorLoading}
            isDefaultCard={metaCards.list.length === 0}
            doSubmit={doSubmit}
            onSubmitStart={onSubmitStart}
            onSubmitEnd={onSubmitEnd}
            onSubmit={onSubmit}
            onStripeIsReady={onStripeIsReady}
          />
        </div>

        {stripeIsReady ? (
          <>
            {/* Separator */}

            <hr />

            {/* Actions */}

            <div className={`${ClassName}--content--actions`}>
              {/* Add */}

              <PaymentButton
                className={`${ClassName}--content--actions--action first`}
                intlId="pages.common.AddPaymentMethod"
                running={isRunning}
                disabled={isRunning}
                active={!isRunning}
                onClick={onClickAdd}
              />

              {/* Cancel */}

              <Button
                className={`${ClassName}--content--actions--action`}
                colorType="default"
                intlId="common.Cancel"
                running={false}
                disabled={false}
                active={!isRunning}
                onClick={onClickGoBack}
              />
            </div>
          </>
        ) : undefined}
      </div>
    </STUserZoneCommonPaymentMethodNew>
  )
}
