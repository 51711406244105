import React from 'react'
import { useLayoutTopContentContext } from '../../hooks/use-layout-top-content-context'

// ~~~~~~ Component

export const LayoutTopContentFooter: React.FC<any> = ({ children }) => {
  const { kind } = useLayoutTopContentContext()

  return <>{kind === 'footer' ? children : null}</>
}
