import { css } from 'styled-components'
import { MediaQuery } from '../../../../../style/constants/media-queries'
import { Colors } from '../../../../../style/theme/colors'
import { HexAlpha } from '../../../../../style/theme/colors/alpha'
import { Fonts } from '../../../../../style/theme/fonts'
import { Layout } from '../../../../../style/theme/layout'
import { theme } from '../../../../../style/utils/theme'

// ~~~~~~

export const genSTUserZonePlansRootAddProductCommonStyle = (ClassName: string) => css`
  max-width: 702px;

  box-shadow: 0px 4px 16px ${theme(Colors.WOLF_90_90)}${HexAlpha[10]};

  border: solid 1px ${theme(Colors.WOLF_10_10)};

  ${Layout.Border.Radius.B}

  padding: 16px;
  display: flex;

  .${ClassName}--left {
    margin-right: 16px;

    .${ClassName}--left--img-square {
      width: 192px;
      height: 180px;
    }

    .${ClassName}--left--img-rectangle {
      display: none;
      max-width: 343px;
      width: 100%;
    }
  }

  .${ClassName}--right {
    display: flex;
    flex-direction: column;

    .${ClassName}--right--logo {
      width: 86px;
    }

    .${ClassName}--right--title {
      ${Fonts.Weight[700]}
      ${Fonts.Scale.Subtitle}

      margin-bottom: 8px;
    }

    .${ClassName}--right--subtitle {
      ${Fonts.Weight[400]}
      ${Fonts.Scale.Body}
    }

    .${ClassName}--right--action {
      margin-top: auto;

      display: flex;
      justify-content: flex-end;
      align-items: flex-end;
    }
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    flex-direction: column;

    .${ClassName}--right {
      .${ClassName}--right--subtitle {
        margin-bottom: 24px;
      }
    }

    .${ClassName}--left {
      margin-right: 0px;
      margin-bottom: 16px;

      .${ClassName}--left--img-square {
        display: none;
      }

      .${ClassName}--left--img-rectangle {
        display: unset;
      }
    }
  }
`
