import styled from 'styled-components'
import { Fonts } from '../../../../style/theme/fonts'
import { Colors } from '../../../../style/theme/colors'
import { theme } from '../../../../style/utils/theme'
import { MediaQuery } from '../../../../style/constants/media-queries'

// ~~~~~~ Constants

export const ClassName = 'page-common-op-step2'

// ~~~~~~ Component

export const STPageTrialsOrFreeCommonStep2VerifyEmail = styled.div`
  max-width: 622px;
  margin-left: auto;
  margin-right: auto;

  margin-top: 32px;

  /* Steps */

  .${ClassName}--steps {
    margin-bottom: 56px;
  }

  /* Logo */

  .${ClassName}--logo {
    width: 98px;

    margin-bottom: 8px;
  }

  /* Title */

  .${ClassName}--title {
    ${Fonts.Weight[800]}
    ${Fonts.Scale.OldA}

    margin-bottom: 16px;

    .${ClassName}--title--icon {
      position: relative;

      svg {
        position: absolute;
        left: 8px;

        width: 56px;
        height: 56px;
      }
    }
  }

  /* Subtitle */

  .${ClassName}--subtitle {
    ${Fonts.Weight[300]}
    ${Fonts.Scale.OldD}

    margin-bottom: 24px;

    .${ClassName}--subtitle--email {
      ${Fonts.Weight[600]}
    }

    .${ClassName}--subtitle--ch-email {
      cursor: pointer;

      ${Fonts.Weight[600]}
      border-bottom: solid 2px ${theme(Colors.WOLF_90_90)};
    }
  }

  /* Field */

  .${ClassName}--field {
    max-width: 282px;

    margin-bottom: 24px;

    &.last {
      margin-bottom: 32px;
    }
  }

  /* Action */

  .${ClassName}--action {
    margin-bottom: 32px;
  }

  /* Resend code */

  .${ClassName}--footer {
    ${Fonts.Scale.BodyBig}

    max-width: 400px;

    padding-bottom: 108px;

    .${ClassName}--footer--link {
      cursor: pointer;

      margin-left: 8px;

      ${Fonts.Weight[700]}
      border-bottom: solid 1px ${theme(Colors.WOLF_90_90)};
    }
  }

  /* 1280 */

  @media (max-width: ${MediaQuery.MaxWidth2k}) {
    /* Title */

    .${ClassName}--title {
      ${Fonts.Scale.OldB}

      .${ClassName}--title--icon {
        svg {
          width: 48px;
          height: 48px;
        }
      }
    }

    /* Resend code */

    .${ClassName}--footer {
      ${Fonts.Scale.Body}
    }
  }

  /* 960 */

  @media (max-width: ${MediaQuery.MD}) {
    /* Steps */

    .${ClassName}--steps {
      margin-bottom: 50px;
    }

    /* Logo */

    .${ClassName}--logo {
      width: 86px;
    }

    /* Title */

    .${ClassName}--title {
      ${Fonts.Scale.Headline1}

      .${ClassName}--title--icon {
        svg {
          width: 40px;
          height: 40px;
        }
      }
    }

    /* Subtitle */

    .${ClassName}--subtitle {
      ${Fonts.Scale.Subtitle}
    }

    /* Action */

    .${ClassName}--action {
      margin-bottom: 24px;
    }
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    /* Steps */

    .${ClassName}--steps {
      margin-bottom: 32px;
    }

    /* Title */

    .${ClassName}--title {
      ${Fonts.Scale.Headline2}

      .${ClassName}--title--icon {
        svg {
          width: 32px;
          height: 32px;
        }
      }
    }

    /* Subtitle */

    .${ClassName}--subtitle {
      ${Fonts.Scale.BodyBig}
    }

    /* Field */

    .${ClassName}--field {
      &.code {
        max-width: unset;
      }

      &.last {
        margin-bottom: 24px;
      }
    }

    /* Action */

    .${ClassName}--action {
      margin-bottom: 16px;
    }

    /* Privacy and Terms */

    .${ClassName}--footer {
      text-align: center;
    }
  }
`
