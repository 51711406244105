import { ofType, StateObservable } from 'redux-observable'
import { Observable } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'
import { StoreState } from '../../../../models/app/model'
import { apiStoreListProducts$ } from '../../../../services/api-store/products/list'
import { ApiStoreProductsAC, ApiStoreProductsAT } from '../../../actions/api-store/products/actions'
import { ApiUtils } from '../../utils'
import { ApiStoreProductsEpicMC } from './mutators'

// ~~~~~ Types

type Action = ReturnType<typeof ApiStoreProductsAC.listProducts>

// ~~~~~~ Epic

export const apiStoreListProductsEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiStoreProductsAT.LIST_PRODUCTS),
    mergeMap(({ payload }) =>
      apiStoreListProducts$(payload.productId).pipe(
        map((res) => ({
          productId: payload.productId,
          res,
        })),
      ),
    ),
    map(({ productId, res }) => {
      if (!ApiUtils.isNotError(res)) return ApiStoreProductsEpicMC.error(productId, res.response)

      switch (productId) {
        case 'broadcast-subscription':
          return ApiStoreProductsEpicMC.okBroadcastProducts(
            res.response as DN.Models.Product.Api.List.ResParsedBroadcastProducts,
          )

        case 'montage':
          return ApiStoreProductsEpicMC.okMontageProducts(
            res.response as DN.Models.Product.Api.List.ResParsedMontageProducts,
          )

        case 'launcher':
          return ApiStoreProductsEpicMC.okList(
            productId,
            res.response as DN.Models.Product.Api.List.ResParsedList,
          )
      }
    }),
  )
