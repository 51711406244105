import styled from 'styled-components'

import { MediaQuery } from '../../../style/constants/media-queries'
import { Colors } from '../../../style/theme/colors'
import { Fonts } from '../../../style/theme/fonts'
import { theme } from '../../../style/utils/theme'
import { HexAlpha } from '../../../style/theme/colors/alpha'
import { Layout } from '../../../style/theme/layout'

// ~~~~~~ Constants

export const ClassName = 'product-card'

// ~~~~~~ Component

export const STProductCard = styled.div`
  position: relative;

  padding: 24px;
  max-width: 384px;
  height: 100%;

  ${Layout.Border.Radius.C}

  border: 1px solid ${theme(Colors.WOLF_10_10)};
  box-sizing: border-box;
  box-shadow: 0px 4px 16px ${theme(Colors.WOLF_90_90)}${HexAlpha[10]};

  display: flex;
  flex-direction: column;

  /* Left/Top */

  .${ClassName}--left {
    .${ClassName}--left--image {
      img {
        width: 100%;
      }
    }

    margin-bottom: 18px;
  }

  /* Right/Bottom */

  .${ClassName}--right {
    height: 100%;

    display: flex;
    flex-direction: column;
    justify-content: space-between;

    /* Logo */

    .${ClassName}--right--logo {
      margin-bottom: ${Layout.Spacing[2]}px;
    }

    /* Text */

    .${ClassName}--right--text {
      .${ClassName}--right--text--title {
        ${Fonts.Weight[800]}
        ${Fonts.Scale.Title}
      }

      .${ClassName}--right--text--subtitle {
        ${Fonts.Weight[400]}
        ${Fonts.Scale.Body}
      }
    }

    /* Action */

    .${ClassName}--right--action {
      margin-top: 8px;

      display: flex;
      flex-direction: row-reverse;
    }
  }

  /* 960 - Vertical stacked - Horizontal cards */

  @media (max-width: ${MediaQuery.MD}) {
    max-width: 100%;

    flex-direction: row;

    .${ClassName}--left {
      flex: 1;
      margin-right: 24px;
      margin-bottom: 0;

      .${ClassName}--left--image {
        img {
          min-height: 180px;
          min-width: 192px;
        }
      }
    }

    .${ClassName}--right {
      min-height: 100%;

      padding-bottom: 24px;

      .${ClassName}--right--action {
        margin-top: unset;

        position: absolute;

        bottom: 16px;
        right: 16px;
      }
    }
  }

  /* Mobile - (Mobile) Vertial stacked - Vertial cards */

  @media (max-width: ${MediaQuery.SM}) {
    max-width: 100%;

    flex-direction: column;

    margin-left: 0;
    margin-right: 0;

    .${ClassName}--left {
      margin-right: 0;
      margin-bottom: 24px;

      .${ClassName}--left--image {
        img {
          min-height: unset;
          min-width: unset;
        }
      }
    }

    .${ClassName}--right {
      min-height: 100%;
      padding-bottom: 0;

      .${ClassName}--right--action {
        padding-top: 24px;

        position: unset;
        bottom: unset;
        right: unset;

        margin-top: 8px;

        display: flex;
        flex-direction: row-reverse;
      }
    }
  }
`
