import { LazySVG } from '../../../svgs/lazy-svg/component'
import { DangerTrans } from '../../intl/danger-trans'
import { ClassName, STInfosBox, STProps } from './style'

// ~~~~~~ Constants

const InfoIcon = LazySVG('icons/info')

const ErrorIcon = LazySVG('icons/exclamation-triangle')

const GiftIcon = LazySVG('icons/gift')

// ~~~~~~ Types

type Props = STProps & {
  className?: string

  kind?: 'blue' | 'red' | 'yellow'
  icon?: 'info' | 'error' | 'gift'

  titleIntl: IntlMsgId
  titleIntlValues?: {
    [key: string]: any
  }

  contentText?: Infos[]

  children?: React.ReactNode
}

// ~~~~~~ Component

export const InfosBox: React.FC<Props> = ({
  className,
  kind = 'blue',
  icon,
  titleIntl,
  titleIntlValues,
  contentText,
  children,

  // Style
  $titleFontWeight: titleFontWeight,
}) => {
  // ~~~~~~ Computed

  const Icon = icon
    ? icon === 'error'
      ? ErrorIcon
      : icon === 'gift'
        ? GiftIcon
        : InfoIcon
    : kind === 'red' || kind === 'yellow'
      ? ErrorIcon
      : icon === 'gift'
        ? GiftIcon
        : InfoIcon

  // ~~~~~~ Render

  return (
    <STInfosBox $titleFontWeight={titleFontWeight} className={`${className || ''} ${kind}`}>
      <div className={`${ClassName}--title`}>
        <div className={`${ClassName}--title--icon`}>
          <Icon size={14} />
        </div>

        <div className={`${ClassName}--title--text`}>
          <DangerTrans template={titleIntl} id={titleIntl} values={titleIntlValues} />
        </div>
      </div>

      <div className={`${ClassName}--content`}>
        {contentText?.map((line, idx) => (
          <div key={idx} className={`${ClassName}--line`}>
            <DangerTrans template={line.id} id={line.id} values={line.values} />
          </div>
        ))}

        <div>{children}</div>
      </div>

      {/* - */}
    </STInfosBox>
  )
}
