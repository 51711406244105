import styled from 'styled-components'
import { Colors } from '../../../../../../style/theme/colors'
import { Fonts } from '../../../../../../style/theme/fonts'
import { theme } from '../../../../../../style/utils/theme'
import { Layout } from '../../../../../../style/theme/layout'
import { MediaQuery } from '../../../../../../style/constants/media-queries'

// ~~~~~~ Constants

export const ClassName = 'user-zone-plans-product-detail-bcast-free'

// ~~~~~~ Component

export const STUserZonePlansProductDetailBcastFree = styled.div`
  .${ClassName}--title {
    ${Fonts.Weight[700]}
    ${Fonts.Scale.OldD}

    margin-top: 16px;
    margin-bottom: 24px;
  }

  .${ClassName}--info {
    margin-top: 16px;
    margin-bottom: 24px;
  }

  .${ClassName}--details {
    margin-bottom: 24px;

    .${ClassName}--details--row {
      border-bottom: solid 1px ${theme(Colors.WOLF_05_05)};

      padding-top: 8px;
      padding-bottom: 8px;

      display: grid;
      grid-template-columns: repeat(2, 1fr);
      grid-column-gap: 8px;

      .${ClassName}--details--row--title {
        ${Fonts.Weight[300]}
        ${Fonts.Scale.BodyBig}
      }

      .${ClassName}--details--row--highlight {
        ${Fonts.Weight[700]}
        ${Fonts.Scale.BodyBig}

        margin-bottom: 8px;
      }
    }
  }

  .${ClassName}--cancel {
    padding-bottom: 72px;

    .${ClassName}--cancel--line {
      margin-bottom: 8px;

      ${Fonts.Weight[400]}
      ${Fonts.Scale.Body}
    }
  }

  /* 1280 */

  @media (max-width: ${MediaQuery.MaxWidth2k}) {
    ${Layout.PaddingWidth.Desktop}

    .${ClassName}--title {
      ${Fonts.Scale.Subtitle}
    }
  }

  /* 960 */

  @media (max-width: ${MediaQuery.MD}) {
    ${Layout.PaddingWidth.Tablet}
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    ${Layout.PaddingWidth.Mobile}
  }
`
