import { useLocation, useNavigate } from 'react-router'
import { Trans } from '../../../../components/common/intl/trans'
import { ClassName, STUserZoneCommonNavigationTabs } from './style'
import { Routes } from '../../../../constants/routes'
import { NeedHelp } from '../need-help/component'

// ~~~~~~ Constants

const ShowInPaths = [Routes.UserZone, Routes.UserZonePlans, Routes.UserZoneBilling]

// ~~~~~~ Component

export const UserZoneCommonNavigationTabs = () => {
  // ~~~~~~ Hooks

  const path = useLocation().pathname

  const isVisible = ShowInPaths.includes(path as any)

  const navigate = useNavigate()

  // ~~~~~~ Handlers

  function onClickPlans() {
    navigate(Routes.UserZonePlans)
  }

  function onClickBilling() {
    navigate(Routes.UserZoneBilling)
  }

  // ~~~~~~ Render

  if (!isVisible) return null

  return (
    <STUserZoneCommonNavigationTabs>
      <div className={`${ClassName}--content`}>
        {/* Plans */}

        <div
          className={`${ClassName}--content--tab ${
            path === Routes.UserZone || path === Routes.UserZonePlans ? 'active' : ''
          }`}
          onClick={onClickPlans}
        >
          <Trans id="pages.common.Plans" />
        </div>

        {/* Billing */}

        <div
          className={`${ClassName}--content--tab ${
            path === Routes.UserZoneBilling ? 'active' : ''
          }`}
          onClick={onClickBilling}
        >
          <Trans id="pages.user-zone.tabs.Billing" />
        </div>

        {/* Help */}

        <NeedHelp className={`${ClassName}--content--tab help`} />

        {/* - */}
      </div>
    </STUserZoneCommonNavigationTabs>
  )
}
