import styled from 'styled-components'
import { Colors } from '../../../style/theme/colors'
import { HexAlpha } from '../../../style/theme/colors/alpha'
import { Layout } from '../../../style/theme/layout'
import { theme } from '../../../style/utils/theme'
import { Fonts } from '../../../style/theme/fonts'
import { Transition } from '../../../style/animations/css-animations'

// ~~~~~~ Constants

export const ClassName = 'device-number-selector'

// ~~~~~~ Component

export const STDeviceNumberSelector = styled.div`
  ${Layout.Border.Radius.B}

  box-sizing: border-box;
  box-shadow: 0px 4px 16px ${theme(Colors.WOLF_90_90)}${HexAlpha[10]};
  border: solid 1px ${theme(Colors.WOLF_10_10)};

  hr.${ClassName}--separator {
    border: none;
    border-top: solid 1px ${theme(Colors.WOLF_10_10)};

    margin-left: 20px;
    margin-right: 20px;
  }

  .${ClassName}--row {
    padding: 20px 16px;

    display: flex;
    align-items: center;
    justify-content: space-between;

    .${ClassName}--row--left {
      ${Fonts.Weight[700]}
      ${Fonts.Scale.BodyBig}

      .${ClassName}--row--left--smooth {
        ${Fonts.Weight[300]}
      }
    }

    .${ClassName}--row--right {
      ${Fonts.Weight[700]}
      ${Fonts.Scale.Subtitle}

      display: flex;

      .${ClassName}--row--right--price {
        ${Fonts.Scale.Title}
      }

      &.select {
        position: relative;

        .${ClassName}--row--right--icon-select {
          position: absolute;
          right: 8px;
          top: 16px;

          pointer-events: none;

          svg {
            width: 24px;
            height: 24px;

            path {
              fill: ${theme(Colors.WOLF_90_90)};
            }
          }
        }

        .${ClassName}--row--right--select {
          ${Fonts.Weight[400]}
          ${Fonts.Scale.BodyBig}

          min-width: 190px;

          padding: 16px;
          padding-right: 34px;

          border: solid 2px ${theme(Colors.WOLF_40_40)};
          border-radius: 6px;

          appearance: none;
        }
      }

      .${ClassName}--row--right--input {
        max-width: 68px;
        margin-right: 8px;

        input {
          min-width: 30px;
        }
      }

      .${ClassName}--row--right--buttons {
        display: flex;
        align-items: center;

        .${ClassName}--row--right--buttons--button {
          cursor: pointer;

          ${Fonts.Weight[500]}
          ${Fonts.Scale.OldD}

          display: flex;
          align-items: center;
          justify-content: center;

          border-radius: 6px;

          width: 56px;
          height: 56px;

          ${Transition.BackgroundColorFaster}

          background-color: ${theme(Colors.WOLF_05_05)};

          @media (hover: hover) {
            &:hover {
              background-color: ${theme(Colors.WOLF_10_10)};
            }
          }

          &.left {
            border-top-right-radius: unset;
            border-bottom-right-radius: unset;
          }

          &.right {
            border-top-left-radius: unset;
            border-bottom-left-radius: unset;
          }
        }

        .${ClassName}--row--right--buttons--sep {
          width: 1px;
          height: 100%;

          background-color: ${theme(Colors.WOLF_05_05)};

          display: flex;
          align-items: center;

          .${ClassName}--row--right--buttons--sep--bar {
            width: 1px;
            height: 65%;

            background-color: ${theme(Colors.WOLF_10_10)};
          }
        }
      }
    }
  }
`
