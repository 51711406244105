import { Observable, of } from 'rxjs'
import { ajax } from 'rxjs/ajax'
import { catchError, map } from 'rxjs/operators'
import { ApiRoutes } from '../constants/routes'
import { ApiHeadersUtils } from '../utils/headers'

const { Cards } = ApiRoutes

// ####################################################################################################
// ~~~~~~ Add Card
// ####################################################################################################

type Req = DN.Models.Card.Api.Change.Req
type Res = DN.Models.Card.Api.Change.Res
type ResParsed = DNApiResParsed<DN.Models.Card.Api.Change.ResParsed>
type ResError = DNApiError<DN.Models.Card.Api.Change.ResError>

export const apiStoreCardChange$ = (
  token: string,
  cardId: string,
  paymentId: string,
  headers?: AppHeaders,
) => {
  const body: Req = {
    setup_intent: paymentId,
  }

  return ajax<Res>({
    ...ApiHeadersUtils.genHeadersWithAuth(token, headers),
    method: Cards.ChangeCard.Method,
    url: Cards.ChangeCard.getUrl(cardId),
    body,
  }).pipe(
    map((res): ResParsed => {
      const brand = res.response.card.brand

      return {
        status: res.status,
        response: {
          card: {
            ...res.response.card,
            brand: brand,
          },
          sourceId: cardId,
        },
      }
    }),
    catchError((res: DNApiRes<void>): Observable<ResError> => {
      return of({
        status: res.status,
        response: {
          cardId,
          errors: [{ id: 'api.card.change-card.Error' }],
        },
      })
    }),
  )
}
