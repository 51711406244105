import { Elements } from '@stripe/react-stripe-js'
import { SetupIntent, StripeElementsOptions, loadStripe } from '@stripe/stripe-js'
import { useState, useEffect } from 'react'
import { useSelector } from 'react-redux'
import { Loader } from '../../../../../components/common/loader/component'
import { StoreState } from '../../../../../models/app/model'
import { apiStoreCheckoutCardIntent$ } from '../../../../../services/api-store/checkout/card-intent'
import { ApiUtils } from '../../../../../store/epics/utils'
import { Colors } from '../../../../../style/theme/colors'
import { Fonts } from '../../../../../style/theme/fonts'
import { Config } from '../../../../../config'
import { useTheme } from 'styled-components'
import { InfosList } from '../../../../../components/common/infos/list/component'
import { UserZoneCommonPaymentMethodFormInnerForm } from './inner-form/component'

// ~~~~~~ Constants

const StripePromise = loadStripe(Config.ThirdParty.Stripe.PublishableKey)

// ~~~~~~ Types

type Props = {
  stripeReturnUrl?: string
  isDefaultCard: boolean
  doSubmit: number

  onErrorLoading: () => void
  onSubmitStart: () => void
  onSubmitEnd: () => void
  onSubmit: (intent: SetupIntent, setAsDefault: boolean) => void

  onStripeIsReady: () => void
}

// ~~~~~~ Component

export const UserZoneCommonPaymentMethodForm: React.FC<Props> = ({
  stripeReturnUrl,
  isDefaultCard,
  doSubmit,

  onErrorLoading,
  onSubmitStart,
  onSubmitEnd,
  onSubmit,

  onStripeIsReady,
}) => {
  // ~~~~~~ Hooks

  const theme = (useTheme() as { mode: Theme }).mode

  // ~~~~~~ State

  // - Local

  const [isLoading, setIsLoading] = useState(false)

  const [clientSecret, setClientSecret] = useState('')

  const [errors, setErrors] = useState<Infos[]>([])

  // - Store

  const { token } = useSelector((state: StoreState) => state.currentUser)

  // ~~~~~~ Computed

  const stripeElementsOptions: StripeElementsOptions = {
    clientSecret,

    appearance: {
      theme: 'flat',

      variables: {
        fontFamily: 'Mulish, Arial, sans-serif',

        colorPrimary: Colors.WOLF_90_90[theme],
        colorText: Colors.WOLF_90_90[theme],

        borderRadius: '6px',
      },

      rules: {
        '.Label': {
          fontSize: Fonts.ScaleOnlySize.Body2,
          lineHeight: '24px',
          fontWeight: '700',
        },

        '.Input': {
          border: `2px solid ${Colors.WOLF_90_90[theme]}`,
          marginBottom: '16px',
        },

        '.Input::placeholder': {
          fontSize: Fonts.ScaleOnlySize.Body2,
          lineHeight: '24px',
          fontWeight: '400',

          color: Colors.WOLF_40_40[theme],
          padding: '12px',
        },
      },
    },
  }

  // ~~~~~~ Effects

  // - Get client secret of payment element

  useEffect(() => {
    if (!token || isLoading || clientSecret) return

    setIsLoading(true)

    apiStoreCheckoutCardIntent$(token).subscribe({
      next: (res) => {
        if (!ApiUtils.isNotError(res)) {
          setErrors(res.response)

          setIsLoading(false)

          return
        }

        setClientSecret(res.response.intent.client_secret)

        setIsLoading(false)
      },

      error: () => {
        setIsLoading(false)

        onErrorLoading()
      },
    })
  }, [clientSecret, isLoading, onErrorLoading, token])

  // ~~~~~~ Render

  if (!clientSecret) {
    return <Loader size={100} />
  }

  return (
    <Elements stripe={StripePromise} options={stripeElementsOptions}>
      {/* Errors */}

      <InfosList infos={errors} />

      {/* Stripe Form */}

      <UserZoneCommonPaymentMethodFormInnerForm
        stripeReturnUrl={stripeReturnUrl}
        isDefaultCard={isDefaultCard}
        doSubmit={doSubmit}
        onStripeIsReady={onStripeIsReady}
        onSubmitStart={onSubmitStart}
        onSubmitEnd={onSubmitEnd}
        onSubmit={onSubmit}
      />
    </Elements>
  )
}
