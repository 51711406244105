const prefix = 'mt-root'

// ~~~~~~ Mutator types

export const RootMT = {
  CHANGE_THEME: `${prefix}-change-theme`,

  SHOW_SIDE_MENU: `${prefix}-show-side-menu`,
  HIDE_SIDE_MENU: `${prefix}-hide-side-menu`,

  USER_SESSION_COMPLETED: `${prefix}-user-and-session-completed`,
} as const

// ~~~~~~ Mutator creators

export const RootMC = {
  changeTheme: (theme: Theme) => ({
    type: RootMT.CHANGE_THEME,
    payload: theme,
  }),

  showSideMenu: () => ({
    type: RootMT.SHOW_SIDE_MENU,
  }),

  hideSideMenu: () => ({
    type: RootMT.HIDE_SIDE_MENU,
  }),

  setUserAndSessionComplete: () => ({
    type: RootMT.USER_SESSION_COMPLETED,
  }),
} as const
