import styled from 'styled-components'

// ~~~~~~ Constants

export const ClassName = 'page-purchase-launcher'

// ~~~~~~ Component

export const STPagePurchaseLauncher = styled.div`
  height: 100vh;
`
