import { Config } from '../../../config'

export type AppHeaders = {
  [key: string]: string
}

const genHeaders = (headers?: AppHeaders) => ({
  headers: {
    ...Config.Api.Store.Headers,
    ...headers,
  },
})

const genAuthHeader = (token: string) => ({
  Authorization: `Bearer ${token}`,
})

const genHeadersWithAuth = (token: string, headers?: AppHeaders) => ({
  ...genHeaders({
    ...headers,
    Authorization: `Bearer ${token}`,
  }),
})

// ~~~~~~

export const ApiHeadersUtils = {
  genHeaders,
  genAuthHeader,
  genHeadersWithAuth,
}
