import Skeleton from 'react-loading-skeleton'
import { useEffect } from 'react'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'
import { UtilsDates, UtilsEncDec } from '@dn/utils'

import { LazySVG } from '../../../../../../components/svgs/lazy-svg/component'
import { ClassName, STUserZonePlansProductDetailLauncher } from './style'
import { Trans } from '../../../../../../components/common/intl/trans'
import { InfosBox } from '../../../../../../components/common/infos/box/component'
import { StoreState } from '../../../../../../models/app/model'
import { Dollars } from '../../../../../../components/common/dollars/component'
import { ToastUtil } from '../../../../../../components/common/toast/util'
import { ApiLauncherReleaseInfoAC } from '../../../../../../store/actions/api-launcher/release-info/action'
import { Button } from '../../../../../../components/common/button/component'
import { Config } from '../../../../../../config'
import { CreditCard } from '../../../../../../components/common/credit-card/component'
import { DangerTrans } from '../../../../../../components/common/intl/danger-trans'
import { SubscriptionMC } from '../../../../../../store/actions-mutators/subscriptions/mutators'
import { ModalsIds } from '../../../../../../constants/modals'
import { ModalsMC } from '../../../../../../store/actions-mutators/modals/mutators'
import {
  RouteDataAddPaymentMethod,
  RouteDataChangePaymentMethod,
  Routes,
} from '../../../../../../constants/routes'
import { useTrans } from '../../../../../../hooks/use-trans'

// ~~~~~~ Constants

const EditIcon = LazySVG('icons/pencil')

const AddIcon = LazySVG('icons/plus-circle')

const DownloadIcon = LazySVG('icons/arrow-down-circle')

const ResumeIcon = LazySVG('icons/arrow-circle')

const ErrorIcon = LazySVG('icons/exclamation-triangle')

const StripeGracePeriodDays = 5

// ~~~~~~ Types

type Props = {
  isTrial: boolean
  isNew: boolean
  sub: DN.Models.Subscription.ModelFront
}

// ~~~~~~ Component

export const UserZonePlansProductDetailLauncher: React.FC<Props> = ({ isTrial, isNew, sub }) => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  const dispatch = useDispatch()

  const backText = useTrans('v2.pages.plan-details.PlanDetails')

  // ~~~~~~ State

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  const lcherReleaseInfo = useSelector((state: StoreState) => state.launcherReleaseInfo)

  const cards = useSelector((state: StoreState) => state.cards)

  // ~~~~~~ Computed

  const isLoading = lcherReleaseInfo.uiStatus !== 'completed'

  const nextBillingDate = UtilsDates.getMediumDate(sub.current_period_end)

  const lastBillingDate = sub.last_invoice
    ? UtilsDates.getMediumDate(sub.last_invoice.payment_date)
    : '-'

  const curPeriodEnd = new Date(sub.current_period_end)

  const card = cards.list.find((card) => card.payment_method_id === sub.payment_method_id)

  const isCanceled = !!sub.cancel_at

  const isUnpaid = sub.subscription_status === 'past_due' || sub.subscription_status === 'unpaid'

  curPeriodEnd.setDate(curPeriodEnd.getDate() + StripeGracePeriodDays)

  const maxUnpaidBillingDate = UtilsDates.getMediumDate(curPeriodEnd.toISOString())

  const showNextBillingDate = !isCanceled && (isTrial ? sub.converted_to_paid : true)

  const showLastBillingDate = !isTrial

  // ~~~~~~ Handlers

  function onClickLicense() {
    if (!sub || !sub.license_key || !navigator.clipboard) return

    navigator.clipboard
      .writeText(sub.license_key)
      .then(() => {
        ToastUtil.basic({
          kind: 'success',
          intlIds: [{ id: 'v2.pages.plan-details.license-copy.Success' }],
        })
      })
      .catch(() => {
        ToastUtil.basic({
          kind: 'error',
          intlIds: [{ id: 'v2.pages.plan-details.license-copy.Error' }],
        })
      })
  }

  function onClickDownload() {
    window.open(Config.Links.LauncherDownload, '_blank')
  }

  function onClickResumePlan() {
    dispatch(SubscriptionMC.setTmpSubscription(sub))

    dispatch(ModalsMC.open(ModalsIds.ConfirmReactivatePlan))
  }

  function onClickChangePaymentMethod() {
    const curPath = `${window.location.pathname}${window.location.search}`

    const nextDataObj: RouteDataChangePaymentMethod = {
      backPath: curPath,
      backText: backText,
      successPath: curPath,
      subId: sub.subscription_id,
    }

    const nextData = UtilsEncDec.base64UrlEncode(JSON.stringify(nextDataObj))

    const changeCardRoute = Routes.genUserZonePlansChangePaymentMethod(nextData)

    navigate(changeCardRoute)
  }

  function onClickAddPaymentMethod() {
    const hasCards = !!cards.list.length

    if (hasCards) {
      onClickChangePaymentMethod()
      return
    }

    const curPath = `${window.location.pathname}${window.location.search}`

    const nextDataObj: RouteDataAddPaymentMethod = {
      backPath: curPath,
      backText: backText,
      successPath: curPath,
    }

    const nextData = UtilsEncDec.base64UrlEncode(JSON.stringify(nextDataObj))

    const addCardRoute = Routes.genUserZonePlansAddPaymentMethod(nextData)

    navigate(addCardRoute)
  }

  function onClickCancelPlan() {
    if (!sub) return

    dispatch(SubscriptionMC.setTmpSubscription(sub))

    dispatch(ModalsMC.open(ModalsIds.ConfirmCancelPlan))
  }

  // ~~~~~~ Effects

  // - Get Launcher release info

  useEffect(() => {
    if (lcherReleaseInfo.uiStatus !== 'init') return

    dispatch(ApiLauncherReleaseInfoAC.start())

    //
  }, [dispatch, lcherReleaseInfo.uiStatus])

  // ~~~~~~ Render

  return (
    <STUserZonePlansProductDetailLauncher>
      {/* Plan Details */}

      <div className={`${ClassName}--title`}>
        <Trans id="v2.pages.plan-details.PlanDetails" />
      </div>

      {/* Info for new Trials subs */}

      {isNew && !isCanceled ? (
        <InfosBox
          className={`${ClassName}--info`}
          titleIntl="v2.pages.plan-details.new-subscription-description.titles.Trial"
          titleIntlValues={{ value: 'Launcher' }}
          contentText={[
            {
              id: 'v2.pages.plan-details.new-subscription-description.montage.EmailHelp',
              values: {
                value: currentUser.email,
                c: (chunks: string) => <span className={`${ClassName}--info--bold`}>{chunks}</span>,
              },
            },

            {
              id: 'v2.pages.plan-details.new-subscription-description.montage.WhenNeedLicense',
            },
          ]}
        />
      ) : undefined}

      {/* Info: Unpaid */}

      {isUnpaid ? (
        <InfosBox
          kind="yellow"
          className={`${ClassName}--info`}
          $titleFontWeight={300}
          titleIntl="v2.pages.plan-details.ProblemWithPaymentAlert"
          titleIntlValues={{
            value: maxUnpaidBillingDate,
          }}
        />
      ) : undefined}

      {/* Info: Canceled */}

      {isCanceled ? (
        <InfosBox
          kind="red"
          className={`${ClassName}--info`}
          titleIntl="v2.pages.plan-details.CanceledCommonTitle"
          contentText={[
            {
              id: 'v2.pages.plan-details.canceled-paid-plan.Content',
              values: {
                productName: 'Launcher',
                endDate: nextBillingDate,
              },
            },
          ]}
        >
          <div style={{ marginTop: 8 }}>
            <Button
              colorType="quaternary-link"
              intlId="v2.pages.plan-details.ResumePlan"
              IconLeft={ResumeIcon}
              leftIconMarginRight={4}
              noBorder
              padding="0"
              size="s"
              fontWeight="500"
              running={false}
              disabled={false}
              active={true}
              onClick={onClickResumePlan}
            />
          </div>
        </InfosBox>
      ) : undefined}

      {/* Info: no-Canceled & no-Card */}

      {!isTrial && !isCanceled && !card ? (
        <InfosBox
          kind="red"
          className={`${ClassName}--info`}
          titleIntl="v2.pages.plan-details.unpaid-payment-removed-warning.Title"
          contentText={[
            {
              id: 'v2.pages.plan-details.unpaid-payment-removed-warning.Content',
              values: {
                productName: 'Launcher',
                endDate: nextBillingDate,
              },
            },
          ]}
        >
          <div style={{ marginTop: 8 }}>
            <Button
              colorType="quaternary-link"
              intlId="v2.pages.plan-details.ResumePlan"
              IconLeft={ResumeIcon}
              leftIconMarginRight={4}
              noBorder
              padding="0"
              size="s"
              fontWeight="500"
              running={false}
              disabled={false}
              active={true}
              onClick={onClickAddPaymentMethod}
            />
          </div>
        </InfosBox>
      ) : undefined}

      {isTrial && !isCanceled && !card ? (
        <div>
          <InfosBox
            kind="yellow"
            icon="info"
            className={`${ClassName}--info`}
            $titleFontWeight={300}
            titleIntl="v2.pages.plan-details.add-payment-alert.Content1"
            titleIntlValues={{ value: 'Launcher' }}
            contentText={[
              {
                id: 'v2.pages.plan-details.add-payment-alert.Content2',
                values: {
                  value: sub.amount,
                  c: (chunks: string) => (
                    <span>
                      <Dollars value={Number(chunks)} />
                    </span>
                  ),
                },
              },
            ]}
          />
        </div>
      ) : undefined}

      {/* Details */}

      <div className={`${ClassName}--details`}>
        {/* Subscription */}

        <div className={`${ClassName}--details--row`}>
          {/* Title */}

          <div className={`${ClassName}--details--row--title`}>
            <Trans id="pages.user-zone.plans-tab.Subscription" />
          </div>

          {/* Value */}

          <div>
            {/* $$$ total */}

            <div className={`${ClassName}--details--row--highlight`}>
              <Dollars value={sub.amount * sub.quantity} />

              <div className={`${ClassName}--details--row--highlight--interval`}>
                (<Trans id="common.Yearly" />)
              </div>
            </div>

            {/* $$$ per device */}

            {sub.quantity > 1 ? (
              <div>
                <span>
                  <Dollars value={sub.amount} />
                </span>
                <span> </span>
                <span>
                  <Trans id="v2.pages.plan-details.PerDevice" />
                </span>
              </div>
            ) : undefined}

            {/* - */}
          </div>
        </div>

        {/* License key */}

        <div className={`${ClassName}--details--row`}>
          {/* Title */}

          <div className={`${ClassName}--details--row--title`}>
            <Trans id="v2.pages.plan-details.LicenseKey" />
          </div>

          {/* Value */}

          {sub.license_key ? (
            <div className={`${ClassName}--details--row--highlight`} onClick={onClickLicense}>
              <span>{sub.license_key}</span>
              {/* Note: for some reason <span> </span> does not add the space */}
              <span>&nbsp;</span>
              <span className={`${ClassName}--details--row--highlight smooth`}>
                (<Trans id="v2.pages.plan-details.Activation" values={{ value: sub.quantity }} />)
              </span>
            </div>
          ) : (
            <Trans id="common.Generaing" />
          )}
        </div>

        {/* Latest version */}

        <div className={`${ClassName}--details--row`}>
          {/* Title */}

          <div className={`${ClassName}--details--row--title`}>
            <Trans id="v2.pages.plan-details.LatestVersion" />
          </div>

          {/* Value + Download */}

          <div>
            <div className={`${ClassName}--details--row--highlight`}>
              <div>{isLoading ? <Skeleton /> : lcherReleaseInfo.version}</div>
            </div>

            <div>
              <Button
                colorType="quaternary-link"
                intlId="v2.pages.plan-details.DownloadForPlatform"
                intlValues={{ value: 'Windows' }}
                IconLeft={DownloadIcon}
                leftIconMarginRight={4}
                padding="0"
                size="s"
                fontWeight="500"
                running={false}
                disabled={false}
                active={true}
                onClick={onClickDownload}
              />
            </div>
          </div>
        </div>

        {/* Payment method */}

        <div className={`${ClassName}--details--row`}>
          {/* Title */}

          <div className={`${ClassName}--details--row--title`}>
            <Trans id="pages.common.PaymentMethod" />
          </div>

          {/* Value */}

          {/* Card: A) Card -> change B) No Card -> Add */}

          <div>
            <div className={`${ClassName}--details--row--highlight ${card ? '' : 'smooth'}`}>
              {card ? (
                <>
                  <CreditCard card={card} />

                  {isUnpaid ? (
                    <div className={`${ClassName}--details--row--highlight--error`}>
                      <ErrorIcon size={14} />
                    </div>
                  ) : undefined}
                </>
              ) : (
                <Trans id="v2.pages.plan-details.NoneAdd" />
              )}
            </div>

            {/* Info (warn) */}

            {isTrial || card ? undefined : (
              <div style={{ marginBottom: 16 }}>
                <InfosBox
                  kind="yellow"
                  $titleFontWeight={300}
                  titleIntl="v2.pages.plan-details.add-payment-alert.Content1"
                  titleIntlValues={{ value: 'Launcher' }}
                  contentText={[
                    {
                      id: 'v2.pages.plan-details.add-payment-alert.Content2',
                      values: {
                        value: sub.amount,
                        c: (chunks: string) => (
                          <span className="hard">
                            <Dollars value={Number(chunks)} />
                          </span>
                        ),
                      },
                    },
                  ]}
                />
              </div>
            )}

            {/* Add/Change payment method */}

            <div>
              {card ? (
                <Button
                  colorType="quaternary-link"
                  intlId="pages.common.ChangePayment"
                  IconLeft={EditIcon}
                  leftIconMarginRight={4}
                  padding="0"
                  size="s"
                  fontWeight="500"
                  running={false}
                  disabled={false}
                  active={true}
                  onClick={onClickChangePaymentMethod}
                />
              ) : (
                <Button
                  colorType="quaternary-link"
                  intlId="pages.common.AddPaymentMethod"
                  IconLeft={AddIcon}
                  leftIconMarginRight={4}
                  padding="0"
                  size="s"
                  fontWeight="500"
                  running={false}
                  disabled={false}
                  active={true}
                  onClick={onClickAddPaymentMethod}
                />
              )}
            </div>
          </div>
        </div>

        {/* Next billing date */}

        <div className={`${ClassName}--details--row`}>
          <div className={`${ClassName}--details--row--title`}>
            <Trans id="pages.user-zone.plans-tab.NextBillingDate" />
          </div>

          <div className={`${ClassName}--details--row--highlight only`}>
            {showNextBillingDate ? nextBillingDate : '-'}
          </div>
        </div>

        {/* Last billing date */}

        <div className={`${ClassName}--details--row`}>
          <div className={`${ClassName}--details--row--title`}>
            <Trans id="pages.common.LastBillingDate" />
          </div>

          <div className={`${ClassName}--details--row--highlight only`}>
            {showLastBillingDate ? lastBillingDate : '-'}
          </div>
        </div>
      </div>

      {/* Cancel Plan */}

      {!isCanceled ? (
        <>
          <div className={`${ClassName}--title`}>
            <Trans id="v2.pages.plan-details.CancelPlan" />
          </div>

          <div className={`${ClassName}--cancel`}>
            <div className={`${ClassName}--cancel--line`}>
              <DangerTrans
                template="... <c>{value}</c>..."
                id="v2.pages.plan-details.CancelHelp"
                values={{
                  value: Config.Emails.Orders,
                  c: (chunks: string) => <b>{chunks}</b>,
                }}
              />
            </div>

            <div className={`${ClassName}--cancel--line`}>
              <Trans
                id={
                  isTrial
                    ? 'v2.pages.plan-details.IfCancelTrial'
                    : 'v2.pages.plan-details.IfCancelPLan'
                }
                values={{
                  productName: 'Launcher',
                  endDate: nextBillingDate,
                }}
              />
            </div>

            <div className={`${ClassName}--cancel--action`}>
              <Button
                colorType="quinary-link"
                intlId="dialogs.confirm.cancel-plan.Title"
                rightIconMarginLeft={4}
                padding="0"
                size="s"
                fontWeight="500"
                running={false}
                disabled={false}
                active={true}
                onClick={onClickCancelPlan}
              />
            </div>
          </div>
        </>
      ) : undefined}

      {/* - */}
    </STUserZonePlansProductDetailLauncher>
  )
}
