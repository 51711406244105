import { ofType, StateObservable } from 'redux-observable'
import { Observable } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'

import { StoreState } from '../../../../../models/app/model'

import { ApiUtils } from '../../../utils'
import { ApiStoreSubscriptionsChangePaymentMethodEpicMC } from './mutators'
import { apiStoreSubscriptionsChangePaymentMethod$ } from '../../../../../services/api-store/subscriptions/change-payment-method'
import {
  ApiStoreSubscriptionsAC,
  ApiStoreSubscriptionsAT,
} from '../../../../actions/api-store/subscriptions/actions'

type Action = ReturnType<typeof ApiStoreSubscriptionsAC.changePaymentMethod>

export const apiStoreSubscriptionsChangePaymentMethodEpic$ = (
  action$: Observable<Action>,
  state$: StateObservable<StoreState>,
) =>
  action$.pipe(
    ofType(ApiStoreSubscriptionsAT.CHANGE_PAYMENT_METHOD),

    mergeMap(({ payload }) =>
      apiStoreSubscriptionsChangePaymentMethod$(
        state$.value.currentUser.token,
        payload.subscriptionId,
        payload.paymentMethodId,
      ),
    ),

    map((res) => {
      if (ApiUtils.isError(res)) {
        ApiStoreSubscriptionsChangePaymentMethodEpicMC.error(res.response)
      }

      const card = state$.value.cards.list.find(
        (card) => card.payment_method_id === res.response.paymentMethodId,
      )

      if (!card) {
        return ApiStoreSubscriptionsChangePaymentMethodEpicMC.error({
          ...res.response,
          errors: [{ id: 'api.subscriptions.change-payment-method.Error' }],
        })
      }

      return ApiStoreSubscriptionsChangePaymentMethodEpicMC.ok(res.response, card)
    }),
  )
