import { useNavigate, useParams } from 'react-router'
import { useSelector } from 'react-redux'
import { UserZoneCommonBannerBack } from '../../../@-common/banner-back/component'
import { STUserZonePlansDetailLauncher } from './style'
import { Routes } from '../../../../../constants/routes'
import { StoreState } from '../../../../../models/app/model'
import { useEffect } from 'react'
import { UserZonePlansProductDetailLauncher } from './detail/component'

// ~~~~~~ Component

export const UserZonePlansDetailLauncher = () => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  const subId = useParams<{ subId: string }>().subId

  const isNew = new URLSearchParams(window.location.search).get('new') === 'true'

  // ~~~~~~ State

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  const subs = useSelector((state: StoreState) => state.subscriptions)

  const launcherProducts = useSelector((state: StoreState) => state.launcher)

  // ~~~~~~ Computed

  const isLoading = !currentUser.isSessionChecked || currentUser.uiStatus === 'running'

  const sub = subId ? subs.find((sub) => sub.subscription_id === subId) : undefined

  const productYearly = launcherProducts.list.find((product) => product.interval === 'year')

  const isTrial = sub
    ? (!!sub.trial_end && !sub.converted_to_paid) || sub.subscription_status === 'trialing'
    : false

  // ~~~~~~ Handlers

  function onClickGoBack() {
    navigate(Routes.UserZonePlans)
  }

  // ~~~~~~ Effects

  // - If no sub return to user-zone plans

  useEffect(() => {
    if (isLoading || sub) return

    navigate(Routes.UserZonePlans)
    //
  }, [isLoading, navigate, sub])

  // ~~~~~~ Render

  if (!productYearly || !sub) return null

  return (
    <STUserZonePlansDetailLauncher>
      {/* Banner back */}

      <UserZoneCommonBannerBack
        kind="launcher"
        text={isTrial ? 'Trial' : ''}
        onClickGoBack={onClickGoBack}
      />

      {/* Content */}

      <UserZonePlansProductDetailLauncher isTrial={isTrial} isNew={isNew} sub={sub} />

      {/* - */}
    </STUserZonePlansDetailLauncher>
  )
}
