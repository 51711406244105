import { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { fromEvent, takeUntil } from 'rxjs'
import { UtilsCycle, UtilsDates } from '@dn/utils'

import { Config } from '../../config'
import { ElementEvents } from '../../constants/element-events'
import { StoreState } from '../../models/app/model'
import { FirstPortal } from '../../portals/debug-portal'
import { SessionStorageDebug } from '../../services/storage/session/debug'
import { DebuggerMC } from '../../store/actions-mutators/debugger/mutators'
import { STDebugActions } from './style'

// ~~~~~~ [P]

const genState = (actions: any[]) => {
  return JSON.stringify({
    appInfo: Config.App,
    payload: UtilsCycle.decycle(actions),
    preloadedState: JSON.stringify({}),
  })
}

// ~~~~~~ Component

export const DebugActions = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  // - Local

  const [pos, setPos] = useState({ x: 0, y: 0 })

  const [download, setDownload] = useState<{ file: string; name: string } | undefined>(undefined)

  const {
    enable: debugIsEnabled,
    actions,
    uiStatus,
    sendError,
  } = useSelector((store: StoreState) => store.debugger)

  const selfRef = useRef<HTMLDivElement | null>(null)

  const buttonRef = useRef<HTMLButtonElement | null>(null)

  const downloadRef = useRef<HTMLAnchorElement | null>(null)

  // - Store

  const intlLang = useSelector((state: StoreState) => state.intl.lang)

  // ~~~~~~ Computed

  const disabled = uiStatus === 'running'

  const sended = uiStatus === 'completed'

  // ~~~~~~ Handlers

  function startDrag(evt: React.MouseEvent<HTMLDivElement, MouseEvent>) {
    const self = selfRef.current

    if (!self || evt.target === buttonRef.current) {
      return
    }

    const init = {
      x: evt.clientX - parseInt(self.style.left),
      y: evt.clientY - parseInt(self.style.top),
    }

    const mouseMove$ = fromEvent<MouseEvent>(document, ElementEvents.MouseMove)

    const mouseUp$ = fromEvent<MouseEvent>(document, ElementEvents.MouseUp)

    mouseMove$.pipe(takeUntil(mouseUp$)).subscribe({
      next: (evt) => {
        if (!evt.target) return

        setPos({
          x: evt.clientX - init.x,
          y: evt.clientY - init.y,
        })
      },
    })
  }

  function onClickDisable() {
    SessionStorageDebug.clear()
    dispatch(DebuggerMC.disable())
  }

  function onClickSave() {
    if (disabled) return

    const state = genState(actions)

    const data = new Blob([state], { type: 'application/json' })

    const file = window.URL.createObjectURL(data)

    const dateTime = UtilsDates.getFileTimestamp(intlLang)

    setDownload({ file, name: `${Config.ProjectName}-${dateTime}.json` })
  }

  // ~~~~~~ Effects

  // - Auto download

  useEffect(() => {
    if (!downloadRef.current || !download) return

    downloadRef.current.click()

    window.URL.revokeObjectURL(download.file)
    setDownload(undefined)

    SessionStorageDebug.clear()
    dispatch(DebuggerMC.disable())

    //
  }, [dispatch, download])

  // ~~~~~~ Render

  if (!debugIsEnabled) return null

  return (
    <FirstPortal>
      <STDebugActions
        ref={selfRef}
        $hasSendError={!!sendError}
        $sended={sended}
        $disabled={disabled}
        onMouseDown={startDrag}
        style={{ left: pos.x, top: pos.y }}
      >
        {/* Title */}
        <div className="title">Debug Session</div>

        {/* Sended ok */}
        {sended ? <div className="ok">Issue sended</div> : undefined}

        {/* Close */}
        <button onClick={onClickDisable}>Close</button>

        {/* Send or Save */}
        <button disabled={disabled} ref={buttonRef} onClick={onClickSave}>
          Save
        </button>

        {/* Hidden auto download json */}
        {download ? (
          <a
            ref={downloadRef}
            style={{ display: 'none' }}
            href={download.file}
            download={download.name}
          >
            {download.name}
          </a>
        ) : undefined}
      </STDebugActions>
    </FirstPortal>
  )
}
