import { ProductButtonBcast } from '../../../../../components/common/product-button/bcast/component'
import { LazySVG } from '../../../../../components/svgs/lazy-svg/component'
import { PageCommonOPStep2VerifyEmail } from '../../../../@-common/obtaining-products/step-2-verify-email/component'
import { PagePurchaseCommonTabSteps } from '../../../@-common/tab-steps/component'

// ~~~~~~ Constants

const Logo = LazySVG('logos/broadcast-gradient')

// ~~~~~~ Component

export const PagePurchaseBcastContentStep2VerifyEmail = () => {
  // ~~~~~~ Render

  return (
    <PageCommonOPStep2VerifyEmail
      Logo={Logo}
      ProductButton={ProductButtonBcast}
      tabSteps={<PagePurchaseCommonTabSteps kind="subscription" />}
    />
  )
}
