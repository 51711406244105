import React, { useState } from 'react'
import { Menu, Wrapper, Button, MenuItem, WrapperState } from 'react-aria-menubutton'
import { useDispatch, useSelector } from 'react-redux'

import { Trans } from '../../../../../../components/common/intl/trans'
import { LazySVG } from '../../../../../../components/svgs/lazy-svg/component'
import { ModalsIds } from '../../../../../../constants/modals'
import { StoreState } from '../../../../../../models/app/model'
import { CardMC } from '../../../../../../store/actions-mutators/card/mutators'
import { ModalsMC } from '../../../../../../store/actions-mutators/modals/mutators'
import { ClassName, STUserZoneBillingRootCardMenu } from './style'
import { useNavigate } from 'react-router'
import { Routes } from '../../../../../../constants/routes'

// ~~~~~ Types

type Props = {
  card: DN.Models.Card.ModelFront
}

// ~~~~~ Constants

const MenuIcon = LazySVG('icons/more-horizontal')

// ~~~~~ Component

export const UserZoneBillingRootCardMenu: React.FC<Props> = ({ card }) => {
  // ~~~~~ Hooks

  const navigate = useNavigate()

  const dispatch = useDispatch()

  // ~~~~~ State

  const [isMenuOpen, setMenuOpen] = useState(false)

  const { uiStatusChange } = useSelector((state: StoreState) => state.cards)

  // ~~~~~ Computed

  const changeIsRunning = uiStatusChange === 'running'

  // ~~~~~ Handlers

  function onMenuToggle(evt: WrapperState) {
    setMenuOpen(evt.isOpen)
  }

  function onSelection(itemSelected: 'edit-payment' | 'set-default-card' | 'delete-card') {
    if (changeIsRunning) return

    switch (itemSelected) {
      // Edit payment method (edit card)

      case 'edit-payment':
        navigate(Routes.genEditPaymentMethod(card.payment_method_id))

        break

      // Show modal to confirm change default card

      case 'set-default-card':
        dispatch(CardMC.setTmpCardId(card.payment_method_id))

        dispatch(ModalsMC.open(ModalsIds.ConfirmChangeDefaultCard))

        break

      // Show modal to confirm delete card

      case 'delete-card':
        dispatch(CardMC.setTmpCardId(card.payment_method_id))

        dispatch(ModalsMC.open(ModalsIds.ConfirmRemCard))

        break
    }
  }

  // ~~~~~ Render

  return (
    <STUserZoneBillingRootCardMenu>
      <Wrapper onMenuToggle={onMenuToggle} onSelection={onSelection}>
        {/* More choice button icon */}

        <Button className={`${ClassName}--main-btn ${isMenuOpen ? 'opened' : ''}`}>
          <MenuIcon size={24} />
        </Button>

        {/* Menu */}

        <div className={`${ClassName}--dropdown`}>
          <Menu>
            <div className={`${ClassName}--dropdown--content`}>
              {/* Edit payment choice */}

              <MenuItem className={`${ClassName}--dropdown--content--item`} value="edit-payment">
                <Trans id="v2.pages.billing.EditPayment" />
              </MenuItem>

              {/* Set default choice */}

              {!card.is_default ? (
                <MenuItem
                  className={`${ClassName}--dropdown--content--item highlight`}
                  value="set-default-card"
                >
                  <Trans id="v2.pages.billing.SetDefault" />
                </MenuItem>
              ) : undefined}

              {/* Delete choice */}

              <MenuItem
                className={`${ClassName}--dropdown--content--item danger`}
                value="delete-card"
              >
                <Trans id="v2.pages.billing.Delete" />
              </MenuItem>
            </div>
          </Menu>
        </div>
      </Wrapper>
    </STUserZoneBillingRootCardMenu>
  )
}
