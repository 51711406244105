import { useNavigate } from 'react-router'
import { useEffect, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { HooksData } from '@dn/hooks'
import { UtilsEncDec } from '@dn/utils'

import { ClassName, STUserZonePlansSubChangePaymentMethod } from './style'
import {
  RouteDataAddPaymentMethod,
  RouteDataChangePaymentMethod,
  Routes,
} from '../../../../constants/routes'
import { useUserZoneDecDataParam } from '../../../../hooks/user-zone-dec-data-param/hook'
import { BackLink } from '../../../../components/common/links/back-link/component'
import { StoreState } from '../../../../models/app/model'
import { Trans } from '../../../../components/common/intl/trans'
import { LazySVG } from '../../../../components/svgs/lazy-svg/component'
import { CreditCard } from '../../../../components/common/credit-card/component'
import { Button } from '../../../../components/common/button/component'
import { useTrans } from '../../../../hooks/use-trans'
import { PaymentButton } from '../../../../components/common/gradient-button/component'
import { ApiStoreSubscriptionsAC } from '../../../../store/actions/api-store/subscriptions/actions'

// ~~~~~~ Constants

const CheckedIcon = LazySVG('icons/check-full')

const AddPaymentIcon = LazySVG('icons/plus-circle')

// ~~~~~~ Component

export const UserZonePlansSubChangePaymentMethod = () => {
  // ~~~~~~ Hooks

  const data = useUserZoneDecDataParam<RouteDataChangePaymentMethod>()

  const navigate = useNavigate()

  const addPaymentBackText = useTrans('pages.common.ChangePayment')

  const dispatch = useDispatch()

  // ~~~~~~ State

  // - Local

  const [userSelectedCardId, setUserSelectedCardId] = useState('')

  // - Store

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  const cards = useSelector((state: StoreState) => state.cards)

  const subs = useSelector((state: StoreState) => state.subscriptions)

  // ~~~~~~ Computed

  const isLoading = !currentUser.isSessionChecked || currentUser.uiStatus === 'running'

  const sub = data ? subs.find((sub) => sub.subscription_id === data.subId) : undefined

  const defaultCard = cards.list.find((card) => card.is_default)

  const selectedCardId = userSelectedCardId || sub?.payment_method_id

  const canChange = !!sub && !!userSelectedCardId && userSelectedCardId !== sub.payment_method_id

  const isRunning = sub?.uiStatus === 'running'

  const prevPaymentId = HooksData.PrevValue.useHook(sub?.payment_method_id)

  // ~~~~~~ Handlers

  function onClickGoBack() {
    if (!data) return

    navigate(data.backPath)
  }

  function onClickAddPayment() {
    if (!data) return

    const dataObj: RouteDataAddPaymentMethod = {
      backPath: window.location.pathname,
      backText: addPaymentBackText,
      successPath: window.location.pathname,
    }

    const nextData = UtilsEncDec.base64UrlEncode(JSON.stringify(dataObj))

    const addPaymentRoute = Routes.genUserZonePlansAddPaymentMethod(nextData)

    navigate(addPaymentRoute)
  }

  function onClickCard(cardId: string) {
    setUserSelectedCardId(cardId)
  }

  function onSubmit() {
    if (!data || !canChange) return

    dispatch(ApiStoreSubscriptionsAC.changePaymentMethod(data.subId, userSelectedCardId))
  }

  // ~~~~~~ Effects

  // - On update payment method success
  //   Go to back route

  useEffect(() => {
    if (!data || prevPaymentId === undefined || prevPaymentId === sub?.payment_method_id) {
      return
    }

    navigate(data.backPath)

    //
  }, [data, navigate, prevPaymentId, sub?.payment_method_id])

  // ~~~~~~ Render

  if (!data || isLoading) return null

  return (
    <STUserZonePlansSubChangePaymentMethod>
      <div className={`${ClassName}--content`}>
        {/* <- Go Back */}

        <BackLink
          className={`${ClassName}--content--go-back`}
          intlId="wildcard"
          intlValues={{ value: data.backText }}
          onClick={onClickGoBack}
        />

        {/* Title */}

        <div className={`${ClassName}--content--title`}>
          <Trans id="pages.common.ChangePayment" />
        </div>

        {/* Subtitle */}

        <div className={`${ClassName}--content--subtitle`}>
          <Trans id="v2.pages.change-payment-method.SelectPaymentMethodToUse" />
        </div>

        {/* Select title */}

        <div className={`${ClassName}--content--select--title`}>
          <Trans id="v2.pages.change-payment-method.SelectPaymentMethod" />
        </div>

        {/* Cards */}

        <div className={`${ClassName}--content--cards`}>
          {cards.list.map((card) => (
            <div
              key={card.payment_method_id}
              //
              onClick={() => onClickCard(card.payment_method_id)}
              //
              className={`${ClassName}--content--cards--card ${
                card.payment_method_id === selectedCardId ? 'selected' : ''
              }`}
            >
              {/* Check and last4 */}

              <div className={`${ClassName}--content--cards--card--a`}>
                <div className={`${ClassName}--content--cards--card--a--check`}>
                  <CheckedIcon size={28} />

                  <div className={`${ClassName}--content--cards--card--a--check--empty`} />
                </div>

                <div className={`${ClassName}--content--cards--card--a--last4`}>
                  <CreditCard card={card} />
                </div>
              </div>

              {/* Default badge */}

              <div className={`${ClassName}--content--cards--card--b`}>
                {defaultCard?.payment_method_id === card.payment_method_id ? (
                  <div className={`${ClassName}--content--cards--card--b--default`}>DEFAULT</div>
                ) : undefined}
              </div>
            </div>
          ))}
        </div>

        {/* Add Payment Method */}

        <div className={`${ClassName}--content--add-payment`}>
          <Button
            colorType="quaternary-link"
            intlId="pages.common.AddPaymentMethod"
            leftIconMarginRight={4}
            IconLeft={AddPaymentIcon}
            size="s"
            fontWeight="600"
            running={false}
            disabled={isRunning}
            active={!isRunning}
            onClick={onClickAddPayment}
          />
        </div>

        {/* Action */}

        <div className={`${ClassName}--content--actions`}>
          <PaymentButton
            className={`${ClassName}--content--actions--action first`}
            intlId="pages.common.ChangePayment"
            running={isRunning}
            disabled={!canChange || isRunning}
            active={canChange && !isRunning}
            onClick={onSubmit}
          />

          <Button
            colorType="default"
            intlId="common.Cancel"
            running={false}
            disabled={false}
            active={true}
            onClick={onClickGoBack}
          />
        </div>

        {/* - */}
      </div>
    </STUserZonePlansSubChangePaymentMethod>
  )
}
