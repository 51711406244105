import { css } from 'styled-components'

const Spacing = {
  1: 4,
  2: 8,
  3: 16,
  4: 24,
  5: 32,
  6: 40,
  7: 48,
  8: 56,
  9: 64,
  10: 72,
  11: 80,
  12: 94,
} as const

const Border = {
  Size: {
    A: 'border-width: 1px;',
    B: 'border-width: 2px;',
    C: 'border-width: 4px;',
    D: 'border-width: 8px;',

    Number: {
      A: 1,
      B: 2,
      C: 4,
      D: 8,
    },
  },
  Radius: {
    A: 'border-radius: 4px;',
    B: 'border-radius: 8px;',
    C: 'border-radius: 12px;',
    D: 'border-radius: 16px;',
    E: 'border-radius: 80px;',
  },
} as const

const PaddingWidth = {
  Clear: css`
    padding-left: 0;
    padding-right: 0;
    width: unset;
  `,

  Default: css`
    padding-left: 120px;
    padding-right: 120px;
    width: calc(100% - 240px);
  `,

  Desktop: css`
    padding-left: 40px;
    padding-right: 40px;
    width: calc(100% - 80px);
  `,

  Tablet: css`
    padding-left: 32px;
    padding-right: 32px;
    width: calc(100% - 64px);
  `,

  Mobile: css`
    padding-left: 16px;
    padding-right: 16px;
    width: calc(100% - 32px);
  `,
}

const MarginMaxWidth = {
  MiddleContent: css`
    max-width: 600px;
    margin-left: auto;
    margin-right: auto;
  `,
}

// ~~~~~~

export const Layout = {
  Spacing,
  Border,
  PaddingWidth,
  MarginMaxWidth,
} as const
