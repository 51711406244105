import styled from 'styled-components'

import { MediaQuery } from '../../../style/constants/media-queries'
import { Colors } from '../../../style/theme/colors'
import { Fonts } from '../../../style/theme/fonts'
import { theme } from '../../../style/utils/theme'

// ~~~~~~

export const STFooter = styled.div`
  max-width: ${MediaQuery.LG};

  padding: 32px 24px;
  width: calc(100% - ${24 * 2}px);

  display: flex;
  justify-content: space-between;
  align-items: baseline;

  .footer-text {
    ${Fonts.Scale.Body}
    ${Fonts.Weight[300]}
  }

  a {
    text-decoration: none;

    color: ${theme(Colors.WOLF_90_90)};

    ${Fonts.Scale.Body}
    ${Fonts.Weight[600]}
  }

  .left-footer {
    flex: 1;
    display: flex;

    .back-to-link {
      margin-right: 5px;
    }
  }

  .footer-right {
    flex: 1;
    display: flex;
    justify-content: space-between;
  }

  @media (max-width: ${MediaQuery.MaxWidth2k}) {
    .left-footer {
      flex: 1;
    }
  }

  @media (max-width: ${MediaQuery.MD}) {
    flex-direction: column;
    align-items: center;

    background-color: ${theme(Colors.WOLF_90_90)};

    .footer-text,
    a {
      color: ${theme(Colors.WOLF_00_00)};
    }

    .left-footer {
      margin-bottom: 24px;
    }

    .footer-right {
      width: 70%;
    }
  }

  @media (max-width: ${MediaQuery.MaxWidthLand}) {
    .footer-right {
      width: 100%;
      flex-direction: column;
      align-items: center;

      a {
        margin-bottom: 24px;
      }
    }
  }
`
