import { ofType } from 'redux-observable'
import { Observable } from 'rxjs'
import { map, mergeMap } from 'rxjs/operators'

import { apiStoreCheckoutSendEmailPass$ } from '../../../../../services/api-store/checkout/send-email-with-pass'
import {
  ApiStoreCheckoutAC,
  ApiStoreCheckoutAT,
} from '../../../../actions/api-store/checkout/actions'
import { ApiUtils } from '../../../utils'
import { ApiStoreCheckoutSendEmailPassEpicMC } from './mutators'

type Action = ReturnType<typeof ApiStoreCheckoutAC.sendEmailPass>

export const apiStoreCheckoutSendEmailPassEpic$ = (action$: Observable<Action>) =>
  action$.pipe(
    ofType(ApiStoreCheckoutAT.SEND_EMAIL_PASS),
    mergeMap(({ payload }) => apiStoreCheckoutSendEmailPass$(payload)),
    map((res) =>
      ApiUtils.isNotError(res)
        ? ApiStoreCheckoutSendEmailPassEpicMC.ok()
        : ApiStoreCheckoutSendEmailPassEpicMC.error(res.response),
    ),
  )
