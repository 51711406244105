import { ofType } from 'redux-observable'
import { map, mergeMap, Observable } from 'rxjs'

import { Config } from '../../../../../config'
import { apiLauncherReleaseInfoGet$ } from '../../../../../services/api-launcher/release-info/get'
import {
  ApiLauncherReleaseInfoAC,
  ApiLauncherReleaseInfoAT,
} from '../../../../actions/api-launcher/release-info/action'
import { ApiUtils } from '../../../utils'
import { EpicApiLauncherReleaseInfoMC } from './mutators'

type Action = ReturnType<typeof ApiLauncherReleaseInfoAC.start>

export const apiLauncherReleaseInfoGetEpic$ = (action$: Observable<Action>) =>
  action$.pipe(
    ofType(ApiLauncherReleaseInfoAT.GET),
    mergeMap(() => apiLauncherReleaseInfoGet$(Config.Api.Launcher.Headers)),
    map((res) =>
      ApiUtils.isNotError(res)
        ? EpicApiLauncherReleaseInfoMC.ok(res.response)
        : EpicApiLauncherReleaseInfoMC.error(res.response),
    ),
  )
