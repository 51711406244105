import { useEffect } from 'react'
import { useParams, useNavigate } from 'react-router'
import { UtilsEncDec } from '@dn/utils'

import { Routes } from '../../constants/routes'
import { UtilsLog } from '../../utils/logs'

// ~~~~~~ Helper

const getData = (paramData: string | undefined) => {
  if (!paramData) return ['bad', undefined] as const

  let data = {}

  try {
    const object = UtilsEncDec.base64UrlDecode(paramData)

    data = JSON.parse(object)
  } catch (err) {
    UtilsLog.devLog('parse url data', err)

    return ['bad', undefined] as const
  }

  return ['ok', data] as const
}

// ~~~~~~ Hook

// TODO(Cuenca): Accept concrete T and use guards to check it

export const useUserZoneDecDataParam = <T>() => {
  // ~~~~~~ Hooks

  const paramData = useParams<{ data: string }>().data

  const navigate = useNavigate()

  // ~~~~~~ Computed

  let [res, data] = getData(paramData)

  // ~~~~~~ Effects

  // - If no param data go to main

  useEffect(() => {
    if (paramData) return

    navigate(Routes.UserZonePlans)

    //
  }, [data, navigate, paramData])

  // - If bad parse go to main

  useEffect(() => {
    if (res === 'ok') return

    navigate(Routes.UserZonePlans)

    //
  }, [navigate, res])

  // ~~~~~~

  return data as undefined | T
}
