export const RoutesOld = {
  Debug: '/debug/:kind', // (on|qa|off)

  Common: '/',
  Main: '/',

  // User Zone

  UserZone: '/user-zone', // Subscriptions
  UserZonePayments: '/user-zone/payments',
  UserZoneAddPayment: '/user-zone/add-payment',
  UserZoneEditPayment: '/user-zone/edit-payment',
  UserZoneChangePayment: '/user-zone/change-payment',
  UserZoneInvoices: '/user-zone/invoices',
  UserZoneManagePlanWithId: '/user-zone/manage-plan/:subscriptionId', // subscriptionId: subscription id in plans list
  UserZoneManagePlanSuccess: '/user-zone/manage-plan-success',
  UserZoneManagePlanSuccessWithSessionId: '/user-zone/manage-plan-success/:sessionId', // sessionId: session id generated by stripe and verified inside a useEffect
  UserZoneUpgradeBroadcast: '/user-zone/upgrade-broadcast',
  UserZoneSwitchBroadcastBilling: '/user-zone/switch-billing',
  UserZoneChangeBillingDetails: '/user-zone/change-billing-details',
  UserZoneAddSolutionPayment: '/user-zone/add-solution/payment',
  UserZoneSolutionOrder: '/user-zone/add-solution/order',

  // - Add another solution

  UserZoneAddLauncher: '/user-zone/add-solution/launcher',
  UserZoneAddMontage: '/user-zone/add-solution/montage',
  UserZoneAddBroadcast: '/user-zone/add-solution/broadcast',

  // Buy success

  BuySuccess: '/buy-success/:sessionId', // sessionId: session id generated by stripe and verified inside a useEffect

  // Products

  Launcher: '/launcher',

  Montage: '/montage',

  Broadcast: '/broadcast',

  // Redirect with login

  RedirectWithLogin: '/redirect-with-login',

  // From email sended to a customer on end of trial
  // BuyFromEmailLinkLink: '/buy/:sessionId',

  // Purchase flow

  FreeAccountPurchaseFlow: '/purchase-flow/:productId', // (launcher|montage|broadcast)

  PurchaseFlow: '/purchase-flow/buy/:productId', // (launcher|montage)

  PurchaseFlowSuccess: '/purchase-flow/buy/:productId/:sessionId', // productId: (launcher|montage|broadcast), sessionId: session id generated by stripe and verified inside a useEffect

  // - Broadcast purchase flow

  PurchaseFlowBroadcast: '/purchase-flow/buy/broadcast/:interval', // (month|year)

  PurchaseFlowBroadcastSuccess: '/purchase-flow/buy/broadcast/:interval/:sessionId', // interval: (month|year), sessionId: session id generated by stripe and verified inside a useEffect

  genFreePurchaseFlowProductId: (productId: string) =>
    RoutesOld.FreeAccountPurchaseFlow.replace(':productId', productId),

  genPurchaseFlowProductId: (productId: string) =>
    RoutesOld.PurchaseFlow.replace(':productId', productId),

  genUserZoneManagePlanWithId: (subscriptionId: string) =>
    RoutesOld.UserZoneManagePlanWithId.replace(':subscriptionId', subscriptionId),
} as const

// ~~~~~~

export const RoutesRegExps = {
  // Buy success

  BuySuccess: new RegExp(/^\/buy-success\/[a-zA-Z0-9_]+$/),

  // Purchase flow

  FreeAccountPurchaseFlow: new RegExp(/^\/purchase-flow\/(montage|launcher|broadcast)$/),

  PurchaseFlowSuccess: new RegExp(
    /^\/purchase-flow\/buy\/(montage|launcher|broadcast)\/[a-zA-Z0-9_]+$/,
  ),

  // User Zone

  UserZoneManagePlanSuccess: new RegExp(/^\/user-zone\/manage-plan-success\/[a-zA-Z0-9_]+$/),
} as const
