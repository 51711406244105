const Bronx = {
  80: '#003D7A',
  70: '#0052A3',
  60: '#0066CC',
  50: '#007AF5',
  40: '#4BA5FF',
  30: '#70B8FF',
  20: '#99CCFF',
  10: '#CCE6FF',
  5: '#EBF5FF',
} as const

// ~~~~~~

const BRONX_05_05: ThemeColor = {
  light: Bronx[5],
  dark: Bronx[5],
}

const BRONX_10_10: ThemeColor = {
  light: Bronx[10],
  dark: Bronx[10],
}

const BRONX_30_30: ThemeColor = {
  light: Bronx[30],
  dark: Bronx[30],
}

const BRONX_40_40: ThemeColor = {
  light: Bronx[40],
  dark: Bronx[40],
}

const BRONX_50_50: ThemeColor = {
  light: Bronx[50],
  dark: Bronx[50],
}

const BRONX_60_60: ThemeColor = {
  light: Bronx[60],
  dark: Bronx[60],
}

const BRONX_70_70: ThemeColor = {
  light: Bronx[70],
  dark: Bronx[70],
}

// ~~~~~~

export const BronxColors = {
  BRONX_05_05,
  BRONX_10_10,
  BRONX_30_30,
  BRONX_40_40,
  BRONX_50_50,
  BRONX_60_60,
  BRONX_70_70,
} as const
