import { UtilsMobile } from '@dn/utils'

// ~~~~~~ Constants

const isMobile = UtilsMobile.calcIsMobile()

// ~~~~~~

export const DialogsMargins = {
  Window: {
    Top: isMobile ? 20 : 40,
    Bottom: 20,
    Side: 20,
  },
  Dialogs: {
    InitialYDiff: 100,
    Padding: 20,
    AllPaddings: 60,
  },
  WindowAndDialogs: isMobile ? 100 : 120,
} as const
