import { Toaster } from 'react-hot-toast'
import { Navigate, Route, Routes as Switch } from 'react-router'
import { DebugActions } from '../debug/actions/component'
import { DebugLang } from '../debug/lang/component'
import { useCheckSession } from '../hooks/session/use-check-session'
import { useRedirectUser } from '../hooks/session/use-redirect-user'
import { DebugPage } from '../pages/debug/component'
import { Routes } from '../constants/routes'
import { PageRoot } from '../pages/0-root/component'
import { PageProductBcast } from '../pages/1-product/bcast/component'
import { PageProductLauncher } from '../pages/1-product/launcher/component'
import { PageProductMontage } from '../pages/1-product/montage/component'
import { PageTrialsOrFreeLauncher } from '../pages/2-trials-or-free/launcher/component'
import { PageTrialsOrFreeBcast } from '../pages/2-trials-or-free/bcast/component'
import { PageTrialsOrFreeMontage } from '../pages/2-trials-or-free/montage/component'
import { UserZone } from '../pages/3-user-zone/component'
import { PagePurchaseBcast } from '../pages/2-purchase/bcast/component'
import { PagePurchaseSubSuccess } from '../pages/2-purchase/@-sub-success/component'
import { PagePurchaseLauncher } from '../pages/2-purchase/launcher/component'
import { PagePurchaseLicenseSuccess } from '../pages/2-purchase/@-license-success/component'
import { PagePurchaseMontage } from '../pages/2-purchase/montage/component'
import { GTM } from './common/gtm/component'
import { useDocTitle } from '../hooks/use-doc-title'

// ~~~~~ Component

export const App = () => {
  // ~~~~~~ Hooks

  useDocTitle('common.DocTitle')

  useCheckSession()

  useRedirectUser()

  // ~~~~~~ Computed

  const toastOptions = {
    className: 'toast',
    style: {
      borderRadius: 4,
      overflow: 'hidden',
      padding: 0,
      margin: 0,
      maxWidth: 'unset',
    },
  }

  // ~~~~~~ Render

  return (
    <div data-test-id="app">
      {/* Debugs */}

      <DebugLang />

      <DebugActions />

      {/* GTM */}

      <GTM />

      {/* Toast messages */}

      <Toaster
        position="bottom-center"
        toastOptions={toastOptions}
        containerStyle={{
          bottom: 40,
        }}
      />

      {/* Pages */}

      <Switch>
        {/* Debug page */}

        <Route path={Routes.Debug} element={<DebugPage />} />

        {/* ##################################################################### */}
        {/* Public                                                                */}
        {/* ##################################################################### */}

        {/* ~~~~~~ [0] Main page */}

        <Route path={Routes.Root} element={<PageRoot />} />

        {/* ~~~~~~  [1] Products */}

        {/* - Bcast */}

        <Route path={Routes.Bcast} element={<PageProductBcast />} />

        {/* - Launcher */}

        <Route path={Routes.Launcher} element={<PageProductLauncher />} />

        {/* - Montage */}

        <Route path={Routes.Montage} element={<PageProductMontage />} />

        {/* ~~~~~~ [2] trials or free */}

        {/* - Montage */}

        <Route path={Routes.MontageStartTrial} element={<PageTrialsOrFreeMontage />} />

        {/* - Launcher */}

        <Route path={Routes.LauncherStartTrial} element={<PageTrialsOrFreeLauncher />} />

        {/* - Bcast */}

        <Route path={Routes.BcastSub2Free} element={<PageTrialsOrFreeBcast />} />

        {/* ~~~~~~  [2] purchase */}

        {/* - Montage */}

        <Route path={Routes.MontagePurchase} element={<PagePurchaseMontage />} />

        {/* -- Montage Success license */}

        <Route path={Routes.MontagePurchaseSuccess} element={<PagePurchaseLicenseSuccess />} />

        {/* - Launcher */}

        <Route path={Routes.LauncherPurchase} element={<PagePurchaseLauncher />} />

        {/* -- Launcher Success license */}

        <Route path={Routes.LauncherPurchaseSuccess} element={<PagePurchaseLicenseSuccess />} />

        {/* - Bcast */}

        <Route path={Routes.BcastSub2Premium} element={<PagePurchaseBcast />} />

        {/* -- Bcast Success subscription */}

        <Route path={Routes.BcastSub2PremiumSuccess} element={<PagePurchaseSubSuccess />} />

        {/* ##################################################################### */}
        {/* Private                                                               */}
        {/* ##################################################################### */}

        {/* [4] user zone */}

        <Route path={Routes.UserZoneNested} element={<UserZone />} />

        {/* ... */}

        {/* Unknown routes */}

        <Route path="*" element={<Navigate replace to={Routes.Root} />} />

        {/* - */}
      </Switch>
    </div>
  )
}
