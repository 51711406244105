import { useEffect } from 'react'
import { useSelector } from 'react-redux'
import { StoreState } from '../../../../models/app/model'
import { ProductIds } from '../../../../constants/product-ids'
import { ApiStoreProductsAC } from '../../../../store/actions/api-store/products/actions'
import { useDispatch } from 'react-redux'
import { BuyLicenseMC } from '../../../../store/actions-mutators/buy-license/mutators'
import { Config } from '../../../../config'
import { PageTrialsOrFreeMontageContentStep1CreateAccount } from './step-1-create-account/component'
import { PageTrialsOrFreeMontageContentStep2VerifyEmail } from './step-2-verify-email/component'
import { PageTrialsOrFreeMontageContentStep4Ready } from './step-4-trial-ready/component'
import { PageTrialsOrFreeMontageContentStep3SelectPlatform } from './step-3-select-platform/component'

export const PageTrialsOrFreeMontageContent = () => {
  // ~~~~~~ Hooks

  const dispatch = useDispatch()

  // ~~~~~~ State

  const metaProduct = useSelector((state: StoreState) => state.montage)

  const buyLicense = useSelector((state: StoreState) => state.buyLicense)

  // ~~~~~~ Computed

  const curStep = buyLicense.formStep

  // ~~~~~~ Effects

  // - For reload: if we don't have product bcast, call the api

  useEffect(() => {
    if (metaProduct.uiStatus !== 'init') return

    dispatch(ApiStoreProductsAC.listProducts(ProductIds.Montage))

    //
  }, [dispatch, metaProduct.uiStatus])

  // - For reload: if buy license is not setted, set as freeProduct to
  // start the "create account" flow

  useEffect(() => {
    if (metaProduct.uiStatus !== 'completed') return

    if (buyLicense.subId) return

    const product = metaProduct.products.windows.year

    dispatch(
      BuyLicenseMC.setLicense({
        productId: ProductIds.Montage,
        platform: 'windows',
        subId: product.id,
        unitPrice: product.price,
        trial: true,
        alwaysFree: false,
        trialPeriodDays: Number(Config.Trials.Montage.days),
      }),
    )

    //
  }, [buyLicense.subId, dispatch, metaProduct.products.windows.year, metaProduct.uiStatus])

  // ~~~~~~ Render

  switch (curStep) {
    case 1:
      return <PageTrialsOrFreeMontageContentStep1CreateAccount />

    case 2:
      return <PageTrialsOrFreeMontageContentStep2VerifyEmail />

    case 3:
      return <PageTrialsOrFreeMontageContentStep3SelectPlatform />

    case 4:
      return <PageTrialsOrFreeMontageContentStep4Ready />

    default:
      return null
  }
}
