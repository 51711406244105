const prefix = 'mt-store-current-user-show-epic'

// ~~~~~~ Mutators Types

export const EpicApiStoreCurrentUserShowMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const EpicApiStoreCurrentUserShowMC = {
  ok: (data: DN.Models.CurrentUser.Api.Show.ResParsed) => ({
    type: EpicApiStoreCurrentUserShowMT.OK,
    payload: data,
  }),

  error: (errors: DN.Models.CurrentUser.Api.Show.ResError) => ({
    type: EpicApiStoreCurrentUserShowMT.ERROR,
    error: true,
    payload: {
      errors,
    },
  }),
} as const
