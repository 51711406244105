export const Emmet = {
  90: '#002B6B',
  80: '#00388C',
  70: '#0048B5',
  60: '#005DE8',
  50: '#0066FF',
  40: '#3385FF',
  30: '#5498FF',
  20: '#8AB9FF',
  10: '#B0D0FF',
  5: '#E6F0FF',
  0: '#FFFFFF',
} as const

// ~~~~~~

const EMMET_05_05: ThemeColor = {
  light: Emmet[5],
  dark: Emmet[5],
}

const EMMET_40_40: ThemeColor = {
  light: Emmet[40],
  dark: Emmet[40],
}

const EMMET_50_50: ThemeColor = {
  light: Emmet[50],
  dark: Emmet[50],
}

// ~~~~~~

export const EmmetColors = {
  EMMET_05_05,
  EMMET_40_40,
  EMMET_50_50,
} as const
