const prefix = 'mt-store-api-checkout-send-email-pass-epic'

// ~~~~~~ Mutators Types

export const ApiStoreCheckoutSendEmailPassEpicMT = {
  OK: `${prefix}-ok`,
  ERROR: `${prefix}-err`,
} as const

// ~~~~~~ Mutators Creators

export const ApiStoreCheckoutSendEmailPassEpicMC = {
  ok: () => ({
    type: ApiStoreCheckoutSendEmailPassEpicMT.OK,
  }),

  error: (errors: DN.Models.Checkout.Api.SendEmail.ResError) => ({
    type: ApiStoreCheckoutSendEmailPassEpicMT.ERROR,
    error: true,
    payload: {
      errors,
    },
  }),
} as const
