import { getThemeValue } from '../utils/theme'
import { Colors } from './colors'

// ~~~~~~

export const Misc = {
  BorderRadius: 'border-radius: 4px;',
  BorderRadiusValue: '4px',

  BorderWidth: '1px',

  PaddingBox: '24px',

  Border: (props: { $hasErrors?: boolean; $focussed?: boolean; $customBorderColor?: ThemeColor }) =>
    `border: solid 2px ${
      props.$hasErrors
        ? getThemeValue(props, Colors.BROOKLYN_80_80)
        : props.$focussed
          ? getThemeValue(props, Colors.WOLF_90_90)
          : getThemeValue(props, props.$customBorderColor || Colors.WOLF_40_40)
    };`,
} as const
