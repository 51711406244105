import styled from 'styled-components'
import { Fonts } from '../../../../style/theme/fonts'
import { Layout } from '../../../../style/theme/layout'
import { MediaQuery } from '../../../../style/constants/media-queries'

import BannerImgBcast from '../../../../imgs/pngs/broadcast-banner.png'
import BannerImgMobileBcast from '../../../../imgs/pngs/broadcast-banner-sm.png'

import BannerImgLauncher from '../../../../imgs/pngs/launcher-banner.png'
import BannerImgMobileLauncher from '../../../../imgs/pngs/launcher-banner-sm.png'

import BannerImgMontage from '../../../../imgs/pngs/montage-banner.png'
import BannerImgMobileMontage from '../../../../imgs/pngs/montage-banner-sm.png'

// ~~~~~~ Constants

export const ClassName = 'user-zone-common-banner-back'

// ~~~~~~ Types

type STProps = {
  $noImage?: true
  $kind: 'bcast' | 'launcher' | 'montage'
}

// ~~~~~~ Component

export const STUserZoneCommonBannerBack = styled.div<STProps>`
  overflow: hidden;

  .${ClassName}--go-back {
    margin-top: 32px;
    margin-bottom: 16px;
  }

  .${ClassName}--a {
    ${Layout.Border.Radius.B}

    background-image: ${({ $noImage: noImage, $kind: kind }) =>
      noImage
        ? 'unset'
        : kind === 'bcast'
          ? `url(${BannerImgBcast})`
          : kind === 'launcher'
            ? `url(${BannerImgLauncher})`
            : `url(${BannerImgMontage})`};

    padding: 16px;
    padding-top: ${({ $noImage: noImage }) => (noImage ? 0 : 16)}px;

    padding-left: ${({ $noImage: noImage }) => (noImage ? 0 : 16)}px;

    display: flex;
    align-items: center;

    .${ClassName}--a--logo {
      display: flex;

      width: 83px;

      margin-right: 8px;
    }

    .${ClassName}--a--text {
      ${Fonts.Weight[600]}
      ${Fonts.Scale.Subtitle}
    }
  }

  .${ClassName}--b {
    display: none;
  }

  /* 1280 */

  @media (max-width: ${MediaQuery.MaxWidth2k}) {
    ${Layout.PaddingWidth.Desktop}

    .${ClassName}--go-back {
      margin-top: 24px;
    }
  }

  /* 960 */

  @media (max-width: ${MediaQuery.MD}) {
    ${Layout.PaddingWidth.Tablet}
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    ${Layout.PaddingWidth.Mobile}

    background-image: ${({ $noImage: noImage, $kind: kind }) =>
      noImage
        ? 'unset'
        : kind === 'bcast'
          ? `url(${BannerImgMobileBcast})`
          : kind === 'launcher'
            ? `url(${BannerImgMobileLauncher})`
            : `url(${BannerImgMobileMontage})`};

    .${ClassName}--go-back {
      margin-top: 24px;
    }

    .${ClassName}--a {
      padding-left: unset;
      max-width: unset;
      margin: unset;
      background: unset;
      border-radius: unset;
    }
  }
`
