const prefix = 'mt-card'

// ~~~~~~ Mutators Types

export const CardMT = {
  SET_TMP_CARD_ID: `${prefix}-set-tmp-card-id`,
} as const

// ~~~~~~ Mutators Creators

export const CardMC = {
  setTmpCardId: (cardId: string) => ({
    type: CardMT.SET_TMP_CARD_ID,
    payload: {
      cardId,
    },
  }),
} as const
