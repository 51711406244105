import { useNavigate } from 'react-router'
import { ClassName, STUserZonePlansRootAddProductBcast } from './style'
import { Routes } from '../../../../../../constants/routes'
import { Trans } from '../../../../../../components/common/intl/trans'
import { LazySVG } from '../../../../../../components/svgs/lazy-svg/component'
import { Button } from '../../../../../../components/common/button/component'

import BcastImageSquare from '../../../../../../imgs/pngs/broadcast-card-md.png'
import BcastImageRectangle from '../../../../../../imgs/pngs/broadcast-card-xl.png'
import { useDispatch } from 'react-redux'
import { BuyLicenseMC } from '../../../../../../store/actions-mutators/buy-license/mutators'

// ~~~~~~ Constants

const Logo = LazySVG('logos/broadcast-gradient')

const ArrowRightIcon = LazySVG('icons/arrow-long-right')

// ~~~~~~ Component

export const UserZonePlansRootAddProductBcast = () => {
  // ~~~~~~ Hooks

  const navigate = useNavigate()

  const dispatch = useDispatch()

  // ~~~~~~ Handlers

  function onClickAction() {
    dispatch(BuyLicenseMC.clearData())

    navigate(Routes.UserZonePlansBcast)
  }

  // ~~~~~~ Render

  return (
    <STUserZonePlansRootAddProductBcast>
      <div className={`${ClassName}--left`}>
        <img
          className={`${ClassName}--left--img-square`}
          src={BcastImageSquare}
          alt="broadcast square"
        />

        <img
          className={`${ClassName}--left--img-rectangle`}
          src={BcastImageRectangle}
          alt="broadcast rectangle"
        />
      </div>

      <div className={`${ClassName}--right`}>
        <div className={`${ClassName}--right--logo`}>
          <Logo size={24} />
        </div>

        <div className={`${ClassName}--right--title`}>
          <Trans id="pages.main.cards.broadcast.Title" />
        </div>

        <div className={`${ClassName}--right--subtitle`}>
          <Trans id="pages.main.cards.broadcast.Description" />
        </div>

        <div className={`${ClassName}--right--action`}>
          <Button
            colorType="only-link"
            intlId="pages.common.AddProduct"
            intlValues={{ value: 'Broadcast' }}
            padding="0"
            width="unset"
            IconRight={ArrowRightIcon}
            disabled={false}
            running={false}
            active={true}
            onClick={onClickAction}
          />
        </div>
      </div>
    </STUserZonePlansRootAddProductBcast>
  )
}
