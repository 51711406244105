const prefix = 'mt-modals'

// ~~~~~~ Mutators Types

export const ModalsMT = {
  OPEN_MODAL: `${prefix}-open-modal`,
  CLOSE_MODAL: `${prefix}-close-modal`,
} as const

// ~~~~~~ Mutators Creators

export const ModalsMC = {
  open: (modalId: string, fun?: DN.Models.Modals.ModalFun) => ({
    type: ModalsMT.OPEN_MODAL,
    payload: {
      modalId,
      fun,
    },
  }),

  close: (modalId: string) => ({
    type: ModalsMT.CLOSE_MODAL,
    payload: {
      modalId,
    },
  }),
} as const
