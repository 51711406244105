import React from 'react'
import { AllDialogContext } from '../context/dialog-context'

// ~~~~~~ Hook

export const useAllDialogContext = () => {
  const context = React.useContext(AllDialogContext)

  if (!context) {
    throw new Error(`Dialog compound components cannot be rendered outside the Dialog component`)
  }

  return context
}
