import Skeleton from 'react-loading-skeleton'
import { HooksData } from '@dn/hooks'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useDispatch, useSelector } from 'react-redux'

import { LazySVG } from '../../../../../components/svgs/lazy-svg/component'
import { ClassName, STPageTrialsOrFreeMontageContentStep4Ready } from './style'
import { StoreState } from '../../../../../models/app/model'
import { Trans } from '../../../../../components/common/intl/trans'
import { Config } from '../../../../../config'
import { LinkCard } from '../../../../../components/common/link-card/component'
import { useCustomAuth } from '../../../../../hooks/auth/use-custom-auth'
import { Routes } from '../../../../../constants/routes'
import { DangerTrans } from '../../../../../components/common/intl/danger-trans'
import { ProductButtonMontage } from '../../../../../components/common/product-button/montage/component'
import { ApiStoreCheckoutAC } from '../../../../../store/actions/api-store/checkout/actions'
import { PageTrialsOrFreeMontageTabSteps } from '../../tab-steps/component'

// ~~~~~~ Constants

const Logo = LazySVG('logos/montage-gradient')

const TitleIcon = LazySVG('icons/party')

const InActionIcon = LazySVG('icons/videos')

const GetConnected = LazySVG('icons/connect-device')

const SupportIcon = LazySVG('icons/support-call')

const ArrowDownIcon = LazySVG('icons/arrow-long-down')

// ~~~~~~ Component

export const PageTrialsOrFreeMontageContentStep4Ready = () => {
  // ~~~~~~ Hooks

  const { login } = useCustomAuth()

  const navigate = useNavigate()

  const disatch = useDispatch()

  // ~~~~~~ State

  // - Local

  const [apiHasBeenCalled, setApiHasBeenCalled] = useState(false)

  // - Store

  const buyLicense = useSelector((state: StoreState) => state.buyLicense)

  const currentUserEmail = useSelector((state: StoreState) => state.currentUser.email)

  // ~~~~~~ Computed

  const isRunning = !apiHasBeenCalled || buyLicense.uiStatus === 'running'

  const prevCurUserEmail = HooksData.PrevValue.useHook(currentUserEmail)

  // ~~~~~~ Handlers

  function onClickLogIn() {
    login()
  }

  function onClickAction() {
    buyLicense.platform === 'android'
      ? window.open(Config.Links.MontageDownloadAndroid)
      : window.open(Config.Links.MontageDownloadWindows)
  }

  function onClickShowInAction() {
    window.open(Config.Links.MontageInAction, '_blank')
  }

  function onClickGetConnected() {
    window.open(Config.Links.MontageConnectDevice, '_blank')
  }

  function onClickSupport() {
    window.open(Config.Links.MontageSupport, '_blank')
  }

  // ~~~~~~ Effects

  // - On mount create free trieal

  useEffect(() => {
    disatch(
      ApiStoreCheckoutAC.startFreeSubOrTrial('montage', {
        email: buyLicense.email,
        price_of_license_of_product_id: buyLicense.subId,
        code: buyLicense.code,
      }),
    )

    setApiHasBeenCalled(true)

    //
  }, [buyLicense.code, buyLicense.email, buyLicense.platform, buyLicense.subId, disatch])

  // - From not-logged to logged: Redirect to user-zone

  useEffect(() => {
    if (prevCurUserEmail || !currentUserEmail) return

    navigate(Routes.UserZone)

    //
  }, [currentUserEmail, navigate, prevCurUserEmail])

  // ~~~~~~ Render

  return (
    <STPageTrialsOrFreeMontageContentStep4Ready>
      {/* Steps */}

      <div className={`${ClassName}--steps`}>
        <PageTrialsOrFreeMontageTabSteps kind="trial" />
      </div>

      {/* Logo */}

      <div className={`${ClassName}--logo`}>
        <Logo size={32} />
      </div>

      {/* Title */}

      <div className={`${ClassName}--title`}>
        {isRunning ? (
          <Skeleton />
        ) : (
          <>
            <Trans
              id="pages.purchase-flow.account-ready-step.trialStarted"
              values={{ value: buyLicense.trialPeriodDays || '??' }}
            />

            <span className={`${ClassName}--title--icon`}>
              <TitleIcon size={56} />
            </span>
          </>
        )}
      </div>

      {/* Subtitle */}

      <div className={`${ClassName}--subtitle`}>
        {isRunning ? (
          <Skeleton />
        ) : (
          <DangerTrans
            template="... on the way to <c>{value}</c>. Be sure to <d>check...</d> ... or <e>log in</e>..."
            id="v2.pages.purchase-flow.account-ready-step.SendLicense"
            values={{
              value: buyLicense.email,

              c: (chunks: string) => (
                <span className={`${ClassName}--subtitle--acent`}>{chunks}</span>
              ),

              d: (chunks: string) => (
                <span className={`${ClassName}--subtitle--acent`}>{chunks}</span>
              ),

              e: (chunks: string) => (
                <span className={`${ClassName}--subtitle--login`} onClick={onClickLogIn}>
                  {chunks}
                </span>
              ),
            }}
          />
        )}
      </div>

      {/* Action */}

      <div className={`${ClassName}--action`}>
        {isRunning ? (
          <Skeleton />
        ) : (
          <ProductButtonMontage
            intlId="pages.purchase-flow.account-ready-step.DownloadMontage"
            IconRight={ArrowDownIcon}
            running={false}
            disabled={false}
            active={true}
            onClick={onClickAction}
          />
        )}
      </div>

      {/* Tip (only windows) */}

      {buyLicense.platform === 'windows' ? (
        <div className={`${ClassName}--tip`}>
          {isRunning ? (
            <Skeleton />
          ) : (
            <DangerTrans
              template="... <c>Windows</c>..."
              id="pages.purchase-flow.account-ready-step.tip-product.Montage"
              values={{
                c: (chunks: string) => <b>{chunks}</b>,
              }}
            />
          )}
        </div>
      ) : undefined}

      {/* Separator */}

      <hr />

      {/* Links */}

      <div className={`${ClassName}--links`}>
        <div className={`${ClassName}--links--title`}>
          <Trans id="pages.purchase-flow.account-ready-step.OtherLinksUseFul" />
        </div>

        {/* Montage in Action */}

        <div className={`${ClassName}--links--link`}>
          {isRunning ? (
            <Skeleton />
          ) : (
            <LinkCard
              Icon={InActionIcon}
              intlId="pages.common.ProductInAction"
              intlValues={{ value: 'Montage' }}
              onClick={onClickShowInAction}
            />
          )}
        </div>

        {/* Get Connected */}

        <div className={`${ClassName}--links--link`}>
          {isRunning ? (
            <Skeleton />
          ) : (
            <LinkCard
              Icon={GetConnected}
              intlId="pages.purchase-flow.account-ready-step.GetConnectOnDevice"
              onClick={onClickGetConnected}
            />
          )}
        </div>

        {/* Support Center */}

        <div className={`${ClassName}--links--link`}>
          {isRunning ? (
            <Skeleton />
          ) : (
            <LinkCard
              Icon={SupportIcon}
              intlId="pages.purchase-flow.account-ready-step.CheckSupport"
              onClick={onClickSupport}
            />
          )}
        </div>
      </div>

      {/* - */}
    </STPageTrialsOrFreeMontageContentStep4Ready>
  )
}
