import { AppInitState } from '../../../models/app/model'
import { addElementToList, dynReducer, updateElementInList } from '../../../utils/store'
import { CardMC, CardMT } from '../../actions-mutators/card/mutators'
import { ApiStoreCardAC, ApiStoreCardAT } from '../../actions/api-store/card/actions'
import { ApiStoreCheckoutAT } from '../../actions/api-store/checkout/actions'
import {
  EpicApiStoreCardAddMC,
  EpicApiStoreCardAddMT,
} from '../../epics/api-store/card/add/mutators'
import {
  EpicApiStoreCardChangeMC,
  EpicApiStoreCardChangeMT,
} from '../../epics/api-store/card/change/mutators'
import {
  EpicApiStoreCardRemMC,
  EpicApiStoreCardRemMT,
} from '../../epics/api-store/card/rem/mutators'
import {
  ApiStoreCheckoutSetDefaultCardEpicMC,
  ApiStoreCheckoutSetDefaultCardEpicMT,
} from '../../epics/api-store/checkout/set-default-card/mutators'
import {
  EpicApiStoreCurrentUserShowMC,
  EpicApiStoreCurrentUserShowMT,
} from '../../epics/api-store/current-user/show/mutators'

const initState = AppInitState.cards

type State = typeof initState

const reducer: any = {}

// ####################################################################################################
// ~~~~~~ Current User Show - OK
// ####################################################################################################

reducer[EpicApiStoreCurrentUserShowMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiStoreCurrentUserShowMC.ok>,
): State => {
  const newList: State['list'] = payload.cards.map((card) => ({
    ...card,
    uiStatus: 'init',
    errors: [],
  }))

  return {
    ...state,
    list: newList,
  }
}

// ####################################################################################################
// ~~~~~~ Set tmp card id
// ####################################################################################################

reducer[CardMT.SET_TMP_CARD_ID] = (
  state: State,
  { payload }: ReturnType<typeof CardMC.setTmpCardId>,
): State => {
  return {
    ...state,
    tmpCardId: payload.cardId,
  }
}

// ####################################################################################################
// ~~~~~~ Remove card - START
// ####################################################################################################

reducer[ApiStoreCardAT.REMOVE] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreCardAC.remove>,
): State => {
  const newList = updateElementInList(
    state.list,
    {
      uiStatus: 'running',
      errors: [],
    },
    (card) => card.payment_method_id === payload.cardId,
  )

  return {
    ...state,
    list: newList,
  }
}

// ####################################################################################################
// ~~~~~~ Remove card - OK
// ####################################################################################################

reducer[EpicApiStoreCardRemMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiStoreCardRemMC.ok>,
): State => {
  const newList = state.list.filter((card) => card.payment_method_id !== payload.id)

  return {
    ...state,
    list: newList,
  }
}

// ####################################################################################################
// ~~~~~~ Remove card - ERROR
// ####################################################################################################

reducer[EpicApiStoreCardRemMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiStoreCardRemMC.error>,
): State => {
  const newList = updateElementInList(
    state.list,
    {
      uiStatus: 'init',
      errors: payload.errors,
    },
    (card) => card.payment_method_id === payload.cardId,
  )

  return {
    ...state,
    list: newList,
  }
}

// ####################################################################################################
// ~~~~~~ Add card - START
// ####################################################################################################

reducer[ApiStoreCardAT.ADD] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreCardAC.add>,
): State => {
  return {
    ...state,
    uiStatusAdd: 'running',
    errorsAdd: [],
  }
}

// ####################################################################################################
// ~~~~~~ Add card - OK
// ####################################################################################################

reducer[EpicApiStoreCardAddMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiStoreCardAddMC.ok>,
): State => {
  const newList = addElementToList(state.list, {
    ...payload.data.card,
    uiStatus: 'init',
    errors: [],
  })

  return {
    ...state,
    uiStatusAdd: 'init',
    errorsAdd: [],
    list: newList,

    tmpCardId: payload.data.card.payment_method_id,
  }
}

// ####################################################################################################
// ~~~~~~ Add card - ERROR
// ####################################################################################################

reducer[EpicApiStoreCardAddMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiStoreCardAddMC.error>,
): State => {
  return {
    ...state,
    uiStatusAdd: 'init',
    errorsAdd: payload.errors,
  }
}

// ####################################################################################################
// ~~~~~~ Change card - START
// ####################################################################################################

reducer[ApiStoreCardAT.CHANGE] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreCardAC.change>,
): State => {
  const newList = updateElementInList(
    state.list,
    {
      uiStatus: 'running',
      errors: [],
    },
    (card) => card.payment_method_id === payload.cardId,
  )

  return {
    ...state,
    list: newList,
  }
}

// ####################################################################################################
// ~~~~~~ Change card - OK
// ####################################################################################################

reducer[EpicApiStoreCardChangeMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiStoreCardChangeMC.ok>,
): State => {
  const newList = updateElementInList(
    state.list,
    {
      ...payload.data.card,
      uiStatus: 'init',
      errors: [],
    },
    (card) => card.payment_method_id === payload.data.sourceId,
  )

  return {
    ...state,
    list: newList,
  }
}

// ####################################################################################################
// ~~~~~~ Change card - ERROR
// ####################################################################################################

reducer[EpicApiStoreCardChangeMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof EpicApiStoreCardChangeMC.error>,
): State => {
  const newList = updateElementInList(
    state.list,
    {
      uiStatus: 'init',
      errors: payload.errors,
    },
    (card) => card.payment_method_id === payload.cardId,
  )

  return {
    ...state,
    list: newList,
  }
}

// ####################################################################################################
// ~~~~~~ Change payment method (Default)
// ####################################################################################################

reducer[ApiStoreCheckoutAT.SET_DEFAULT_CARD] = (state: State): State => {
  return {
    ...state,
    uiStatusChange: 'running',
  }
}

// ####################################################################################################
// ~~~~~~ Change payment method (Default) - OK
// ####################################################################################################

reducer[ApiStoreCheckoutSetDefaultCardEpicMT.OK] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreCheckoutSetDefaultCardEpicMC.ok>,
): State => {
  const listWithoutDefautCard = state.list.map((card) => ({
    ...card,
    is_default: false,
  }))

  const newList = updateElementInList(
    listWithoutDefautCard,
    {
      is_default: true,
    },
    (card) => card.payment_method_id === payload.data.paymentMethodId,
  )

  return {
    ...state,
    uiStatusChange: 'init',
    list: newList,
  }
}

// ####################################################################################################
// ~~~~~~ Change payment method (Default) - ERROR
// ####################################################################################################

reducer[ApiStoreCheckoutSetDefaultCardEpicMT.ERROR] = (
  state: State,
  { payload }: ReturnType<typeof ApiStoreCheckoutSetDefaultCardEpicMC.error>,
): State => {
  return {
    ...state,
    uiStatusChange: 'init',
  }
}

// ~~~~~~

export const cardsReducer = dynReducer(initState, reducer)
