import { useEffect } from 'react'
import { useNavigate, useParams } from 'react-router'
import { Routes } from '../../../../../constants/routes'

import { useSelector } from 'react-redux'
import { StoreState } from '../../../../../models/app/model'

// ~~~~~~ Component

export const UserZonePlansPurchaseStripeSuccessPurchaseMontage = () => {
  // ~~~~~~ Hooks

  const stripeSessionId = useParams<{ stripeSessionId: string }>().stripeSessionId

  const navigate = useNavigate()

  // ~~~~~~ State

  const currentUser = useSelector((state: StoreState) => state.currentUser)

  const subs = useSelector((state: StoreState) => state.subscriptions)

  // ~~~~~~ Computed

  const isLoading = !currentUser.isSessionChecked || currentUser.uiStatus === 'running'

  // ~~~~~~ Effects

  // - Return to plans if now stripe session id

  useEffect(() => {
    if (stripeSessionId) return

    navigate(Routes.UserZonePlans)

    //
  }, [navigate, stripeSessionId])

  // TODO(Cuenca): Maybe backend can modify ApiStoreCheckoutAC.showSession({ session_id: stripeSessionId })
  //               to return sub_id
  // - Get last created bcast sub and redirect to details

  useEffect(() => {
    if (isLoading) return

    const lastSub = subs.sort((a, b) => {
      const aDate = new Date(a.created_at)
      const bDate = new Date(b.created_at)

      return aDate === bDate ? 0 : aDate > bDate ? -1 : 1
    })[0]

    if (!lastSub || lastSub.product_id !== 'montage') {
      navigate(Routes.UserZonePlans)
      return
    }

    const route = `${Routes.genUserZonePlansMontageDetail(lastSub.subscription_id)}?new=true`

    navigate(route)

    //
  }, [isLoading, navigate, subs])

  // ~~~~~~ Render

  return null
}
