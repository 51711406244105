import Skeleton from 'react-loading-skeleton'
import { HooksData } from '@dn/hooks'
import { useEffect, useState } from 'react'
import { useNavigate } from 'react-router'
import { useSelector } from 'react-redux'

import { LazySVG } from '../../../../../components/svgs/lazy-svg/component'
import { PageTrialsOrFreeCommonTabSteps } from '../../../@-common/tab-steps/component'
import { ClassName, STPageTrialsOrFreeBcastContentStep3Ready } from './style'
import { StoreState } from '../../../../../models/app/model'
import { Trans } from '../../../../../components/common/intl/trans'
import { ProductButtonBcast } from '../../../../../components/common/product-button/bcast/component'
import { Config } from '../../../../../config'
import { LinkCard } from '../../../../../components/common/link-card/component'
import { useCustomAuth } from '../../../../../hooks/auth/use-custom-auth'
import { Routes } from '../../../../../constants/routes'
import { useDispatch } from 'react-redux'
import { ApiStoreCheckoutAC } from '../../../../../store/actions/api-store/checkout/actions'

// ~~~~~~ Constants

const Logo = LazySVG('logos/broadcast-gradient')

const TitleIcon = LazySVG('icons/party')

const InActionIcon = LazySVG('icons/videos')

const ManageAccountIcon = LazySVG('icons/user-circle-filled')

const SupportIcon = LazySVG('icons/support-call')

// ~~~~~~ Component

export const PageTrialsOrFreeBcastContentStep3Ready = () => {
  // ~~~~~~ Hooks

  const { login } = useCustomAuth()

  const navigate = useNavigate()

  const disatch = useDispatch()

  // ~~~~~~ State

  // - Local

  const [apiHasBeenCalled, setApiHasBeenCalled] = useState(false)

  // - Store

  const buyLicense = useSelector((state: StoreState) => state.buyLicense)

  const currentUserEmail = useSelector((state: StoreState) => state.currentUser.email)

  // ~~~~~~ Computed

  const isRunning = !apiHasBeenCalled || buyLicense.uiStatus === 'running'

  const prevCurUserEmail = HooksData.PrevValue.useHook(currentUserEmail)

  // ~~~~~~ Handlers

  function onClickAction() {
    window.open(Config.BroadcastWeb.Url, '_blank')
  }

  function onClickShowInAction() {
    window.open(Config.Links.BroadcastInAction, '_blank')
  }

  function onClickManageAccount() {
    login()
  }

  function onClickSupport() {
    window.open(Config.Links.BroadcastSupport, '_blank')
  }

  // ~~~~~~ Effects

  // - On mount create free sub

  useEffect(() => {
    disatch(
      ApiStoreCheckoutAC.startFreeSubOrTrial('broadcast-subscription', {
        email: buyLicense.email,
        price_of_license_of_product_id: buyLicense.subId,
        code: buyLicense.code,
      }),
    )

    setApiHasBeenCalled(true)

    //
  }, [buyLicense.code, buyLicense.email, buyLicense.subId, disatch])

  // - From not-logged to logged: Redirect to user-zone

  useEffect(() => {
    if (prevCurUserEmail || !currentUserEmail) return

    navigate(Routes.UserZone)

    //
  }, [currentUserEmail, navigate, prevCurUserEmail])

  // ~~~~~~ Render

  return (
    <STPageTrialsOrFreeBcastContentStep3Ready>
      {/* Steps */}

      <div className={`${ClassName}--steps`}>
        <PageTrialsOrFreeCommonTabSteps kind="account" />
      </div>

      {/* Logo */}

      <div className={`${ClassName}--logo`}>
        <Logo size={32} />
      </div>

      {/* Title */}

      <div className={`${ClassName}--title`}>
        {isRunning ? (
          <Skeleton />
        ) : (
          <>
            <Trans id="pages.purchase-flow.account-ready-step.AccountCreated" />

            <span className={`${ClassName}--title--icon`}>
              <TitleIcon size={56} />
            </span>
          </>
        )}
      </div>

      {/* Subtitle */}

      <div className={`${ClassName}--subtitle`}>
        {isRunning ? (
          <Skeleton />
        ) : (
          <Trans id="pages.purchase-flow.account-ready-step.ReadyToStartBroadcastingFreeFullVersion" />
        )}
      </div>

      {/* Action */}

      <div className={`${ClassName}--action`}>
        {isRunning ? (
          <Skeleton />
        ) : (
          <ProductButtonBcast
            intlId="common.StartBroadcasting"
            running={false}
            disabled={false}
            active={true}
            onClick={onClickAction}
          />
        )}
      </div>

      {/* Separator */}

      <hr />

      {/* Links */}

      <div className={`${ClassName}--links`}>
        <div className={`${ClassName}--links--title`}>
          <Trans id="pages.purchase-flow.account-ready-step.OtherLinksUseFul" />
        </div>

        {/* Bcast in Action */}

        <div className={`${ClassName}--links--link`}>
          {isRunning ? (
            <Skeleton />
          ) : (
            <LinkCard
              Icon={InActionIcon}
              intlId="pages.common.ProductInAction"
              intlValues={{ value: 'Broadcast' }}
              onClick={onClickShowInAction}
            />
          )}
        </div>

        {/* Manage Account */}

        <div className={`${ClassName}--links--link`}>
          {isRunning ? (
            <Skeleton />
          ) : (
            <LinkCard
              Icon={ManageAccountIcon}
              intlId="pages.purchase-flow.account-ready-step.ManageAccount"
              onClick={onClickManageAccount}
            />
          )}
        </div>

        {/* Support Center */}

        <div className={`${ClassName}--links--link`}>
          {isRunning ? (
            <Skeleton />
          ) : (
            <LinkCard
              Icon={SupportIcon}
              intlId="pages.purchase-flow.account-ready-step.CheckSupport"
              onClick={onClickSupport}
            />
          )}
        </div>
      </div>

      {/* - */}
    </STPageTrialsOrFreeBcastContentStep3Ready>
  )
}
