import styled from 'styled-components'
import { MediaQuery } from '../../../../../style/constants/media-queries'
import { Fonts } from '../../../../../style/theme/fonts'

// ~~~~~~ Constants

export const ClassName = 'page-purchase-launcher-step-3'

// ~~~~~~ Component

export const STPagePurchaseLauncherStep3Payment = styled.div`
  max-width: 622px;
  margin-left: auto;
  margin-right: auto;

  margin-top: 32px;

  /* Steps */

  .${ClassName}--steps {
    margin-bottom: 56px;
  }

  /* Logo */

  .${ClassName}--logo {
    width: 98px;

    margin-bottom: 8px;
  }

  /* Title */

  .${ClassName}--title {
    ${Fonts.Weight[800]}
    ${Fonts.Scale.OldA}

    margin-bottom: 16px;
  }

  /* Order */

  .${ClassName}--order {
    margin-bottom: 32px;
  }

  /* Action */

  .${ClassName}--action {
    margin-bottom: 24px;
  }

  /* 1280 */

  @media (max-width: ${MediaQuery.MaxWidth2k}) {
    padding-left: 120px;
    padding-right: 120px;

    /* Title */

    .${ClassName}--title {
      ${Fonts.Scale.OldB}
    }
  }

  /* 960 */

  @media (max-width: ${MediaQuery.MD}) {
    padding-left: 40px;
    padding-right: 40px;

    /* Steps */

    .${ClassName}--steps {
      margin-bottom: 50px;
    }

    /* Logo */

    .${ClassName}--logo {
      width: 86px;
    }

    /* Title */

    .${ClassName}--title {
      ${Fonts.Scale.Headline1}
    }

    /* Action */

    .${ClassName}--action {
      margin-bottom: 24px;
    }
  }

  /* Mobile */

  @media (max-width: ${MediaQuery.SM}) {
    padding-left: 16px;
    padding-right: 16px;

    /* Steps */

    .${ClassName}--steps {
      margin-bottom: 32px;
    }

    /* Title */

    .${ClassName}--title {
      ${Fonts.Scale.Headline2}
    }

    /* Order */

    .${ClassName}--order {
      margin-bottom: 24px;
    }

    /* Action */

    .${ClassName}--action {
      margin-bottom: 16px;
    }
  }
`
