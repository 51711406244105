import React from 'react'
import { STProductButtonMontage } from './style'
import { ButtonProps } from '../../button/component'
import { LazySVG } from '../../../svgs/lazy-svg/component'

// ~~~~~~ Constants

const ArrowRightIcon = LazySVG('icons/arrow-long-right')

// ~~~~~~ Types

type Props = Omit<ButtonProps, 'colorType'>

// ~~~~~~ Component

export const ProductButtonMontage: React.FC<Props> = (props) => {
  // ~~~~~~ Render

  return <STProductButtonMontage colorType="default" IconRight={ArrowRightIcon} {...props} />
}
